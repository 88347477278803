import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { VictoryLabel, VictoryPie } from "victory";
import DonutChart from "react-donut-chart";


const MainContainer = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #fff;
`;

const DataCategoryContainer = styled.div`
//   padding-left: 80px;
  display: flex;
  flex-direction: column;

  padding-top: 30px;

  height: 80%;
`;

const GenderDesc = styled.div`
  display: flex;
  /* align-items: center; */

  margin-bottom: 10px;
  font-size: 12px;
`;

const GenderColor = styled.div`
  background-color: ${(props) => props.bgColor && props.bgColor};
  width: 30px;
  height: 10px;
  border-radius: 10px;
  margin-top: 5px;
`;

const GenderTitle = styled.div`
  margin-left: 10px;
`;



const color = [

  "#83f2d9",

  "#91a9ab",

  "#FFF7C3",

  "#20A7E3",

  "#c999f0",

  "#000000",

 
];

function GdByRacePieChart(props) {
  const gdByRacePieData = props.gdPiaChart;
  const getgdchart = props.gdchart;

  // console.log("getgdchart ");
  // console.log(gdByRacePieData);

  return (
    <MainContainer>
      {/* <VictoryPie
        width={330}
        colorScale={color}
        style={{
          parent: {
            width: "300px",
            height: "90%",
          },
          labels: { fill: "white" },
        }}
        innerRadius={100}
        // labelRadius={120}
        // labels={({ datum }) => `# ${datum.y}`}
        //   labelComponent={<CustomLabel />}

        data={getgdchart}
        labelComponent={
          <VictoryLabel
            
            backgroundPadding={{ left: 10, right: 15 }}
            style={{
              marginLeft: '2px',
              fontSize: 22,
              lineHeight: "10px",
              fontFamily: "Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif",
            }}
          />
        }
      />{" "} */}

<DonutChart
                  width={450}
                  height={330}
                  // data={[
                  //   {
                  //     label: 'American Indian',
                  //     value: 25,
                  //   },
                  //   {
                  //     label: 'Asian',
                  //     value: 10,
                  //   },
                  //   {
                  //     label: 'Black',
                  //     value: 175,
                  //   },
                  //   {
                  //     label: 'White',
                  //     value: 275,
                  //   }
                  //   ,
                  //   {
                  //     label: 'native Hawaiian',
                  //     value: 5,
                  //   },
                  //   {
                  //     label: 'Blank',
                  //     value: 5,
                  //   },
                  // ]}
                  data={getgdchart}
                  
                  colors={["#B3DAD1", "#92BBDA","#89BC3B","#59BEE9","red","pink","yellow"]}
                  style={{
                    parent: {
                      marginTop:"30px",
                      width: "100%",
                      height: "300px",
                      fontSize: "15px ",
                      fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif  ",

                    },
                  }}
                />;


      {/* <DataCategoryContainer>
        {" "}
        {gdByRacePieData.map((data, index) => {
          return (
         
              <div className="row  chartDesc">
                <div className="col-2">
                <GenderColor bgColor={data.color}></GenderColor>
                </div>
                <div className="col-10">
                <GenderTitle>{data.country}</GenderTitle>
                </div>
              </div>

         
          );
        })}{" "} */}
      {/* </DataCategoryContainer>{" "} */}
    </MainContainer>
  );
}

export default GdByRacePieChart;
