import React from 'react'
import {Prompt, NavLink} from 'react-router-dom'
import Modal from './Modals/Modal/index';

export class ConfirmBeforeLeave extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }

 componentDidMount(){
      this.props.onRef(this)
    /*  window.addEventListener("beforeunload", function (event) {
            console.log("hellooww 3")
            event.preventDefault();
            event.returnValue = "Hellooww 2"
        })*/
 }

  componentWillUnmount() {
        /*window.removeEventListener("beforeunload", function (event) {
            console.log("hellooww 2")
            event.returnValue = "Hellooww 2"
        })*/
    }

 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = () => {
  console.log('closing Modal');
  this.setState({
   modalVisible: false
 })
}
 handleBlockedNavigation = (nextLocation) => {
   const {confirmedNavigation} = this.state
   //const {shouldBlockNavigation} = this.props
   if (this.props.unSaved/* && shouldBlockNavigation(nextLocation)*/){
       this.showModal(nextLocation)
       return false
   }
   
   return true
 }

 handleNotConfirmNavigationClick = () => {
   const {lastLocation} = this.state;
   if (lastLocation) {
   this.redirectToPage();
 }
 }

 handleConfirmNavigationClick = () => {
   //const {navigate} = this.props
   const {lastLocation} = this.state
   if (lastLocation) {
      this.setState({
         modalVisible: false
      });

      //save the information and move on
      this.props.onSaveRequest();
   }
 }

 redirectToPage = () => {
  let location_path = this.state.lastLocation.pathname;
  const query = location_path.split("/id=");
  let client_id = 0;
  location_path = query[0].trim();
  if(query[1] !== undefined && query[1] !== null && query[1] !== ''){
    client_id = query[1].trim();
  }
  

  console.log('location_path', location_path, query, client_id)
  switch(location_path){
    case '/AdminRequests':
    sessionStorage.setItem('client_search_filter', 'requested_status');
    location_path = "/clients";
    break;

    case '/clients':
     sessionStorage.removeItem('client_search_filter');
    break;

    case '/basicinfo':
      sessionStorage.setItem('clientId', client_id);
      sessionStorage.setItem('viewMode', 'false');
      location_path = '/intake-basicinfo';
    break;



  }
  sessionStorage.removeItem('changes_unsaved')
  window.location.href = location_path
 }
 render() {
   const {when} = this.props
   const {modalVisible, lastLocation, closeModal} = this.state
   if(this.props.unSaved){
    sessionStorage.setItem('changes_unsaved', true)
   }else{
    sessionStorage.removeItem('changes_unsaved')
   }
   return (
     <>

          {!this.props.unSaved && lastLocation !== null ? this.redirectToPage() : <Prompt
           when={this.props.unSaved}
           message={this.handleBlockedNavigation} />}
        

           <Modal 
                showOverlay={true}
                show = {modalVisible}
                onClose = {closeModal}
                disableOverlayClick = {true}
                showCloseButton = {false}
                size = "sm"
                className="modal-md modal-dialog"
                
                >
                <Modal.Header style={{ borderTopLeftRadius : "10px" , borderTopRightRadius : "10px"}}>
                    <Modal.Title>
                        <div className="row" >
                            <div className="col-md-12">
                                <h4 className="modal-title float-left text-green">Leave this page</h4>
                            </div>
                            
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight:'auto', textAlign:'center'}}>
                    <h4>Do you want to save the changes?</h4>
                </Modal.Body>
               <Modal.Footer style={{ borderBottomLeftRadius : "10px" , borderBottomRightRadius : "10px" }}>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="justify-content-between" >
                                <button className="btn btn-default float-left" onClick={this.handleNotConfirmNavigationClick} type="button">No</button>                            
                            </div>
                        </div>
                        <div className="col-md-6" > 
                                <button id="Open"  className="btn btn-success float-right btnmargin" onClick={this.handleConfirmNavigationClick}> <i className="fas fa-save"></i> Yes</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
     </>
   )
 }
}
export default ConfirmBeforeLeave