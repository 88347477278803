import React , {Component} from 'react';

import $ from 'jquery';	
import { Link} from 'react-router-dom'
import '../global.js';
import {customURLGen} from '../ReusableComponents/urlGenerator' ;

const axios = require('axios').default;

class Forgotpass extends Component{
	constructor () {
    super();
    this.state = {
      
      screen:'index'
    };
    this.home = this.home.bind(this);
  }
  home(){
  	this.setState({
  		screen : 'index'
  	})
  }

  componentDidMount() {
  	
    $.validator.setDefaults({
	    submitHandler: function () {

			var data = new FormData();
			data.append('forgot_pwd_email', $('#email').val());
			// data.append('url', global.domain+'setnewpass/');
          	axios.post( customURLGen("recoverPassword") , data)
          	.then(function (res) { 
          	    console.log(res);
			//var responce = res.data;
			$("#message").show();
			
			//console.log(responce.message);
			//console.log(responce.data.apiKey);
			//global.tocken = responce.data.apiKey;
          	//alert(global.tocken);
			//window.location.href="/";
  
            })
            .catch(function (err) {
			  //alert(err.message);
			  console.log(err)
            });
	    }
	  });
    
  }
	render(){
		
		$(document).ready(function () {$(document).ready(function(){

		$("#message").hide();
			/*$("#request").click(function(){

				$("#message").show();
				
			});*/
		});	
  		$('#forgotpassform').validate({
	    rules: {
	      email: {
	        required: true,
	        maxlength: 100
	      }
	    },
	    
	    errorElement: 'span',
	    errorPlacement: function (error, element) {
	      error.addClass('invalid-feedback');
	      element.closest('.input-group').append(error);
	    },
	    highlight: function (element, errorClass, validClass) {
	      $(element).addClass('is-invalid');
	    },
	    unhighlight: function (element, errorClass, validClass) {
	      $(element).removeClass('is-invalid');
	    }
	  });
  		});
		return <div className='login-page'>
		<div className="login-box">
  <div className="login-logo">
   <a href="#">
              <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
            </a><br/> <br/>
    <h5>Data Collection and Reporting Information System</h5> for Opioid Peer Support Specialists
  </div>
  
  <div className="card">
    <div className="card-body login-card-body">
      <p className="login-box-msg">Please enter your email below to retrieve your password</p>

      <form action="javascript:void(0);" method="post" id="forgotpassform">
        <div className="input-group mb-3">
          <input type="email" className="form-control" placeholder="Email" name="email" id="email" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="submit" className="btn btn-primary btn-block" id="request">Request new password</button>

            <div className="icheck-primary" style={{textAlign:'center'}}> <Link to="/" >Login</Link> </div>

			<div id="message">Password reset instructions have been emailed to you, please follow the instructions in your email.</div>

          </div>
          
        </div>
      </form>
    </div>
    
  </div>
</div>
		</div>
	}
}
export default Forgotpass
