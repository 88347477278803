import React from 'react' ;
import ReactTooltip from 'react-tooltip'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Tooltip = ({ name, text, visibiity}) => {
	//console.log('text', text, visibiity);
	return (
	
    <span style={{paddingLeft:"5px"}}>
        <FontAwesomeIcon icon={faInfoCircle} data-tip='' data-for={name} style={{display: visibiity }} />
        <ReactTooltip place="right" type="dark" effect="solid" id={name}>
		  {text}
		</ReactTooltip>
    </span>
    
)};

export default Tooltip ;