import React, { useState, useEffect } from "react";

import styled from "styled-components";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import CoverFilter from "./CoverFilter";

//import { sampleData } from "./ClientsStackBar";
const MainContainer = styled.div`
  margin-top: 16px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #cbcbcb;
  box-sizing:border-box;
`;

const TableContainer = styled.div`
  /* overflow: auto; */
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;

`;

const Table = styled.table`
  border: none;
  width: 100%;
  border-spacing: 0;
  /* overflow: auto; */
  th,
  td {
    white-space: wrap;
    font-style: normal;
  }
  th {
    background-color: #e9e9e9;
  }

`;

const TableHead = styled.thead``;

const TableHeadRow = styled.tr`
  border-bottom: 1px solid #ececec;
  height:70px;
`;

const TableHeadRowColumnVal = styled.th`
  border: none;

  border-left: 1px solid #bbbbbb;
  min-width: 90px;
  text-align: center;
`;

const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #fdfcf5;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td {
    border-left: 1px solid #bbbbbb;
    border-right: none;
    border-bottom: none;
    border-top: none;

    height: 50px;
    text-align: center;
  }
  border-bottom: 1px solid #bbbbbb;
  border-top: none;
`;

const TableHeadTh = styled.th`
  display: flex;

  min-width: 150px;
  height: 80px;
  align-items: center;
  justify-content: center;

  border-left: 1px solid #bbbbbb;
  background-color: #e9e9e9;
`;

const SourceNameVal = styled.th`
  width: 309px;

  padding-left: 59px;
  padding-right: 59px;
  border-left: 1px solid #bbbbbb;
  text-align:center;

`;

const SourceCell = styled.td`
  width: 110px;
  font-weight: 600;
  border-right: none;
  border-left: none;
  text-align: center;
  background-color: #f9f9f9;
  
`;


const AdmhTableContainer = (props) => {
    const getSampleData1 = props.genderData;
      const sampleData1 = [
        {
          year: "2021",
          data: [
            {
              name: "Male",
              YTD: "5",
              data: [
                // {
                //     x: "21-22 YTD",
                //     val: 100,
                // },
                {
                  x: "January",
                  val: 100,
                },
                {
                  x: "Feburary",
                  val: "100",
                },
                {
                  x: "March",
                  val: "100",
                },
                {
                  x: "April",
                  val: 100,
                },
                {
                  x: "May",
                  val: 100,
                },
                {
                  x: "June",
                  val: 100,
                },
                {
                  x: "July",
                  val: 100,
                },
                {
                  x: "August",
                  val: 100,
                },
                {
                  x: "September",
                  val: 100,
                },
                {
                  x: "October",
                  val: 100,
                },
                {
                  x: "November",
                  val: 100,
                },
                {
                  x: "December",
                  val: 100,
                },
              ],
            },
          ],
        },
      ];
    
  return (
    <>
      <MainContainer>
      <TableContainer>
        <Table>
          <TableHead >
            <TableHeadRow style={{ borderBottom: "1px solid #bbb"}}>
              <SourceNameVal colSpan={"1"}>Source</SourceNameVal>
              <SourceNameVal colSpan={"1"}>Name</SourceNameVal>
              <SourceNameVal colSpan={"1"}>21-22&nbsp;YTD</SourceNameVal>
              {sampleData1[0].data[0].data?.map((value) => (
                <TableHeadRowColumnVal key={value}>
                  {value.x}
                </TableHeadRowColumnVal>
              ))}
              {/* <TableHeadTh style={{ backgroundColor: "#F8F8F8" }}>
                Total
              </TableHeadTh> */}
            </TableHeadRow>
          </TableHead>
          {getSampleData1.map((e) => (
            <TableBody key={e}>
              <SourceCell rowSpan={"0"}>{e.source}</SourceCell>

              {e.data.map((e, index) => {
                return (
                  <TableHeadRow
                    key={index}
                    style={{
                      borderBottom: index === e.length - 1 && "none",
                    }}
                  >
                    <td style={{ borderRight: "none" ,width:"199px",wordWrap: "break-word"}}>{e.name}</td>
                    <td style={{ borderRight: "none" ,width:"199px",wordWrap: "break-word"}}>{e.YTD}</td>

                    {e.data.map((val) => {
                      return (
                        <td>{val.val == null || val.val == 0 ? 0 : val.val}</td>
                      );
                    })}
                    
                  </TableHeadRow>
                );
              })}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </MainContainer>
    </>
  )
}

export default AdmhTableContainer
