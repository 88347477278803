import axios from 'axios'
import React from 'react'
import FileItem from './../FileItem/FileItem'

const FileList = ({ files, removeFile }) => {
    return (
        <ul className="file-list">
            {
                files && files.length>0 && 
                files.map(f => (<FileItem
                    key={f}
                    file={f}
                    deleteFile={()=>removeFile(f)} 
                    
                    />))
            }
        </ul>
    )
}

export default FileList
