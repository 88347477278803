import { ErrorMessage, useField } from "formik";
import React from "react";
import styled from "styled-components";

const Input = styled.input`
  height: 52px;
  width: 300px;
  background-color: #f7f7f7;
  border-radius: 6px;
  padding: 0 10px;
  border: none;
  color: #989898;

  ::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 19px;
    color: #989898;
  }
  @media (max-width: 1014px) {
    width: 200px;
  }
  @media (max-width: 925px) {
    width: 220px;
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 685px) {
    width: 280px;
  }
  @media (max-width: 643px) {
    width: 250px;
  }
  @media (max-width: 597px) {
    width: 220px;
  }
  @media (max-width: 534px) {
    width: min(80vw, 400px);
  }
`;
const ErrorMessageContainer = styled(ErrorMessage)`
  color: red;
`;

function TextField({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <Input
        className={props.type === "date" ? "" : "input-group date"}
        {...field}
        {...props}
        autoComplete="off"
      />
      <ErrorMessageContainer component="span" name={field.name} />
    </>
  );
}

export default TextField;
