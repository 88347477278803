import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $, { event } from 'jquery';
import '../global.js';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import { validationParser } from '../ReusableComponents/validationParser2';
import { formObjDataMapper, formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import Select from 'react-select';
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import { Button } from './Modals/Buttons/index';
import DateTime from 'react-datetime';
import '../../node_modules/react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput';
import { can_edit, can_add } from '../ReusableComponents/urlGenerator';
import TextAreaAutosize from 'react-textarea-autosize';
import { encryptStorage } from './LocalStorageInterceptor.js';
// import 'rc-time-picker/assets/index.css';


var validationObj = {};
var context;
var disable_button = false;
const axios = require('axios').default;

const customStyles = {
    table: {
        style: {
            color: defaultThemes.default.text.primary,
            backgroundColor: defaultThemes.default.background.default,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#F9F9F9"
        },
    },
    header: {
        style: {
            minHeight: '1px',
        },
    },
    headRow: {
        style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: defaultThemes.default.divider.default,
            backgroundColor: "#F9F9F9",
            minHeight: "40px"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                fontWeight: "bold",
                fontSize: "0.91rem"
            },
        },
    },
    row: {
        style: {
            minHeight: '20px',
            borderRightStyle: 'solid',
            borderRightWidth: '10px',
            borderRightColor: defaultThemes.default.divider.default,
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                fontSize: '0.9rem'
            },
        },
    },
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "green"
                    : isFocused
                        ? "#0074F0"
                        : null,
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? "white"
                    : isSelected
                        ? "white"
                        : "black",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
            },
        };
    },
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: "#007BFF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ':hover': {
            backgroundColor: "red",
            color: 'white',
        },
    }),
};

class Followups extends Component {
    constructor() {
        super();
        context = this;

        this.handleAddFollowUpSelectChange = this.handleAddFollowUpSelectChange.bind(this);

        this.state = {

            followupdata: this.getModal("followupdata"),
            mandatoryInfo: this.getModal("followupdata"),
            labelText: this.getModal("followupdata"),
            tooltips: this.getModal("followupdata"),
            tooltipsVisibility: this.getModal("followupdata"),
            fieldName: this.getModal("followupdata"),
            fieldType: this.getModal("followupdata"),
            button_type: 'Open',

            addfollowupData: this.getModal("addfollowupData"),

            followupHistoryData: [],
            followupHistoryColumns: this.getModal("followupHistoryColumns"),

            showModal: sessionStorage.getItem('modalOpen') ? true : false,
            showFollowupDetailsModal: false,
            showNext1ActionModal: false,
            showNext2ActionModal: false,
            nextActionModalType: 1,
            interviewers: [],

            formAccessMode: "",

            //Select options
            assignedToSelectOptions: [],
            modeOfContactSelectOptions: [],
            haveFollowedReferralSelectOptions: [],
            
            //Code added by ashraf
            compliantWithTreatmentOptions:[],
            compliantWithTreatmentOptionsWithNo:[],
            wasTreatmentCompletedOptions:[],
            dischargeReasonOption:[],
            agenciesOption:[],

            getSubAbuseTreatmentSelectOptions: [],
            isInterestedInTreatmentSelectOptions: [],
            didBeginTreatmentSelectOptions: [],
            haveReceivedMedicationSelectOptions: [],
            doNeedReferralsSelectOptions: [],
            likeContinueFollowUpSelectOptions: [],
            overdoseSinceContactRRCSelectOptions: [],

            //Multi select options
            subAbuseServicesMultiOptions: [],
            unableToTreatmentMultiOptions: [],
            whereNeedReferralMultiOptions: [],

            //selected dates
            admission_date: '',
            medication_dose_date: '',

            loadedFollowUpId: '',

            date: "1990-06-05",
            format: "YYYY-MM-DD",
            inputFormat: "DD/MM/YYYY",
            mode: "date"
        };

    }

    componentWillMount() {

        if (context.state.interviewers.length === 0) {
            let getInterviewersListBody = {
                apikey: sessionStorage.getItem('__TOKEN__'),
                organization: sessionStorage.getItem('selected_organization')
            };

            axios.post(global.restApiURL + 'customRest/getPeerSupportSpecialist', JSON.stringify(getInterviewersListBody))
                .then(async (res) => {
                    if (res.data.success && res.status == 200) {
                        let interviewers = [];
                        interviewers.push({ name: '' });
                        res.data.data.map(Filteredinterviewer => {
                            interviewers.push({ id: Filteredinterviewer.id, name: Filteredinterviewer.full_name });
                        })
                        this.setState({
                            interviewers: interviewers,
                        });
                        await context.createSelectOptions();
                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })
        }

        // for fetching layout definition
        axios.get(global.restApiURL + 'webservice/rest/class/id/16?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success && res.status == 200) {
                    validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
                    console.log("validation : ", validationObj);

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,
                        fieldType: validationObj.fieldtype,
                    });

                    this.createSelectOptions();

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    console.log(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });
    }

    componentDidMount() {

        this.getFollowupHistory();
        if (sessionStorage.getItem('viewDetail') > 0) {
            console.log('viewDetail exits')
            context.handleFollowupDetails(sessionStorage.getItem('viewDetail'))
        }
    }

    getFollowupHistory() {
        sessionStorage.removeItem('new_follow_up_id')
        if (sessionStorage.getItem('clientId') !== '') {

            let getFollowupListBody = {
                apikey: sessionStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
            };

            axios.post(global.restApiURL + 'customRest/getFollowupsList', JSON.stringify(getFollowupListBody))
                .then(res => {

                    if (res.data.success) {
                        // const response = res.data.data.elements;

                        setTimeout(function () {

                            context.setState({
                                followupHistoryData: context.parseFollowupHistory(res.data.data)
                            });
                            sessionStorage.setItem('followupHistoryData_length', res.data.data.length)
                            console.log("obj fet data : ", context.state.followupHistoryData);
                        }, 50)



                        // this.overallResponseCalculator();    


                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }


                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })
        }
    }

    parseFollowupHistory(historyDataArray) {
        let parsedData = [];

        parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {

            var op = {};

            item.map((val) => {
                if (val.name !== "assigned_to")
                    op[val.name] = val.value;
                else
                    op[val.name] = context.getAssignedInterviewerName(val.value);
            })

            // console.log("op : " , op );

            return op;

        }, this);

        // console.log("parsed Data : " , parsedData);

        return parsedData;
    }

    getAssignedInterviewerName(staffId) {
        let name = '';
        context.state.interviewers.map((val) => {
            if (Number(val.id) === Number(staffId)) {
                name = val.name;
            }
        });

        return name;
    }

    onModalOpen(event) {
        context.setState({
            showNext1ActionModal: false,
            showNext2ActionModal: false,
            showFollowupDetailsModal: false,
            showModal: true,
            addfollowupData: context.getModal('resetfollowupData'),
            addNewFollowUp: context.getModal("resetfollowupData")
        });
    }

    onModalClose() {
        context.getFollowupHistory();
        context.setState({
            showModal: false,
            addfollowupData: context.getModal('resetfollowupData'),

        })
        sessionStorage.removeItem('modalOpen');
    }

    onFollowUpDetailsModalOpen() {
        context.setState({
            showNext1ActionModal: false,
            showNext2ActionModal: false,
            showModal: false,
            showFollowupDetailsModal: true,
            addfollowupData: context.getModal('resetfollowupData'),
        })
    }

    onNext1ActionModalClose() {
        console.log("close");
        context.getFollowupHistory();
        context.setState({
            showNext1ActionModal: false,
            addfollowupData: context.getModal('resetfollowupData'),
        })


    }

    onNext1ActionModalOpen() {
        context.setState({
            showNext2ActionModal: false,
            showFollowupDetailsModal: false,
            showModal: false,
            showNext1ActionModal: true,
            addfollowupData: context.getModal('resetfollowupData'),
        })
    }

    onNext2ActionModalClose() {

        sessionStorage.removeItem('Object_id');
        sessionStorage.removeItem('Object_key');
        context.getFollowupHistory();
        context.setState({
            showNext2ActionModal: false
        })

    }

    onNext2ActionModalOpen() {
        context.setState({
            showNext1ActionModal: false,
            showFollowupDetailsModal: false,
            showModal: false,
            showNext2ActionModal: true
        })
    }

    onFollowUpDetailModalClose() {
        sessionStorage.removeItem('Object_id');
        sessionStorage.removeItem('Object_key');
        context.getFollowupHistory();
        context.setState({
            showFollowupDetailsModal: false
        })

    }

    route = (route) => {
        sessionStorage.setItem('modalOpen', true);
        switch (route) {
            case 'Referral':
                window.location.href = global.domain + 'referrals';
                break;

            case 'Routing':
                window.location.href = global.domain + 'routing';
                break;

        }
    }
    getModal(type) {
        let dataModel = '';
        switch (type) {
            case "followupdata":
                dataModel = {
                    schedule_date: '',
                    schedule_time: '',
                    assigned_to: '',
                    mode_of_contact: '',
                    notes: '',
                    did_contact_to_patient: '',
                    did_receive_response: '',
                    did_leave_voicemail: '',
                    did_send_message: '',
                    have_followed_with_refral: '',
                    followed_often: '',
                    get_sub_abuse_treatment: '',
                    // Code Added by ashraf 
                    date_placed_waiting_list:'',
                    days_clean:"",
                    compliant_with_treatment:'',
                    compliant_with_treatment_no:'',
                    was_treatment_completed:'',
                    discharge_reason:"",
                    agencies:"",
                    other_agency:"",


                    sub_abuse_services: '',
                    admission_date: '',
                    why_unable_to_treatment: '',
                    is_interested_in_treatment: '',
                    did_begin_treatment: '',
                    have_received_medication: '',
                    medication_dose_date: '',
                    do_need_referrals: '',
                    where_need_referral: '',
                    like_continue_follow_up: '',
                    overdose_since_contact_rrc: '',
                    overdose_days: '',
                    client_id: '',
                    status: '',
                    discharge_date: '',
                    potential_admission_date: '',
                }
                break;
            case "addfollowupData":
                dataModel = {
                    schedule_date: '',
                    schedule_time: '',
                    assigned_to: '',
                    mode_of_contact: '',
                    client_id: '',
                    status: ''
                };
                break;
            case "resetfollowupData":
                dataModel = {
                    schedule_date: '',
                    schedule_time: '',
                    assigned_to: '',
                    mode_of_contact: '',
                    client_id: '',
                    status: ''
                };
                break;
            case "followupHistoryColumns":
                dataModel = [
                    {
                        name: 'Date/Time',
                        selector: 'date',
                        sortable: true,
                        cell: (row) => {
                            return moment.unix(row.date).tz("America/New_York").format("MM/DD/YYYY hh:mm a");
                        },

                    },
                    {
                        name: 'Mode of contact',
                        selector: 'mode_of_contact',
                        sortable: true,
                    },
                    {
                        name: 'Assigned To',
                        selector: 'assigned_to',
                        sortable: true,
                    },
                    {
                        name: 'Status',
                        selector: 'status',
                        sortable: true,
                    },
                    {
                        name: 'Details',
                        sortable: false,
                        cell: (row) => (
                            <div>
                                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={() => this.handleFollowupDetails(row.id)}></i></Link>
                            </div>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    },
                ];
                break;
            default:
                dataModel = {};
                break;
        }
        return dataModel;
    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({
                //Select options
                assignedToSelectOptions: interviewersOptionsGenerator(this.state.interviewers, ""),
                modeOfContactSelectOptions: selectOptionsGenerator(validationObj.options.mode_of_contact, ""),
                haveFollowedReferralSelectOptions: selectOptionsGenerator(validationObj.options.have_followed_with_refral, context.state.followupdata.have_followed_with_refral),
                
                // Code Added by ashraf
                compliantWithTreatmentOptions:selectOptionsGenerator(validationObj.options.compliant_with_treatment, context.state.followupdata.compliant_with_treatment),
                compliantWithTreatmentOptionsWithNo:selectOptionsGenerator(validationObj.options.compliant_with_treatment_no, context.state.followupdata.compliant_with_treatment_no),
                wasTreatmentCompletedOptions:selectOptionsGenerator(validationObj.options.was_treatment_completed , context.state.followupdata.was_treatment_completed),
                dischargeReasonOption:selectOptionsGenerator(validationObj.options.discharge_reason , context.state.followupdata.discharge_reason),
                agenciesOption:multiSelectOptionGenerator(validationObj.options.agencies , context.state.followupdata.agencies),

                getSubAbuseTreatmentSelectOptions: selectOptionsGenerator(validationObj.options.get_sub_abuse_treatment, context.state.followupdata.get_sub_abuse_treatment),
                isInterestedInTreatmentSelectOptions: selectOptionsGenerator(validationObj.options.is_interested_in_treatment, context.state.followupdata.is_interested_in_treatment),
                didBeginTreatmentSelectOptions: selectOptionsGenerator(validationObj.options.did_begin_treatment, context.state.followupdata.did_begin_treatment),
                haveReceivedMedicationSelectOptions: selectOptionsGenerator(validationObj.options.have_received_medication, context.state.followupdata.have_received_medication),
                doNeedReferralsSelectOptions: selectOptionsGenerator(validationObj.options.do_need_referrals, context.state.followupdata.do_need_referrals),
                likeContinueFollowUpSelectOptions: selectOptionsGenerator(validationObj.options.like_continue_follow_up, context.state.followupdata.like_continue_follow_up),
                overdoseSinceContactRRCSelectOptions: selectOptionsGenerator(validationObj.options.overdose_since_contact_rrc, context.state.followupdata.overdose_since_contact_rrc),
                // Multiselect
                subAbuseServicesMultiOptions: multiSelectOptionGenerator(validationObj.options.sub_abuse_services),
                unableToTreatmentMultiOptions: multiSelectOptionGenerator(validationObj.options.why_unable_to_treatment),
                whereNeedReferralMultiOptions: multiSelectOptionGenerator(validationObj.options.where_need_referral),
            });

        }
    }

    handleFollowupDetails = (obj_id) => {
        disable_button = true;
        let historyID = obj_id;
        context.setState({
            followupdata: context.getModal("followupdata")
        })
        axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
            .then(res => {
                const response = res.data.data.elements;
                sessionStorage.setItem('Object_id', obj_id);
                sessionStorage.setItem('Object_key', res.data.data.key);
                let parsedFollowUpDetails = formObjDataMapper2(response, context.getModal("followupdata"));
                let formAccessMode = '';
                if (parsedFollowUpDetails.status === "Open" || parsedFollowUpDetails.status === "open") {
                    formAccessMode = "all";
                }
                if (parsedFollowUpDetails.status === "Close" || parsedFollowUpDetails.status === "close") {
                    formAccessMode = "none";
                }
                context.setState({
                    followupdata: parsedFollowUpDetails,
                    formAccessMode: formAccessMode,
                    loadedFollowUpId: historyID
                })
                context.createSelectOptions();
                context.onFollowUpDetailsModalOpen();
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            })
        if (sessionStorage.getItem('viewDetail') !== null && sessionStorage.getItem('viewDetail') !== undefined && sessionStorage.getItem('viewDetail') !== '' && sessionStorage.getItem('viewDetail') > 0) {
            sessionStorage.removeItem('viewDetail');
        }
    }

    handleTimer = date => {
        context.setState(prevState => ({
            ...prevState,
            addfollowupData: {
                ...prevState.addfollowupData,
                schedule_time: this.timeConverter(date._d, 24)
            }
        }));
    };

    FocusTimer = () => {
        console.log("timer", new Date());
        context.setState(prevState => ({
            ...prevState,
            addfollowupData: {
                ...prevState.addfollowupData,
                schedule_time: this.timeConverter(moment(), 24)
            }
        }));
    }

    timeConverter(timeString, type) {
        let convertedString = 't';
        console.log('timeString', timeString)
        if (timeString === undefined) {
            return '';
        }
        if (type === 24) {
            timeString = String(timeString).substr(16, 5)
            let val = timeString.split(":");
            if (Number(val[0]) > 12) {
                if ((Number(val[0]) - 12) > 9)
                    convertedString = (Number(val[0]) - 12) + ":" + val[1] + " PM"
                else
                    convertedString = "0" + (Number(val[0]) - 12) + ":" + val[1] + " PM"
            }
            else if (Number(val[0]) === 12) {
                convertedString = val[0] + ":" + val[1] + " PM"
            }
            else if (Number(val[0]) === 0) {
                convertedString = (Number(val[0]) + 12) + ":" + val[1] + " AM"
            }
            else {
                convertedString = val[0] + ":" + val[1] + " AM"
            }
        }

        if (type === 12) {
            let time = String(timeString).substr(0, 5);
            let val = time.split(":");
            let ampm = String(timeString).substr(6, 2);
            if (ampm === "AM") {
                if (Number(val[0]) === 12) {
                    convertedString = "00:" + val[1];
                }
                else {
                    convertedString = time;
                }
            }
            if (ampm === "PM") {
                if (Number(val[0]) < 12) {
                    convertedString = (Number(val[0]) + 12) + ":" + val[1];
                }
                else {
                    convertedString = time;
                }
            }
        }
        return convertedString;
    }

    handleChange = (date, event) => {
        context.setState(prevState => ({
            ...prevState,
            addfollowupData: {
                ...prevState.addfollowupData,
                schedule_date: date
            }
        }));
    };

    handleAddmissionDateChange = (date) => {
        disable_button = false;
        context.setState(prevState => ({
            ...prevState,
            admission_date: date,
            followupdata: {
                ...prevState.followupdata,
                admission_date: context.getFormattedDate(date)
            }
        }));

    };

    handleMedicationDateChange = (date) => {
        disable_button = false;
        context.setState(prevState => ({
            ...prevState,
            medication_dose_date: date,
            followupdata: {
                ...prevState.followupdata,
                medication_dose_date: context.getFormattedDate(date)
            }
        }));
    };

    handleChange1 = (newDate) => {
        return this.setState({ date: newDate });
    }

    async handleAddFollowUpSelectChange(event) {
        disable_button = false;
        let name = event.target.name;
        let value = event.target.value;
        if (name === "mode_of_contact" || name === "assigned_to") {
            await this.setState((prevState) => ({
                ...prevState,
                addfollowupData: {
                    ...prevState.addfollowupData,
                    [name]: value
                }
            }));
        }
        else {
            await this.setState((prevState) => ({
                ...prevState,
                followupdata: {
                    ...prevState.followupdata,
                    [name]: value
                }
            }));
        }
    }

    getFormattedDate(dateString) {
        let formattedString = '';
        formattedString = dateString.getFullYear() + "-";
        if (dateString.getMonth() < 9) {
            formattedString = formattedString + "0" + (dateString.getMonth() + 1) + "-";
        }
        else {
            formattedString = formattedString + dateString.getMonth();
        }

        if (dateString.getDate() < 10) {
            formattedString = formattedString + "0" + dateString.getDate();
        }
        else {
            formattedString = formattedString + dateString.getDate();
        }
        return formattedString;
    }

    addNewFollowUp(event) {
        var jsonData = '';
        if (sessionStorage.getItem('followupHistoryData_length') === 0) {
            jsonData = { 'className': 'Followups', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
        } else {

            jsonData = { 'className': 'Followups', 'key': (sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('followupHistoryData_length')) + 1)), 'published': true };
        }
        jsonData.elements = [];
        let keys = Object.keys(context.getModal("addfollowupData"));
        let addfollowupDataToSend = context.getModal("addfollowupData");
        addfollowupDataToSend.schedule_date = moment(context.state.addfollowupData.schedule_date).format('MM/DD/YYYY')
        addfollowupDataToSend.schedule_time = context.timeConverter(context.state.addfollowupData.schedule_time, 12);
        addfollowupDataToSend.assigned_to = context.state.addfollowupData.assigned_to;
        addfollowupDataToSend.mode_of_contact = context.state.addfollowupData.mode_of_contact;
        addfollowupDataToSend.client_id = sessionStorage.getItem('clientId');
        addfollowupDataToSend.status = "Open";
        keys.map((key) => {
            jsonData.elements.push({ name: context.state.fieldName[key], value: addfollowupDataToSend[key] });
        })
        var json = JSON.stringify(jsonData);
        axios.post(global.save_url, json)
            .then(function (res) {
                var response = res.data;
                if (response.success && res.status == 200) {
                    sessionStorage.setItem('success_msg', "FollowUp added successfully.");
                    sessionStorage.setItem('followupHistoryData_length', (parseInt(sessionStorage.getItem('followupHistoryData_length')) + 1));
                    sessionStorage.setItem('new_follow_up_id', response.id);
                    context.onNext1ActionModalOpen();
                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                    context.onModalClose();
                }
            })
            .catch(function (err) {
                console.log(err.message);
            });
    }

    handleRadioChange(event) {
        disable_button = false;
        let tempOb = context.state.followupdata;
        tempOb[event.target.name] = event.target.value;
        if (event.target.name == "did_contact_to_patient" && event.target.value !== "Yes") {
            tempOb['did_receive_response'] = '';
            tempOb['did_leave_voicemail'] = '';
            tempOb['did_send_message'] = '';
        }
        if (event.target.name == "did_receive_response" && event.target.value === "Yes") {
            tempOb['did_leave_voicemail'] = '';
            tempOb['did_send_message'] = '';
        }
        if (event.target.name == "did_leave_voicemail" && event.target.value === "Yes") {
            tempOb['did_send_message'] = '';
        }
        context.setState((prevState) => ({
            ...prevState,
            followupdata: tempOb
        })
        );
    }

    handleMultiSelectChanges = (selectedOption, event) => {
        var updatedState = this.state.followupdata;
        updatedState[event.name] = selectedOption;
        this.setState(prevState => ({
            ...prevState,
            followupdata: updatedState
        }));


        if(event.name == 'agencies'){

            $('.agencyN').css('border','');
            $('.agencyN').css('border-radius','');
            $('.nRemove').remove();
        }
    };


    showSpecifyOtherAgencies = (agnc = []) => {
        if (agnc.filter(e => e.label === 'Other (Please Specify Below)').length > 0) {
            // this.setState({ showSpecifyOther: true });
            return true;
        } else {
            // this.setState({ showSpecifyOther: false });
            return false;
        }
    };
    
    saveFollowUpdetails = (event) => {
            var jsonData = '';
            jsonData = { 'className': 'Followups', 'key': sessionStorage.getItem('Object_key'), id: sessionStorage.getItem('Object_id'), 'published': true, 'apikey': sessionStorage.getItem('__TOKEN__') };
            jsonData.elements = [];
            $.each(context.state.followupdata, function (key, value) {
                if (key !== "status" && key !== "sub_abuse_services" && key !== "why_unable_to_treatment" && key !== "where_need_referral" &&key !=="agencies") {
                    jsonData.elements.push({ name: key, value: value });
                }
            });
            jsonData.elements.push({ name: 'status', value: context.state.button_type });
            jsonData.elements.push({ name: 'sub_abuse_services', value: createMultiselectDataToSend(context.state.followupdata.sub_abuse_services) });
            jsonData.elements.push({ name: 'agencies', value: createMultiselectDataToSend(context.state.followupdata.agencies) });
            jsonData.elements.push({ name: 'why_unable_to_treatment', value: createMultiselectDataToSend(context.state.followupdata.why_unable_to_treatment) });
            jsonData.elements.push({ name: 'where_need_referral', value: createMultiselectDataToSend(context.state.followupdata.where_need_referral) });
            var json = JSON.stringify(jsonData);
            console.log("Data to send : ", json);
            axios.post(global.save_url, json)
                .then(function (res) {
                    var response = res.data;
                    console.log("save response", response.success);
                    if (response.success) {
                        sessionStorage.setItem('success_msg', "Follow-Up saved successfully.");
                        context.onNext2ActionModalOpen();
                    }
                    else {
                        sessionStorage.setItem('success_msg', "Something went wrong!");
                    }
                    console.log("Msg : ", sessionStorage.getItem('success_msg'));
                    sessionStorage.removeItem('Object_id');
                    sessionStorage.removeItem('Object_key');
                    sessionStorage.removeItem('modalOpen');
                })
                .catch(function (err) {
                    console.log(err.message);
                }
            );
    }

    submitForm = (type) => {
        this.setState({ button_type: type })
    }

    handleDateTimer(date, field) {

        let addfollowupData_temp = this.state.addfollowupData;

        addfollowupData_temp[field] = date._id !== undefined ? moment(date._d).format('MM/DD/YYYY') : date;

        context.setState({ addfollowupData: addfollowupData_temp });
        disable_button = false;
    }

    async handleTimeronBlur(date, field) {
        date = date ? date : context.state.addfollowupData[field];
        let addfollowupData_temp = this.state.followupdata;
        const dateFormat = 'MM/DD/YYYY';
        let formattedDate = "";
        if (typeof date === 'string' && date !== "") {
            if (moment(date, dateFormat, true).isValid()) {
                formattedDate = moment(date).format(dateFormat);
            } else {
                alert('Invalid Date');
                formattedDate = "";
            }
        } else if (typeof date === 'object' && date !== null) {
            if (moment(date._d, dateFormat, true).isValid()) {
                formattedDate = moment(date._d).format(dateFormat);
            } else {
                alert('Invalid Date');
                formattedDate = "";
            }
        }
        addfollowupData_temp[field] = formattedDate;
        await context.setState({ addfollowupData: addfollowupData_temp, unSaved: true });
    }

    
    async handleDateTimerChange(date, field) {
        let addfollowupData_temp = this.state.followupdata;
        addfollowupData_temp[field] = typeof date !== "string" ? moment(date._d).format('MM/DD/YYYY') : date;
        await context.setState({ followupdata: addfollowupData_temp });
        disable_button = false;
    }

    async handleDateTimerChange_poten(date, field) {
        

        let addfollowupData_temp = this.state.followupdata;
        console.log("timer new", typeof date, date, field);

        addfollowupData_temp[field] = typeof date !== "string" ? moment(date._d).format('MM/DD/YYYY') : date;
        // addfollowupData_temp['discharge_date']=""

        await context.setState({ followupdata: addfollowupData_temp });
        disable_button = false;

    }

    handleDateTimerChange1(date, field) {

        let addfollowupData_temp = this.state.followupdata;

        addfollowupData_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY') : date;
        // addfollowupData_temp['discharge_date']=""
        // context.state.followupdata.admission_date

        context.setState({ followupdata: addfollowupData_temp });
        disable_button = false;

    }

    handleDateTimerChange_for_potential_admission() {

        let addfollowupData_temp = this.state.followupdata;

        addfollowupData_temp['discharge_date'] = ""
        context.setState({ followupdata: addfollowupData_temp });
        disable_button = false;
    }

    validDate(current, selectedDate) {
        // return current.isAfter(moment().subtract(1, 'days'));
        return  global.is_peer_specialist?true:current.isAfter(moment().subtract(1, 'days'))
        
    }

    beforeToday(current, selectedDate) {
        return current.isBefore(moment());
    }

    render() {
        $(document).ready(function () {
            $('#Followupform').validate({
                submitHandler: function () {
                    if ($("input[name=agencies]").length > 0) {
                        if ($("input[name=agencies]").val() == null || $("input[name=agencies]").val() == '' || $("input[name=agencies]").val() == undefined) {
                            // $('#Open').attr('disabled');
                            // alert("Please Select Agencies!!");
                            $('.agencyN').css('border','1px solid red');
                            $('.agencyN').css('border-radius','5px');
                            if($(".nRemove").length == 0){
                                $('.nitin').append("<span class='nRemove' style='color:red'>This field is required.<span>");
                            }
                        }else{
                            context.saveFollowUpdetails();
                        }
                    }else{
                        context.saveFollowUpdetails();
                    }
                },
                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.form-group').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                }
            });

            $('#AddNewFollowup').validate({

                submitHandler: function () {
                    context.addNewFollowUp();
                },

                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.form-group').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                }
            });


            
            
            
            


        });
        sessionStorage.setItem('formname', 'Follow-Up');
        if (this.state.followupdata.schedule_time != "") {
            var arr = (this.state.followupdata.schedule_time).split(":");
            var hours = arr[0];
            var AmOrPm = hours >= 12 ? 'pm' : 'am';
            hours = (hours % 12) || 12;
            var minutes = arr[1];
            var finalTime = hours + ":" + minutes + " " + AmOrPm;
        }

        const prevdate = moment(this.state.followupdata.admission_date).subtract(1, 'day');
        const disablePastDt = current => {
            return current.isAfter(prevdate);

        };

        return (
            <div className="hold-transition sidebar-mini text-sm">
                <ReactTooltip place="right" type="dark" effect="solid" />
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">
                        <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
                        <section className="content">
                            <div className="row">
                                <Sidenav />
                                <div className="container-fluid col-md-9 col-lg-10">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <p className="lead float-left"><b className="text-green">Follow-Up</b></p>
                                            <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['followup']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                            {can_add('Followups') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.onModalOpen} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Follow-Up</button> : ''}
                                            <input type="hidden" id="clicked" value="" readOnly />
                                            <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem('clientId')} />
                                        </div>
                                        <div className="card-body">
                                            <DataTable
                                                columns={this.state.followupHistoryColumns}
                                                data={this.state.followupHistoryData}
                                                customStyles={global.customTableStyles}
                                                striped={true}
                                                pagination
                                                paginationComponentOptions={global.paginationComponentOptions}
                                                highlightOnHover
                                                pointerOnHover
                                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                            />
                                        </div>
                                        <div className="card-footer float-right">
                                            <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['followup']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                            {can_add('Followups')  ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.onModalOpen} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Follow-Up</button> : ''}
                                        </div>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showModal}
                                            onClose={this.onModalClose}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-6">
                                                            <h4 className="modal-title float-left text-green">Add Follow-Up</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <form id="AddNewFollowup" method="post" action="javascript:void(0)">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label style={{ display: 'block' }}>Schedule Date (MM/DD/YYYY) <span class="required">*</span> </label>
                                                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                    </div>
                                                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>

                                                                        <span style={{ width: '100%' }}>

                                                                            {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                            {/*<DateTime />*/}
                                                                            <DateTime
                                                                                onChange={(date) => this.handleDateTimer(date, 'schedule_date')}
                                                                                inputProps={{ value: typeof context.state.addfollowupData.schedule_date === 'string' ? context.state.addfollowupData.schedule_date : moment.utc(context.state.addfollowupData.schedule_date).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.schedule_date, name: 'schedule_date', placeholder: 'Schedule Date' }}
                                                                                defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                // isValidDate={this.validDate}
                                                                                input={true}
                                                                                onBlur={(date) => this.handleTimeronBlur(date, 'schedule_date')}
                                                                            />

                                                                            
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label>{this.state.labelText.schedule_time} <span class="required">*</span></label>
                                                                    <Tooltip text={context.state.tooltips.schedule_time} visibiity={context.state.tooltipsVisibility.schedule_time} />
                                                                    <div className="input-group date timepicker">

                                                                        <span style={{ width: "calc(100% - 41px)" }}>

                                                                            {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                            {/* <DateTime /> */}
                                                                            <DateTime
                                                                                onChange={this.handleTimer}
                                                                                viewMode="time"
                                                                                inputProps={{ value: this.state.addfollowupData.schedule_time, required: context.state.mandatoryInfo.schedule_time, placeholder: 'Schedule Time' }}
                                                                                onFocus={this.FocusTimer}
                                                                                dateFormat={false}
                                                                                defaultValue={moment()}
                                                                                input={true}

                                                                            />
                                                                        </span>
                                                                        <div className="input-group-append" >
                                                                            <div className="input-group-text"><i className="far fa-clock"></i></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6">
                                                                <label>{this.state.labelText.assigned_to} <span class="required">*</span></label>
                                                                <Tooltip text={context.state.tooltips.assigned_to} visibiity={context.state.tooltipsVisibility.assigned_to} />
                                                                <div className="form-group">
                                                                    <select name="assigned_to" className="form-control" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} required={context.state.mandatoryInfo.assigned_to}>
                                                                        {this.state.assignedToSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6">
                                                                <label>{this.state.labelText.mode_of_contact} <span class="required">*</span></label>
                                                                <Tooltip text={context.state.tooltips.mode_of_contact} visibiity={context.state.tooltipsVisibility.mode_of_contact} />
                                                                <div className="form-group">
                                                                    <select name="mode_of_contact" className="form-control" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} required={context.state.mandatoryInfo.mode_of_contact}>
                                                                        {this.state.modeOfContactSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/* <div >
                                                            <button type="submit" className="btn btn-primary float-right" value="proceed" ><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents : "none" }} /> Save &amp; Proceed </button>                                          
                                                            <button type="submit" value='save' className="btn btn-success float-right btnmargin" > <i className="fas fa-save" style={{ pointerEvents : "none" }}></i> Save</button>
                                                        </div> */}
                                                        <div className="justify-content-between">
                                                            <button className="btn btn-default float-left" id="closeButton" onClick={this.onModalClose} type="button">Close</button>
                                                            <button className="btn btn-success" id="saveButton" htmlType="submit" form="AddNewFollowup" onClick={() => this.submitForm('Open')}><i className="fas fa-save"></i>&nbsp;Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showFollowupDetailsModal}
                                            onClose={this.onFollowUpDetailModalClose}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="lg"
                                            className="modal-lg modal-dialog"

                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-6">
                                                            <h4 className="modal-title float-left text-green">Follow-Up Details</h4>
                                                        </div>
                                                        {(can_edit('Followups')) ? <div className="col-md-6" style={{ display: this.state.formAccessMode }} >
                                                            <button id="Close" className="btn btn-primary float-right btnmargin" htmlType="submit" form="Followupform" onClick={() => this.submitForm('Completed')} disabled={disable_button}><img src="dist/img/save-proceed_icon.png" alt="" /> Complete Follow-Up </button>
                                                            <button id="Open" className="btn btn-success float-right btnmargin" htmlType="submit" form="Followupform" onClick={() => this.submitForm('Open')} disabled={disable_button}> <i className="fas fa-save"></i> Save Draft</button>

                                                        </div> : ''}
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <fieldset disabled={!can_edit('Followups') }>
                                                    <form id="Followupform" method="post" action="javascript:void(0)">
                                                        <div style={{ pointerEvents: this.state.formAccessMode }}>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">
                                                                    <h6 className="modal-title">Schedule Information</h6>
                                                                    <hr />
                                                                </div>

                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label><b>Follow-Up</b></label>
                                                                        <br />
                                                                        <label>{this.state.followupdata.schedule_date} {finalTime}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label><b>Mode of contact</b> </label>
                                                                        <br />
                                                                        <label>{this.state.followupdata.mode_of_contact}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label><b>Assigned To</b></label>
                                                                        <br />
                                                                        <label>{this.getAssignedInterviewerName(this.state.followupdata.assigned_to)}</label>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-md-12 col-lg-12 form-group">
                                                            <label><b>Notes</b></label>
                                                            <p>
                                                                Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans.
                                                            </p>
                                                        </div> */}

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">
                                                                    <h6 className="modal-title">Follow-Up Information</h6>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.did_contact_to_patient}</label>
                                                                        <Tooltip text={context.state.tooltips.did_contact_to_patient} visibiity={context.state.tooltipsVisibility.did_contact_to_patient} />

                                                                        <div className="form-check radioleft">
                                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.did_contact_to_patient} checked={this.state.followupdata.did_contact_to_patient === "Yes"} onChange={this.handleRadioChange} value="Yes" />&nbsp;Yes
                                                                        </div>
                                                                        <div className="form-check radioleft">
                                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.did_contact_to_patient} checked={this.state.followupdata.did_contact_to_patient === "No"} onChange={this.handleRadioChange} value="No" />&nbsp;No
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {this.state.followupdata.did_contact_to_patient === "Yes" &&
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.did_receive_response}</label>
                                                                            <Tooltip text={context.state.tooltips.did_receive_response} visibiity={context.state.tooltipsVisibility.did_receive_response} />
                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_receive_response} checked={this.state.followupdata.did_receive_response === "Yes"} onChange={this.handleRadioChange} value="Yes" />&nbsp;Yes
                                                                            </div>
                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_receive_response} checked={this.state.followupdata.did_receive_response === "No"} onChange={this.handleRadioChange} value="No" />&nbsp;No
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                                {this.state.followupdata.did_receive_response === "No" &&
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.did_leave_voicemail}<Tooltip name="did_leave_voicemail" text={context.state.tooltips.did_leave_voicemail} visibiity={context.state.tooltipsVisibility.did_leave_voicemail} /></label>

                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_leave_voicemail} checked={this.state.followupdata.did_leave_voicemail === "Yes"} onChange={this.handleRadioChange} value="Yes" />&nbsp;Yes
                                                                            </div>
                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_leave_voicemail} checked={this.state.followupdata.did_leave_voicemail === "No"} onChange={this.handleRadioChange} value="No" />&nbsp;No
                                                                            </div>
                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_leave_voicemail} checked={this.state.followupdata.did_leave_voicemail === "Voicemail not setup"} onChange={this.handleRadioChange} value="Voicemail not setup" />&nbsp;Voicemail not setup
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(this.state.followupdata.did_leave_voicemail === "No" || this.state.followupdata.did_leave_voicemail === "Voicemail not setup") &&
                                                                    <div className="col-lg-5 col-md-5">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.did_send_message}<Tooltip name="did_send_message" text={context.state.tooltips.did_send_message} visibiity={context.state.tooltipsVisibility.did_send_message} /></label>

                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_send_message} checked={this.state.followupdata.did_send_message === "Yes"} onChange={this.handleRadioChange} value="Yes" />&nbsp;Yes
                                                                            </div>
                                                                            <div className="form-check radioleft">
                                                                                <input className="form-check-input" type="radio" name={this.state.fieldName.did_send_message} checked={this.state.followupdata.did_send_message === "No"} onChange={this.handleRadioChange} value="No" />&nbsp;No
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">
                                                                    <h6 className="modal-title">Referral Information</h6>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.have_followed_with_refral}<Tooltip text={context.state.tooltips.have_followed_with_refral} visibiity={context.state.tooltipsVisibility.have_followed_with_refral} /></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6">
                                                                    <select name={context.state.fieldName.have_followed_with_refral} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.haveFollowedReferralSelectOptions}
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            {context.state.followupdata.have_followed_with_refral === "Yes" ? (
                                                                <div><div className="row">
                                                                    <div className="col-lg-6 col-md-6">

                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.followed_often}<Tooltip text={context.state.tooltips.followed_often} visibiity={context.state.tooltipsVisibility.followed_often} /></label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <input name={context.state.fieldName.followed_often} type="number" min="0" max="999999" className="form-control" defaultValue={context.state.followupdata.followed_often} onChange={context.handleRadioChange} />
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            ) : <input name="followed_often" value="" type="hidden" />}

                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.get_sub_abuse_treatment}<Tooltip text={context.state.tooltips.get_sub_abuse_treatment} visibiity={context.state.tooltipsVisibility.get_sub_abuse_treatment} />
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6">
                                                                    <select name={context.state.fieldName.get_sub_abuse_treatment} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.getSubAbuseTreatmentSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {context.state.followupdata.get_sub_abuse_treatment === "Yes" ? (

                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6">

                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.sub_abuse_services}<Tooltip text={context.state.tooltips.sub_abuse_services} visibiity={context.state.tooltipsVisibility.sub_abuse_services} /></label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <Select
                                                                            name={context.state.fieldName.sub_abuse_services}
                                                                            isMulti
                                                                            closeMenuOnSelect={false}
                                                                            value={this.state.followupdata.sub_abuse_services}
                                                                            onChange={this.handleMultiSelectChanges}
                                                                            options={this.state.subAbuseServicesMultiOptions}
                                                                            styles={colourStyles}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : <input name="sub_abuse_services" value="" type="hidden" />}
                                                            {/* Code Added by Ashraf start */}
                                                            {context.state.followupdata.get_sub_abuse_treatment === "On waiting list" &&
                                                                <>
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="col-lg-6 col-md-6">
                                                                                <div className="form-group">
                                                                                    {/* <label >{this.state.labelText.date_placed_waiting_list}<Tooltip text={context.state.tooltips.date_placed_waiting_list} visibiity={context.state.tooltipsVisibility.date_placed_waiting_list} />(MM/DD/YYYY)</label> */}
                                                                                    <LabelField title={this.state.labelText.date_placed_waiting_list} tooltip={this.state.tooltips.date_placed_waiting_list} name="date_placed_waiting_list"  id="date_placed_waiting_list"/>&nbsp;<span style={{color:"red"}}>*</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-6 form-group">
                                                                                <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                                </div>
                                                                                <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                                    <span style={{ width: '100%' }}>
                                                                                        <DateTime
                                                                                            onChange={(date) => this.handleDateTimerChange(date, 'date_placed_waiting_list')}
                                                                                            onBlur={(date) => this.handleTimeronBlur(date, 'date_placed_waiting_list')}
                                                                                            inputProps={{ value: typeof context.state.followupdata.date_placed_waiting_list === 'string' ? context.state.followupdata.date_placed_waiting_list : moment(context.state.followupdata.date_placed_waiting_list).format('MM/DD/YYYY'),  name: 'date_placed_waiting_list' , required: true}}
                                                                                            defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                            dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                            isValidDate={this.beforeToday}
                                                                                            input={true}
                                                                                        />
                                                                                        {/* <input type='date' required={true}/> */}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <LabelField title={this.state.labelText.agencies} tooltip={context.state.tooltips.agencies} name="agencies"  id="agencies"/>&nbsp;<span style={{color:"red"}}>*</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6 nitin">
                                                                        <Select
                                                                            name={context.state.fieldName.agencies}
                                                                            isMulti
                                                                            mandatory={true}
                                                                            closeMenuOnSelect={false}
                                                                            value={this.state.followupdata.agencies}
                                                                            onChange={this.handleMultiSelectChanges}
                                                                            options={this.state.agenciesOption}
                                                                            styles={colourStyles}
                                                                            required={true}
                                                                            className="agencyN"
                                                                        />
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    {/* Other (Please Specify Below) */}
                                                                    <div>
                                                                        {this.showSpecifyOtherAgencies(this.state.followupdata.agencies == false ? [] : this.state.followupdata.agencies) ?
                                                                            <>
                                                                                <div className="row mb-1">
                                                                                    <div className="col-lg-6 col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label>{this.state.labelText.other_agency}<Tooltip text={context.state.tooltips.other_agency} visibiity={context.state.tooltipsVisibility.other_agency} /></label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6">
                                                                                        <input name={context.state.fieldName.other_agency} type="text" className="form-control" defaultValue={context.state.followupdata.other_agency} onChange={context.handleAddFollowUpSelectChange} placeholder="If other , please specify" />
                                                                                    </div>
                                                                                </div>
                                                                            </> : ""
                                                                        }
                                                                    </div>


                                                                </>}

                                                                {/* Days Clean start */}
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">

                                                                            <div className="form-group">
                                                                                <label>{this.state.labelText.days_clean}<Tooltip text={context.state.tooltips.days_clean} visibiity={context.state.tooltipsVisibility.days_clean} /></label>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <input name={context.state.fieldName.days_clean} type="number" min="0" max="999999" className="form-control" defaultValue={context.state.followupdata.days_clean} onChange={context.handleAddFollowUpSelectChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Days Clean End */}

                                                                {/* Compliant with treatment? start */}
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{this.state.labelText.compliant_with_treatment}<Tooltip text={context.state.tooltips.compliant_with_treatment} visibiity={context.state.tooltipsVisibility.compliant_with_treatment} /></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                        <select name={context.state.fieldName.compliant_with_treatment} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.compliantWithTreatmentOptions}
                                                                        </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {context.state.followupdata.compliant_with_treatment === "No" &&
                                                                <>
                                                                <div className="row">
                                                                        <div className="col-lg-6 col-md-6">

                                                                            <div className="form-group">
                                                                                <label>{this.state.labelText.compliant_with_treatment_no}<Tooltip text={context.state.tooltips.compliant_with_treatment_no} visibiity={context.state.tooltipsVisibility.compliant_with_treatment_no} /></label>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                        <select name={context.state.fieldName.compliant_with_treatment_no} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.compliantWithTreatmentOptionsWithNo}
                                                                    </select>
                                                                        </div>
                                                                </div>
                                                                </>
                                                                }
                                                                {/* Compliant with treatment? End */}
                                                            {/* Code Added by Ashraf End */}
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.admission_date}<Tooltip text={context.state.tooltips.admission_date} visibiity={context.state.tooltipsVisibility.admission_date} />(MM/DD/YYYY)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                    </div>
                                                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                        <span style={{ width: '100%' }}>

                                                                            {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                            {/*<DateTime />*/}
                                                                            <DateTime
                                                                                onChange={(date) => this.handleDateTimerChange(date, 'admission_date')}
                                                                                onBlur={(date) => this.handleTimeronBlur(date, 'admission_date')}
                                                                                inputProps={{ value: typeof context.state.followupdata.admission_date === 'string' ? context.state.followupdata.admission_date : moment(context.state.followupdata.admission_date).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.admission_date, name: 'admission_date' }}
                                                                                defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                isValidDate={this.beforeToday}
                                                                                input={true}

                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* newly added */}

                                                            {/* Code Added by Ashraf Start */}

                                                                {/* Was treatment completed Start*/}
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">

                                                                            <div className="form-group">
                                                                                <label>{this.state.labelText.was_treatment_completed}<Tooltip text={context.state.tooltips.was_treatment_completed} visibiity={context.state.tooltipsVisibility.was_treatment_completed} /></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                        <select name={context.state.fieldName.was_treatment_completed} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.wasTreatmentCompletedOptions}
                                                                    </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                         
                                                            {context.state.followupdata.was_treatment_completed === "Yes"  && 
                                                            <>
                                                                 <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.discharge_date}<Tooltip text={context.state.tooltips.discharge_date} visibiity={context.state.tooltipsVisibility.discharge_date} />(MM/DD/YYYY)</label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                    </div>
                                                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                        <span style={{ width: '100%' }}>
                                                                            {/* <DateTime
                                                                                onChange={(date) => this.handleDateTimerChange1(date, 'discharge_date')}
                                                                                // onBlur={(date) => this.handleTimeronBlur(date, 'discharge_date')}
                                                                                inputProps={{ value: this.state.followupdata.discharge_date !== '' ? moment.utc(this.state.followupdata.discharge_date).format('MM/DD/YYYY') : "", required: this.state.mandatoryInfo.discharge_date, name: 'discharge_date' }}
                                                                                defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                isValidDate={this.state.followupdata.admission_date ? disablePastDt : false}
                                                                            /> */}
                                                                            <DateTime
                                                                                onChange={(date) => this.handleDateTimerChange(date, 'discharge_date')}
                                                                                onBlur={(date) => this.handleTimeronBlur(date, 'discharge_date')}
                                                                                inputProps={{ value: typeof context.state.followupdata.discharge_date === 'string' ? context.state.followupdata.discharge_date : moment.utc(context.state.followupdata.discharge_date).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.discharge_date, name: 'discharge_date' }}
                                                                                defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                isValidDate={this.state.followupdata.admission_date ? disablePastDt : false}
                                                                                input={true}                                                           

                                                                            />
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </>
                                                            }
                                                           
                                                           { context.state.followupdata.discharge_date != "" && context.state.followupdata.was_treatment_completed === "Yes" &&
                                                           <>
                                                           <div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <label>{this.state.labelText.discharge_reason}<Tooltip text={context.state.tooltips.discharge_reason} visibiity={context.state.tooltipsVisibility.discharge_reason} /></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-6">
                                                                        <select name={context.state.fieldName.discharge_reason} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.dischargeReasonOption}
                                                                        </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                           </>

                                                           }
                                                        {/* Code Added by Ashraf End */}


                                                            {/* potentials discharge date start */}


                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.potential_admission_date}<Tooltip text={context.state.tooltips.potential_admission_date} visibiity={context.state.tooltipsVisibility.potential_admission_date} />(MM/DD/YYYY)</label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">


                                                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                    </div>
                                                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>

                                                                        <span style={{ width: '100%' }}>
                                                                            {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                            {/*<DateTime />*/}
                                                                            <DateTime
                                                                                onBlur={(date) => this.handleTimeronBlur(date, 'potential_admission_date')}
                                                                                onChange={(date) => this.handleDateTimerChange_poten(date, 'potential_admission_date')}
                                                                                inputProps={{ disabled: context.state.followupdata.admission_date !== "" ? true : false, value: context.state.followupdata.admission_date ? "" : (typeof context.state.followupdata.potential_admission_date !== 'string' || context.state.followupdata.potential_admission_date !== "" || context.state.followupdata.potential_admission_date !== null ? context.state.followupdata.potential_admission_date : moment.utc(context.state.followupdata.potential_admission_date).format('MM/DD/YYYY')), required: this.state.mandatoryInfo.potential_admission_date, name: 'potential_admission_date', }}
                                                                                defaultValue={moment.utc().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                input={true}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            {/* potentials discharge date end************************************ */}








                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.why_unable_to_treatment}<Tooltip text={context.state.tooltips.why_unable_to_treatment} visibiity={context.state.tooltipsVisibility.why_unable_to_treatment} /></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <Select
                                                                        name={context.state.fieldName.why_unable_to_treatment}
                                                                        isMulti
                                                                        closeMenuOnSelect={false}
                                                                        value={this.state.followupdata.why_unable_to_treatment}
                                                                        onChange={this.handleMultiSelectChanges}
                                                                        options={this.state.unableToTreatmentMultiOptions}
                                                                        styles={colourStyles}
                                                                    />

                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.is_interested_in_treatment}<Tooltip text={context.state.tooltips.is_interested_in_treatment} visibiity={context.state.tooltipsVisibility.is_interested_in_treatment} /></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <select name={context.state.fieldName.is_interested_in_treatment} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.isInterestedInTreatmentSelectOptions}
                                                                    </select>

                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">

                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.did_begin_treatment}<Tooltip text={context.state.tooltips.did_begin_treatment} visibiity={context.state.tooltipsVisibility.did_begin_treatment} /></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <select name={context.state.fieldName.did_begin_treatment} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.didBeginTreatmentSelectOptions}
                                                                    </select>

                                                                </div>

                                                            </div>
                                                            {this.state.followupdata.did_begin_treatment === "Yes" ? (
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.have_received_medication}</label>
                                                                            <Tooltip text={context.state.tooltips.have_received_medication} visibiity={context.state.tooltipsVisibility.have_received_medication} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <select name={context.state.fieldName.have_received_medication} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                            {this.state.haveReceivedMedicationSelectOptions}
                                                                        </select>

                                                                    </div>

                                                                </div>
                                                            ) : <input type="hidden" name="have_received_medication" value="" />}
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">

                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.medication_dose_date}<Tooltip text={context.state.tooltips.medication_dose_date} visibiity={context.state.tooltipsVisibility.medication_dose_date} />(MM/DD/YYYY)</label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">

                                                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                    </div>
                                                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>

                                                                        <span style={{ width: '100%' }}>

                                                                            {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                            {/*<DateTime />*/}
                                                                            <DateTime
                                                                                onChange={(date) => this.handleDateTimerChange(date, 'medication_dose_date')}
                                                                                inputProps={{ value: this.state.followupdata.medication_dose_date !== '' ? moment(this.state.followupdata.medication_dose_date).format('MM/DD/YYYY') : '', required: this.state.mandatoryInfo.medication_dose_date, name: 'medication_dose_date' }}
                                                                                defaultValue={moment().format('MM/DD/YYYY')}
                                                                                dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                isValidDate={this.beforeToday}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        <label>{this.state.labelText.do_need_referrals}<Tooltip text={context.state.tooltips.do_need_referrals} visibiity={context.state.tooltipsVisibility.do_need_referrals} /></label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <select name={context.state.fieldName.do_need_referrals} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.doNeedReferralsSelectOptions}
                                                                    </select>

                                                                </div>

                                                            </div>
                                                            {context.state.followupdata.do_need_referrals === "Yes" ? (
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.where_need_referral}<Tooltip text={context.state.tooltips.where_need_referral} visibiity={context.state.tooltipsVisibility.where_need_referral} /></label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <Select
                                                                            name={context.state.fieldName.where_need_referral}
                                                                            isMulti
                                                                            closeMenuOnSelect={false}
                                                                            value={this.state.followupdata.where_need_referral}
                                                                            onChange={this.handleMultiSelectChanges}
                                                                            options={this.state.whereNeedReferralMultiOptions}
                                                                            styles={colourStyles}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            ) : <input type="hidden" value="" name="where_need_referral" />}
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        {/* <label>{this.state.labelText.like_continue_follow_up}<Tooltip text={context.state.tooltips.like_continue_follow_up} visibiity={context.state.tooltipsVisibility.like_continue_follow_up} /></label> */}
                                                                        <label>Would you like the {sessionStorage.getItem("selected_organization")} to continue to follow up with you?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <select name={context.state.fieldName.like_continue_follow_up} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.likeContinueFollowUpSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6">
                                                                    <div className="form-group">
                                                                        {/* <label>{this.state.labelText.overdose_since_contact_rrc}<Tooltip text={context.state.tooltips.overdose_since_contact_rrc} visibiity={context.state.tooltipsVisibility.overdose_since_contact_rrc} /></label> */}
                                                                        <label> Have you overdosed since your last contact with the {sessionStorage.getItem("selected_organization")}?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 form-group">
                                                                    <select name={context.state.fieldName.overdose_since_contact_rrc} className="custom-select" aria-hidden="true" onChange={this.handleAddFollowUpSelectChange} >
                                                                        {this.state.overdoseSinceContactRRCSelectOptions}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {context.state.followupdata.overdose_since_contact_rrc === "Yes" ? (
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{this.state.labelText.overdose_days}<Tooltip text={context.state.tooltips.overdose_days} visibiity={context.state.tooltipsVisibility.overdose_days} /></label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 form-group">
                                                                        <input className="form-control" type="number" min="0" max="999999" name={context.state.fieldName.overdose_days} defaultValue={context.state.followupdata.overdose_days} onChange={context.handleRadioChange} />
                                                                    </div>
                                                                </div>
                                                            ) : <input type="hidden" value="" name="overdose_days" />}
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label for="exampleFormControlTextarea3">Notes</label>
                                                                        <TextAreaAutosize className="form-control" id={context.state.fieldName.notes} name={context.state.fieldName.notes} defaultValue={context.state.followupdata.notes} onChange={this.handleRadioChange} rows={'auto'} placeholder="Enter..."></TextAreaAutosize>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <input type="hidden" name="status" defaultValue={this.state.button_type} />
                                                    </form>
                                                </fieldset>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="justify-content-between" >
                                                            <button className="btn btn-default float-left" onClick={this.onFollowUpDetailModalClose} type="button">Close</button>

                                                        </div>
                                                    </div>
                                                    {can_edit('Followups') ? <div className="col-md-6" style={{ display: this.state.formAccessMode }} >
                                                        <button id="Close" className="btn btn-primary float-right btnmargin" htmlType="submit" form="Followupform" onClick={() => this.submitForm('Completed')} disabled={disable_button}><img src="dist/img/save-proceed_icon.png" alt="" /> Complete Follow-Up </button>
                                                        <button id="Open" className="btn btn-success float-right btnmargin" htmlType="submit" form="Followupform" onClick={() => this.submitForm('Open')} disabled={disable_button}> <i className="fas fa-save"></i> Save Draft</button>
                                                    </div> : ''}
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showNext1ActionModal}
                                            onClose={this.onNext1ActionModalClose}
                                            disableOverlayClick={true}
                                            showCloseButton={true}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-8">
                                                            <h4 className="modal-title float-left text-green">Next Actions</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <p style={{ paddingLeft: "10px" }}>Your follow-up has been scheduled. Would you like to proceed to one of the following pages?</p>
                                                    </div>
                                                    <div className="row" style={{ margin: "10px -17px 40px -10px", width: "100%" }}>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-success" type="button" style={{ width: "140px" }} onClick={() => this.handleFollowupDetails(sessionStorage.getItem('new_follow_up_id'))}><i className="fas fa-calendar-check"></i> Complete Follow-Up</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "140px" }} onClick={() => this.route('Routing')}><i className="fas fa-medkit"></i> Add Routing</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-success" type="button" style={{ width: "140px" }} onClick={() => this.route('Referral')}><i className="fas fa-prescription"></i> Add Referral</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Modal.Body>

                                        </Modal>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showNext2ActionModal}
                                            onClose={this.onNext2ActionModalClose}
                                            disableOverlayClick={true}
                                            showCloseButton={true}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-8">
                                                            <h4 className="modal-title float-left text-green">Next Actions</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <p style={{ paddingLeft: "10px" }}>Your follow-up has been scheduled. Would you like to proceed to one of the following pages?</p>
                                                    </div>
                                                    <div className="row" style={{ margin: "10px 0px 50px 0px", width: "100%" }}>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={this.onModalOpen}><i className="fas fa-calendar-check"></i> Add Follow-Up</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Routing')}><i className="fas fa-medkit"></i> Add Routing</div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Referral')}><i className="fas fa-prescription"></i> Add Referral</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Modal.Body>

                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default Followups;