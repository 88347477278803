
 import { createBrowserHistory } from 'history';

 /**
  * The history library lets you easily manage session history anywhere JavaScript runs.
  * history abstracts away the differences in various environments and provides a minimal API
  * that lets you manage the history stack, navigate, and persist state between sessions.
  */
 
 
 export const history = createBrowserHistory();
 