global.leave_msg = [
	{id: "0", name: ""},
    {id: "Yes", name: "Yes"},
    {id: "No", name: "No"},
    {id: "Don't Know", name: "Don't Know"},
    {id: "Refused", name: "Refused"},
  ] ;

global.type_lns = [
	{id: "0", name: ""},
    {id: "Emergency Shelter", name: "Emergency Shelter"},
    {id: "Streets or Outdoors", name: "Streets or Outdoors"},
    {id: "Foster Care", name: "Foster Care"},
    {id: "Hospital", name: "Hospital"},
	{id: "Jail", name: "Jail"},
	{id: "Psych Hospital", name: "Psych Hospital"},
	{id: "Substance Abuse Treatment", name: "Substance Abuse Treatment"},
	{id: "PSH", name: "PSH"},
	{id: "Own Home", name: "Own Home"},
	{id: "Rental", name: "Rental"},
	{id: "Family", name: "Family"},
	{id: "Friends", name: "Friends"},
	{id: "Transitional Housing", name: "Transitional Housing"},
	{id: "Other", name: "Other"}
  ] ;
  
global.state=[
	{id: "0", name: ""},
	{id: "AA", name: "AA"},
	{id: "AE", name: "AE"},
	{id: "AK", name: "AK"},
	{id: "AL", name: "AL"},
	{id: "AP", name: "AP"},
	{id: "AR", name: "AR"},
	{id: "AZ", name: "AZ"},
	{id: "CA", name: "CA"},
	{id: "CO", name: "CO"},
	{id: "CT", name: "CT"},
	{id: "DC", name: "DC"},
	{id: "DE", name: "DE"},
	{id: "FL", name: "FL"},
	{id: "GA", name: "GA"},
	{id: "HI", name: "HI"},
	{id: "IA", name: "IA"},
	{id: "ID", name: "ID"},
	{id: "IL", name: "IL"},
	{id: "IN", name: "IN"},
	{id: "KS", name: "KS"},
	{id: "KY", name: "KY"},
	{id: "LA", name: "LA"},
	{id: "MA", name: "MA"},
	{id: "MD", name: "MD"},
	{id: "ME", name: "ME"},
	{id: "MI", name: "MI"},
	{id: "MN", name: "MN"},
	{id: "MO", name: "MO"},
	{id: "MS", name: "MS"},
	{id: "MT", name: "MT"},
	{id: "NC", name: "NC"},
	{id: "NE", name: "NE"},
	{id: "NH", name: "NH"},
	{id: "NJ", name: "NJ"},
	{id: "NM", name: "NM"},
	{id: "NV", name: "NV"},
	{id: "NY", name: "NY"},
	{id: "ND", name: "ND"},
	{id: "OH", name: "OH"},
	{id: "OK", name: "OK"},
	{id: "OR", name: "OR"},
	{id: "PA", name: "PA"},
	{id: "RI", name: "RI"},
	{id: "SC", name: "SC"},
	{id: "SD", name: "SD"},
	{id: "TN", name: "TN"},
	{id: "TX", name: "TX"},
	{id: "UT", name: "UT"},
	{id: "VT", name: "VT"},
	{id: "VA", name: "VA"},
	{id: "WA", name: "WA"},
	{id: "WI", name: "WI"},
	{id: "WV", name: "WV"},
	{id: "WY", name: "WY"},
	{id: "AS", name: "AS"},
	{id: "GU", name: "GU"},
	{id: "MP", name: "MP"},
	{id: "PR", name: "PR"},
	{id: "UM", name: "UM"},
	{id: "VI", name: "VI"}
];

global.agency=[
	{id: "0", name: ""},
	{id: "1917 Clinic", name: "1917 Clinic"},
	{id: "4D", name: "4D"},
	{id: "Addiction Prevention Coalition", name: "Addiction Prevention Coalition"},
	{id: "AIDS Alabama", name: "AIDS Alabama"},
	{id: "Alabama DMH", name: "Alabama DMH"},
	{id: "Aletheia House", name: "Aletheia House"},
	{id: "ALR", name: "ALR"},
	{id: "ARMS", name: "ARMS"},
	{id: "Birmingham AIDS Outreach", name: "Birmingham AIDS Outreach"},
	{id: "Birmingham Fellowship House", name: "Birmingham Fellowship House"},
	{id: "Birmingham Mountain Radio", name: "Birmingham Mountain Radio"},
	{id: "Bradford", name: "Bradford"},
	{id: "Bright Health", name: "Bright Health"},
	{id: "Brookwood", name: "Brookwood"},
	{id: "Brother Bryan", name: "Brother Bryan"},
	{id: "Children's Aid Society", name: "Children's Aid Society"},
	{id: "Children's Hospital", name: "Children's Hospital"},
	{id: "Christ Health Center", name: "Christ Health Center"},
	{id: "Church", name: "Church"},
	{id: "Church of the Reconciler", name: "Church of the Reconciler"},
	{id: "Cooper Green", name: "Cooper Green"},
	{id: "Corrections/Law Enforcement", name: "Corrections/Law Enforcement"},
	{id: "Crisis Center", name: "Crisis Center"},
	{id: "Dannon Project", name: "Dannon Project"},
	{id: "DCH", name: "DCH"},
	{id: "DHR", name: "DHR"},
	{id: "Doctor", name: "Doctor"},
	{id: "Eastside Mental Health", name: "Eastside Mental Health"},
	{id: "Expect a Miracle", name: "Expect a Miracle"},
	{id: "Family", name: "Family"},
	{id: "Family Connection", name: "Family Connection"},
	{id: "Firehouse", name: "Firehouse"},
	{id: "First Light", name: "First Light"},
	{id: "Foundry", name: "Foundry"},
	{id: "Friend", name: "Friend"},
	{id: "Grandview", name: "Grandview"},
	{id: "Guiding Light Church", name: "Guiding Light Church"},
	{id: "Helping Hands", name: "Helping Hands"},
	{id: "Hope Health Center", name: "Hope Health Center"},
	{id: "Housing Assistance Fund", name: "Housing Assistance Fund"},
	{id: "Indian Rivers", name: "Indian Rivers"},
	{id: "Internet", name: "Internet"},
	{id: "JAFI", name: "JAFI"},
	{id: "JBS", name: "JBS"},
	{id: "JCDH", name: "JCDH"},
	{id: "JCHA", name: "JCHA"},
	{id: "Jimmie Hale Mission", name: "Jimmie Hale Mission"},
	{id: "Olivia's House", name: "Olivia's House"},
	{id: "One Roof", name: "One Roof"},
	{id: "Pathways", name: "Pathways"},
	{id: "Pearson Hall", name: "Pearson Hall"},
	{id: "Phoenix House", name: "Phoenix House"},
	{id: "ROSS", name: "ROSS"},
	{id: "Safe Care", name: "Safe Care"},
	{id: "Salvation Army", name: "Salvation Army"},
	{id: "SAMHSA", name: "SAMHSA"},
	{id: "Self", name: "Self"},
	{id: "Self-Recovery", name: "Self-Recovery"},
	{id: "Shelby Emergency Assistance", name: "Shelby Emergency Assistance"},
	{id: "Sober Living America", name: "Sober Living America"},
	{id: "St. Vincent's East", name: "St. Vincent's East"},
	{id: "St. Vincent's Main", name: "St. Vincent's Main"},
	{id: "Television", name: "Television"},
	{id: "Therapist", name: "Therapist"},
	{id: "Tri-County Treatment Center", name: "Tri-County Treatment Center"},
	{id: "Turning Point", name: "Turning Point"},
	{id: "UAB", name: "UAB"},
	{id: "UAB ARP", name: "UAB ARP"},
	{id: "UAB Beacon", name: "UAB Beacon"},
	{id: "UAB CAC", name: "UAB CAC"},
	{id: "UAB CAP Clinic", name: "UAB CAP Clinic"},
	{id: "UAB COMP Clinic", name: "UAB COMP Clinic"},
	{id: "UAB REACT", name: "UAB REACT"},
	{id: "UAB TASC", name: "UAB TASC"},
	{id: "Walker Baptist", name: "Walker Baptist"},
	{id: "Word Alive", name: "Word Alive"},
	{id: "Workfaith", name: "Workfaith"},
	{id: "Yana", name: "Yana"},
	{id: "YWCA", name: "YWCA"},
	{id: "Other (Please Specify Below)", name: "Other (Please Specify Below)"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"}
];

global.locationtype=[
	{id: "0", name: "Select"},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"}
];

global.probationparolecase=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
    {id: "No", name: "No"},
    {id: "Don't Know", name: "Don't Know"},
    {id: "Refused", name: "Refused"}
];

global.regularly_stay_shelter=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"}
];

global.gender=[
	{id: "0", name: ""},
	{id: "Male", name: "Male"},
	{id: "Female", name: "Female"},
	{id: "Trans MTF", name: "Trans MTF"},
	{id: "Trans FTM", name: "Trans FTM"},
	{id: "Doesn’t identify as M/F/T", name: "Doesn’t identify as M/F/T"},
];

global.disabl_cond=[
	{id: "0", name: ""},
	{id: "Alcohol Abuse", label: "Alcohol Abuse",value: false},
    {id: "Developmental", label: "Developmental",value: false},
    {id: "Drug Abuse", label: "Drug Abuse",value: false},
    {id: "HIV or AIDS", label: "HIV or AIDS",value: false},
    {id: "Mental Health", label: "Mental Health",value: false},
    {id: "Physical", label: "Physical",value: false}
];

global.race=[
	{id: "0", label: "",value: false},
	{id: "Black", label: "Black",value: false},
	{id: "White", label: "White",value: false},
	{id: "American Indian or Alaska Native", label: "American Indian or Alaska Native",value: false},
	{id: "Asian", label: "Asian",value: false},
	{id: "Native Hawaiian or Pacific Islander", label: "Native Hawaiian or Pacific Islander",value: false},
];

global.hisp_lat=[
	{id: "0", name: ""},
	{id: "Hispanic or Latino", name: "Hispanic or Latino"},
	{id: "Non-Hispanic or Non-Latino", name: "Non-Hispanic or Non-Latino"},
];

global.rrc_services=[
	{id: "0", label: "",value: false},
	{id: "Assessment", label: "Assessment",value: false},
	{id: "Treatment Referral", label: "Treatment Referral",value: false},
	{id: "Peer Support", label: "Peer Support",value: false},
	{id: "Information", label: "Information",value: false},
	{id: "Don't Know", label: "Don't Know",value: false},
	{id: "Refused", label: "Refused",value: false},
];

global.hear_about_rrc=[
	{id: "0", name: ""},
	{id: "Item1", name: "Item1"},
	{id: "Item2", name: "Item2"},
	{id: "Item3", name: "Item3"},
	{id: "Item4", name: "Item4"},
	{id: "Item5", name: "Item5"},
	{id: "Item6", name: "Item6"},
	{id: "Item7", name: "Item7"},
	{id: "Item8", name: "Item8"},
];

global.rec_rrc_ser=[
	{id: "0", label: "",value: false},
	{id: "MAT", label: "MAT",value: false},
	{id: "Treatment Referral", label: "Treatment Referral",value: false},
	{id: "Peer Support", label: "Peer Support",value: false},
	{id: "Information", label: "Information",value: false},
	{id: "Don't Know", label: "Don't Know",value: false},
	{id: "Refused", label: "Refused",value: false},
];

global.military_status=[
	{id: "0", name: ""},
	{id: "Active", name: "Active"},
	{id: "Nonactive", name: "Nonactive"},
	{id: "Honorable", name: "Honorable"},
	{id: "Nonactive Dishonorable", name: "Nonactive Dishonorable"},
	{id: "Other", name: "Other"},
];

global.relationship_status=[
	{id: "0", name: ""},
	{id: "Single", name: "Single"},
	{id: "Living Together", name: "Living Together"},
	{id: "Married", name: "Married"},
	{id: "Separated", name: "Separated"},
	{id: "Divorced", name: "Divorced"},
	{id: "Widowed", name: "Widowed"},
];

global.receive_income=[
	{id: "0", label: "",value: false},
	{id: "Alimony", label: "Alimony",value: false},
	{id: "Child Support", label: "Child Support",value: false},
	{id: "Earned Income", label: "Earned Income",value: false},
	{id: "Private Disability Payment", label: "Private Disability Payment",value: false},
	{id: "SSDI", label: "SSDI",value: false},
	{id: "SSI", label: "SSI",value: false},
	{id: "TANF", label: "TANF",value: false},
	{id: "Unemployed Insurance", label: "Unemployed Insurance",value: false},
	{id: "Veteran's Disability Payment", label: "Veteran's Disability Payment",value: false},
	{id: "Veteran's Pension", label: "Veteran's Pension",value: false},
	{id: "Worker's Compensation", label: "Worker's Compensation",value: false},
];

global.monthly_income=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"}
];
global.non_cash_benefits=[
	{id: "0", label: "",value: false},
	{id: "Food Stamps", label: "Food Stamps",value: false},
	{id: "WIC", label: "WIC",value: false},
	{id: "TANF", label: "TANF",value: false},
	{id: "Other", label: "Other",value: false},
];

global.health_insurance=[
	{id: "0", label: "",value: false},
	{id: "Medicaid", label: "Medicaid",value: false},
	{id: "Medicare", label: "Medicare",value: false},
	{id: "Medical", label: "Medical",value: false},
	{id: "Employer Provided", label: "Employer Provided",value: false},
	{id: "Private Pay", label: "Private Pay",value: false},
	{id: "COBRA", label: "COBRA",value: false},
	{id: "Indian Health", label: "Indian Health",value: false},
	{id: "Other", label: "Other",value: false},
];

global.worry_exc_uncon=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"}
];

global.suicide_attempt=[
	{id: "0", name: ""},
	{id: "Past 3 Months", name: "Past 3 Months"},
	{id: "Lifetime", name: "Lifetime"},
];

global.suicidal_ideation=[
	{id: "0", name: ""},
	{id: "Wish to be dead", name: "Wish to be dead"},
	{id: "Suicidal Thoughts", name: "Suicidal Thoughts"},
	{id: "Suicidal Thoughts with methods(but without specific plan or intent to act)", name: "Suicidal Thoughts with methods(but without specific plan or intent to act)"},
	{id: "Suicidal intent(without specific plan)", name: "Suicidal intent(without specific plan)"},
	{id: "Suicidal intent with specific plan", name: "Suicidal intent with specific plan"},
];

global.activating_events=[
	{id: "0", name: ""},
	{id: "Wish to be dead", name: "Wish to be dead"},
	{id: "Suicidal Thoughts", name: "Suicidal Thoughts"},
	{id: "Suicidal Thoughts with methods(but without specific plan or intent to act)", name: "Suicidal Thoughts with methods(but without specific plan or intent to act)"},
];

global.treatment_history=[
	{id: "0", name: ""},
	{id: "Previous psychiatric diagnoses and treatments", name: "Previous psychiatric diagnoses and treatments"},
	{id: "Hopless or dissatisfied with treatment", name: "Hopless or dissatisfied with treatment"},
	{id: "Non Complaint with treatment", name: "Non Complaint with treatment"},
];

global.other_factors=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"},
];

global.clinical_status=[
	{id: "0", name: ""},
	{id: "Hoplessness", name: "Hoplessness"},
	{id: "Major depressive episode", name: "Major depressive episode"},
	{id: "Mixed affictive episode(e.g. Bipolar)", name: "Mixed affictive episode(e.g. Bipolar)"},
	{id: "Command hallucinations to hurt self", name: "Command hallucinations to hurt self"},
	{id: "Highly impulsive behavior", name: "Highly impulsive behavior"},
	{id: "Substance abuse or dependence", name: "Substance abuse or dependence"},
	{id: "Agitation or sever anxiety", name: "Agitation or sever anxiety"},
	{id: "Perceived burden on family or others", name: "Perceived burden on family or others"},
	{id: "Chronic physical pain or other acute medical", name: "Chronic physical pain or other acute medical"},
	{id: "Problems(HIV/AIDS/COPD/CANCER/etc.)", name: "Problems(HIV/AIDS/COPD/CANCER/etc.)"},
	{id: "Homicidal ideation", name: "Homicidal ideation"},
	{id: "Aggressive behavior towards others", name: "Aggressive behavior towards others"},
	{id: "Methods for suicide available(guns/pills/etc.)", name: "Methods for suicide available(guns/pills/etc.)"},
	{id: "Refuses or feels unable to agree to safety plant", name: "Refuses or feels unable to agree to safety plant"},
	{id: "Sexual abuse(lifetime)", name: "Sexual abuse(lifetime)"},
	{id: "Family history of suicide(lifetime)", name: "Family history of suicide(lifetime)"},
];

global.protective_factors=[
	{id: "0", name: ""},
	{id: "Identifies reason for living", name: "Identifies reason for living"},
	{id: "Supportive social network or family", name: "Supportive social network or family"},
	{id: "Fear of death or dying due to pain or suffering", name: "Fear of death or dying due to pain or suffering"},
	{id: "Belief that suicide is immortal; high spiritually", name: "Belief that suicide is immortal; high spiritually"},
	{id: "Engaged in work or school", name: "Engaged in work or school"},
];

global.interview_type=[
	{id: "0", name: ""},
	{id: "Intake", name: "Intake"},
	{id: "6-month follow-up: Did you conduct a follow-up interview?", name: "6-month follow-up: Did you conduct a follow-up interview?"},
	{id: "3-month follow-up: Did you conect a follow-up interview?", name: "3-month follow-up: Did you conect a follow-up interview?"},
	{id: "Discharge: did you conduct a discharge interview?", name: "Discharge: did you conduct a discharge interview?"},
];

global.client_type=[
	{id: "0", name: ""},
	{id: "Treatment client", name: "Treatment client"},
	{id: "Client in recovery", name: "Client in recovery"},
];

global.diagnose=[
	{id: "0", name: ""},
	{id: "Diagnosed", name: "Diagnosed"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
	{id: "None of the above", name: "None of the above"},
];

global.treatment=[
	{id: "0", name: ""},
	{id: "Primary", name: "Primary"},
	{id: "Secondary", name: "Secondary"},
	{id: "Tertiary", name: "Tertiary"},
	{id: "Unknown", name: "Unknown"},
];

global.sbirt_program=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
	{id: "No", name: "No"},
];

global.screening=[
	{id: "0", name: ""},
	{id: "Positive", name: ""},
	{id: "Negative", name: ""},
];

global.modality=[
	{id: "0", name: ""},
	{id: "Case Management", name: "Case Management"},
	{id: "Day Treatment", name: "Day Treatment"},
	{id: "Inpatient/Hospital (Other Than Detox)", name: "Inpatient/Hospital (Other Than Detox)"},
	{id: "Outpatient", name: "Outpatient"},
	{id: "Outreach", name: "Outreach"},
	{id: "Intensive Outpatient", name: "Intensive Outpatient"},
	{id: "Methadone", name: "Methadone"},
	{id: "Residential/Rehabilitation", name: "Residential/Rehabilitation"},
	{id: "After Care", name: "After Care"},
	{id: "Recovery Support", name: "Recovery Support"},
];

global.detoxification=[
	{id: "0", name: ""},
	{id: "Hospital Inpatient", name: "Hospital Inpatient"},
	{id: "Free-Standing Residential", name: "Free-Standing Residential"},
	{id: "Ambulatory Detoxification", name: "Ambulatory Detoxification"},
];

global.treatment_services=[
	{id: "0", name: ""},
	{id: "Screening", name: "Screening"},
	{id: "Brief Intervention", name: "Brief Intervention"},
	{id: "Brief Treatment", name: "Brief Treatment"},
	{id: "Referral to Treatment", name: "Referral to Treatment"},
	{id: "Assessment", name: "Assessment"},
	{id: "Individual Counseling", name: "Individual Counseling"},
	{id: "Group Counseling", name: "Group Counseling"},
	{id: "Family/Marriage Counseling", name: "Family/Marriage Counseling"},
	{id: "Co-Occurring Treatment/Recovery Services", name: "Co-Occurring Treatment/Recovery Services"},
	{id: "Pharmacological Interventions", name: "Pharmacological Interventions"},
	{id: "HIV/AIDS Counseling", name: "HIV/AIDS Counseling"},
];

global.case_manag_services=[
	{id: "0", name: ""},
	{id: "Family Services (Including Marriage Education; Parenting; Child Development Services)", name: "Family Services (Including Marriage Education; Parenting; Child Development Services)"},
	{id: "Child Care", name: "Child Care"},
	{id: "Individual Services Coordination", name: "Individual Services Coordination"},
	{id: "Transportation", name: "Transportation"},
	{id: "HIV/AIDS Service", name: "HIV/AIDS Service"},
	{id: "Supportive Transitional Drug-Free Housing Services", name: "Supportive Transitional Drug-Free Housing Services"},
];

global.employment_service=[
	{id: "0", name: ""},
	{id: "Pre-Employment", name: "Pre-Employment"},
	{id: "Employment Coaching", name: "Employment Coaching"},
];

global.medical_services=[
	{id: "0", name: ""},
	{id: "Medical Care", name: "Medical Care"},
	{id: "Alcohol/Drug Testing", name: "Alcohol/Drug Testing"},
	{id: "HIV/AIDS Medical Support and Testing", name: "HIV/AIDS Medical Support and Testing"},
];
global.aftercare_services=[
	{id: "0", name: ""},
	{id: "Continuing Care", name: "Continuing Care"},
	{id: "Relapse Prevention", name: "Relapse Prevention"},
	{id: "Recovery Coaching", name: "Recovery Coaching"},
	{id: "Self-Help and Support Groups", name: "Self-Help and Support Groups"},
	{id: "Spiritual Support", name: "Spiritual Support"},
];

global.education=[
	{id: "0", name: ""},
	{id: "Substance Abuse Education", name: "Substance Abuse Education"},
	{id: "HIV/AIDS Education", name: "HIV/AIDS Education"},
];

global.peer_rss=[
	{id: "0", name: ""},
	{id: "Peer Coaching or Mentoring", name: "Peer Coaching or Mentoring"},
	{id: "Housing Support", name: "Housing Support"},
	{id: "Alcohol- and Drug-Free Social Activities", name: "Alcohol- and Drug-Free Social Activities"},
	{id: "Information and Referral", name: "Information and Referral"},
];

global.ethnic_group=[
	{id: "0", name: ""},
	{id: "Central american", name: "Central american"},
	{id: "Cuban", name: "Cuban"},
	{id: "Dominican", name: "Dominican"},
	{id: "Mexican", name: "Mexican"},
	{id: "Puerto Rican", name: "Puerto Rican"},
	{id: "South American", name: "South American"},
	{id: "Other", name: "Other"},
	{id: "Don't know", name: "Don't know"},
	{id: "Refused", name: "Refused"},
];

global.ever_served=[
	{id: "0", name: ""},
	{id: "No", name: "No"},
	{id: "Yes, in the Armed Forces", name: "Yes, in the Armed Forces"},
	{id: "Yes, in the Reserves", name: "Yes, in the Reserves"},
	{id: "Yes, in the National Guard", name: "Yes, in the National Guard"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.active_duty=[
	{id: "0", name: ""},
	{id: "No, separated or retired from the armed forces, reserves, or national guard", name: "No, separated or retired from the armed forces, reserves, or national guard"},
	{id: "Yes, in the Armed Forces", name: "Yes, in the Armed Forces"},
	{id: "Yes, in the Reserves", name: "Yes, in the Reserves"},
	{id: "Yes, in the National Guard", name: "Yes, in the National Guard"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.combat_zone=[
	{id: "0", name: ""},
	{id: "Never deployed", name: "Never deployed"},
	{id: "Iraq or afghanistan (e.g., Operation Enduring Freedom [OEF]/Operation Iraqi Freedom [OIF]/Operation New Dawn [OND])", name: "Iraq or afghanistan (e.g., Operation Enduring Freedom [OEF]/Operation Iraqi Freedom [OIF]/Operation New Dawn [OND])"},
	{id: "Persian Gulf (Operation Desert Shield/Desert Storm)", name: "Persian Gulf (Operation Desert Shield/Desert Storm)"},
	{id: "Vietnam/Southeast Asia", name: "Vietnam/Southeast Asia"}, 
	{id: "Korea", name: "Korea"},
	{id: "WWII", name: "WWII"},
	{id: "Deployed to a combat zone not listed above (e.g., Bosnia/Somalia)", name: "Deployed to a combat zone not listed above (e.g., Bosnia/Somalia)"},
	{id: "Refused", name: "Refused"}, 
	{id: "Don't Know", name: "Don't Know"},
];

global.member_list=[
	{id: "0", name: ""},
	{id: "Mother", name: "Mother"},
	{id: "Father", name: "Father"},
	{id: "Brother", name: "Brother"},
	{id: "Sister", name: "Sister"},
	{id: "Spouse", name: "Spouse"},
	{id: "Partner", name: "Partner"},
	{id: "Child", name: "Child"},
	{id: "Other", name: "Other"},
];

global.route=[
	{id: "0", name: ""},
	{id: "Oral", name: "Oral"},
	{id: "Nasal", name: "Nasal"},
	{id: "Smoking", name: "Smoking"},
	{id: "Non-intravenous(IV) injection", name: "Non-intravenous(IV) injection"},
	{id: "IV", name: "IV"},
];

global.syringe_needle_used=[
	{id: "0", name: ""},
	{id: "Always", name: "Always"},
	{id: "More than half the time", name: "More than half the time"},
	{id: "Half the time", name: "Half the time"},
	{id: "Less than half the time", name: "Less than half the time"},
	{id: "Never", name: "Never"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
];

global.living_most=[
	{id: "0", name: ""},
	{id: "Shelter (safe havens, transitional living center [TLC], low-demand facilities, reception centers, other temporary day or evening facility)", name: "Shelter (safe havens, transitional living center [TLC], low-demand facilities, reception centers, other temporary day or evening facility)"},
	{id: "Street/outdoors (sidewalk, doorway, park, public or abandoned building)", name: "Street/outdoors (sidewalk, doorway, park, public or abandoned building)"},
	{id: "Institution (hospital, nursing home, jail/prison)", name: "Institution (hospital, nursing home, jail/prison)"},
	{id: "Housed - Own/rent apartment, room, or house", name: "Housed - Own/rent apartment, room, or house"},
	{id: "Housed - someone else's apartment, room, or house", name: "Housed - someone else's apartment, room, or house"},
	{id: "Housed - Dormitory/college residence", name: "Housed - Dormitory/college residence"},
	{id: "Housed - Halfway house", name: "Housed - Halfway house"},
	{id: "Housed - Residential treatment", name: "Housed - Residential treatment"},
	{id: "Housed - Other (specify below)", name: "Housed - Other (specify below)"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
];

global.living_space_cond=[
	{id: "0", name: ""},
	{id: "Very dissatisfied", name: "Very dissatisfied"},
	{id: "Dissatisfied", name: "Dissatisfied"},
	{id: "Neither satisfied nor dissatisfied", name: "Neither satisfied nor dissatisfied"},
	{id: "Very satisfied", name: "Very satisfied"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.stressful_things=[
	{id: "0", name: ""},
	{id: "Not at all", name: "Not at all"},
	{id: "Somewhat", name: "Somewhat"},
	{id: "Considerably", name: "Considerably"},
	{id: "Extremely", name: "Extremely"},
	{id: "Not applicable (use only if B1a AND B1C = 0)", name: "Not applicable (use only if B1a AND B1C = 0)"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.enroll_school_job_tra=[
	{id: "0", name: ""},
	{id: "Not enrolled", name: "Not enrolled"},
	{id: "Enrolled, full time", name: "Enrolled, full time"},
	{id: "Enrolled, part time", name: "Enrolled, part time"},
	{id: "Other (Please specify below)", name: "Other (Please specify below)"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.education_degree=[
	{id: "0", name: ""},
	{id: "Never attended", name: "Never attended"},
	{id: "1st Grade", name: "1st Grade"},
	{id: "2nd Grade", name: "2nd Grade"},
	{id: "3rd Grade", name: "3rd Grade"},
	{id: "4th Grade", name: "4th Grade"},
	{id: "5th Grade", name: "5th Grade"},
	{id: "6th Grade", name: "6th Grade"},
	{id: "7th Grade", name: "7th Grade"},
	{id: "8th Grade", name: "8th Grade"},
	{id: "9th Grade", name: "9th Grade"},
	{id: "10th Grade", name: "10th Grade"},
	{id: "11th Grade", name: "11th Grade"},
	{id: "12th Grade/High School Diploma/Equivalent", name: "12th Grade/High School Diploma/Equivalent"},
	{id: "College or University/1st Year Completed", name: "College or University/1st Year Completed"},
	{id: "College or University/2nd Year Completed/Associate's Degree (AA, AS)", name: "College or University/2nd Year Completed/Associate's Degree (AA, AS)"},
	{id: "College or University/3rd Year Completed", name: "College or University/3rd Year Completed"},
	{id: "Bachelor's Degree (BA, BS) or Higher", name: "Bachelor's Degree (BA, BS) or Higher"},
	{id: "Vocational/Technical (Voc/Tech) Program After High School, but no voc/tech diploma", name: "Vocational/Technical (Voc/Tech) Program After High School, but no voc/tech diploma"},
	{id: "Voc/Tech Diploma after high school", name: "Voc/Tech Diploma after high school"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.currently_employed=[
	{id: "0", name: ""},
	{id: "Employed, full time (35 + hours per week, or would have been)", name: "Employed, full time (35 + hours per week, or would have been)"},
	{id: "Employed, part time", name: "Employed, part time"},
	{id: "Unemployed, looking for work", name: "Unemployed, looking for work"},
	{id: "Unemployed, disabled", name: "Unemployed, disabled"},
	{id: "Unemployed, volunteer work", name: "Unemployed, volunteer work"},
	{id: "Unemployed, retired", name: "Unemployed, retired"},
	{id: "Unemployed, not looking for work", name: "Unemployed, not looking for work"},
	{id: "Other (please specify below)", name: "Other (please specify below)"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.money_meet_need=[
	{id: "0", name: ""},
	{id: "Not at all", name: "Not at all"},
	{id: "A little", name: "A little"},
	{id: "Moderately", name: "Moderately"},
	{id: "Mostly", name: "Mostly"},
	{id: "Completely", name: "Completely"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.health_rate=[
	{id: "0", name: ""},
	{id: "Excellent", name: "Excellent"},
	{id: "Very good", name: "Very good"},
	{id: "Good", name: "Good"},
	{id: "Fair", name: "Fair"},
	{id: "Poor", name: "Poor"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.quality_of_life=[
	{id: "0", name: ""},
	{id: "Very poor", name: "Very poor"},
	{id: "Poor", name: "Poor"},
	{id: "Neither poor nor good", name: "Neither poor nor good"},
	{id: "Good", name: "Good"},
	{id: "Very Good", name: "Very Good"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.psy_emo_problem=[
	{id: "0", name: ""},
	{id: "Not at all", name: "Not at all"},
	{id: "Slightly", name: "Slightly"},
	{id: "Moderately", name: "Moderately"},
	{id: "Considerably", name: "Considerably"},
	{id: "Extremely", name: "Extremely"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.hit_kick_slapp=[
	{id: "0", name: ""},
	{id: "Never", name: "Never"},
	{id: "A few times", name: "A few times"},
	{id: "More than a few times", name: "More than a few times"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.having_trouble=[
	{id: "0", name: ""},
	{id: "No one", name: "No one"},
	{id: "Clergy member", name: "Clergy member"},
	{id: "Family member", name: "Family member"},
	{id: "Friends", name: "Friends"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.client_subsequent=[
	{id: "0", name: ""},
	{id: "Client was reunited with child (or children)", name: "Client was reunited with child (or children)"},
	{id: "Client avoided out-of-home placement for child (or children)", name: "Client avoided out-of-home placement for child (or children)"},
	{id: "None of the above", name: "None of the above"},
	{id: "Don't Know", name: "Don't Know"},
];

global.rrc_benefits=[
	{id: "0", name: ""},
	{id: "Private health insurance", name: "Private health insurance"},
	{id: "Medicaid", name: "Medicaid"},
	{id: "Supplementary Security Income (SSI)/Social Security disability insurance (SSDI)", name: "Supplementary Security Income (SSI)/Social Security disability insurance (SSDI)"},
	{id: "Temporary Assistance for Needy Families (TANF)", name: "Temporary Assistance for Needy Families (TANF)"},
	{id: "Other (Specify Below)", name: "Other (Specify Below)"},
	{id: "None of the above", name: "None of the above"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.parent_fam_res=[
	{id: "0", name: ""},
	{id: "Strongly disagree", name: "Strongly disagree"},
	{id: "Disagree", name: "Disagree"},
	{id: "Undecided", name: "Undecided"},
	{id: "Agree", name: "Agree"},
	{id: "Strongly agree", name: "Strongly agree"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.funding_for_sbirt=[
	{id: "0", name: ""},
	{id: "Current SAMHSA grant funding", name: "Current SAMHSA grant funding"},
	{id: "State Funding", name: "State Funding"},
	{id: "Client's private insurance", name: "Client's private insurance"},
	{id: "Medicaid/Medicare", name: "Medicaid/Medicare"},
	{id: "Other (please specify below)", name: "Other (please specify below)"},
	{id: "None of the above", name: "None of the above"},
	{id: "Don't know", name: "Don't know"},
];

global.brief_intervention=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
    {id: "No", name: "No"},
    {id: "Refused", name: "Refused"},
];

global.brief_treatment=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
    {id: "No", name: "No"},
    {id: "Don't Know", name: "Don't Know"},
];

global.text_result=[
	{id: "0", name: ""},
	{id: "Positive", name: "Positive"},
	{id: "Negative", name: "Negative"},
	{id: "Indeterminate", name: "Indeterminate"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
];

global.quality_of_life=[
	{id: "0", name: ""},
	{id: "To a great extent", name: "To a great extent"},
	{id: "Somewhat", name: "Somewhat"},
	{id: "Very little", name: "Very little"},
	{id: "Not at all", name: "Not at all"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
];

global.client_scr_ment_heal_dis=[
	{id: "0", name: ""},
	{id: "Client screened positive", name: "Client screened positive"},
	{id: "Client screened negative (Skip to question 2)", name: "Client screened negative (Skip to question 2)"},
	{id: "Client was not screened (Skip to question 2)", name: "Client was not screened (Skip to question 2)"},
	{id: "Don't know (Skip to question 2)", name: "Don't know (Skip to question 2)"},
];

global.client_ref_ment_heal_ser=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
	{id: "No (Skip to question 2)", name: "No (Skip to question 2)"},
	{id: "Don't know (Skip to question 2)", name: "Don't know (Skip to question 2)"},
	{id: "Refused (Skip to question 2)", name: "Refused (Skip to question 2)"},
];

global.client_scr_pos_sub_dis=[
	{id: "0", name: ""},
	{id: "Client screened positive", name: "Client screened positive"},
	{id: "Client screened negative", name: "Client screened negative"},
	{id: "Client was not screened", name: "Client was not screened"},
	{id: "Don't know", name: "Don't know"},
];

global.follow_up_status=[
	{id: "0", name: ""},
	{id: "01 = Deceased at time of due date", name: "01 = Deceased at time of due date"},
	{id: "11 = Completed interview within specified window", name: "11 = Completed interview within specified window"},
	{id: "12 = Completed interview outside specified window", name: "12 = Completed interview outside specified window"},
	{id: "21 = Located, but refused, unspecified", name: "21 = Located, but refused, unspecified"},
	{id: "22 = Located, but unable to gain institutional access", name: "22 = Located, but unable to gain institutional access"},
	{id: "23 = Located, but otherwise unable to gain access", name: "23 = Located, but otherwise unable to gain access"},
	{id: "24 = Located, but withdrawn from project", name: "24 = Located, but withdrawn from project"},
	{id: "31 = Unable to locate, moved", name: "31 = Unable to locate, moved"},
	{id: "32 = Unable to locate, other (specify below)", name: "32 = Unable to locate, other (specify below)"},
];

global.discharge_status=[
	{id: "0", name: ""},
	{id: "01 = Completion/Graduate", name: "01 = Completion/Graduate"},
	{id: "02 = Termination", name: "02 = Termination"},
];

global.reas_for_termi=[
	{id: "0", name: ""},
	{id: "01 = Left on own against staff advice with satisfactory progress", name: "01 = Left on own against staff advice with satisfactory progress"},
	{id: "02 = Left on own against staff advice without satisfactory progress", name: "02 = Left on own against staff advice without satisfactory progress"},
	{id: "03 = Involuntarily discharged due to nonparticipation", name: "03 = Involuntarily discharged due to nonparticipation"},
	{id: "04 = Involuntarily discharged due to violation of rules", name: "04 = Involuntarily discharged due to violation of rules"},
	{id: "05 = Referred to another program or other services with satisfactory progress", name: "05 = Referred to another program or other services with satisfactory progress"},
	{id: "06 = Referred to another program or other services with unsatifactory progress", name: "06 = Referred to another program or other services with unsatifactory progress"},
	{id: "07 = Incarcerated due to offense committed while in treatment/recovery with satifactory progress", name: "07 = Incarcerated due to offense committed while in treatment/recovery with satifactory progress"},
	{id: "08 = Incarcerated due to offense committed while in treatment/recovery with unsatisfactory progress", name: "08 = Incarcerated due to offense committed while in treatment/recovery with unsatisfactory progress"},
	{id: "09 = Incarcerated due to old warrant or charged from before entering treatment/recovery with satisfactory progress", name: "09 = Incarcerated due to old warrant or charged from before entering treatment/recovery with satisfactory progress"},
	{id: "10 = Incarcerated due to old warrant or charged from before entering treatment/recovery with unsatisfactory progress", name: "10 = Incarcerated due to old warrant or charged from before entering treatment/recovery with unsatisfactory progress"},
	{id: "11 = Transferred to another facility for health reasons", name: "11 = Transferred to another facility for health reasons"},
	{id: "12 = Death", name: "12 = Death"},
	{id: "13 = Other (Please specify below)", name: "13 = Other (Please specify below)"},
];

global.hiv_test=[
	{id: "0", name: ""},
	{id: "Yes (skip to Section K)", name: "Yes (skip to Section K)"},
	{id: "No (go to question 4)", name: "No (go to question 4)"},
];

global.cope_without_substances=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"},
];

global.cope_with_life_stress=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"},
];

global.what_services=[
	{id: "0", name: ""},
	{id: "Item1", name: "Item1"},
	{id: "Item2", name: "Item2"},
	{id: "Item3", name: "Item3"},
	{id: "Item4", name: "Item4"},
	{id: "Item5", name: "Item5"},
	{id: "Item6", name: "Item6"},
	{id: "Item7", name: "Item7"},
	{id: "Item8", name: "Item8"},
];

global.unable_treatment=[
	{id: "0", name: ""},
	{id: "Item1", name: "Item1"},
	{id: "Item2", name: "Item2"},
	{id: "Item3", name: "Item3"},
	{id: "Item4", name: "Item4"},
	{id: "Item5", name: "Item5"},
	{id: "Item6", name: "Item6"},
	{id: "Item7", name: "Item7"},
	{id: "Item8", name: "Item8"},
];

global.to_where=[
	{id: "0", name: ""},
	{id: "Item1", name: "Item1"},
	{id: "Item2", name: "Item2"},
	{id: "Item3", name: "Item3"},
	{id: "Item4", name: "Item4"},
	{id: "Item5", name: "Item5"},
	{id: "Item6", name: "Item6"},
	{id: "Item7", name: "Item7"},
	{id: "Item8", name: "Item8"},
];

global.coninute_follow_up=[
	{id: "0", name: ""},
    {id: "Yes", name: "Yes"},
    {id: "No", name: "No"},
    {id: "Don't Know", name: "Don't Know"},
    {id: "Refused to say", name: "Refused to say"},
];

global.client_contact=[
	{id: "0", name: ""},
	{id: "Phone", name: "Phone"},
	{id: "Email", name: "Email"},
	{id: "Text", name: "Text"},
];

global.reason_for_change=[
	{id: "0", name: ""},
	{id: "Client moving to long-term follow-up", name: "Client moving to long-term follow-up"},
	{id: "Client being discharged", name: "Client being discharged"},
];

global.treatment_type=[
	{id: "0", name: ""},
	{id: "Patient Did Not Begin Treatment", name: "Patient Did Not Begin Treatment"},
	{id: "Detox", name: "Detox"},
	{id: "MAT", name: "MAT"},
	{id: "Faith Based", name: "Faith Based"},
	{id: "Inpatient", name: "Inpatient"},
	{id: "Outpatient", name: "Outpatient"},
	{id: "Sober Living", name: "Sober Living"},
];

global.mat_clinic=[
	{id: "0", name: ""},
	{id: "Aletheia House", name: "Aletheia House"},
	{id: "Awakenings Recovery", name: "Awakenings Recovery"},
	{id: "BHG Bessemer", name: "BHG Bessemer"},
	{id: "Birmingham Fellowship House", name: "Birmingham Fellowship House"},
	{id: "Birmingham Metro", name: "Birmingham Metro"},
	{id: "Fritz Clinic", name: "Fritz Clinic"},
	{id: "Kolbe Clinic", name: "Kolbe Clinic"},
	{id: "UAB Beacon", name: "UAB Beacon"},
];

global.refused_contact=[
	{id: "0", name: ""},
	{id: "Wants to seek treatment on their own", name: "Wants to seek treatment on their own"},
	{id: "Contact to frequent", name: "Contact to frequent"},
	{id: "Other", name: "Other"},
	{id: "Refused", name: "Refused"},
	{id: "Don't Know", name: "Don't Know"},
];

global.unable_to_contact=[
	{id: "0", name: ""},
	{id: "Phone disconnected", name: "Phone disconnected"},
	{id: "Stopped answering or returning calls", name: "Stopped answering or returning calls"},
	{id: "Other", name: "Other"},
];
global.attempted_suicide=[
	{id: "0", name: ""},
	{id: "Past 3 months", name: "Past 3 months"},
	{id: "Lifetime", name: "Lifetime"},
	{id: "Never", name: "Never"},
	{id: "Don't Know", name: "Don't Know"},
	{id: "Refused", name: "Refused"},
];

global.opioids_specify=[
	{id: "0", name: ""},
	{id: "option 1", name: "option 1"},
	{id: "option 2", name: "option 2"},
	{id: "option 3", name: "option 3"},
	{id: "option 4", name: "option 4"},
	{id: "option 5", name: "option 5"},
];


global.sexual_activity=[
	{id: "0", name: ""},
	{id: "Yes", name: "Yes"},
	{id: "No", name: "No"},
	{id: "Not permitted to ask", name: "Not permitted to ask"},
	{id: "Refused", name: "Refused"},
	{id: "Don't know", name: "Don't know"},
];