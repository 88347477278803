import React from 'react'
import {Prompt, NavLink} from 'react-router-dom'
import Modal from './Modals/Modal/index';
import $ from 'jquery';
export class Test extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = () => {
  console.log('closing Modal');
  this.setState({
   modalVisible: false
 })
}


 handleBlockedNavigation = (nextLocation) => {
   const {confirmedNavigation} = this.state
   //const {shouldBlockNavigation} = this.props
   if (!confirmedNavigation/* && shouldBlockNavigation(nextLocation)*/){
       this.showModal(nextLocation)
       return false
   }
   
   return true
 }

 handleNotConfirmNavigationClick = () => {
   const {lastLocation} = this.state;
   if (lastLocation) {
   window.location.href = lastLocation.pathname;
 }
 }

 handleConfirmNavigationClick = () => {
   //const {navigate} = this.props
   const {lastLocation} = this.state
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true,
         modalVisible: false
      });

      //save the information and move on

      window.location.href = lastLocation.pathname;
   }
 }

 submit(){
  console.log('save button clicked');  
  $('#phq9form').submit(); 

 }
 render() {
   const {when} = this.props
   const {modalVisible, lastLocation, closeModal} = this.state

   setTimeout(() =>this.submit(), 1000)

   $(document).ready(function () {
        console.log('checking')
            console.log('checking',$('#phq9form').validate({
              debug:true,
              onkeyup:true,
              submitHandler: function() { alert("Submitted!") },
          rules: {
           
          },      
          errorElement: 'span',
          errorPlacement: function (error, element) {
            error.addClass('invalid-feedback');
            element.closest('tr, .form-group').append(error);
          },
          highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
          },
          unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
          }
        }));
            });


   return (
     <>
     <form id="phq9form">
     <table>
     <tr>
     <td><input type="radio" name="a" value="a" required/> A</td>
     <td><input type="radio" name="a" value="b" /> B</td>
     <td><input type="radio" name="a" value="c" /> C</td>
     </tr>
     </table>
     <div className="form-group">
     </div>
      <div className="form-group">
     <input type="text" name="b" required /> A<br/>
     </div>
     <button type="button" onClick={this.submit}>Submit</button></form>
        
     </>
   )
 }
}
export default Test