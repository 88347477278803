import React,{ useState, useEffect} from "react";
import styled from "styled-components";
import { CSVLink } from 'react-csv';

import CoverTableHeaderContainer from "./CoverTableHeader";
import Filter from "./CoverFilter";

import BjaTableContainer from "./BjaTableContainer";
// import { sampleData } from "./ClientsStackBar";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    fontSize: "1rem",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const MainContainer = styled.section`
box-sizing:border-box;
  width: 100%;
  margin: 50px 16px 0 16px;
  // border:2px solid red;
`;


// header design 

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Title = styled.span`
  flex: 0 0 40%;
  @media (max-width: 1370px) {
    flex: 0 0 30%;
  }
`;

const FilterContainer = styled.div`
  width:100%;
  display: flex;
  flex: 0 0 60%;
  justify-content: end;
  @media (max-width: 1370px) {
    flex: 0 0 70%;
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 150px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
`;

const width="220px";



// header design ends 

function BjaSourcesTable({title, filter, filterOptions }) {

  const [date, setDate] = useState("");
  const [age, setage] = useState("2021");
  const classes = useStyles();
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [client, setClient] = useState([]);
  const [getBjaReport, setBjaReport] = useState([]);
  

  const [isGender, setGender] = useState(false);

   const sampleData = [
    {
      name: "Male",
      data: [
        { x: "January", val: 1 },
        { x: "Feburary", val: 2 },
        { x: "March", val: 3 },
        { x: "April", val: 4 },
        { x: "May", val: 5 },
        { x: "June", val: 6 },
        { x: "July", val: 7 },
        { x: "August", val: 8 },
        { x: "September", val: 9 },
        { x: "October", val: 10 },
        { x: "November", val: 11 },
        { x: "December", val: 12 },
      ],
    },
    {
      name: "Female",
      data: [
        { x: "January", val: 100 },
        { x: "Feburary", val: 200 },
        { x: "March", val: 500 },
        { x: "April", val: 300 },
        { x: "May", val: 150 },
        { x: "June", val: 300 },
        { x: "July", val: 200 },
        { x: "August", val: 100 },
        { x: "September", val: 400 },
        { x: "October", val: 120 },
        { x: "November", val: 350 },
        { x: "December", val: 250 },
      ],
    },
    {
      name: "Trans",
      data: [
        { x: "January", val: 100 },
        { x: "Feburary", val: 200 },
        { x: "March", val: 500 },
        { x: "April", val: 300 },
        { x: "May", val: 150 },
        { x: "June", val: 300 },
        { x: "July", val: 200 },
        { x: "August", val: 100 },
        { x: "September", val: 400 },
        { x: "October", val: 120 },
        { x: "November", val: 350 },
        { x: "December", val: 250 },
      ],
    },
    {
      name: "Blank",
      data: [
        { x: "January", val: 100 },
        { x: "Feburary", val: 100 },
        { x: "March", val: 100 },
        { x: "April", val: 100 },
        { x: "May", val: 100 },
        { x: "June", val: 100 },
        { x: "July", val: 100 },
        { x: "August", val: 100 },
        { x: "September", val: 100 },
        { x: "October", val: 100 },
        { x: "November", val: 100 },
        { x: "December", val: 100 },
      ],
    },
  ];
  
  const optionData = sampleData[0].data;
 

  const arr = [];
  
  
  
  const options=[
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
  ];

//    const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  // const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  


  

  const yearhandleChange = (event) => {
       
    const {
      target: { value },
    } = event;
    setYear(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
      console.log("Year handle change");
      console.log(event);
  
  };

  const monthhandleChange = (event) => {
       
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );

  
  };

  
  useEffect(() => {
     
    fetchBjaReportsList(year, month);
  }, [year, month]);

  const fetchBjaReportsList = (year, month) => {
  //  for year  //
    if(year == undefined || year == "") {
        year = "2021";
    }
    else {
        year = year.toString();
    }
    
    // for month //
    if(month == undefined || month == "") {
      month = 0;
    }
    else {

      
      if(month.length > 0) {
        month.forEach(function(item, i) {
           if (item == "January") {
              arr[i] = 1;
           }
           if (item == "Feburary") {
            arr[i] = 2;
           }
           if (item == "March") {
            arr[i] = 3;
           }
           if (item == "April") {
            arr[i] = 4;
           }
           if (item == "May") {
            arr[i] = 5;
           }
           if (item == "June") {
            arr[i] = 6;
           }
           if (item == "July") {
            arr[i] = 7;
           }
           if (item == "August") {
            arr[i] = 8;
           }
           if (item == "September") { 
            arr[i] = 9;
           }
           if (item == "October") { 
            arr[i] = 10;
           }
           if (item == "November") {  
            arr[i] = 11;
           }
           if (item == "December") { 
            arr[i] = 12;
           }
          }
        );

      }
      
      month = arr.toString();
     
       
      
    }

      // for gender
      axios.get(API_URL+`bja_report_list?Token=${sessionStorage.getItem('__TOKEN__')}&year=${year}&month=04`)
      .then((response) => {
        console.log(response);
        setBjaReport(response.data.data);
      
      })
      .catch((error) => {
        console.log(error)
      });
    
    
};


const sampleData1 = [
    {
      "x": "GPRA Client ID",
      "val": 100
    },
    {
      "x": "Entry Date",
      "val": 100
      
    },
    {
      "x": "First Name",
      "val": 100
    },
    {
      "x": "Last Name",
      "val": 100
    },
    {
      "x": "Specific Referral Source",
      "val": 100
    },
    {
      "x": "Ref. Target",
      "val": 100
    },
    {
      "x": "Agency Target",
      "val": 100
    },
    {
      "x": "Assigned Peer Support Specialist",
      "val": 100
    },
    {
      "x": "Discharge",
      "val": 100
    },
    {
      "x": "Participation Type",
      "val": 100
    },
    {
      "x": "Received MAT",
      "val": 100
    },
    {
      "x": "Overdose",
      "val": 100
    },
    {
      "x": "Follow-Up Contacts",
      "val": 100
    },
    {
      "x": "Referral & Treatment Days",
      "val": 100
    },
    {
      "x": "Duration",
      "val": 100
    }
  ];



  const printheaders = [
    { label: 'Source', key: 'source' },
    { label: 'GPRA Client ID', key: 'GPRA_Client_ID' },
    { label: 'Entry Date', key: 'Entry_Date' },
    { label: 'First Name', key: 'First_Name' },
    { label: 'Last Name', key: 'Last_Name' },
    { label: 'Specific Referral Source', key: 'Specific_Referral_Source' },
    { label: 'Ref. Target', key: 'Ref_Target' },
    { label: 'Agency Target', key: 'Agency_Target' },
    { label: 'Assigned Peer Support Specialist', key: 'Assigned_Peer_Support_Specialist' },
    { label: 'Discharge', key: 'Discharge' },
    { label: 'Participation Type', key: 'RRC_Participation_Type' },
    { label: 'Received MAT', key: 'Received_MAT_Post_RRC' },
    { label: 'Overdose', key: 'Overdose_Post_RRC' },
    { label: 'Follow-Up Contacts', key: 'Follow_Up_Contacts' },
    { label: 'Referral & Treatment Days', key: 'Days_Between_RRC_Referral_Treatment' },
    { label: 'Duration', key: 'duration' },
    { label: 'Total', key: 'Total' }
    
];


// const downloadArr = [];

//   for(let index = 0; index < getBjaReport.length; index++) {
    
//     downloadArr.push({  Source:getBjaReport[index].source,
//                         GPRA_Client_ID:getBjaReport[index].GPRA_Client_ID,
//                         Entry_Date:getBjaReport[index].Entry_Date,
//                         First_Name:getBjaReport[index].First_Name,
//                         Last_Name:getBjaReport[index].Last_Name,
//                         Specific_Referral_Source:getBjaReport[index].Specific_Referral_Source,
//                         Ref_Target:getBjaReport[index].Ref_Target,
//                         Agency_Target:getBjaReport[index].Agency_Target,
//                         Assigned_Peer_Support_Specialist:getBjaReport[index].Assigned_Peer_Support_Specialist,
//                         Discharge:getBjaReport[index].Discharge,
//                         RRC_Participation_Type:getBjaReport[index].RRC_Participation_Type,
//                         Received_MAT_Post_RRC:getBjaReport[index].Received_MAT_Post_RRC,
//                         Overdose_Post_RRC:getBjaReport[index].Overdose_Post_RRC,
//                         Follow_Up_Contacts:getBjaReport[index].Follow_Up_Contacts,
//                         Days_Between_RRC_Referral_Treatment: getBjaReport[index].Days_Between_RRC_Referral_Treatment,
//                         duration : getBjaReport[index].duration,
//                         Total:getBjaReport[index].total,
//                         });
//       }
   


//  console.log("getBjaReport");
//  console.log(getBjaReport);


  return (
    <MainContainer>
      
      <HeaderContainer>
      <Title>{title}</Title>
      <FilterContainer  
      
      >
    {/******************************************* for Multi year *********************  */}
        <div 
          
          style={{ height: "50px" }}
          selected={age}
          value={age}
          placeholder="Show Data for Year 2021"
          width="220px"
         
          
         >
      
        <FormControl
          sx={{
            m: 1,
            width: 150,
          }}
          style={{
            width: width,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "50px",
            border: "none",
            margin: "0px 10px 0px 10px",
          }}
        >
          <InputLabel
            id="demo-multiple-checkbox-label"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
  
              paddingTop: "20px",
              transform: "none",
              transition: "none",
            }}
          >
            {year.length !== 0 ? "" : "Show Data for Year 2021"}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={year}
            
            onChange={yearhandleChange}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            className={classes.root}
            style={{ width: width }}
          >
            {options.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  // color="#7BB731"
                  style={{ color: "#7BB731" }}
                  checked={year.indexOf(name) > -1}
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
         {/* year multiselect end */}

        {/* <Filter
          selected={age}
          value={age}
          placeholder="Show Data for Year 2021"
          width="220px"
          
          options={[
            "2021",
            "2020",
            "2019",
            "2018",
            "2017",
          ]}
          
        />  */}
      {/******************************************* for Multi year end *********************  */}

      {/********************************************for Multi Month ************************** */}
      {/* <div  style={{ height: "50px" }}
            selected={month}
            placeholder="All Months"
            width="150px"
      >
        <FormControl
          sx={{
            m: 1,
            width: 150,
          }}
          style={{
            width: width,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "50px",
            border: "none",
            m:4,
            margin: "0px 10px 0px 10px",
          }}
        >
          <InputLabel
            id="demo-multiple-checkbox-label"
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
  
              paddingTop: "20px",
              transform: "none",
              transition: "none",
            }}
          >
            {month.length !== 0 ? "" : "All Months"}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={month}
            onChange={monthhandleChange}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            className={classes.root}
            style={{ width: width }}
          >
            {optionData.map((name) => (
              <MenuItem key={name.x} value={name.x}>
                <Checkbox
                  // color="#7BB731"
                 
                  style={{ color: "#7BB731" }}
                  checked={month.indexOf(name.x) > -1}
                />
                <ListItemText primary={name.x} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div> */}

       {/********************************************for Multi Month ends ************************** */}

      {/******************************************* for month  start *********************  */}

         {/* <Filter placeholder={filter} width="150px" options={options}  /> */}
        {/* <Filter
         selected={month}
          placeholder="All Months"
          width="150px"
          
          options={sampleData[0].data.map((e) => e.x)}
          
        /> */}

        
        {/* <Button data={genderData[0].data}>Download CSV</Button> */}
        <Button> <CSVLink filename='bja_report.csv' data={getBjaReport} headers={printheaders} target="_blank"  style ={{color: "#ffffff"}}>Download CSV  </CSVLink></Button>
       
        
      </FilterContainer>
      
    </HeaderContainer>
    {/* <div  style ={{margin: "10px"}}>
      <Title>{title}</Title>
    </div> */}

    {/* <CoverTableContainer genderData = {getSampleData1} /> */}
    <BjaTableContainer  genderData = {getBjaReport} bjaReportHeader ={sampleData1} />
     

    </MainContainer>
  );
}

export default BjaSourcesTable;
