import moment from "moment";
import React, { Component } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { newMultiSelectOptionGenerator } from "../ReusableComponents/OptionsGenerator";
import services from "../services";
import Patientinfoheader from "./Patientinfoheader";
import Sidenav from "./Sidenav";
import { Alert } from "./toastify";
import Topnav from "./Topnav";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import "../App.css";
import 'react-toastify/dist/ReactToastify.css';
import TextAreaAutosize from 'react-textarea-autosize';
import swal from "sweetalert";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class BioMedicalCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_medical_problem: '',
      current_medical_problems_explain: '',
      pregnant: '',
      due_date: '',
      weeks: '',
      prenatal_care: '',
      medical_conditions_comment: '',
      number_of_Pregnancies: '',
      known_allergies: '',
      known_allergies_explain: '',
      had_tb_tested_positive: '',
      had_tb_tested_positive_explain: '',
      sputum_producing_cough: '',
      sputum_producing_cough_explain: '',
      cough_up_blood: '',
      cough_up_blood_explain: '',
      loss_of_appetite: '',
      loss_of_appetite_explain: '',
      night_sweats: '',
      night_sweats_explain: '',
      fever: '',
      fever_explain: '',
      tb_medication: '',
      tb_medication_explain: '',
      notes: '',

      biomedical_risk_rating: "",
      bioMedications:[
        {
          bio_medication:"",
          bio_prescribedFor:"",
          bio_dosage:"",
          bio_frequency:"",
          bio_taking_as_prescribed:"",
          bio_taking_as_directed:"",
        }
      ],

      biomedical_comments: "",
      form_type: props.form_type,
      biomedical_risk_rating_information: '',
      Saved: true,
      isSaveAndComplete: false,
      isRiskratingSummuryMandatory: false,
      validation: {
        number_of_Pregnancies_error: "",
        Pregnancies_week_error: "",
      }
    };
  }

  dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: this.props.form_type,
    uploaded_by: sessionStorage.getItem("username"),

  }
  

  biomedical_risk_rating0 = `Fully functioning and able to cope with any physical discomfort or pain.`
  biomedical_risk_rating1 = `Adequate ability to cope with physical discomfort. Mild to moderate symptoms (such as mild to moderate pain) interfere with daily functioning.`
  biomedical_risk_rating2 = `Some difficulty tolerating physical problems. Acute, non-life threatening medical symptoms are present. Serious biomedical problems are neglected.`
  biomedical_risk_rating3 = `Serious medical problems are neglected during outpatient TX. Severe medical problems are present but stable. Poor ability to cope w/physical problems.`
  biomedical_risk_rating4 = `The patient is incapacitated, with severe medical problems.`

  async componentDidMount() {
    this.loadData();
  }
  
  handleChange1 = (date) => {
    this.setState({ Saved: false });
    if (!date) {
      this.setState({ weeks: "", due_date: null }, () => {
        this.props.childToParent(this.state);
      });
    } else {
      let current_date = moment();
      let due_date = moment(date);
      const formattedDueDate = due_date.format('MM-DD-YYYY');
      const pregnancyDate = due_date.subtract(252, 'days');
      let diff = moment.duration(current_date.diff(pregnancyDate));
      let weeks = Math.floor(diff.asWeeks());
      this.setState({ weeks: weeks, due_date: formattedDueDate }, () => {
        this.props.childToParent(this.state);
      });
    }
  }

  handleChange = (e,index) => {
    this.setState({ Saved: false });
    const updatedMedications = [...this.state.bioMedications];
    const updatedMedication = { ...updatedMedications[index], [e.target.name]: e.target.value };
    updatedMedications[index] = updatedMedication;
    this.setState({ bioMedications: updatedMedications });
    if (e.target.name == "weeks") {
      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        validation.Pregnancies_week_error = "* Only numbers are allowed";
        this.setState({ validation: validation })

      } else {
        if (e.target.value.length > 2) {
          let validation = this.state.validation;
          validation.Pregnancies_week_error = "*More than 2 numbers are not allowed";
          this.setState({ validation: validation });
        } else {
          this.setState({ [e.target.name]: e.target.value });
        }
      }
    } else if (e.target.name == "number_of_Pregnancies") {

      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        validation.number_of_Pregnancies_error = "* Only numbers are allowed";
        this.setState({ validation: validation })

      } else {
        if (e.target.value.length > 2) {
          let validation = this.state.validation;
          validation.number_of_Pregnancies_error = "* More than 2 numbers are not allowed";
          this.setState({ validation: validation });
        } else {
          this.setState({ [e.target.name]: e.target.value });
        }
      }
    }else {
      this.setState({ [e.target.name]: e.target.value });
    }
    if (e.target.name == "current_medical_problem" && e.target.value == "no") {
      this.setState({ ["current_medical_problem"]: e.target.value });
    }else if (e.target.name == "current_medical_problems_explain" && e.target.value == "no") {
      this.setState({ ["current_medical_problems_explain"]: e.target.value });
    } else if (e.target.name == "pregnant" && e.target.value == "no") {
      this.setState({ ["due_date"]: "", ["weeks"]: "", ["prenatal_care"]: "", ["number_of_Pregnancies"]: "", });
    } else if (e.target.name == "known_allergies" && e.target.value == "no") {
      this.setState({ ["known_allergies"]: e.target.value });
    } else if (e.target.name == "had_tb_tested_positive" && e.target.value == "no") {
      this.setState({ ["had_tb_tested_positive_explain"]: e.target.value });
    } else if (e.target.name == "sputum_producing_cough" && e.target.value == "no") {
      this.setState({ ["sputum_producing_cough"]: e.target.value });
    } else if (e.target.name == "cough_up_blood" && e.target.value == "no") {
      this.setState({ ["cough_up_blood"]: e.target.value });
    } else if (e.target.name == "loss_of_appetite" && e.target.value == "no") {
      this.setState({ ["loss_of_appetite"]: e.target.value });
    } else if (e.target.name == "night_sweats" && e.target.value == "no") {
      this.setState({ ["night_sweats"]: e.target.value });
    } else if (e.target.name == "fever" && e.target.value == "no") {
      this.setState({ ["fever"]: e.target.value });
    } else if (e.target.name == "tb_medication" && e.target.value == "no") {
      this.setState({ ["tb_medication"]: e.target.value });
    } else if (e.target.name == "biomedical_risk_rating") {
      let val = e.target.value;
      switch (e.target.value) {
        case "0": this.setState({ ["biomedical_risk_rating_information"]: this.biomedical_risk_rating0, ["biomedical_risk_rating"]: 0 })
          break;
        case "1": this.setState({ ["biomedical_risk_rating_information"]: this.biomedical_risk_rating1, ["biomedical_risk_rating"]: 1 })
          break;
        case "2": this.setState({ ["biomedical_risk_rating_information"]: this.biomedical_risk_rating2, ["biomedical_risk_rating"]: 2 })
          break;
        case "3": this.setState({ ["biomedical_risk_rating_information"]: this.biomedical_risk_rating3, ["biomedical_risk_rating"]: 3 })
          break;
        case "4": this.setState({ ["biomedical_risk_rating_information"]: this.biomedical_risk_rating4, ["biomedical_risk_rating"]: 4 })
          break;
      }
      this.props.riskRatingDecisionWithValue(e.target.value);
      if(val >= 2 && this.state.biomedical_comments.length < 1){
        this.setState({isRiskratingSummuryMandatory : true});
      }else{
        this.setState({isRiskratingSummuryMandatory : false});
      }
    }else if(e.target.name == 'biomedical_comments'){
      if(e.target.value.length < 1 && this.state.biomedical_risk_rating >= 2){
        this.setState( {isRiskratingSummuryMandatory : true});
      }else{
        this.setState( {isRiskratingSummuryMandatory : false});
      }
      this.setState({ ["recovery_mental_comments"]: e.target.value });
      this.props.riskRatingDecisionWithComment(e.target.value);
      this.props.riskRatingDecisionWithValue(this.state.biomedical_risk_rating);
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 400)
    // if (e.target.name === 'due_date') {
    //   if (!e.target.value) {
    //     this.setState({ 'weeks': "" }, () => {
    //       this.props.childToParent(this.state);
    //     });
    //   } else {
    //     let current_date = moment();
    //     let due_date = moment(e.target.value, 'Y-MM-DD');
    //     const pregnancyDate = moment(due_date).subtract(252, 'days');
    //     let diff = moment.duration(current_date.diff(pregnancyDate));
    //     let weeks = Math.floor(diff.asWeeks());
    //     this.setState({ 'weeks': weeks, 'due_date': e.target.value }, () => {
    //       this.props.childToParent(this.state);
    //     });
    //   }
    // }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if(this.state.biomedical_risk_rating >= 2 && !this.state.biomedical_comments.length > 0) {
      this.setState({ isRiskratingSummuryMandatory: true });
      alert("Risk rating summary field is mandatory.");
    }
    else {
      let final_data = {
        BioMedicalCondition: this.state,
        dataListBody: this.dataListBody,
      };
      let res = await axios.post(
        global.restApiURL + "customRest/savebiomedicalcondition",
        JSON.stringify(final_data)
      );
      if (res.data.success == true) {
        toast.success('Data added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_biomedicalCondition',
        });
        this.loadData();
      }
    }
  };

  loadData = async (e) => {
    if (!this.props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customrest/getBioMedicalCondition",
        JSON.stringify(this.dataListBody)
      );
      if (res.status == 200 && res.data.success == true) {
        let bioMedications = res.data.data.BioMedicalCondition.bioMedications || [];
  
        // Add a default row if problemBlock is empty
        if (bioMedications.length === 0) {
          bioMedications = [
            {
              bio_medication: "",
              bio_prescribedFor: "",
              bio_dosage: "",
              bio_frequency: "",
              bio_taking_as_prescribed: "",
              bio_taking_as_directed: "",
            },
          ];
        }
        this.setState({
          current_medical_problem: res.data.data.BioMedicalCondition.current_medical_problem,
          current_medical_problems_explain: res.data.data.BioMedicalCondition.current_medical_problems_explain,
          pregnant: res.data.data.BioMedicalCondition.pregnant,
          due_date: res.data.data.BioMedicalCondition.due_date,
          weeks: res.data.data.BioMedicalCondition.weeks,
          prenatal_care: res.data.data.BioMedicalCondition.prenatal_care,
          medical_conditions_comment: res.data.data.BioMedicalCondition.medical_conditions_comment,
          number_of_Pregnancies: res.data.data.BioMedicalCondition.number_of_Pregnancies,
          known_allergies: res.data.data.BioMedicalCondition.known_allergies,
          known_allergies_explain: res.data.data.BioMedicalCondition.known_allergies_explain,
          had_tb_tested_positive: res.data.data.BioMedicalCondition.had_tb_tested_positive,
          had_tb_tested_positive_explain: res.data.data.BioMedicalCondition.had_tb_tested_positive_explain,
          sputum_producing_cough: res.data.data.BioMedicalCondition.sputum_producing_cough,
          sputum_producing_cough_explain: res.data.data.BioMedicalCondition.sputum_producing_cough_explain,
          cough_up_blood: res.data.data.BioMedicalCondition.cough_up_blood,
          cough_up_blood_explain: res.data.data.BioMedicalCondition.cough_up_blood_explain,
          loss_of_appetite: res.data.data.BioMedicalCondition.loss_of_appetite,
          loss_of_appetite_explain: res.data.data.BioMedicalCondition.loss_of_appetite_explain,
          night_sweats: res.data.data.BioMedicalCondition.night_sweats,
          night_sweats_explain: res.data.data.BioMedicalCondition.night_sweats_explain,
          fever: res.data.data.BioMedicalCondition.fever,
          fever_explain: res.data.data.BioMedicalCondition.fever_explain,
          tb_medication: res.data.data.BioMedicalCondition.tb_medication,
          tb_medication_explain: res.data.data.BioMedicalCondition.tb_medication_explain,
          notes: res.data.data.BioMedicalCondition.notes,
          biomedical_risk_rating_information: res.data.data.BioMedicalCondition.biomedical_risk_rating_information,

          // responseID: 0,
          biomedical_risk_rating: res.data.data.BioMedicalCondition.biomedical_risk_rating,
          bioMedications:bioMedications,

          biomedical_comments: res.data.data.BioMedicalCondition.biomedical_comments,
          Saved: true,

        });
        // this.setState({ biomedical_comments:res.data.data.BioMedicalCondition.biomedical_comments})
        setTimeout(() => {
          this.props.childToParent(this.state);
        }, 1000)

      }
    } else {
      // let final_data = { dataListBody: this.dataListBody  }
      let res = await axios.post(
        global.restApiURL + "customrest/getBioMedicalConditionTemp",
        JSON.stringify(this.dataListBody)
      );
      if (res.status == 200 && res.data.success == true) {
        let bioMedications = res.data.data.BioMedicalCondition.bioMedications || [];
  
        // Add a default row if problemBlock is empty
        if (bioMedications.length === 0) {
          bioMedications = [
            {
              bio_medication: "",
              bio_prescribedFor: "",
              bio_dosage: "",
              bio_frequency: "",
              bio_taking_as_prescribed: "",
              bio_taking_as_directed: "",
            },
          ];
        }
        this.setState({
          current_medical_problem: res.data.data.BioMedicalCondition.current_medical_problem,
          current_medical_problems_explain: res.data.data.BioMedicalCondition.current_medical_problems_explain,
          pregnant: res.data.data.BioMedicalCondition.pregnant,
          due_date: res.data.data.BioMedicalCondition.due_date,
          weeks: res.data.data.BioMedicalCondition.weeks,
          prenatal_care: res.data.data.BioMedicalCondition.prenatal_care,
          medical_conditions_comment: res.data.data.BioMedicalCondition.medical_conditions_comment,
          number_of_Pregnancies: res.data.data.BioMedicalCondition.number_of_Pregnancies,
          known_allergies: res.data.data.BioMedicalCondition.known_allergies,
          known_allergies_explain: res.data.data.BioMedicalCondition.known_allergies_explain,
          had_tb_tested_positive: res.data.data.BioMedicalCondition.had_tb_tested_positive,
          had_tb_tested_positive_explain: res.data.data.BioMedicalCondition.had_tb_tested_positive_explain,
          sputum_producing_cough: res.data.data.BioMedicalCondition.sputum_producing_cough,
          sputum_producing_cough_explain: res.data.data.BioMedicalCondition.sputum_producing_cough_explain,
          cough_up_blood: res.data.data.BioMedicalCondition.cough_up_blood,
          cough_up_blood_explain: res.data.data.BioMedicalCondition.cough_up_blood_explain,
          loss_of_appetite: res.data.data.BioMedicalCondition.loss_of_appetite,
          loss_of_appetite_explain: res.data.data.BioMedicalCondition.loss_of_appetite_explain,
          night_sweats: res.data.data.BioMedicalCondition.night_sweats,
          night_sweats_explain: res.data.data.BioMedicalCondition.night_sweats_explain,
          fever: res.data.data.BioMedicalCondition.fever,
          fever_explain: res.data.data.BioMedicalCondition.fever_explain,
          tb_medication: res.data.data.BioMedicalCondition.tb_medication,
          tb_medication_explain: res.data.data.BioMedicalCondition.tb_medication_explain,
          notes: res.data.data.BioMedicalCondition.notes,
          biomedical_risk_rating_information: res.data.data.BioMedicalCondition.biomedical_risk_rating_information,

          // responseID: 0,
          biomedical_risk_rating: res.data.data.BioMedicalCondition.biomedical_risk_rating,
          bioMedications:bioMedications,

          biomedical_comments: res.data.data.BioMedicalCondition.biomedical_comments,
          Saved: true,
          isSaveAndComplete: true,
        });
        // this.setState({ biomedical_comments:res.data.data.BioMedicalCondition.biomedical_comments})
      }
    }

  };
  addRows = (e) => {
    e.preventDefault();
    const data = {
      bio_medication:"",
      bio_prescribedFor:"",
      bio_dosage:"",
      bio_frequency:"",
      bio_taking_as_prescribed:"",
      bio_taking_as_directed:"",
      
    };
    this.setState({
      bioMedications: [...this.state.bioMedications, data],
    });

    this.setState({ Saved: false })
  };
  deleteRow = (index) => {
    const updatedMedications = [...this.state.bioMedications];
    const rowToRemove = updatedMedications[index];
    const isRowNotBlank = Object.values(rowToRemove).some(value => value.trim() !== '');
    if (isRowNotBlank) {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this row?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          updatedMedications.splice(index, 1);
          this.setState({ bioMedications: updatedMedications, Saved: false }, () => {
            // Call the childToParent function after state update
            this.props.childToParent(this.state);
          });
        }
      });
    } else {
      // Remove the blank row without showing the confirmation modal
      updatedMedications.splice(index, 1);
      this.setState({ bioMedications: updatedMedications, Saved: false }, () => {
        // Call the childToParent function after state update
        this.props.childToParent(this.state);
      });
    }
  };

  render() {
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card shadow-none">
                          <div>
                            <p className="lead float-left" style={{ marginBottom: '1rem' }}>
                              <b className="text-green">
                                Biomedical conditions and complications
                              </b>
                            </p>
                          </div>
                          <div>
                            <div className="row marginBottom1 mb-2">
                              <div className="col-md-8">
                                Do you have any current medical problems, including infectious communicable diseases?
                              </div>
                              <div className="col-md-4">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="current_medical_problem" onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes" selected={this.state.current_medical_problem == "Yes" ? true : false}>Yes</option>
                                  <option value="no" selected={this.state.current_medical_problem == "No" ? true : false}>No</option>
                                </select>
                              </div>
                            </div>
                            {this.state.current_medical_problem == "Yes" &&
                              <div className="row marginBottom1">
                                <div className="col-md-12">
                                  <label>Please explain</label>
                                  <TextAreaAutosize 
                                    className="form-control" 
                                    minRows={(this.state.current_medical_problems_explain?.length/110) + this.state.current_medical_problems_explain?.split(/\r\n|\r|\n/).length}
                                    // minRows={(adultAssesmentData.acute_comments.length/110) + adultAssesmentData.acute_comments.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    style={{overflow:'hidden'}} 
                                    value={this.state.current_medical_problems_explain} 
                                    name="current_medical_problems_explain" 
                                    onChange={this.handleChange} 
                                  />
                                </div>
                              </div>
                            }
                            <div className="row marginBottom1">
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label>Are you pregnant?</label>
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="pregnant" onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.pregnant == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.pregnant == "no" ? true : false}>No</option>
                                    <option value="na" selected={this.state.pregnant == "na" ? true : false}>NA</option>
                                    <option value="unknown" selected={this.state.pregnant == "unknown" ? true : false}>Unknown</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              {this.state.pregnant == "yes" && <>
                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label>Due date </label>
                                    <DatePicker
                                      selected={this.state.due_date ? moment(this.state.due_date).toDate() : null}
                                      onChange={this.handleChange1}
                                      disabled={this.state.isSaveAndComplete}
                                      dateFormat="MM/dd/yyyy"
                                      placeholder="MM/DD/YYYY"
                                      className="form-control"
                                      minDate={ new Date()}
                                      showIcon
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label>If yes, how many weeks?</label>
                                    <input type="text" name="weeks" disabled={this.state.isSaveAndComplete} value={this.state.weeks} className="form-control" onChange={this.handleChange} maxlength="2" />
                                    <span style={{ color: "red" }}>{this.state.validation.Pregnancies_week_error}</span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label>If yes, are you receiving prenatal care? </label>
                                    <select name="prenatal_care" disabled={this.state.isSaveAndComplete} className="form-control" onChange={this.handleChange}>
                                      <option value="">Select</option>
                                      <option value="yes" selected={this.state.prenatal_care == "yes" ? true : false}>Yes</option>
                                      <option value="no" selected={this.state.prenatal_care == "no" ? true : false}>No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                  <div className="form-group">
                                    <label>Total Number of Pregnancies</label>
                                    <input type="text" disabled={this.state.isSaveAndComplete} name="number_of_Pregnancies" value={this.state.number_of_Pregnancies} maxLength={2} className="form-control" onChange={this.handleChange} />
                                    <span style={{ color: "red" }}>{this.state.validation.number_of_Pregnancies_error}</span>
                                  </div>
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="form-group" >
                                <div className="col-md-12">
                                  <button
                                    className="btn bt-sm btn-secondary mt-2 mr-3 float-right"
                                    onClick={this.addRows}
                                    disabled={this.state.isSaveAndComplete} 
                                  >
                                    <i className="fa fa-plus mr-1"></i>Add
                                  </button>
                                </div>
                                  <label>
                                    List any medications you currently take for <strong>medical conditions</strong>, have taken, or should take including over the counter medications:
                                  </label>
                                  <div style={{ overflowX: "scroll"}} >
                                  <table className="table table-bordered table-striped"style={{ width: "max-content" }}>
                                    <thead>
                                      <th>Medication</th>
                                      <th>Prescribed For</th>
                                      <th>Dosage</th>
                                      <th>Frequency</th>
                                      <th>Taking as Prescribed?</th>
                                      <th>If OTC, Taking as Directed?</th>
                                    </thead>
                                    <tbody>
                                    {this.state.bioMedications.map((medication, index) => (
                                        <tr key={index}>
                                          <td className="td1">
                                            <TextAreaAutosize
                                              name="bio_medication"
                                              value={medication.bio_medication}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.bio_medication?.length / 24) + medication.bio_medication?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextAreaAutosize
                                              name={"bio_prescribedFor"}
                                              value={medication.bio_prescribedFor}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.bio_prescribedFor?.length / 24) + medication.bio_prescribedFor?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextAreaAutosize
                                              name={"bio_dosage"}
                                              value={medication.bio_dosage}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.bio_dosage?.length / 24) + medication.bio_dosage?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td3">
                                            <TextAreaAutosize
                                              name={"bio_frequency"}
                                              value={medication.bio_frequency}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.bio_frequency?.length / 24) + medication.bio_frequency?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td4">
                                            <select
                                              className="form-control"
                                              value={medication.bio_taking_as_prescribed}
                                              name={"bio_taking_as_prescribed"}
                                              onChange={(e) => this.handleChange(e, index)}
                                              disabled={this.state.isSaveAndComplete}
                                            >
                                              <option>Select</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </td>
                                          <td className="td5">
                                            <select
                                              className="form-control"
                                              value={medication.bio_taking_as_directed}
                                              name={"bio_taking_as_directed"}
                                              onChange={(e) => this.handleChange(e, index)}
                                              disabled={this.state.isSaveAndComplete}
                                            >
                                              <option>Select</option>
                                              <option value="yes">Yes</option>
                                              <option value="no">No</option>
                                            </select>
                                          </td>
                                          
                                          {index !== 0 && (
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                                onClick={() => this.deleteRow(index)}
                                                disabled={this.state.isSaveAndComplete}
                                              >
                                                Remove
                                              </button>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                      
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Comments</label>
                                  <TextAreaAutosize 
                                    minRows={(this.state.medical_conditions_comment?.length/110) + this.state.medical_conditions_comment?.split(/\r\n|\r|\n/).length}
                                    style={{overflow:'hidden'}} 
                                    disabled={this.state.isSaveAndComplete} 
                                    name="medical_conditions_comment" 
                                    value={this.state.medical_conditions_comment} 
                                    onChange={this.handleChange} 
                                    className="form-control" 
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className='col-lg-4 col-md-8 mb-2'>
                                <label>Do you have any known allergies (including food or medications)?</label>
                              </div>
                              <div className='col-lg-2 col-md-4 mb-2'>
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="known_allergies" value={this.state.known_allergies} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes" selected={this.state.known_allergies == "yes" ? true : false}>Yes</option>
                                  <option value="no" selected={this.state.known_allergies == "no" ? true : false}>No</option>
                                </select>
                              </div>
                              {this.state.known_allergies == "yes" && <>
                                <div className='col-lg-6 col-md-12 mb-2'>
                                  <TextAreaAutosize 
                                    style={{overflow:'hidden'}} 
                                    minRows={(this.state.known_allergies_explain?.length/46) + this.state.known_allergies_explain?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    placeholder="Please explain" 
                                    value={this.state.known_allergies_explain} 
                                    className="form-control" 
                                    name="known_allergies_explain" 
                                    onChange={this.handleChange} 
                                  />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className='col-lg-4 col-md-8 mb-2'>
                                <label>Have you had TB or tested positive for TB in the past?</label>
                              </div>
                              <div className='col-lg-2 col-md-4 mb-2'>
                                <select className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.had_tb_tested_positive} name="had_tb_tested_positive" onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes" selected={this.state.had_tb_tested_positive == "yes" ? true : false}>Yes</option>
                                  <option value="no" selected={this.state.had_tb_tested_positive == "no" ? true : false}>No</option>
                                </select>
                              </div>
                              {/* {this.state.had_tb_tested_positive == "yes" && <>
                                <div className='col-lg-6 col-md-12 mb-2'>
                                  <TextAreaAutosize rows={1} disabled={this.state.isSaveAndComplete} placeholder="Please explain" value={this.state.had_tb_tested_positive_explain} className="form-control" name="had_tb_tested_positive_explain" onChange={this.handleChange} />
                                </div>
                              </>} */}
                            </div>

                            {this.state.had_tb_tested_positive == "yes" && <>
                              <div className="row marginBottom1">
                                <div className="col-md-12">
                                  For more than two weeks do you…. (consider
                                  possible withdrawal symptoms)
                                </div>
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label>1. Have sputum-producing cough?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="sputum_producing_cough" disabled={this.state.isSaveAndComplete} value={this.state.sputum_producing_cough} className="form-control" onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.sputum_producing_cough == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.sputum_producing_cough == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.sputum_producing_cough == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                      disabled={this.state.isSaveAndComplete} 
                                      minRows={(this.state.sputum_producing_cough_explain?.length/46) + this.state.sputum_producing_cough_explain?.split(/\r\n|\r|\n/).length}
                                      style={{overflow:'hidden'}} 
                                      placeholder="Please explain" 
                                      value={this.state.sputum_producing_cough_explain} 
                                      name="sputum_producing_cough_explain" 
                                      className="form-control" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label>2. Cough up blood?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="cough_up_blood" disabled={this.state.isSaveAndComplete} className="form-control" value={this.state.cough_up_blood} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.cough_up_blood == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.cough_up_blood == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.cough_up_blood == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                    style={{overflow:'hidden'}} 
                                    minRows={(this.state.cough_up_blood_explain?.length/46) + this.state.cough_up_blood_explain?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    placeholder="Please explain" 
                                    value={this.state.cough_up_blood_explain} 
                                    name="cough_up_blood_explain" 
                                    className="form-control" 
                                    onChange={this.handleChange} 
                                  />
                                  </div>
                                </>}
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label> 3. Have loss of appetite?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="loss_of_appetite" disabled={this.state.isSaveAndComplete} value={this.state.loss_of_appetite} className="form-control" onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.loss_of_appetite == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.loss_of_appetite == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.loss_of_appetite == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                      style={{overflow:'hidden'}} 
                                      minRows={(this.state.loss_of_appetite_explain?.length/46) + this.state.loss_of_appetite_explain?.split(/\r\n|\r|\n/).length}
                                      disabled={this.state.isSaveAndComplete} 
                                      placeholder="Please explain" 
                                      value={this.state.loss_of_appetite_explain} 
                                      name="loss_of_appetite_explain" 
                                      className="form-control" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label> 4. Have night sweats?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="night_sweats" disabled={this.state.isSaveAndComplete} value={this.state.night_sweats} className="form-control" onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.night_sweats == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.night_sweats == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.night_sweats == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                      style={{overflow:'hidden'}} 
                                      minRows={(this.state.night_sweats_explain?.length/46) + this.state.night_sweats_explain?.split(/\r\n|\r|\n/).length}
                                      disabled={this.state.isSaveAndComplete} 
                                      placeholder="Please explain" 
                                      value={this.state.night_sweats_explain} 
                                      name="night_sweats_explain" 
                                      className="form-control" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label>5. Have a fever?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="fever" className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.fever} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.fever == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.fever == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.fever == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                      style={{overflow:'hidden'}} 
                                      minRows={(this.state.fever_explain?.length/46) + this.state.fever_explain?.split(/\r\n|\r|\n/).length}
                                      disabled={this.state.isSaveAndComplete} 
                                      placeholder="Please explain" 
                                      value={this.state.fever_explain} 
                                      name="fever_explain" 
                                      className="form-control" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                              <div className="row marginBottom1">
                                <div className='col-lg-4 col-md-8 mb-2'>
                                  <label> 6. Receive a TB medication?</label>
                                </div>
                                <div className='col-lg-2 col-md-4 mb-2'>
                                  <select name="tb_medication" disabled={this.state.isSaveAndComplete} className="form-control" value={this.state.tb_medication} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="yes" selected={this.state.tb_medication == "yes" ? true : false}>Yes</option>
                                    <option value="no" selected={this.state.tb_medication == "no" ? true : false}>No</option>
                                  </select>
                                </div>
                                {this.state.tb_medication == "yes" && <>
                                  <div className='col-lg-6 col-md-12 mb-2'>
                                    <TextAreaAutosize 
                                      style={{overflow:'hidden'}} 
                                      minRows={(this.state.tb_medication_explain?.length/46) + this.state.tb_medication_explain?.split(/\r\n|\r|\n/).length}
                                      disabled={this.state.isSaveAndComplete} 
                                      placeholder="Please explain" 
                                      value={this.state.tb_medication_explain} 
                                      name="tb_medication_explain" 
                                      className="form-control" 
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </>}
                              </div>
                            </>}

                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Notes or comments</label>
                                  <TextAreaAutosize 
                                    name="notes" 
                                    disabled={this.state.isSaveAndComplete} 
                                    value={this.state.notes} 
                                    minRows={(this.state.notes?.length/110) + this.state.notes?.split(/\r\n|\r|\n/).length}
                                    style={{overflow:'hidden'}} 
                                    className="form-control" 
                                    onChange={this.handleChange} 
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="green_band">
                                  <div className="row">
                                    <div className='col-lg-4 col-md-8 mb-2'>
                                      <p className="lead sa_risk">
                                        <b>Substance Use Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className='col-lg-4 col-md-8 mb-2'>
                                      <div className="form-group">
                                        <input className="autoMargin" type="radio" disabled={this.state.isSaveAndComplete} data-tip data-for="label0" name="biomedical_risk_rating" value="0" onChange={this.handleChange} checked={this.state.biomedical_risk_rating === 0 ? true : false} />&nbsp;
                                        <label className="labelMargin">0</label>
                                        <input className="autoMargin" type="radio" disabled={this.state.isSaveAndComplete} data-tip data-for="label1" name="biomedical_risk_rating" value="1" onChange={this.handleChange} checked={this.state.biomedical_risk_rating == 1 ? true : false} />&nbsp;
                                        <label className="labelMargin">1</label>
                                        <input className="autoMargin" type="radio" disabled={this.state.isSaveAndComplete} data-tip data-for="label2" name="biomedical_risk_rating" value="2" onChange={this.handleChange} checked={this.state.biomedical_risk_rating == 2 ? true : false} />{""}&nbsp;
                                        <label className="labelMargin">2</label>
                                        <input className="autoMargin" type="radio" disabled={this.state.isSaveAndComplete} data-tip data-for="label3" name="biomedical_risk_rating" value="3" onChange={this.handleChange} checked={this.state.biomedical_risk_rating == 3 ? true : false} />&nbsp;
                                        <label className="labelMargin">3</label>
                                        <input className="autoMargin" type="radio" disabled={this.state.isSaveAndComplete} data-tip data-for="label4" name="biomedical_risk_rating" value="4" onChange={this.handleChange} checked={this.state.biomedical_risk_rating == 4 ? true : false} />&nbsp;
                                        <label className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ReactTooltip id="label0" place="bottom" effect="solid" className="toolTipLabel0">{this.biomedical_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label1" place="bottom" effect="solid" className="toolTipLabel1">{this.biomedical_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label2" place="bottom" effect="solid" className="toolTipLabel2">{this.biomedical_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label3" place="bottom" effect="solid" className="toolTipLabel3">{this.biomedical_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label4" place="bottom" effect="solid" className="toolTipLabel4">{this.biomedical_risk_rating4}</ReactTooltip>
                            <div className="row">
                              <div className='col-md-12'>
                                Diagnostic summary for this dimension
                              </div>
                              <hr />
                              <div className=' col-12 mb-2'>
                                <div className='form-group'>
                                  <label>Analysis</label>
                                  <TextAreaAutosize 
                                    minRows={this.state.biomedical_risk_rating == 1 || this.state.biomedical_risk_rating == 2 || this.state.biomedical_risk_rating == 3 ? 2 : 1}
                                    className="form-control" 
                                    name="biomedical_risk_rating_information" 
                                    autoFocus 
                                    style={{ backgroundColor: '#FEE5A1', overflow: "hidden"}} 
                                    value={this.state.biomedical_risk_rating_information} 
                                  />
                                </div>
                              </div>
                              <div className='col -12 mb-2'>
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextAreaAutosize 
                                    className="form-control"
                                    minRows={(this.state.biomedical_comments?.length/110) + this.state.biomedical_comments?.split(/\r\n|\r|\n/).length} 
                                    disabled={this.state.isSaveAndComplete} 
                                    style={{overflow:'hidden'}} 
                                    name="biomedical_comments" 
                                    value={this.state.biomedical_comments} 
                                    onChange={this.handleChange}
                                  />
                                  {(this.state.isRiskratingSummuryMandatory && !this.state.biomedical_comments.length > 0) && <> <span style={{color:"red"}}>*This field is mandatory.</span></>}
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <button type="submit" data-save="save" disabled={this.state.Saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div >
        </div >
      </div >
    );
  }
}
