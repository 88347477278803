import Axios from 'axios';
import React, { Component, Fragment } from 'react';
import Topnav from './Topnav';
import Footer from './Footer';
import '../global.js';
import Loader from './Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const axios = require('axios').default;
var context;

class changePassword extends Component {
    constructor(props) {
        super(props);
        context = this;
        this.state = {
            token: '',
            old_Password: '',
            new_Password: '',
            confirm_Password: '',
            user: '',
            error: false,
            showLoader: false,
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
        };

        this.onChange = this.onChange.bind(this);
        this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    }

    componentWillMount() {
        var loggeduser = sessionStorage.getItem('loggedinUserID');
        this.setState({ user: loggeduser });
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        this.setState({ token: token });
    }

    onChange = (e) => {
        let name = e.target.name;
        this.setState({ [name]: e.target.value });
    };

    changePasswordSubmit = () => {
        if ((this.state.old_Password || this.state.new_Password || this.state.confirm_Password) === '') {
            this.setState({ error: 'Password cannot be blank' });
        } else {
            if (this.state.new_Password === this.state.confirm_Password) {
                console.log(this.state.token);
                if (this.state.old_Password !== '') {
                    context.setState({ showLoader: true });
                    axios.post(global.restApiURL + 'customRest/validateToken', JSON.stringify({
                        'old_pwd': btoa(this.state.old_Password),
                        'new_pwd': btoa(this.state.new_Password),
                        'type': "change_password",
                        'apikey': sessionStorage.getItem('__TOKEN__')
                    })).then(function (res) {
                        var response = res.data;
                        console.log(response);
                        if (response.success) {
                            context.setState({ showLoader: false });
                            console.log(response.msg);
                            alert(response.msg);
                            window.location.href = "/";
                        } else {
                            context.setState({ showLoader: false });
                            alert(response.msg);
                        }
                    }).catch(function (error) {
                        context.setState({ showLoader: false });
                        console.log(error);
                        alert("old password is incorrect");
                    });
                }
                else {
                    context.setState({ showLoader: true });
                    axios.post(global.restApiURL + 'customRest/validateToken', JSON.stringify({
                        'token': this.state.token,
                        'password': btoa(this.state.new_Password),
                    })).then(function (res) {

                        var response = res.data;
                        console.log(response);
                        // this.setState({showLoader : false});
                        if (response.success == true && res.status == 200) {
                            context.setState({ showLoader: false });
                            console.log(response.message);
                            alert(response.msg);
                            window.location.href = "/";
                        } else {
                            context.setState({ showLoader: false });
                            alert(response.msg);
                        }
                    })
                        .catch(function (error) {
                            context.setState({ showLoader: false });
                            console.log(error);
                            alert("Invalid token");
                        });
                }

            } else {
                context.setState({ error: "New Password and Confirm Password not matched" });
                console.log("New Password and Confirm Password not matched");
            }
        }
    };

    checkPassword = (e) => {
        let name = e.target.name;
        if (e.target.value.length < 8) {
            console.log("Password is less than 8 characters");
            this.setState({ [name]: '', error: 'Password needs to be more than 8 characters' });
        } else {
            var pass = e.target.value;
            var reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
            var test = reg.test(pass);
            if (test) {
                console.log("Password is fine");
            } else {
                console.log("Password is incorrect");
                this.setState({ [name]: '', error: 'Password must contain uppercase, lowercase, numbers and symbols' });
                return false;
            }
        }
    };

    onFocus = () => {
        this.setState({ error: '' });
    };

    togglePasswordVisibility(field) {
        this.setState((prevState) => ({
            [field]: !prevState[field]
        }));
    }

    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    {this.state.user && <Topnav />}
                    <div className="content-wrapper">
                        <section className="content">
                            {this.state.user == null &&
                                <div className="login-logo" style={{ top: '80px', position: 'relative' }}>
                                    <a href="#">
                                        <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
                                    </a><br /> <br />
                                    <h5 style={{ fontSize: '1.35rem', color: '#89bc3b' }}>Collaboration Health Information<br /> for Peer Support Specialists</h5>
                                </div>
                            }
                            <form method="post" id="changePassword">
                                <div className="card mx-auto" style={{ width: '50%', top: '150px' }}>
                                    <div className="card-header" >
                                        <h3 className="card-title" style={{ color: '#89bc3b', fontWeight: 'bolder' }}>{this.state.user ? 'Change Password' : 'Reset Password'}</h3>
                                    </div>
                                    <div className="card-body">
                                        <span style={{ color: 'grey', fontSize: '12px', position: 'relative', bottom: '16px' }}>Please enter a password with a minimum of 8 characters which contains uppercase, lowercase, numbers, and symbols. </span><br />
                                        <span style={{ color: 'red' }}>{this.state.error}</span>
                                        {this.state.user &&
                                            <div className="form-group">
                                                <label>Old Password</label>
                                                <div className="input-group">
                                                    <input type={this.state.showOldPassword ? "text" : "password"} className="form-control" minLength={8} required id="old_Password" value={this.state.old_Password} name="old_Password" placeholder="Old Password" onChange={this.onChange} autoComplete="off" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" onClick={() => this.togglePasswordVisibility('showOldPassword')}>
                                                            <FontAwesomeIcon icon={this.state.showOldPassword ? faEyeSlash : faEye} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <div className="input-group">
                                                <input type={this.state.showNewPassword ? "text" : "password"} className="form-control" onBlur={this.checkPassword} onKeyPress={this.onFocus} minLength={8} required id="new_Password" value={this.state.new_Password} name="new_Password" placeholder="New Password" onChange={this.onChange} autoComplete="off" />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.togglePasswordVisibility('showNewPassword')}>
                                                        <FontAwesomeIcon icon={this.state.showNewPassword ? faEyeSlash : faEye} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <div className="input-group">
                                                <input type={this.state.showConfirmPassword ? "text" : "password"} className="form-control" onKeyPress={this.onFocus} minLength={8} required id="confirm_Password" value={this.state.confirm_Password} name="confirm_Password" placeholder="Confirm Password" onChange={this.onChange} autoComplete="off" />
                                                <div className="input-group-append">
                                                    <span className="input-group-text" onClick={() => this.togglePasswordVisibility('showConfirmPassword')}>
                                                        <FontAwesomeIcon icon={this.state.showConfirmPassword ? faEyeSlash : faEye} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="button" className="btn btn-success float-right btnmargin" value="save" onClick={this.changePasswordSubmit}> <i className="fas fa-save"></i> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </section>
                    </div>
                    {this.state.user && <Footer sidebar={false} />}
                    {this.state.showLoader && <Loader />}
                </div>
            </React.Fragment>
        );
    }
}

export default changePassword;
