import { defaultThemes } from 'react-data-table-component';
global.tocken = '';
global.initial_multiselect_values = 0;
global.traversArray = [];
global.route = [];
global.traversArray["intake-basicinfo"] = "emergencycontact";
global.traversArray["emergencycontact"] = "consent";
global.traversArray["otheragency"] = "hangout";
global.traversArray["hangout"] = "cases";
global.traversArray["cases"] = "homelessness";
global.traversArray["homelessness"] = "intakeassessment";
global.traversArray["consent"] = "consent-for-roi";
global.traversArray["consent-for-roi"] = "otheragency";
global.traversArray["intakeassessment"] = "supplementaryinfo";
global.traversArray["lastnightstay"] = "supplementaryinfo"; 
global.traversArray["supplementaryinfo"] = "recoverygoals"; 
global.traversArray["recoverygoals"] = "health_screenings";
global.traversArray["gadscreening"] = "phq9"; 
global.traversArray["phq9"] = "qlesqsf";
global.traversArray["qlesqsf"] = "cssr";
global.traversArray["health_screenings"] = "followup";
global.traversArray["followup"] = "notes";
global.traversArray["notes"] = "referrals";
global.traversArray["referrals"] = "routing";
global.traversArray["routing"] = "change_participation";
global.traversArray["change_participation"] = "state_assessment";
global.traversArray["state_assessment"] = "clients";

global.class_to_url = {
  'Dasboard' : {name:'Dashboard', url: '/dashboard', pimcore_path:'/Dashboard'},
  'index' : {name:'index', url: '/myclients', pimcore_path:'/User_Dashboard'},
  'userManagement' : {name:'userManagement', url: '/userManagement', pimcore_path:'/userManagement'},
  'Authorization' : {name:'Authorization', url: '/consent-for-roi', pimcore_path:'/IntakeForm/BasicInfo/Authorization'},
  'Assessment' : {name:'Assessment', url: '/intakeassessment', pimcore_path:'/IntakeForm/Intake Assessment/Assessment'},
  'ClientInformation' : {name:'Client Information', url: '/intake-basicinfo', pimcore_path:'/IntakeForm/BasicInfo/ClientInformation'},
  'ConsentDetails' : {name:'Consent', url: '/consent', pimcore_path:'/IntakeForm/Consent'},
  'CSSR' : {name:'CSSR', url: '/health_screenings', pimcore_path:'/HealthScreenings'},
  'EmergencyContact' : {name:'Additional Contacts', url: '/emergencycontact', pimcore_path:'/IntakeForm/BasicInfo/EmergencyContact'},
  'FinalAssessments' : {name:'State Assessment', url: '/state_assessment', pimcore_path:'/FinalAssessments'},
  'Followups' : {name:'Follow-up', url: '/followup', pimcore_path:'/FollowUps'},
  'GADScreeningTool' : {name:'GAD Screening', url: '/health_screenings', pimcore_path:'/HealthScreenings'},
  'HangoutDetails' : {name:'Hangout Details', url: '/hangout', pimcore_path:'/IntakeForm/BasicInfo/Hangout'},
  'Notes' : {name:'Notes', url: '/notes', pimcore_path:'/Notes'},
  'OtherAgency' : {name:'Other Agency Details', url: '/otheragency', pimcore_path:'/IntakeForm/BasicInfo/OtherAgencies'},
  'ParticipationChangeInformation' : {name:'Participation Change', url: '/change_participation', pimcore_path:'/ParticipationChangeInfo'},
  'PHQ9' : {name:'PHQ-9', url: '/health_screenings', pimcore_path:'/HealthScreenings'},
  'ProbationParoleCasesDetails' : {name:'Probation/Parole/Cases Details', url: '/cases', pimcore_path:'/IntakeForm/BasicInfo/ProbationParoleCases'},
  'QLESQSF' : {name:'Q-LES-Q-SF', url: '/health_screenings', pimcore_path:'/HealthScreenings'},
  'RecoveryGoals' : {name:'Recovery Goals', url: '/recoverygoals', pimcore_path:'/RecoveryGoals'},
  'Referrals' : {name:'Referrals', url: '/referrals', pimcore_path:'/Referrals'},
  'RoutingForm' : {name:'Routings', url: '/routing', pimcore_path:'/RoutingForm'},
  'SupplementaryInformation' : {name:'Supplementary Information', url: '/supplementaryinfo', pimcore_path:'/IntakeForm/Intake Assessment/SupplementaryInformation'},
  'HomelessnessDetails' : {name:'Homelessness Details', url: '/homelessness', pimcore_path:'/IntakeForm/BasicInfo/Homelessness'},
  'HospitalROI': {name:'Hospital ROI',url :'/verbal-consent',pimcore_path:'/IntakeForm/HospitalROI'},
  'ConsentForm': {name:'Consent Form',url :'/consent7',pimcore_path:'/IntakeForm/ConsentForm'}

}

global.permissions = sessionStorage.getItem('permissions') !== null && sessionStorage.getItem('permissions') !== '' && sessionStorage.getItem('permissions') !== undefined ? JSON.parse(sessionStorage.getItem('permissions')) : {};

global.traversArray["out"] = "logout";

global.validationObj = [];

global.roles = {'Administrator': '64',
                'Staff' : '24',
                'Clinical Specialist': '25',
                'webservice User': '9',
                'Peer Support Specialist': '15',
                'SuperAdmin' : '29'}

global.is_staff = sessionStorage.getItem('__Res__') === global.roles['Staff'] ? true : false;
global.is_admin = sessionStorage.getItem('__Res__') === global.roles['Administrator'] ? true : false;
global.is_super_admin = sessionStorage.getItem('__Res__') === global.roles['SuperAdmin'] ? true:false
global.is_peer_specialist = sessionStorage.getItem('__Res__') === global.roles['Peer Support Specialist'] ? true :  false;
global.is_clinical_specialist  = sessionStorage.getItem('__Res__') === global.roles['Clinical Specialist'] ? true : false;
                 

global.is_client_inactive =  sessionStorage.getItem('client_status') !== 'Active' ? true : false;
global.is_client_status_waiting_for_admin_approval = ['Activate', 'Inactivate'].includes(sessionStorage.getItem('client_status'))  ? true : false;
global.is_client_suspended = sessionStorage.getItem('client_status') === 'Suspended' ? true : false;



global.route["routing"] = "routing";
global.route["referral"] = "referral";

//var restApiURL = 'http://13.126.201.102:8080/';
// global.restApiURL = 'https://chipss.tactionsoft.com/';
global.restApiURL = 'https://api.chipss.org/';
// global.domain = 'http://localhost:3000';
global.domain = 'https://chipss.org/';

global.no_record_found_text = "No record found"

global.save_url = global.restApiURL+'webservice/rest/object?apikey='+sessionStorage.getItem('__TOKEN__') ;
global.save_recovery_goals = global.restApiURL+'customRest/saveRecoveryGoals';

//datatable css
global.paginationComponentOptions={noRowsPerPage:'false'}
global.customTableStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle : "solid",
      borderWidth : "0px",
      borderColor : "#F9F9F9",
      fontSize: "1em",
      display:"block",
    },
  },
  header: {
    style: {  
      minHeight: '0px',
      fontSize: "1em",

    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      minHeight:'35px',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
      backgroundColor : "#F9F9F9",
      fontSize: "1em"
    },
  },
  headCells: {
    style: {
      '&:not(:first-of-type)': {
        borderRightStyle: 'solid',
        padding: ".5em",
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight : "bold",
        fontSize: "1em",
        overflow: "hidden"
      },
      ':first-of-type': {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        padding: ".5em",
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        borderLeftColor: defaultThemes.default.divider.default,
        fontWeight : "bold",
        fontSize: "1em",
        overflow: "hidden"
      },
    },
  },
  rows: {
  	style:{
  		minHeight:'35px',
  	},
    stripedStyle: {
        '&:nth-child(odd)': {
          backgroundColor: defaultThemes.default.background.default,
        },
        '&:nth-child(even)': {
          backgroundColor: '#fcfcfc'
        },
        '&:last-child': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: defaultThemes.default.divider.default,
        },

      },
      highlightOnHoverStyle: {
        backgroundColor: '#c4e2ff',
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        '&:not(:last-of-type)': {
          borderBottomColor: '#c4e2ff',
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: '#c4e2ff',
        }
      },
  },
  cells: {
    style: {
      '&:not(:first-of-type)': {
        borderRightStyle: 'solid',
        padding: ".5em",
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        borderRightColor: defaultThemes.default.divider.default,
        fontSize: "14px",
        overflow: "hidden",
      },
      ':first-of-type': {
        borderLeftStyle: 'solid',
        padding: ".5em",
        borderLeftWidth: '1px',
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: "14px",
        overflow: "hidden"
      },
    },
  },
  pagination: {
    style:{
      justifyContent: 'flex-start',
    },
    pageButtonsStyle:{
      color:'#007bff',
      fill:'#007bff'
    }
  }

};

sessionStorage.setItem('client_basicinfosave_url', global.restApiURL+'customRest/saveClientInfo');
sessionStorage.setItem('emergency_contact_save_url', global.restApiURL+'webservice/rest/object?apikey='+sessionStorage.getItem('__TOKEN__'));
sessionStorage.setItem('other_agency_save_url', global.restApiURL+'webservice/rest/object?apikey='+sessionStorage.getItem('__TOKEN__'));
sessionStorage.setItem('hangout_save_url', global.restApiURL+'webservice/rest/object?apikey='+sessionStorage.getItem('__TOKEN__'));

/**/
