import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MinusIcon from "../../assests/images/minus.svg";
import BlankState from "./BlankState";
import PlusIcon from "../../assests/images/plus.svg";
import TrashIcon from "../../assests/images/trash-2.svg";

import MoveIcon from "../../assests/images/move.svg";
import { VictoryPie } from "victory";
import Draggable from "react-draggable";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import { toast } from "react-toastify";

const MainContainer = styled.section`
  width: 38%;
  border: 1px solid #f0f0f0;
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};
  margin: 20px 0 0 0;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding-left: 16px;
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "15px ",
    display: "flex",
    alignItems: "center",
    height: "50px",
    marginTop: "2px",

    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const PieChartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text {
    padding-left: 20px;
  }
`;

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
  font-size:17px;
`;

function ReferralSource(props) {
  const [blankState, setBlankState] = useState(false);
  const [expand, setExpand] = useState(true);
  const [xpos, setXpos] = useState(0);
  const [year, setYear] = React.useState("2022");
  const [refrallist, setRefrallist] = React.useState([]);

  const [getRefferalSourceList, setRefferalSourceList] = useState([]);

  
  const { addToDashBoard } = props;
  const [final_AddToDashBoard,setfinal_AddToDashBoard]=useState(addToDashBoard);

  useEffect((year) => {
    fetchgetRefferalSourceList(year);
  }, []);
  const OrgId = sessionStorage.getItem("organization_id");
  // const OrgId=5184;

  const fetchgetRefferalSourceList = (year) => {
    if (year == undefined) {
      year = "2022";
    } else {
      year = year;
    }

    axios
      .get(API_URL + `referral_source_list?Token=${sessionStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}`)
      .then((response) => {
        // setRefferalSourceList(response.data.data);
        sessionStorage.setItem("RefSource", true);
       ;

        let res=response.data.data.map(val=>{
           return {
             x:val.x,y:parseInt(val.y),label:val.label
           }
        });
        setRefrallist(res)
      })
      .catch((error) => {
        console.log(error);
      });
    
  };

  // fetchgetRefferalSourceList((year) => {
  //   if(year == undefined) {
  //     year = "2021"
  //   }
  //   else {
  //     year  = year;
  //   }
  //   axios.get(API_URL+`referral_source_list?year=${year}`)
  //     .then((response) => {

  //       setRefferalSourceList(response.data.data);
  //     })
  //     .catch((error) => {

  //     });

  // }, [getRefferalSourceList])

  //console.log(getRefferalSourceList);

  const classes = useStyles(props);
  const handleChange = (event) => {
    setYear(event.target.value);
    const option = event.target.value;
    // console.log("event.target.value");
    // console.log(event.target.value);
    axios
      .get(API_URL + `referral_source_list?Token=${sessionStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${option}`)
      .then((response) => {
        setRefferalSourceList(response.data.data);
        // console.log("getRefferalSourceList data");
        // console.log(response.data.data);

        let res=response.data.data.map(val=>{
           return {
             x:val.x,y:parseInt(val.y),label:val.label
           }
        });
         
        // console.log("getRefferalSourceList");
        // console.log(res);
        setRefrallist(res)
      })
      .catch((error) => {});
  };
// const dummyData1={[
//   {x:"abc",y:50},
//   {x:"abc",y:50},
//   {x:"abc",y:50}
// ]};
  const handleExpand = (e) => setExpand(!expand);
  const handleBlankState = (e) => {
    setBlankState(true);
    sessionStorage.removeItem("stackBar");
    setRefferalSourceList(true);
  };

  function handleDrag(e, data) {
    if (data.x < -100) {
      props.setDirection(false);
    } else if (data.x > 100) {
      props.setDirection(true);
    } else {
      setXpos(0);
    }
  }
  function RefSource() {
    sessionStorage.setItem("RefSource", true);
    // window.location.href = "/chart";

    toast.success("Added to dashboard successfully");
    setRefferalSourceList(false);
  }

  return (
    <Draggable
      handle="#drag"
      axis="x"
      bounds="parent"
      onDrag={handleDrag}
      allowAnyClick={false}
      position={{ x: xpos, y: 0 }}
    >
      <MainContainer expand={expand} blankState={blankState}>
        {blankState ? (
          <BlankState />
        ) : (
          <>
            <HeadContainer>
              <Title>
                Referral Target - Year
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.root}
                  style={{ marginLeft: "5px" }}
                >
                  <Select
                    value={year}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className={classes.root}
                    style={{width:'65px',fontSize:"17px" ,color:" #675757"}}
                  >
                    <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2018">2018</MenuItem>
                  </Select>
                </FormControl>
              </Title>
              {addToDashBoard ? (
                <AddToDashBoard onClick={RefSource}>
                  + Add to Dashboard
                </AddToDashBoard>
              ) : (
                <ActionIconContainer>
                  <ActionIcon
                    src={expand ? MinusIcon : PlusIcon}
                    onClick={handleExpand}
                  />
                  <ActionIcon src={MoveIcon} id="drag" />
                  <ActionIcon src={TrashIcon} onClick={handleBlankState} />
                </ActionIconContainer>
              )}
            </HeadContainer>
            {expand && (
              <PieChartContainer>
                <VictoryPie
                  width={350}
                  // colorScale={["#B3DAD1","#92BBDA","#89BC3B","#59BEE9","#4db6e5","#c999f0","pink"]}
                  colorScale={["#B3DAD1","#92BBDA","#89BC3B","#59BEE9","#25ddda","#c999f0","pink"]}


                  
                   data={refrallist}
                 
                  style={{
                    parent: {
                      marginTop:"-30px",
                      width: "100%",
                      height: "370px",
                      fontSize: "15px ",
                      fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif  ",

                    },
                    
                    labels: {
                      textAlign: "center",
                      display: "flex",
                      lineHeight: "10px",
                      fontSize: "15px ",
                    fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif ",
                    },
                   
                  }}
                />


              </PieChartContainer>
            )}
          </>
        )}
      </MainContainer>
    </Draggable>
  );
}

export default ReferralSource;
