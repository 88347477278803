import React , {Component} from 'react';
import $ from 'jquery';
import { Alert } from "./toastify";
const axios = require('axios').default;


class SessionOut extends Component{

  constructor(){
    super();
    this.state = {
      session :3,
    }
  }
  
	componentDidMount() {
    let WS02Body = {
      apikey: sessionStorage.getItem('__TOKEN__'),
      userName : sessionStorage.getItem('username')
    };
    axios.post(global.restApiURL + 'customRest/WS02Logout', JSON.stringify(WS02Body)).then(res => {
      if (res.data.success && res.status == 200) {
        window.location.href = res.data.url;
      } else if ("error_code" in res) {
        Alert('error', 'Error occured while Logging out from WS02. Try again!');
        window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
      }
      else {
        Alert('error', 'Error occured while Logging out from WS02. Try again!');
        window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
      }
    }).catch(err => {
      // alert(err);
      console.log(err);
    })
    sessionStorage.clear();
    // window.location.href = global.domain;
  }

  render () {
    setTimeout(
      () => this.setState({session: this.state.session - 1}), 1000
    )

    if(this.state.session === 0){
      window.location.href=global.domain;
    }
  	return ( 
        <div className="hold-transition login-page">
          <div className="login-box" >
            <div className="login-logo">
               <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
                <br/> 
                <br/>
                <img src={window.location.origin + '/images/alert.png'} alt="Session Time out" />
                <h4>Session has expired.</h4>
                <small>You will be redirected to <a href={global.domain}>login page</a> in {this.state.session} seconds</small>
            </div>
          </div>
        </div>
  	)
  }
}

export default SessionOut