export function validationParser1(rawData)
      {
        // console.log(rawData);
        var validationObj = {} ;
        
        rawData.map( (val) => {

            let innerValidationObj = {
                name : "",
                title : "",
                full_title : "",
                tooltip : "",
                fieldtype : "",
                options : [] ,
                columnLength : "",
                noeditable : "",
                invisible : "",
                mandatory : "",
                regex : "" 
            } ;

            if(val.childs === undefined )
            {
                innerValidationObj.title = val.title.split('|')[1] ? val.title.split('|')[1].trim() : val.title.split('|')[0].trim() ;
                innerValidationObj.full_title = val.title;
                innerValidationObj.name = val.name ;
                innerValidationObj.tooltip = val.tooltip;
                innerValidationObj.options = val.options ;
                innerValidationObj.fieldtype = val.fieldtype ;
                innerValidationObj.columnLength = val.columnLength;
                innerValidationObj.noteditable = val.noteditable ;
                innerValidationObj.invisible = val.invisible ;
                innerValidationObj.mandatory = val.mandatory ;
                innerValidationObj.regex = val.regex ;

                validationObj[val.name] = innerValidationObj ;
                 
            }
            else
            {
                val.childs.map( (innerChild) => {

                    innerValidationObj.title = val.title.split('|')[1] ? val.title.split('|')[1].trim() : val.title.split('|')[0].trim() ;
                    innerValidationObj.full_title = val.title;
                    innerValidationObj.name = val.name ;
                    innerValidationObj.tooltip = val.tooltip;
                    innerValidationObj.options = val.options ;
                    innerValidationObj.fieldtype = val.fieldtype ;
                    innerValidationObj.columnLength = val.columnLength;
                    innerValidationObj.noteditable = val.noteditable ;
                    innerValidationObj.invisible = val.invisible ;
                    innerValidationObj.mandatory = val.mandatory ;
                    innerValidationObj.regex = val.regex ;
    
                    validationObj[innerChild.name] = innerValidationObj ;
                   
                });
            }  
        } );

        return validationObj ;
}