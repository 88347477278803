import React, {Component} from 'react';

class Loader extends Component{
	render(){
	return(
	<div>
		<div id="loader_overlay"></div>
		<div id="divLoader">
	       <img src={window.location.origin + '/images/loader.gif'} alt="Loader" />
	   </div>
	</div>
	
       )
	}
}

export default Loader