// import React, { Component } from 'react'
// import axios from 'axios';
// import TextareaAutosize from 'react-textarea-autosize';

// export default class Printdischarge extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       asis_id: "",
//       center_case_no: "",
//       review: "",
//       client_name: "",
//       last_assess_date: "",
//       entered_by: "",
//       status: "",
//       // Referral Admission
//       client_seeking_treatment: "",
//       primary_referral: "",
//       secondary_referral_list: [],
//       // Substance use
//       substanceBlock: [{
//         problem_type: "",
//         specific_substance: "",
//         route_of_administration: "",
//         other_route_of_administration: "",
//         frequency_of_use: "",
//         age_of_1st_use: "",
//       }],
//       problem_type_options: [],
//       specific_substance_options: [],
//       // mental Health Disorder
//       mentalHealthDisorderBlock: [{ mental_health_disorder: "",icd_10_code:"", comments: "" }],
//       // other Health Disorder 
//       otherHealthDisorderBlock: [{ disorder_name: "", }],
//       // psychological Enviornmental Problems 
//       psychologicalEnviornmentalProblemsBlock: [{ disorder_name: "" }],
//       disorderOptions: [],
//       // Client Characteristics 
//       maritial_status: "",
//       employment_status: "",
//       educational_status: "",
//       highest_grade_complete: "",
//       hearing_status: "",
//       language_preference: "",
//       number_of_years: "",
//       number_of_months: "",
//       pregnant: "",
//       week: "",
//       prenatal_care: "",

//       // Co-occuring
//       co_occuring_disorder_screen: "",
//       co_occuring_disorder_assesment: "",
//       participated_in_self_care_groups: "",
//       prior_treatment_episodes: "",
//       arrest_last_30_days: "",
//       // participated_in_self_care_groups:"",

//       // Financial
//       princepal_source_of_income: "",
//       insurance_type: "",
//       policy_number: "",
//       group_number: "",
//       medicaid: "",
//       payment_source: "",

//       // Assessed level of care
//       assesssed_level_of_care: [],
//       placed_level_of_care: [],
//       reason_for_difference: "",
//       disposition: [],
//     }
//   }
//   dataListBody = {
//     apikey: sessionStorage.getItem("__TOKEN__"),
//     client_id: sessionStorage.getItem("clientId"),
//     uploaded_by: sessionStorage.getItem("username"),
//     form_type: "Adult_Assessment",
//   }
//   componentDidMount() {
//     this.loadData();
//   }
//   co_occuring_disorder_option = [
//     { value: "not_performed", label: "Not performed" },
//     { value: "administered_results(-)", label: " Administered/Results (-)" },
//     { value: "administered_results(+)", label: "Administered/Results (+)" },
//   ]
//   hearingOptions = [
//     { value: "hearing", label: "Hearing" },
//     { value: "hard_of_hearing", label: "Hard of Hearing" },
//     { value: "deaf", label: "Deaf" },
//   ];
//   insurancetypeOptions = [
//     { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
//     { value: "Health_Maintenance_Organization_(HMO)", label: "Health Maintenance Organization (HMO)", },
//     { value: "Medicaid", label: "Medicaid" },
//     { value: "Medicare", label: "Medicare" },
//     { value: "Private_Insurance", label: "Private Insurance" },
//     { value: "Workers_Compensation", label: "Workers Compensation" },
//     { value: "Unknown", label: "Unknown" },
//     { value: "None", label: "None" },
//     { value: "Other", label: "Other" },
//   ];

//   reasontypeOptions=[
//     {value:"transportation_or_logistical_problem",label:"Transportation or Logistical Problem"},
//     {value:"court_order",label:"Court Order"},
//     {value:"consumer_preference",label:"Consumer Preference"},
//     {value:"clinician_supervisor_override",label:"Clinician/Supervisor Override"},
//     {value:"waiting_for_indicated_level",label:"Waiting for Indicated Leve"},
//     {value:"service_not_available",label:"Service not available"},
//     {value:"na",label:"N/A No Difference"},
//   ]


//   referralSource = [
//     {
//       value: "A_and_or_D_Treatment_Inpatient_Res",
//       label: "A and/or D Treatment, Inpatient Res",
//     },
//     {
//       value: "A_and_or_D_Treatment_Not_Inpatient",
//       label: "A and/or D Treatment, Not Inpatient",
//     },
//     { value: "Clergy", label: "Clergy" },
//     {
//       value: "Court_Correctional_Agency",
//       label: "Court / Correctional Agency",
//     },
//     { value: "dhr", label: "DHR" },
//     {
//       value: "Diversionary_Program_TASC",
//       label: "Diversionary Program / TASC",
//     },
//     { value: "DUI_DWI", label: "DUI / DWI" },
//     { value: "Educational_Agency", label: "Educational Agency" },
//     { value: "Employer_EAP", label: "Employer / EAP" },
//     { value: "Family", label: "Family" },
//     {
//       value: "Formal_Adjudication_Process",
//       label: "Formal Adjudication Process",
//     },
//     { value: "Friend", label: "Friend" },
//     {
//       value: "General_Psychiatric_Hospital",
//       label: "General / Psychiatric Hospital",
//     },
//     { value: "Guardian", label: "Guardian" },
//     { value: "ID_310_Program", label: "ID 310 Program" },
//     { value: "ID_arc", label: "ID ARC" },
//     { value: "ID_Regional_Office", label: "ID Regional Office" },
//     { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
//     { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
//     {
//       value: "Nursing_Home_Extended_Care",
//       label: "Nursing Home / Extended Care",
//     },
//     { value: "Parent", label: "Parent" },
//     { value: "Partial_Day_Organization", label: "Partial Day Organization" },
//     {
//       value: "Personal_Care_Boarding_Home",
//       label: "Personal Care/Boarding Home",
//     },
//     { value: "Physician", label: "Physician" },
//     { value: "Police", label: "Police" },
//     { value: "Prison", label: "Prison" },
//     { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
//     { value: "Probation_Parole", label: "Probation / Parole" },
//     { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
//     { value: "School_System", label: "School System" },
//     { value: "Self", label: "Self" },
//     { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
//     { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
//     { value: "Spouse", label: "Spouse" },
//     {
//       value: "State_County_Psych_Hospital",
//       label: "State / County Psych Hospital",
//     },
//     { value: "State_Federal_Court", label: "State / Federal Court" },
//     { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
//     { value: "If_Other_Specify_below", label: "Other" },
//   ];
//   loadData = async () => {
//     let res = await axios.post(global.restApiURL + "customrest/getProfilePdf", JSON.stringify({
//       apikey: sessionStorage.getItem("__TOKEN__"),
//       client_id: sessionStorage.getItem("clientId"),
//       org: sessionStorage.getItem("selected_organization"),
//       user: sessionStorage.getItem("loggedinusername"),

//     }));
//     let final_data = {
//       dataListBody: {
//         apikey: sessionStorage.getItem("__TOKEN__"),
//         client_id: sessionStorage.getItem("clientId"),
//         uploaded_by: sessionStorage.getItem("username"),
//       }
//     }
//     let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify(final_data));
//     if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
//       let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
//       this.setState({ problem_type_options: options })
//     }
//     let specificOptions_res = await axios.post(global.restApiURL + "customRest/getSubstanceProblemType", JSON.stringify({ dataListBody: this.dataListBody, problem_type: "" }));
//     if (specificOptions_res.status == 200 && specificOptions_res.data.success == true) {
//       this.setState({ specific_substance_options: specificOptions_res.data.data.problem_type.substance })

//     }

//     if (res.data.success && res.status == 200) {
//       this.setState({
//         client_name: res.data.data.profiledata.first_name + " " + res.data.data.profiledata.last_name,
//         asis_id: res.data.data.profiledata.asis_id,
//         center_case_no: res.data.data.profiledata.center_case_no,
//         review: res.data.data.profiledata.review,
//         last_assess_date: res.data.data.profiledata.last_assess_date,
//         entered_by: res.data.data.profiledata.entered_by,
//         status: res.data.data.profiledata.status,
//         // Referral Admission
//         client_seeking_treatment: res.data.data.profiledata.client_seeking_treatment,
//         primary_referral: res.data.data.profiledata.primary_referral,
//         secondary_referral_list: JSON.parse(res.data.data.profiledata.secondary_referral_list).length > 0 ? JSON.parse(res.data.data.profiledata.secondary_referral_list).map((val, index) => {
//           return ({
//             label: val.label, value: val.value,
//           })
//         }) : [],
//         // Substance use 
//         substanceBlock: res.data.data.profiledata.substanceBlock.map((val) => {
//           return ({
//             problem_type: val.problem_type,
//             specific_substance: val.specific_substance,
//             route_of_administration: val.route_of_administration,
//             other_route_of_administration: val.other_route_of_administration,
//             frequency_of_use: val.frequency_of_use,
//             age_of_1st_use: val.age_of_1st_use,
//           })
//         }),
//         // mental Health Disorder
//         mentalHealthDisorderBlock: res.data.data.profiledata.mentalHealthDisorderBlock.map((val) => { return ({ mental_health_disorder: val.mental_health_disorder,icd_10_code:val.icd_10_code, comments: val.comments }) }),
//         // other Health Disorder 
//         otherHealthDisorderBlock: res.data.data.profiledata.otherHealthDisorderBlock.map((val) => { return ({ disorder_name: val.disorder_name }) }),
//         // psychological Enviornmental Problems 
//         psychologicalEnviornmentalProblemsBlock: res.data.data.profiledata.psychologicalEnviornmentalProblemsBlock.map((psyEnvPrblm) => {
//           return ({ disorder_name: psyEnvPrblm.disorder_name })
//         }),
//         disorderOptions: res.data.data.profiledata.disorderOptions.map((disorderOption) => {
//           return ({ label: disorderOption.label, value: disorderOption.value, })
//         }),
//         // Client Characteristics 
//         maritial_status: res.data.data.profiledata.maritial_status,
//         employment_status: res.data.data.profiledata.employment_status,
//         educational_status: res.data.data.profiledata.educational_status,
//         highest_grade_complete: res.data.data.profiledata.educational_status,
//         hearing_status: res.data.data.profiledata.hearing_status,
//         language_preference: res.data.data.profiledata.language_preference,
//         number_of_years: res.data.data.profiledata.number_of_years,
//         number_of_months: res.data.data.profiledata.number_of_months,
//         pregnant: res.data.data.profiledata.pregnant,
//         week: res.data.data.profiledata.weeks,
//         prenatal_care: res.data.data.profiledata.prenatal_care,

//         // Co-Occouring
//         co_occuring_disorder_screen: res.data.data.profiledata.co_occuring_disorder_screen,
//         co_occuring_disorder_assesment: res.data.data.profiledata.co_occuring_disorder_assesment,
//         participated_in_self_care_groups: res.data.data.profiledata.participated_in_self_care_groups,
//         prior_treatment_episodes: res.data.data.profiledata.prior_treatment_episodes,
//         arrest_last_30_days: res.data.data.profiledata.arrest_last_30_days,

//         // Financial
//         princepal_source_of_income: res.data.data.profiledata.princepal_source_of_income,
//         insurance_type: res.data.data.profiledata.insurance_type,
//         policy_number: res.data.data.profiledata.policy_number,
//         group_number: res.data.data.profiledata.group_number,
//         medicaid: res.data.data.profiledata.medicaid,
//         payment_source: res.data.data.profiledata.payment_source,

//         // Assessed level of care
//         assesssed_level_of_care: res.data.data.profiledata.assesssed_level_of_care.length > 0 ? JSON.parse(res.data.data.profiledata.assesssed_level_of_care).map((assesssed, index) => {
//           return ({
//             label: assesssed.label, value: assesssed.value,
//           })
//         }) : [],

//         placed_level_of_care: res.data.data.profiledata.placed_level_of_care.length > 0 ? JSON.parse(res.data.data.profiledata.placed_level_of_care).map((placed, index) => {
//           return ({
//             label: placed.label, value: placed.value,
//           })
//         }) : [],
    

//         reason_for_difference: res.data.data.profiledata.reason_for_difference,
//         disposition: res.data.data.profiledata.disposition.length > 0 ? JSON.parse(res.data.data.profiledata.disposition).map((dispos, index) => {
//           return ({
//             label: dispos.label, value: dispos.value,
//           })
//         }) : [],
//         // disposition:[], 
//       })
//     }


//   }

//   render() {
//     return (
//       <>
//         <div className="row" style={{ padding: "0px 30px", margin: "20px 0px 0px 0px" }} >
//         <div style={{ pageBreakBefore:'always' }}></div>
//           <div className="col-12" >
//             <div className="row">
//               <div className="col-12">
//                 {/* <strong style={{ fontSize: "1rem" }}>Profile Discharge</strong> */}
//                 <div className="col-12" style={{ paddingBottom: "40px" }}>
//                   <h2 className="text-green text-center">Profile Discharge</h2>
//                 </div>
//               </div>
//             </div>
//             <hr />
//             <div className="row">
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>ASAIS ID:</label>
//                   <input type="text" disabled name="asis_id" value="" className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Center case no</label>
//                   <input type="text" disabled name="center_case_no" value={this.state.center_case_no} className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Client Name</label>
//                   <input type="text" disabled name="client_name" value={this.state.client_name} className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Review</label>
//                   <input type="text" disabled name="review" value={this.state.review} className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Date of Assessment</label>
//                   <input type="text" disabled name="last_assess_date " value={this.state.last_assess_date} className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Entered by</label>
//                   <input type="text" disabled name="entered_by" value={this.state.entered_by} className="form-control" />
//                 </div>
//               </div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Status</label>
//                   <input type="text" disabled name="status" value={this.state.status} className="form-control" />
//                 </div>
//               </div>
//               <hr />
//             </div>
//             {/* =======================Referral-Admission Information Start ====================*/}
//             <div className="row">
//               <div className="col-12">
//                 <h4>Referral-Admission Information : </h4>
//               </div>
//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Co-Dependent/Collateral</label>
//                   <input type="text" disabled name="client_seeking_treatment" value={this.state.client_seeking_treatment} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Principal Source Of Referral (Primary)</label>
//                   <select className="form-control" name="primary_referral" value={this.state.primary_referral} disabled={true}>
//                     <option value=""></option>
//                     {this.referralSource.map((referal) => (
//                       <option value={referal.value}>
//                         {referal.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Principal Source Of Referral (Secondary)</label>
//                   <TextareaAutosize rows={2} name="secondary_referral_list" value={this.state.secondary_referral_list.map((val) => { return (val.label) })} disabled={true} className='form-control'  style={{overflow:"hidden"}}/>
//                 </div>
//               </div>
//               <hr />
//             </div>
//             {/*====================== Referral - Admission Information End ====================*/}
//             {/*==================================++++++++++=================================== */}
//             {/*========================Specific substance Abuse Problem Start==================*/}

//             <h4>Specific Substance Use Problem: </h4>

//             <div className='col-12 '>
//               {this.state.substanceBlock.map((val, index) => {
//                 return (
//                   <div className='card' style={{padding:"10px 10px"}}>
//                   <div className='row'>
//                     <div className='col-6'>
//                       <div className='form-group'>
//                         <label>{index == 0 ? "1st" : index == 1 ? "2nd" : index == 2 ? "3rd" : (index + 1) + "th"} Primary Substance Problem Type</label>
//                         <select className='form-control' disabled={true} name="problem_type" value={val.problem_type}>
//                           <option value=""></option>
//                           {this.state.problem_type_options.map((options) => (<option value={options.value}>{options.label}</option>))}
//                         </select>
//                       </div>
//                     </div>
//                     <div className='col-6'>
//                       <div className='form-group'>
//                         <label>Specific Substance/Problem Details</label>
//                         <select className='form-control' disabled={true} name="specific_substance" value={val.specific_substance}>
//                           <option value=""></option>
//                           {this.state.specific_substance_options.map((options) => (<option value={options.value}>{options.label}</option>))}
//                         </select>
//                       </div>
//                     </div>

//                     <div className='col-4'>
//                       <div className='form-group'>
//                         <label>Primary Substance Route Of Admin</label>
//                         <select className='form-control' disabled name="route_of_administration" value={val.route_of_administration}>
//                             <option value=""></option>
//                             <option value="oral">Oral</option>
//                             <option value="smoking">Smoking</option>
//                             <option value="inhalation">Inhalation</option>
//                             <option value="injection">Injection/IV</option>
//                             <option value="injection_intramuscular">Injection/Intramuscular</option>
//                             <option value="vaginal">Vaginal</option>
//                             <option value="other_specify">Other</option>
//                         </select>
//                       </div>
//                     </div>

//                     <div className='col-4'>
//                       <div className='form-group'>
//                         <label>Primary Substance Age of 1st Use</label>
//                         <input type="text" disabled={true} name="age_of_1st_use" value={val.age_of_1st_use} className="form-control" />
//                       </div>
//                     </div>

//                     <div className='col-4'>
//                       <div className='form-group'>
//                         <label>Primary Substance Frequency of Use</label>
//                         <select className='form-control' disabled name="frequency_of_use" value={val.frequency_of_use} >
//                             <option value=""></option>
//                             <option value="no_use_in_the_past_month ">No use in the past month      </option>
//                             <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
//                             <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
//                             <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
//                             <option value="daily">Daily</option>
//                             <option value="other">Other</option>
//                         </select>
//                       </div>
//                     </div>
//                   </div>
                  


                  
//                   </div>
//                 )
//               })}
//             </div>
//             <hr />
//             {/* Specific substance Abuse Problem End*/}
//             {/* =============++++++============ */}
//             {/* Specific Mental Health Problem  Start*/}
//             <div className='row'>
//               <div className='col-12'>
//                 <h4>Specific Mental Health Problems: </h4>
//               </div>

//               {this.state.mentalHealthDisorderBlock?.map((val) => {
//                 return (
//                   <>
//                     <div className="card  col-12">
//                       <div className="card-body row">
//                         <div className="col-12">
//                           <div className="form-group">
//                             <label>Problem code </label>
//                             <input type="text" disabled name="mental_health_disorder" value={val.mental_health_disorder} className="form-control" />
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <div className="form-group">
//                             <label>ICD 10 Code </label>
//                             <input type="text" disabled name="icd_10_code" value={val.icd_10_code} className="form-control" />
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <div className="form-group">
//                             <label>Description</label>
//                             <input type="text" disabled name="comments" value={val.comments} className="form-control" />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 )
//               })}
//             </div>
//             {/* Specific Mental Health Problem  End*/}
//             {/* =============++++++============ */}
//             {/*Other Start*/}

//             <h4>Other: </h4>
//             <div className="col-12  ">
//               <label>Description</label>
//               <div className="form-group">
//                 {this.state.otherHealthDisorderBlock.map((val) => {
//                   return (
//                     <input type="text" disabled name="disorder_name" value={val.disorder_name} className="form-control mb-2" />
//                   )
//                 })}
//               </div>
//             </div>


//             {/*=============================Other Start End===============================*/}
//             {/*================================++++++==================================== */}
//             {/*=============Psychological and Environment Problems Start==================*/}
//             <h4>Psychological and Environmental Problems: </h4>
//             <table className="table table-bordered table-striped">
//               <thead>
//                 {/* <th></th>
//                                   <th></th> */}

//               </thead>
//               <tbody>
//                 {this.state.psychologicalEnviornmentalProblemsBlock.map((psyprblm, index) => {
//                   return (
//                     <tr>
//                       {/* <td style={{ width: "3%" }}>{index + 1} </td> */}
//                       <td style={{ width: "100%" }}>
//                         <select className='form-control' disabled={true} name="disorder_name" value={psyprblm.disorder_name}>
//                           <option value=""></option>
//                           {this.state.disorderOptions.map((disoptions) => (<option value={disoptions.value}>{disoptions.label}</option>))}
//                         </select>
//                       </td>
//                     </tr>
//                   )
//                 })}
//               </tbody>
//             </table>
//             <hr />
//             {/*==============Psychological and Environment Problems End========================*/}
//             {/* ===================================+++++++++++++++============================ */}
//             {/*===============Client Characteristics and Services Start========================*/}
//             <div className="col-12">
//               <div className="form-group">
//                 <label>Client Name</label>
//                 <input type="text" disabled name="client_name" value={this.state.client_name} className="form-control" />
//               </div>
//             </div>

//             <hr />
//             <div className="row">
//               <div className="col-12">
//                 <h4>Client Characteristics and Services </h4>
//               </div>

//               <div className="col-2">
//                 <div className="form-group">
//                   <label>Marital Status</label>
//                   <select className="form-control" disabled={true} name="maritial_status" value={this.state.maritial_status}>
//                     <option value=""></option>
//                     <option value="Common Law">Common Law </option>
//                     <option value="Single">Single</option>
//                     <option value="Living Together">Living Together</option>
//                     <option value="Married">Married</option>
//                     <option value="Separated">Separated</option>
//                     <option value="Divorced">Divorced</option>
//                     <option value="Widowed">Widowed</option>
//                   </select>
//                 </div>
//               </div>
//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Employment Status</label>
//                   <select className="form-control" disabled={true} name="employment_status" value={this.state.employment_status} >
//                     <option value=""></option>
//                     <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
//                     <option value="disabled">Disabled</option>
//                     <option value="full_time">Full-time</option>
//                     <option value="homemaker">Homemaker</option>
//                     <option value="part_time">Part-time</option>
//                     <option value="retired">Retired</option>
//                     <option value="student">Student</option>
//                     <option value="supported_employment">Supported Employment</option>
//                     <option value="unemployed_looking">Unemployed, looking</option>
//                     <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Education</label>
//                   <select className="form-control" disabled={true} name="educational_status" value={this.state.educational_status} >
//                     <option value="none">None</option>
//                     <option value="student">Student</option>
//                     <option value="graduated_hs">Graduated HS</option>
//                     <option value="ged">GED</option>
//                     <option value="dropped_out">Dropped Out</option>
//                     <option value="college_graduate">College Graduate</option>
//                     <option value="some_education_after_hs">Some education after HS</option>
//                     <option value="other">Other</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Highest grade completed</label>
//                   <select className="form-control" disabled={true} name="highest_grade_complete" value={this.state.highest_grade_complete} >
//                     <option value="none">None</option>
//                     <option value="student">Student</option>
//                     <option value="graduated_hs">Graduated HS</option>
//                     <option value="ged">GED</option>
//                     <option value="dropped_out">Dropped Out</option>
//                     <option value="college_graduate">College Graduate</option>
//                     <option value="some_education_after_hs">Some education after HS</option>
//                     <option value="other">Other</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Hearing Status</label>
//                   <select className="form-control" name="hearing_status" value={this.state.hearing_status} disabled={true}  >
//                     <option value=""></option>
//                     {this.hearingOptions.map((hearing) => (
//                       <option value={hearing.value}>
//                         {hearing.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Language Preference</label>
//                   <select className="form-control" name="language_preference" value={this.state.language_preference} disabled={true} >
//                     <option value=""></option>
//                     <option value="english">English</option>
//                     <option value="american_sign_language">American Sign Language</option>
//                     <option value="arabic">Arabic</option>
//                     <option value="chinese">Chinese</option>
//                     <option value="french_and_french_creole">French And French Creole(Including Cajun)</option>
//                     <option value="german">German</option>
//                     <option value="hindi">Hindi</option>
//                     <option value="italian">Italian</option>
//                     <option value="japanese">Japanese</option>
//                     <option value="korean">Korean</option>
//                     <option value="laotian">Laotian</option>
//                     <option value="other_african_languages">Other African Languages</option>
//                     <option value="other_asian_languages">Other Asian Languages</option>
//                     <option value="other_europian_languages">Other Europian Languages</option>
//                     <option value="other_indic_languages">Other Indic Languages</option>
//                     <option value="persian">Persian</option>
//                     <option value="portuguese">Portuguese</option>
//                     <option value="russian">Russian</option>
//                     <option value="portuguese">Spanish And Spanish Creole</option>
//                     <option value="tagalog">Tagalog</option>
//                     <option value="vietnamese">Vietnamese</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-6 row ">
//                 <div className="col-6 form-group">
//                   <label>Living Arrangement</label>
//                   <input type="text" disabled name="number_of_years" value={this.state.number_of_years}  className="form-control" />
//                 </div>
//                 <div className="col-6 " style={{ marginTop: "20px" }}>
//                   <input type="text" disabled name="number_of_months" value={this.state.number_of_months}  className="form-control mt-2" />
//                 </div>
//               </div>


//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Pregnant at The time of Admission</label>
//                   <input type="text" disabled name="pregnant" value={this.state.pregnant} className="form-control" />
//                 </div>
//               </div>
//               {this.state.pregnant == "yes" &&
//                 <>
//                   <div className="col-4">
//                     <div className="form-group">
//                       <label>How many weeks</label>
//                       <input type="text" disabled name="week" value={this.state.week} className="form-control" />
//                     </div>
//                   </div>
//                   <div className="col-4">
//                     <div className="form-group">
//                       <label>Recieving Prenatal Care? </label>
//                       <input type="text" disabled name="prenatal_care" value={this.state.prenatal_care} className="form-control" />
//                     </div>
//                   </div>

//                 </>
//               }
//               <hr />
//             </div>
//             {/*=================Client Characteristics and Services End================*/}
//             {/* ================================++++++=================================*/}
//             {/*===========================Co-occuring Start============================*/}
//             <div className="row">
//               <div className="col-12">
//                 <h4>Co-occuring</h4>
//               </div>

//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Was a Co-occurring Disorder Screen Administered?</label>
//                   <select name="co_occuring_disorder_screen" disabled={true} value={this.state.co_occuring_disorder_screen} className='form-control'>
//                     <option></option>
//                     {this.co_occuring_disorder_option.map(option => (
//                       <option value={option.value}>{option.label}</option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Was a Co-occurring Disorder Assessment Administered? </label>
//                   <select name="co_occuring_disorder_assesment" disabled={true} value={this.state.co_occuring_disorder_assesment} className='form-control'>
//                     <option></option>
//                     {this.co_occuring_disorder_option.map(option => (
//                       <option value={option.value}>{option.label}</option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Is Client Participating in Opioid Replacement Therapy? </label>
//                   <select className='form-control' disabled={true} value={this.state.participated_in_self_care_groups} name="participated_in_self_care_groups">
//                     <option value=""></option>
//                     <option value="yes">Yes</option>
//                     <option value="no">No</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-6">
//                 <div className="form-group">
//                   <label>Number of Prior Treatment Episodes? </label>
//                   <input type="text" disabled name="prior_treatment_episodes" value={this.state.prior_treatment_episodes} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Number of Arrest in the 30 days Prior to Admission?</label>
//                   <input type="text" disabled name="arrest_last_30_days" value={this.state.arrest_last_30_days} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Has client participated in self-help groups or support groups (e.g. AA, NA, etc.) in the last 30 days?</label>
//                   <input type="text" disabled name="participated_in_self_care_groups" value={this.state.participated_in_self_care_groups} className="form-control" />
//                 </div>
//               </div>
//               <hr />
//             </div>
//             {/*==========================Co-occuring  End================================*/}
//             {/* ==================================++++++================================ */}
//             {/*===========================Financial Start================================*/}
//             <div className="row">
//               <div className="col-12"> <h4>Financial</h4></div>
//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Source of Financial Support</label>
//                   {/* <input type="text" disabled name="princepal_source_of_income" value={this.state.princepal_source_of_income} className="form-control" /> */}
//                   <select className="form-control" name="princepal_source_of_income" value={this.state.princepal_source_of_income} disabled={true}>
//                     <option value=""></option>
//                     <option value="wages_salary">Wages/Salary</option>
//                     <option value="public_assistance"> Public Assistance</option>
//                     <option value="retirement_pension">Retirement/Pension</option>
//                     <option value="disablity">Disablity</option>
//                     <option value="none">None</option>
//                     <option value="other">Other</option>
//                     {/* {form_type == "craftScreening" ? (<option value="unknown">Unknown</option>) : ""} */}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-8">
//                 <div className="form-group">
//                   <label>Health Insurance</label>
//                   {/* <input type="text" disabled name="insurance_type" value="" className="form-control" /> */}
//                   <select name="insurance_type" className="form-control" value={this.state.insurance_type} disabled={true} >
//                     <option value=""></option>
//                     {this.insurancetypeOptions.map((insurance) => (
//                       <option value={insurance.value}>
//                         {insurance.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Policy Number</label>
//                   <input type="text" disabled name="policy_number" value={this.state.policy_number} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Group Number</label>
//                   <input type="text" disabled name="group_number" value={this.state.group_number} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Medicaid Number</label>
//                   <input type="text" disabled name="medicaid" value={this.state.medicaid} className="form-control" />
//                 </div>
//               </div>

//               <div className="col-4">
//                 <div className="form-group">
//                   <label>Source of Payment</label>
//                   <input type="text" disabled name="payment_source" value={this.state.payment_source} className="form-control" />
//                 </div>
//               </div>
//               <hr />
//             </div>
//             {/* =============Financial---End=============*/}
//             {/* ===============++++++================= */}
//             {/* =============Intake Level of Care Placement Summary START=============*/}
//             <div className="row">
//               <div className="col-12">
//                 <h4>Intake Level of Care Placement Summary</h4>
//               </div>
//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Assessed Level of Care</label>
//                   <TextareaAutosize rows={2}  name="assesssed_level_of_care" value={this.state.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control'
//                   style={{overflow:"hidden"}} />
//                 </div>
//               </div>
//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Placed Level of Care</label>
//                   <TextareaAutosize rows={2} disabled name="placed_level_of_care" value={this.state.placed_level_of_care.map((val) => { return (val.label) }).join(", ")} className="form-control p-2 p-md-3" style={{overflow:"hidden"}}/>
//                 </div>
//               </div>

//               <div className="col-12">
//                 <div className="form-group">
//                   <label>If placement is different from assessed level what reason?</label>
//                   <select name="reason_for_difference" className="form-control" value={this.state.reason_for_difference} disabled={true} >
//                     <option value=""></option>
//                     {this.reasontypeOptions.map((reason) => (
//                       <option value={reason.value}>
//                         {reason.label}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>

//               <div className="col-12">
//                 <div className="form-group">
//                   <label>Disposition</label>
//                   <TextareaAutosize rows={2} name="disposition" value={this.state.disposition.map((val) => { return (val.label) })} disabled={true} className='form-control' style={{overflow:"hidden"}}/>
//                 </div>
//               </div>
//             </div>

//             {/* </div> */}
//           </div>
//         </div>




//       </>
//     )
//   }
// }







///////////////////////////////////////////////////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> This is from dev to prod for print (PDF) text cut issue and overlapping.  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>////////////////////////////////////////////////









import React, { Component } from 'react'
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize';

export default class Printdischarge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asis_id: "",
      center_case_no: "",
      review: "",
      client_name: "",
      last_assess_date: "",
      entered_by: "",
      status: "",
      // Referral Admission
      client_seeking_treatment: "",
      primary_referral: "",
      secondary_referral_list: [],
      // Substance use
      substanceBlock: [{
        problem_type: "",
        specific_substance: "",
        route_of_administration: "",
        other_route_of_administration: "",
        frequency_of_use: "",
        age_of_1st_use: "",
      }],
      problem_type_options: [],
      specific_substance_options: [],
      // mental Health Disorder
      mentalHealthDisorderBlock: [{ mental_health_disorder: "", icd_10_code: "", comments: "" }],
      // other Health Disorder 
      otherHealthDisorderBlock: [{ disorder_name: "", }],
      // psychological Enviornmental Problems 
      psychologicalEnviornmentalProblemsBlock: [{ disorder_name: "" }],
      disorderOptions: [],
      // Client Characteristics 
      maritial_status: "",
      employment_status: "",
      educational_status: "",
      highest_grade_complete: "",
      hearing_status: "",
      language_preference: "",
      number_of_years: "",
      number_of_months: "",
      pregnant: "",
      week: "",
      prenatal_care: "",

      // Co-occuring
      co_occuring_disorder_screen: "",
      co_occuring_disorder_assesment: "",
      participated_in_self_care_groups: "",
      prior_treatment_episodes: "",
      arrest_last_30_days: "",
      // participated_in_self_care_groups:"",

      // Financial
      princepal_source_of_income: "",
      insurance_type: "",
      policy_number: "",
      group_number: "",
      medicaid: "",
      payment_source: "",

      // Assessed level of care
      assesssed_level_of_care: [],
      placed_level_of_care: [],
      reason_for_difference: "",
      disposition: [],
    }
  }
  dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    uploaded_by: sessionStorage.getItem("username"),
    form_type: "Adult_Assessment",
  }
  componentDidMount() {
    this.loadData();
  }
  co_occuring_disorder_option = [
    { value: "not_performed", label: "Not performed" },
    { value: "administered_results(-)", label: " Administered/Results (-)" },
    { value: "administered_results(+)", label: "Administered/Results (+)" },
  ]
  hearingOptions = [
    { value: "hearing", label: "Hearing" },
    { value: "hard_of_hearing", label: "Hard of Hearing" },
    { value: "deaf", label: "Deaf" },
  ];
  insurancetypeOptions = [
    { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
    { value: "Health_Maintenance_Organization_(HMO)", label: "Health Maintenance Organization (HMO)", },
    { value: "Medicaid", label: "Medicaid" },
    { value: "Medicare", label: "Medicare" },
    { value: "Private_Insurance", label: "Private Insurance" },
    { value: "Workers_Compensation", label: "Workers Compensation" },
    { value: "Unknown", label: "Unknown" },
    { value: "None", label: "None" },
    { value: "Other", label: "Other" },
  ];

  reasontypeOptions = [
    { value: "transportation_or_logistical_problem", label: "Transportation or Logistical Problem" },
    { value: "court_order", label: "Court Order" },
    { value: "consumer_preference", label: "Consumer Preference" },
    { value: "clinician_supervisor_override", label: "Clinician/Supervisor Override" },
    { value: "waiting_for_indicated_level", label: "Waiting for Indicated Leve" },
    { value: "service_not_available", label: "Service not available" },
    { value: "na", label: "N/A No Difference" },
  ]


  referralSource = [
    {
      value: "A_and_or_D_Treatment_Inpatient_Res",
      label: "A and/or D Treatment, Inpatient Res",
    },
    {
      value: "A_and_or_D_Treatment_Not_Inpatient",
      label: "A and/or D Treatment, Not Inpatient",
    },
    { value: "Clergy", label: "Clergy" },
    {
      value: "Court_Correctional_Agency",
      label: "Court / Correctional Agency",
    },
    { value: "dhr", label: "DHR" },
    {
      value: "Diversionary_Program_TASC",
      label: "Diversionary Program / TASC",
    },
    { value: "DUI_DWI", label: "DUI / DWI" },
    { value: "Educational_Agency", label: "Educational Agency" },
    { value: "Employer_EAP", label: "Employer / EAP" },
    { value: "Family", label: "Family" },
    {
      value: "Formal_Adjudication_Process",
      label: "Formal Adjudication Process",
    },
    { value: "Friend", label: "Friend" },
    {
      value: "General_Psychiatric_Hospital",
      label: "General / Psychiatric Hospital",
    },
    { value: "Guardian", label: "Guardian" },
    { value: "ID_310_Program", label: "ID 310 Program" },
    { value: "ID_arc", label: "ID ARC" },
    { value: "ID_Regional_Office", label: "ID Regional Office" },
    { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
    { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
    {
      value: "Nursing_Home_Extended_Care",
      label: "Nursing Home / Extended Care",
    },
    { value: "Parent", label: "Parent" },
    { value: "Partial_Day_Organization", label: "Partial Day Organization" },
    {
      value: "Personal_Care_Boarding_Home",
      label: "Personal Care/Boarding Home",
    },
    { value: "Physician", label: "Physician" },
    { value: "Police", label: "Police" },
    { value: "Prison", label: "Prison" },
    { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
    { value: "Probation_Parole", label: "Probation / Parole" },
    { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
    { value: "School_System", label: "School System" },
    { value: "Self", label: "Self" },
    { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
    { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
    { value: "Spouse", label: "Spouse" },
    {
      value: "State_County_Psych_Hospital",
      label: "State / County Psych Hospital",
    },
    { value: "State_Federal_Court", label: "State / Federal Court" },
    { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
    { value: "If_Other_Specify_below", label: "Other" },
  ];
  loadData = async () => {
    // debugger
    let res = await axios.post(global.restApiURL + "customrest/getProfilePdf", JSON.stringify({
      apikey: sessionStorage.getItem("__TOKEN__"),
      client_id: sessionStorage.getItem("clientId"),
      org: sessionStorage.getItem("selected_organization"),
      user: sessionStorage.getItem("loggedinusername"),

    }));
    let final_data = {
      dataListBody: {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        uploaded_by: sessionStorage.getItem("username"),
      }
    }
    let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify(final_data));
    if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
      let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
      this.setState({ problem_type_options: options })
    }
    let specificOptions_res = await axios.post(global.restApiURL + "customRest/getSubstanceProblemType", JSON.stringify({ dataListBody: this.dataListBody, problem_type: "" }));
    if (specificOptions_res.status == 200 && specificOptions_res.data.success == true) {
      this.setState({ specific_substance_options: specificOptions_res.data.data.problem_type.substance })

    }

    console.log("Hii Ashraf", res.data.data.profiledata);
    if (res.data.success && res.status == 200) {
      this.setState({
        client_name: res.data.data.profiledata.first_name + " " + res.data.data.profiledata.last_name,
        asis_id: res.data.data.profiledata.asis_id,
        center_case_no: res.data.data.profiledata.center_case_no,
        review: res.data.data.profiledata.review,
        last_assess_date: res.data.data.profiledata.last_assess_date,
        entered_by: res.data.data.profiledata.entered_by,
        status: res.data.data.profiledata.status,
        // Referral Admission
        client_seeking_treatment: res.data.data.profiledata.client_seeking_treatment,
        primary_referral: res.data.data.profiledata.primary_referral,
        secondary_referral_list: JSON.parse(res.data.data.profiledata.secondary_referral_list).length > 0 ? JSON.parse(res.data.data.profiledata.secondary_referral_list).map((val, index) => {
          return ({
            label: val.label, value: val.value,
          })
        }) : [],
        // Substance use 
        substanceBlock: res.data.data.profiledata.substanceBlock.map((val) => {
          return ({
            problem_type: val.problem_type,
            specific_substance: val.specific_substance,
            route_of_administration: val.route_of_administration,
            other_route_of_administration: val.other_route_of_administration,
            frequency_of_use: val.frequency_of_use,
            age_of_1st_use: val.age_of_1st_use,
          })
        }),
        // mental Health Disorder
        mentalHealthDisorderBlock: res.data.data.profiledata.mentalHealthDisorderBlock.map((val) => { return ({ mental_health_disorder: val.mental_health_disorder, icd_10_code: val.icd_10_code, comments: val.comments }) }),
        // other Health Disorder 
        otherHealthDisorderBlock: res.data.data.profiledata.otherHealthDisorderBlock.map((val) => { return ({ disorder_name: val.disorder_name }) }),
        // psychological Enviornmental Problems 
        psychologicalEnviornmentalProblemsBlock: res.data.data.profiledata.psychologicalEnviornmentalProblemsBlock.map((psyEnvPrblm) => {
          return ({ disorder_name: psyEnvPrblm.disorder_name })
        }),
        disorderOptions: res.data.data.profiledata.disorderOptions.map((disorderOption) => {
          return ({ label: disorderOption.label, value: disorderOption.value, })
        }),
        // Client Characteristics 
        maritial_status: res.data.data.profiledata.maritial_status,
        employment_status: res.data.data.profiledata.employment_status,
        educational_status: res.data.data.profiledata.educational_status,
        // highest_grade_complete: res.data.data.profiledata.highest_grade_complete,
        highest_grade_complete: res.data.data.profiledata.educational_status,
        hearing_status: res.data.data.profiledata.hearing_status,
        language_preference: res.data.data.profiledata.language_preference,
        number_of_years: res.data.data.profiledata.number_of_years,
        number_of_months: res.data.data.profiledata.number_of_months,
        pregnant: res.data.data.profiledata.pregnant,
        week: res.data.data.profiledata.weeks,
        prenatal_care: res.data.data.profiledata.prenatal_care,

        // Co-Occouring
        co_occuring_disorder_screen: res.data.data.profiledata.co_occuring_disorder_screen,
        co_occuring_disorder_assesment: res.data.data.profiledata.co_occuring_disorder_assesment,
        participated_in_self_care_groups: res.data.data.profiledata.participated_in_self_care_groups,
        prior_treatment_episodes: res.data.data.profiledata.prior_treatment_episodes,
        arrest_last_30_days: res.data.data.profiledata.arrest_last_30_days,

        // Financial
        princepal_source_of_income: res.data.data.profiledata.princepal_source_of_income,
        insurance_type: res.data.data.profiledata.insurance_type,
        policy_number: res.data.data.profiledata.policy_number,
        group_number: res.data.data.profiledata.group_number,
        medicaid: res.data.data.profiledata.medicaid,
        payment_source: res.data.data.profiledata.payment_source,

        // Assessed level of care
        assesssed_level_of_care: res.data.data.profiledata.assesssed_level_of_care.length > 0 ? JSON.parse(res.data.data.profiledata.assesssed_level_of_care).map((assesssed, index) => {
          return ({
            label: assesssed.label, value: assesssed.value,
          })
        }) : [],

        placed_level_of_care: res.data.data.profiledata.placed_level_of_care.length > 0 ? JSON.parse(res.data.data.profiledata.placed_level_of_care).map((placed, index) => {
          return ({
            label: placed.label, value: placed.value,
          })
        }) : [],


        reason_for_difference: res.data.data.profiledata.reason_for_difference,
        disposition: res.data.data.profiledata.disposition.length > 0 ? JSON.parse(res.data.data.profiledata.disposition).map((dispos, index) => {
          return ({
            label: dispos.label, value: dispos.value,
          })
        }) : [],
        // disposition:[], 
      })
    }


  }

  render() {
    return (
      <>
        <div className="row" style={{ padding: "0px 30px", margin: "20px 0px 0px 0px" }} >
          <div style={{ pageBreakBefore: 'always' }}></div>
          <div className="col-12" >
            <div className="row">
              <div className="col-12">
                {/* <strong style={{ fontSize: "1rem" }}>Profile Discharge</strong> */}
                <div className="col-12" style={{ paddingBottom: "40px" }}>
                  <h2 className="text-green text-center">Profile Discharge</h2>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>ASAIS ID:</label>
                    <input type="text" disabled name="asis_id" value="" className="form-control" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Center case no</label>
                    <input type="text" disabled name="center_case_no" value={this.state.center_case_no} className="form-control" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Client Name</label>
                    <input type="text" disabled name="client_name" value={this.state.client_name} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Review</label>
                    <input type="text" disabled name="review" value={this.state.review} className="form-control" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Date of Assessment</label>
                    <input type="text" disabled name="last_assess_date " value={this.state.last_assess_date} className="form-control" />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Entered by</label>
                    <input type="text" disabled name="entered_by" value={this.state.entered_by} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Status</label>
                    <input type="text" disabled name="status" value={this.state.status} className="form-control" />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            {/* =======================Referral-Admission Information Start ====================*/}
            <div>
              <div className="row">
                <div className="col-12">
                  <h4>Referral-Admission Information : </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Co-Dependent/Collateral</label>
                    <input type="text" disabled name="client_seeking_treatment" value={this.state.client_seeking_treatment === 'yes' ? 'Yes' : this.state.client_seeking_treatment === 'no' ? 'No' : ''} className="form-control" />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>Principal Source Of Referral (Primary)</label>
                    <select className="form-control" name="primary_referral" value={this.state.primary_referral} disabled={true}>
                      <option value=""></option>
                      {this.referralSource.map((referal) => (
                        <option value={referal.value}>
                          {referal.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Principal Source Of Referral (Secondary)</label>
                    <div className="form-control heightRemove PdfBackgroundColor" name="secondary_referral_list" style={{ backgroundColor: "#e9ecef" }}>{this.state.secondary_referral_list.map((val) => { return (val.label) })}</div>
                    {/* <TextareaAutosize rows={2} name="secondary_referral_list" value={this.state.secondary_referral_list.map((val) => { return (val.label) })} disabled={true} className='form-control' style={{ overflow: "hidden" }} /> */}
                  </div>
                </div>
              </div>
              <hr />
            </div>
            {/*====================== Referral - Admission Information End ====================*/}
            {/*==================================++++++++++=================================== */}
            {/*========================Specific substance Abuse Problem Start==================*/}

            <h4>Specific Substance Use Problem: </h4>

            <div >
              {this.state.substanceBlock.map((val, index) => {
                return (
                  <div className='card' style={{ padding: "10px 10px" }}>
                    <div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label>{index == 0 ? "1st" : index == 1 ? "2nd" : index == 2 ? "3rd" : (index + 1) + "th"} Primary Substance Problem Type</label>
                            <select className='form-control' disabled={true} name="problem_type" value={val.problem_type}>
                              <option value=""></option>
                              {this.state.problem_type_options.map((options) => (<option value={options.value}>{options.label}</option>))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label>Specific Substance/Problem Details</label>
                            <select className='form-control' style={{ fontSize: "13.7px" }} disabled={true} name="specific_substance" value={val.specific_substance}>
                              <option value=""></option>
                              {this.state.specific_substance_options.map((options) => (<option value={options.value}>{options.label}</option>))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-4'>
                          <div className='form-group'>
                            <label>Primary Substance Route Of Admin</label>
                            <select className='form-control' disabled name="route_of_administration" value={val.route_of_administration}>
                              <option value=""></option>
                              <option value="oral">Oral</option>
                              <option value="smoking">Smoking</option>
                              <option value="inhalation">Inhalation</option>
                              <option value="injection">Injection/IV</option>
                              <option value="injection_intramuscular">Injection/Intramuscular</option>
                              <option value="other_specify">Other</option>
                            </select>
                          </div>
                        </div>


                        <div className='col-4'>
                          <div className='form-group'>
                            <label>Primary Substance Age of 1st Use</label>
                            <input type="text" disabled={true} name="age_of_1st_use" value={val.age_of_1st_use} className="form-control" />
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='form-group'>
                            <label>Primary Substance Frequency of Use</label>
                            <select className='form-control' disabled name="frequency_of_use" value={val.frequency_of_use} >
                              <option value=""></option>
                              <option value="no_use_in_the_past_month ">No use in the past month      </option>
                              <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                              <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                              <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                              <option value="daily">Daily</option>
                              <option value="other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <hr />
            {/* Specific substance Abuse Problem End*/}
            {/* =============++++++============ */}
            {/* Specific Mental Health Problem  Start*/}
            <div>
              <div className='row'>
                <div className='col-12'>
                  <h4>Specific Mental Health Problems: </h4>
                </div>
              </div>

              {this.state.mentalHealthDisorderBlock?.map((val) => {
                return (
                  <>
                    <div className="card ">
                      <div className="card-body ">
                        <div className='row'>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Problem code </label>
                              <input type="text" disabled name="mental_health_disorder" value={val.mental_health_disorder} className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-12">
                            <div className="form-group">
                              <label>ICD 10 Code </label>
                              <input type="text" disabled name="icd_10_code" value={val.icd_10_code} className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-12">
                            <div className="form-group">
                              <label>Description</label>
                              <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{val.comments} </div>
                              {/* <TextareaAutosize
                                minRows={(val.comments?.length / 133) + val.comments?.split(/\r\n|\r|\n/).length}
                                disabled 
                                name="comments" 
                                value={val.comments} 
                                className='form-control'
                                style={{ overflow: "hidden", textAlign: "justify" }}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            {/* Specific Mental Health Problem  End*/}
            {/* =============++++++============ */}
            {/*Other Start*/}

            <h4>Other: </h4>
            <div className="card ">
              <div className="card-body ">
                <div className='row'>
                  <div className="col-12  ">
                    <label>Description</label>
                    <div className="form-group">
                      {this.state.otherHealthDisorderBlock.map((val) => {
                        return (
                          <>
                            <div className="form-control heightRemove PdfBackgroundColor" name="disorder_name" style={{ backgroundColor: "#e9ecef" }}>{val.disorder_name}</div>
                          </>

                          // <TextareaAutosize 
                          //   type="text" 
                          //   style={{overflow:"hidden",textAlign:"justify"}}
                          //   minRows={(val.disorder_name?.length / 133) + val.disorder_name?.split(/\r\n|\r|\n/).length}
                          //   disabled 
                          //   name="disorder_name" 
                          //   value={val.disorder_name} 
                          //   className="form-control mb-2" 
                          // />
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/*=============================Other Start End===============================*/}
            {/*================================++++++==================================== */}
            {/*=============Psychological and Environment Problems Start==================*/}
            <h4>Psychological and Environmental Problems: </h4>
            <div className="card ">
              <div className="card-body ">
                <table className="table table-bordered table-striped">
                  <thead>
                    {/* <th></th>
                                  <th></th> */}

                  </thead>
                  <tbody>
                    {this.state.psychologicalEnviornmentalProblemsBlock.map((psyprblm, index) => {
                      return (
                        <tr>
                          {/* <td style={{ width: "3%" }}>{index + 1} </td> */}
                          <td style={{ width: "100%" }}>
                            <select className='form-control' disabled={true} name="disorder_name" value={psyprblm.disorder_name}>
                              <option value=""></option>
                              {this.state.disorderOptions.map((disoptions) => (<option value={disoptions.value}>{disoptions.label}</option>))}
                            </select>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            {/*==============Psychological and Environment Problems End========================*/}
            {/* ===================================+++++++++++++++============================ */}
            {/*===============Client Characteristics and Services Start========================*/}
            <div className='row'>
              <div className="col-12">
                <div className="form-group">
                  <label>Client Name</label>
                  <input type="text" disabled name="client_name" value={this.state.client_name} className="form-control" />
                </div>
              </div>
            </div>

            <hr />
            <div>
              <div className='row'>
                <div className="col-12">
                  <h4>Client Characteristics and Services </h4>
                </div>
              </div>
              <div className="card ">
                <div className="card-body ">
                  <div className='row'>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Marital Status</label>
                        <select className="form-control" disabled={true} name="maritial_status" value={this.state.maritial_status}>
                          <option value=""></option>
                          <option value="Common Law">Common Law </option>
                          <option value="Single">Single</option>
                          <option value="Living Together">Living Together</option>
                          <option value="Married">Married</option>
                          <option value="Separated">Separated</option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Employment Status</label>
                        <select className="form-control" disabled={true} name="employment_status" value={this.state.employment_status} >
                          <option value=""></option>
                          <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
                          <option value="disabled">Disabled</option>
                          <option value="full_time">Full-time</option>
                          <option value="homemaker">Homemaker</option>
                          <option value="part_time">Part-time</option>
                          <option value="retired">Retired</option>
                          <option value="student">Student</option>
                          <option value="supported_employment">Supported Employment</option>
                          <option value="unemployed_looking">Unemployed, looking</option>
                          <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Education</label>
                        <select className="form-control" disabled={true} name="educational_status" value={this.state.educational_status} >
                          <option value="none">None</option>
                          <option value="student">Student</option>
                          <option value="graduated_hs">Graduated HS</option>
                          <option value="ged">GED</option>
                          <option value="dropped_out">Dropped Out</option>
                          <option value="college_graduate">College Graduate</option>
                          <option value="some_education_after_hs">Some education after HS</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label>Highest grade completed</label>
                        {/* <input type="text" disabled name="highest_grade_complete" value={this.state.highest_grade_complete} className="form-control" /> */}
                        <select className="form-control" disabled={true} name="highest_grade_complete" value={this.state.highest_grade_complete} >
                          <option value="none">None</option>
                          <option value="student">Student</option>
                          <option value="graduated_hs">Graduated HS</option>
                          <option value="ged">GED</option>
                          <option value="dropped_out">Dropped Out</option>
                          <option value="college_graduate">College Graduate</option>
                          <option value="some_education_after_hs">Some education after HS</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-6">
                      <div className="form-group">
                        <label>Hearing Status</label>
                        <select className="form-control" name="hearing_status" value={this.state.hearing_status} disabled={true}  >
                          <option value=""></option>
                          {this.hearingOptions.map((hearing) => (
                            <option value={hearing.value}>
                              {hearing.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label>Language Preference</label>
                        <select className="form-control" name="language_preference" value={this.state.language_preference} disabled={true} >
                          <option value=""></option>
                          <option value="english">English</option>
                          <option value="american_sign_language">American Sign Language</option>
                          <option value="arabic">Arabic</option>
                          <option value="chinese">Chinese</option>
                          <option value="french_and_french_creole">French And French Creole(Including Cajun)</option>
                          <option value="german">German</option>
                          <option value="hindi">Hindi</option>
                          <option value="italian">Italian</option>
                          <option value="japanese">Japanese</option>
                          <option value="korean">Korean</option>
                          <option value="laotian">Laotian</option>
                          <option value="other_african_languages">Other African Languages</option>
                          <option value="other_asian_languages">Other Asian Languages</option>
                          <option value="other_europian_languages">Other Europian Languages</option>
                          <option value="other_indic_languages">Other Indic Languages</option>
                          <option value="persian">Persian</option>
                          <option value="portuguese">Portuguese</option>
                          <option value="russian">Russian</option>
                          <option value="portuguese">Spanish And Spanish Creole</option>
                          <option value="tagalog">Tagalog</option>
                          <option value="vietnamese">Vietnamese</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-6 form-group">
                      <label>Living Arrangement</label>
                      <input type="text" disabled name="number_of_years" value={this.state.number_of_years} className="form-control" />
                    </div>
                    <div className="col-6 " style={{ marginTop: "20px" }}>
                      <input type="text" disabled name="number_of_months" value={this.state.number_of_months} className="form-control mt-2" />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Pregnant at The time of Admission</label>
                        <input type="text" disabled name="pregnant" value={this.state.pregnant === 'yes' ? 'Yes' : this.state.pregnant === 'no' ? 'No' : ''} className="form-control" />
                      </div>
                    </div>
                  </div>
                  {this.state.pregnant == "yes" &&
                    <>
                      <div className="row ">
                        <div className="col-6">
                          <div className="form-group">
                            <label>How many weeks</label>
                            <input type="text" disabled name="week" value={this.state.week} className="form-control" />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Recieving Prenatal Care? </label>
                            <input type="text" disabled name="prenatal_care" value={this.state.prenatal_care === 'yes' ? 'Yes' : this.state.prenatal_care === 'no' ? 'No' : ''} className="form-control" />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
              <hr />
            </div>
            {/*=================Client Characteristics and Services End================*/}
            {/* ================================++++++=================================*/}
            {/*===========================Co-occuring Start============================*/}
            <div>
              <div className="row">
                <div className="col-12">
                  <h4>Co-occuring</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Was a Co-occurring Disorder Screen Administered?</label>
                    <select name="co_occuring_disorder_screen" disabled={true} value={this.state.co_occuring_disorder_screen} className='form-control'>
                      <option></option>
                      {this.co_occuring_disorder_option.map(option => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>Was a Co-occurring Disorder Assessment Administered? </label>
                    <select name="co_occuring_disorder_assesment" disabled={true} value={this.state.co_occuring_disorder_assesment} className='form-control'>
                      <option></option>
                      {this.co_occuring_disorder_option.map(option => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Is Client Participating in Opioid Replacement Therapy? </label>
                    <select className='form-control' disabled={true} value={this.state.participated_in_self_care_groups} name="participated_in_self_care_groups">
                      <option value=""></option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>Number of Prior Treatment Episodes? </label>
                    <input type="text" disabled name="prior_treatment_episodes" value={this.state.prior_treatment_episodes} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Number of Arrest in the 30 days Prior to Admission?</label>
                    <input type="text" disabled name="arrest_last_30_days" value={this.state.arrest_last_30_days} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Has client participated in self-help groups or support groups (e.g. AA, NA, etc.) in the last 30 days?</label>
                    <input type="text" disabled name="participated_in_self_care_groups" value={this.state.participated_in_self_care_groups === 'yes' ? 'Yes' : this.state.participated_in_self_care_groups === 'no' ? 'No' : ''} className="form-control" />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            {/*==========================Co-occuring  End================================*/}
            {/* ==================================++++++================================ */}
            {/*===========================Financial Start================================*/}
            <div>
              <div className="row">
                <div className="col-12"> <h4>Financial</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Source of Financial Support</label>
                    {/* <input type="text" disabled name="princepal_source_of_income" value={this.state.princepal_source_of_income} className="form-control" /> */}
                    <select className="form-control" name="princepal_source_of_income" value={this.state.princepal_source_of_income} disabled={true}>
                      <option value=""></option>
                      <option value="wages_salary">Wages/Salary</option>
                      <option value="public_assistance"> Public Assistance</option>
                      <option value="retirement_pension">Retirement/Pension</option>
                      <option value="disablity">Disablity</option>
                      <option value="none">None</option>
                      <option value="other">Other</option>
                      {/* {form_type == "craftScreening" ? (<option value="unknown">Unknown</option>) : ""} */}
                    </select>
                  </div>
                </div>

                <div className="col-8">
                  <div className="form-group">
                    <label>Health Insurance</label>
                    {/* <input type="text" disabled name="insurance_type" value="" className="form-control" /> */}
                    <select name="insurance_type" className="form-control" value={this.state.insurance_type} disabled={true} >
                      <option value=""></option>
                      {this.insurancetypeOptions.map((insurance) => (
                        <option value={insurance.value}>
                          {insurance.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Policy Number</label>
                    <input type="text" disabled name="policy_number" value={this.state.policy_number} className="form-control" />
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Group Number</label>
                    <input type="text" disabled name="group_number" value={this.state.group_number} className="form-control" />
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Medicaid Number</label>
                    <input type="text" disabled name="medicaid" value={this.state.medicaid} className="form-control" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Source of Payment</label>
                    <input type="text" disabled name="payment_source" value={this.state.payment_source} className="form-control" />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            {/* =============Financial---End=============*/}
            {/* ===============++++++================= */}
            {/* =============Intake Level of Care Placement Summary START=============*/}
            <div>
              <div className="row">
                <div className="col-12">
                  <h4>Intake Level of Care Placement Summary</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Assessed Level of Care</label>
                    <div className="form-control heightRemove PdfBackgroundColor" name="assesssed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{this.state.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                    {/* <TextareaAutosize rows={2} name="assesssed_level_of_care" value={this.state.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control'
                      style={{ overflow: "hidden" }} /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Placed Level of Care</label>
                    <div className="form-control heightRemove PdfBackgroundColor" name="placed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{this.state.placed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                    {/* <TextareaAutosize rows={2} disabled name="placed_level_of_care" value={this.state.placed_level_of_care.map((val) => { return (val.label) }).join(", ")} className="form-control p-2 p-md-3" style={{ overflow: "hidden" }} /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>If placement is different from assessed level what reason?</label>
                    <select name="reason_for_difference" className="form-control" value={this.state.reason_for_difference} disabled={true} >
                      <option value=""></option>
                      {this.reasontypeOptions.map((reason) => (
                        <option value={reason.value}>
                          {reason.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Disposition</label>
                    <div className="form-control heightRemove PdfBackgroundColor" name="disposition" style={{ backgroundColor: "#e9ecef" }}>{this.state.disposition.map((val) => { return (val.label) })}</div>
                    {/* <TextareaAutosize rows={2} name="disposition" value={this.state.disposition.map((val) => { return (val.label) })} disabled={true} className='form-control' style={{ overflow: "hidden" }} /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div >
      </>
    )
  }
}
