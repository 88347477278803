///////////////////////////////////////////////////>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> This is from dev to prod for print (PDF) text cut issue and overlapping.  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>////////////////////////////////////////////////
import React, { Component } from 'react'
import axios from 'axios';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';

export default class PrintAssesment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            printData: [
                {
                    form_type: "Adolescent_Assessment",
                    // acuteIntoxication
                    withdrawl_symptoms: "",
                    Larger_amount_of_aod: "",
                    Larger_amount_aod_explain: "",
                    previous_effort_to_control_aod: "",
                    previous_effort_to_cut_down_explain: "",
                    spend_great_deal_of_time: "",
                    spend_great_deal_of_time_explain: "",
                    strong_desire_to_use_aod: "",
                    strong_desire_to_use_aod_explain: "",
                    neglected_important_social_obligations: "",
                    neglected_important_social_obligations_explain: "",
                    continue_to_use_despite_negative_consequences: "",
                    continue_to_use_despite_negative_consequences_explain: "",
                    affected_you_socially: "",
                    affected_you_socially_explain: "",
                    give_up_on_participate: "",
                    give_up_on_participate_explain: "",
                    placing_yourself_in_danger: "",
                    placing_yourself_in_danger_explain: "",
                    contribute_to_physical_problem: "",
                    contribute_to_physical_problem_explain: "",
                    feel_same_effect: "",
                    feel_same_effect_explain: "",
                    acute_risk_rating: "",
                    acute_risk_rating_information: "",
                    acute_comments: "",
                    new_symptoms: "",
                    new_symptoms_explain: "",
                    old_symptoms: [],
                    acute_risk_rating_summury: "",
                    // substanceUse
                    substances: [{
                        class_of_substance: '',
                        specific_substance: '',
                        problem_type: '',
                        route_of_administration: '',
                        other_route_of_administration: '',
                        age_of_1st_use: '',
                        date_last_used: '',
                        how_long_used: '',
                        how_long_used_days: '',
                        how_long_used_months: '',
                        frequency_of_use: '',
                        age_of_regular_use: '',
                        // periods_of_abstinence: '',

                        periods_of_abstinence_days: '',
                        knownAbstinence: false,
                        periods_of_abstinence_months: '',
                        periods_of_abstinence_years: '',

                        amount_of_regular_use: '',
                        rank_order: 1,
                    },
                    ],
                    substance_comments: '',
                    Class_of_Substances_Options: [],
                    problem_type_options: [],

                    // biomedicalCondition
                    current_medical_problem: '',
                    current_medical_problems_explain: '',
                    pregnant: '',
                    due_date: '',
                    weeks: '',
                    prenatal_care: '',
                    medical_conditions_comment: '',
                    number_of_Pregnancies: '',
                    known_allergies: '',
                    known_allergies_explain: '',
                    had_tb_tested_positive: '',
                    had_tb_tested_positive_explain: '',
                    sputum_producing_cough: '',
                    sputum_producing_cough_explain: '',
                    cough_up_blood: '',
                    cough_up_blood_explain: '',
                    loss_of_appetite: '',
                    loss_of_appetite_explain: '',
                    night_sweats: '',
                    night_sweats_explain: '',
                    fever: '',
                    fever_explain: '',
                    tb_medication: '',
                    tb_medication_explain: '',
                    notes: '',

                    biomedical_risk_rating: "",
                    bioMedications: [
                        {
                            bio_medication: "",
                            bio_prescribedFor: "",
                            bio_dosage: "",
                            bio_frequency: "",
                            bio_taking_as_prescribed: "",
                            bio_taking_as_directed: "",
                        }
                    ],

                    biomedical_comments: "",
                    form_type: props.form_type,
                    biomedical_risk_rating_information: '',

                    // EmotionalBehaviour
                    bvc_trauma: "",
                    bvc_trauma_explain: "",
                    bvc_illness: "",
                    mental_health_disorder_emotion: "",
                    bvc_issues: "",
                    bvc_issues_explain: "",
                    bvc_felt: "",
                    bvc_felt_explain: "",
                    emotional_bvc_hallucinations: "",
                    emotional_bvc_hallucinations_explain: "",

                    orientation_comments: "",
                    general_comments: "",
                    mood_comments: "",
                    speech_comments: "",
                    memory_comments: "",
                    process_comments: "",
                    thought_content_comments: "",
                    insight_comments: "",
                    other_comments: "",
                    bvc_comments: "",

                    emotionalMedications: [
                        {
                            emotional_medication: "",
                            emotional_prescribedFor: "",
                            emotional_dosage: "",
                            emotional_frequency: "",
                            emotional_taking_as_directed: "",
                            emotional_taking_as_prescribed: ""

                        }
                    ],

                    emotional_comments: "",
                    emotional_risk_rating: "",
                    notes: "",
                    emotional_risk_rating_information: "",

                    immediate: [],
                    recent: [],
                    remote: [],
                    speech: [],
                    orientation: [],
                    dress: [],
                    grooming: [],
                    facial_exp: [],
                    mood: [],
                    affect: [],
                    judgement: [],
                    insight: [],
                    process: [],
                    content: [],

                    // readinnessForChange
                    why_are_you_here: '',
                    help_with_any_other_problem: '',
                    help_with_any_other_problem_explain: '',
                    readiness_mental_risk_rating: '',
                    readiness_comments: '',
                    readiness_risk_rating: '',
                    readiness_risk_rating_information: "",
                    action_score: "",
                    contemplation_score: "",
                    precontemplation_score: "",
                    stage_of_change_designation: "",
                    readiness_notes_comment: "",
                    readiness_mental_risk_rating_information: '',
                    readiness_mental_comments: '',


                    questions: [
                        { question: "My substance use is okay as it is.", answer: '', name: "okay_as_it" },
                        { question: "I am trying to use substances less than I used to.", answer: '', name: "less_than_I_used_to" },
                        { question: "I enjoy my substance use but sometimes I use too much.", answer: '', name: "I_use_too_much" },
                        { question: "I should cut down on my substance use.", answer: '', name: "my_substance_use" },
                        { question: "It’s a waste of time thinking about my substance use.", answer: '', name: "thinking_about_my" },
                        { question: "I have just recently changed my substance use habits.", answer: '', name: "substance_use_habits" },
                        { question: "Anyone can talk about wanting to do something about substance use, but I am actually doing something about it.", answer: '', name: "something_about_it" },
                        { question: "I am at the stage where I should think about using less substances.", answer: '', name: "less_substances" },
                        { question: "My substance use is a problem.", answer: '', name: "use_is_a_problem" },
                        { question: "It’s alright for me to keep using substances as I do now.", answer: '', name: "as_I_do_now" },
                        { question: "I am actually changing my substance use habits right now.", answer: '', name: "right_now" },
                        { question: "My life would still be the same, even if I use substances less.", answer: '', name: "use_substances_less" },
                    ],

                    // problemPotential
                    drug_problem: '',
                    drug_problem_who: '',
                    alchol_problem: '',
                    alchol_problem_who: '',
                    mental_illness: '',
                    mental_illness_who: '',
                    handicapped: '',
                    injury_handicapped_who: '',
                    aod_problem: '',
                    recieving_opioid_replacement: '',
                    periods_of_abstinence: '',
                    abstinence: '',
                    relapse_triggers: '',
                    cause_relapse: '',
                    participate_in_any_support_group: '',
                    support_group: '',
                    problem_comments: '',
                    problem_comments2: '',
                    sponser: '',
                    attend_range: '',
                    attendTimeOptions: [],
                    relapse_risk_rating: '',
                    relapse_mental_risk_rating: '',
                    relapse_mental_risk_rating_information: '',
                    relapse_mental_comments: '',

                    form_type: props.form_type,

                    problemBlock:[
                        {
                          alchol: '',
                          when: '',
                          where: '',
                          level_of_care: '',
                          type_of_discharge: '',
                        }
                      ],
                    relapse_risk_rating_information: "",
                    problemPotential_notes_comment: "",

                    // Enviornment
                    enviornment_comments: "",
                    indicate_living_arrangement: "",
                    living_arrangement_comments: "",
                    indicate_living_arrangement_other: "",
                    number_of_years: "",
                    number_of_months: "",
                    current_living_enviornment: "",
                    run_from_home: '',
                    run_from_home_explain: '',
                    employment_status: "",
                    current_school_status: "",
                    educational_status: "",
                    educational_status_other_explain: "",
                    years_schools: "",
                    adl_ever_arrested: "",
                    adl_ever_arrested_explain: "",
                    arrested_in_30_days: "",
                    how_many_times: "",

                    involved_with_court: "",
                    court_officer: "",
                    registered_sex_offender: "",
                    dependent_children: "",
                    dependent_children_number: '',
                    custody: "",
                    child_care_available: "",
                    reliable_transportation: "",
                    reliable_transportation_explain: "",
                    satisfied_with_support_system: "",
                    recovery_risk_rating: "",
                    recovery_mental_risk_rating: "",
                    interaction: "",
                    any_arrest: "",
                    arrests: "",
                    recovery_risk_rating_information: "",
                    recovery_notes_comment: "",
                    recovery_mental_risk_rating_information: "",
                    recovery_mental_comments: "",

                    // substanceUseDisorder
                    substanceUseDisorder: [
                        {
                            disorder: "",
                            icd_10_code: "",
                            specific_substance: "",
                            route_of_administration: "",
                            age_of_1st_use: "",
                            frequency_of_use: "",
                            specifier: "",
                            other_route_of_administration: "",
                            uid: "",
                        },
                    ],
                    mental_health_orders: [
                        {
                            mental_health_disorder: "",
                            icd_10_code: "",
                            comments: "",
                        },
                    ],
                    otherDisorder: [
                        {
                            disorder_name: "",
                        },
                    ],
                    psychological_problems: [
                        {
                            category: "",
                            disorder_name: "",
                            icd_10_code: "",
                            comments: "",
                            disorderOptions: [],
                        },
                    ],
                    // Level of care
                    // placed_level_of_care: '',
                    reason_for_difference: '',
                    assessed_comments: '',
                    co_occuring_disorder_screen: '',
                    co_occuring_disorder_screen_explain: '',
                    co_occuring_disorder_assesment: '',
                    co_occuring_disorder_assesment_explain: '',
                    client_seeking_explain: '',
                    explaination_of_the_above: '',
                    Admitted_to: '',
                    // Referred_to: '',
                    release_of_information: false,
                    appropriate_release_of_information: '',
                    reffered_from_drug_court: '',
                    indigent_offender: '',
                    client_start_date: '',
                    // refered_for_case: '',
                    // assesed_level: '',
                    // no_service_availaible1: '',
                    no_service_availaible2: '',
                    refused_further_services: '',
                    client_seeking_treatment: '',

                    disposition: [],
                    medical_provider_review: [],
                    assesssed_level_of_care: [],
                    placed_level_of_care: [],

                    Referred_to: [],
                    refered_for_case: [],
                    assesed_level: [],
                    no_service_availaible1: [],
                    interim_level_of_care: "",
                    interim_assessed_level_of_care: [],


                    // Signature

                    client_name: '',
                    assesment_staff: '',
                    staff_name: '',
                    physician_name: '',

                    client_sign_uploaded_time: "",
                    client_sign_uploaded_time_Assesment_Staff: "",
                    client_sign_uploaded_time_Physician: "",
                    client_sign_uploaded_time_Staff: "",
                    justification: "",
                    uploaded_by: "",

                    preview1: '',
                    preview2: '',
                    preview3: '',
                    preview4: '',

                }
            ],

            disorderOptions: [],
            categoryOptions: [],
            Class_of_Substances_Options: [],
            specific_substance_options: [],
            problem_type_options: [],
            dataListBody: {
                apikey: sessionStorage.getItem("__TOKEN__"),
                client_id: sessionStorage.getItem("clientId"),
            }
        }
    }

    specifierOption = [
        { value: 'In_Early_Remission', label: 'In Early Remission' },
        { value: 'In_Sustained_Remission', label: 'In Sustained Remission' },
        { value: 'On_Maintenance_Therapy', label: 'On Maintenance Therapy' },
        { value: 'In_a_Controlled_Environment', label: 'In a Controlled Environment' },
        { value: 'N/A', label: 'N/A' },
    ]
    reasonDifferenceOptions = [
        { value: "transportation_or_logistical_problem", label: "Transportation or Logistical Problem" },
        { value: "court_order", label: "Court Order" },
        { value: "consumer_preference", label: "Consumer Preference" },
        { value: "clinician_supervisor_override", label: "Clinician/Supervisor Override" },
        { value: "intensive_outpatient_treatment", label: "Intensive Outpatient Treatment" },
        { value: "waiting_for_indicated_level", label: "Waiting for Indicated Level" },
        { value: "service_not_available", label: "Service not available" },
        { value: "na", label: "N/A No Difference" },
        { value: "other", label: "Other" }
    ]

    co_occuring_disorder_option = [
        { value: "not_performed", label: "Not performed" },
        { value: "administered_results(-)", label: " Administered/Results (-)" },
        { value: "administered_results(+)", label: "Administered/Results (+)" },

    ]
    componentDidMount = async () => {
        this.loadData();
    }
    loadData = async () => {
        let res = await axios.post(global.restApiURL + "customrest/getStateAssessmentPdf", JSON.stringify({
            apikey: sessionStorage.getItem("__TOKEN__"),
            client_id: sessionStorage.getItem("clientId"),
        }));

        if (res.data.success) {
            this.setState({
                printData: res.data.data.map((val, index) => {
                    let problemBlock = val.problemBlock || [];

                    // Add a default row if problemBlock is empty
                    if (problemBlock.length === 0) {
                        problemBlock = [
                            {
                                alchol: "",
                                when: "",
                                where: "",
                                level_of_care: "",
                                type_of_discharge: "",
                            },
                        ];
                    }
                    let bioMedications = val.bioMedications || [];
                    if (bioMedications.length === 0) {
                        bioMedications = [
                            {
                                bio_medication: "",
                                bio_prescribedFor: "",
                                bio_dosage: "",
                                bio_frequency: "",
                                bio_taking_as_prescribed: "",
                                bio_taking_as_directed: "",
                            },
                        ];
                    }
                    return (
                        {
                            form_type: val.form_type,
                            // AcuteIntoxication
                            withdrawl_symptoms: val.withdrawl_symptoms,
                            Larger_amount_of_aod: val.Larger_amount_of_aod,
                            Larger_amount_aod_explain: val.Larger_amount_aod_explain,
                            previous_effort_to_control_aod: val.previous_effort_to_control_aod,
                            previous_effort_to_cut_down_explain: val.previous_effort_to_cut_down_explain,
                            spend_great_deal_of_time: val.spend_great_deal_of_time,
                            spend_great_deal_of_time_explain: val.spend_great_deal_of_time_explain,
                            strong_desire_to_use_aod: val.strong_desire_to_use_aod,
                            strong_desire_to_use_aod_explain: val.strong_desire_to_use_aod_explain,
                            neglected_important_social_obligations: val.neglected_important_social_obligations,
                            neglected_important_social_obligations_explain: val.neglected_important_social_obligations_explain,
                            continue_to_use_despite_negative_consequences: val.continue_to_use_despite_negative_consequences,
                            continue_to_use_despite_negative_consequences_explain: val.continue_to_use_despite_negative_consequences_explain,
                            affected_you_socially: val.affected_you_socially,
                            affected_you_socially_explain: val.affected_you_socially_explain,
                            give_up_on_participate: val.give_up_on_participate,
                            give_up_on_participate_explain: val.give_up_on_participate_explain,
                            placing_yourself_in_danger: val.placing_yourself_in_danger,
                            placing_yourself_in_danger_explain: val.placing_yourself_in_danger_explain,
                            contribute_to_physical_problem: val.contribute_to_physical_problem,
                            contribute_to_physical_problem_explain: val.contribute_to_physical_problem_explain,
                            feel_same_effect: val.feel_same_effect,
                            feel_same_effect_explain: val.feel_same_effect_explain,
                            acute_risk_rating: val.acute_risk_rating,
                            acute_risk_rating_information: val.acute_risk_rating_information,
                            acute_risk_rating_summury: val.acute_risk_rating_summury,
                            acute_comments: val.acute_comments,
                            new_symptoms: val.new_symptoms,
                            new_symptoms_explain: val.new_symptoms_explain,
                            old_symptoms: val.old_symptoms.length > 0 ? JSON.parse(val.old_symptoms).map((oldSym, index) => {
                                return ({
                                    label: oldSym.label, value: oldSym.value,
                                })
                            }) : [],
                            // old_symptoms: [],
                            // substanceUse
                            substances: val.substances.map((val2, index) => {
                                return ({
                                    class_of_substance: val2.class_of_substance,
                                    specific_substance: val2.specific_substance,
                                    problem_type: val2.problem_type,
                                    route_of_administration: val2.route_of_administration,
                                    other_route_of_administration: val2.other_route_of_administration,
                                    age_of_1st_use: val2.age_of_1st_use,
                                    date_last_used: val2.date_last_used,
                                    how_long_used: val2.how_long_used,
                                    how_long_used_days: val2.how_long_used_days,
                                    how_long_used_months: val2.how_long_used_months,
                                    frequency_of_use: val2.frequency_of_use,
                                    age_of_regular_use: val2.age_of_regular_use,
                                    // periods_of_abstinence: val2.periods_of_abstinence,

                                    periods_of_abstinence_days: val2.periods_of_abstinence_days,
                                    knownAbstinence: val2.knownAbstinence,
                                    periods_of_abstinence_months: val2.periods_of_abstinence_months,
                                    periods_of_abstinence_years: val2.periods_of_abstinence_years,

                                    amount_of_regular_use: val2.amount_of_regular_use,
                                    rank_order: val2.rank_order,
                                })
                            }),
                            substance_comments: val.substance_comments,
                            Class_of_Substances_Options: [],


                            // biomedicalCondition
                            current_medical_problem: val.current_medical_problem,
                            current_medical_problems_explain: val.current_medical_problems_explain,
                            pregnant: val.pregnant,
                            due_date: val.due_date,
                            weeks: val.weeks,
                            prenatal_care: val.prenatal_care,
                            medical_conditions_comment: val.medical_conditions_comment,
                            number_of_Pregnancies: val.number_of_Pregnancies,
                            known_allergies: val.known_allergies,
                            known_allergies_explain: val.known_allergies_explain,
                            had_tb_tested_positive: val.had_tb_tested_positive,
                            had_tb_tested_positive_explain: val.had_tb_tested_positive_explain,
                            sputum_producing_cough: val.sputum_producing_cough,
                            sputum_producing_cough_explain: val.sputum_producing_cough_explain,
                            cough_up_blood: val.cough_up_blood,
                            cough_up_blood_explain: val.cough_up_blood_explain,
                            loss_of_appetite: val.loss_of_appetite,
                            loss_of_appetite_explain: val.loss_of_appetite_explain,
                            night_sweats: val.night_sweats,
                            night_sweats_explain: val.night_sweats_explain,
                            fever: val.fever,
                            fever_explain: val.fever_explain,
                            tb_medication: val.tb_medication,
                            tb_medication_explain: val.tb_medication_explain,
                            notes: val.notes,

                            biomedical_risk_rating: val.biomedical_risk_rating,
                            bioMedications: bioMedications,



                            biomedical_comments: val.biomedical_comments,
                            biomedical_risk_rating_information: val.biomedical_risk_rating_information,

                            // EmotionalBehaviour
                            bvc_trauma: val.bvc_trauma,
                            bvc_trauma_explain: val.bvc_trauma_explain,
                            bvc_illness: val.bvc_illness,
                            mental_health_disorder_emotion: val.mental_health_disorder_emotion,

                            bvc_issues: val.bvc_issues,
                            bvc_issues_explain: val.bvc_issues_explain,
                            bvc_felt: val.bvc_felt,
                            bvc_felt_explain: val.bvc_felt_explain,
                            emotional_bvc_hallucinations: val.emotional_bvc_hallucinations,
                            emotional_bvc_hallucinations_explain: val.emotional_bvc_hallucinations_explain,

                            orientation_comments: val.orientation_comments,
                            general_comments: val.general_comments,
                            mood_comments: val.mood_comments,
                            speech_comments: val.speech_comments,
                            memory_comments: val.memory_comments,
                            process_comments: val.process_comments,
                            thought_content_comments: val.thought_content_comments,
                            insight_comments: val.insight_comments,
                            other_comments: val.other_comments,
                            bvc_comments: val.bvc_comments,

                            emotionalMedications: val.emotionalMedications.map((medication, index) => {
                                return ({
                                    emotional_medication: medication.emotional_medication,
                                    emotional_prescribedFor: medication.emotional_prescribedFor,
                                    emotional_dosage: medication.emotional_dosage,
                                    emotional_frequency: medication.emotional_frequency,
                                    emotional_taking_as_directed: medication.emotional_taking_as_directed,
                                    emotional_taking_as_prescribed: medication.emotional_taking_as_prescribed,
                                })
                            }),

                            emotional_comments: val.emotional_comments,
                            emotional_risk_rating: val.emotional_risk_rating,
                            notes: val.notes,
                            emotional_risk_rating_information: val.emotional_risk_rating_information,

                            immediate: val.immediate.length > 0 ? JSON.parse(val.immediate).map((immediate, index) => {
                                return ({
                                    label: immediate.label, value: immediate.value,
                                })
                            }) : [],
                            recent: val.recent.length > 0 ? JSON.parse(val.recent).map((recent, index) => {
                                return ({
                                    label: recent.label, value: recent.value,
                                })
                            }) : [],
                            remote: val.remote.length > 0 ? JSON.parse(val.remote).map((remote, index) => {
                                return ({
                                    label: remote.label, value: remote.value,
                                })
                            }) : [],
                            speech: val.speech.length > 0 ? JSON.parse(val.speech).map((speech, index) => {
                                return ({
                                    label: speech.label, value: speech.value,
                                })
                            }) : [],
                            orientation: val.orientation.length > 0 ? JSON.parse(val.orientation).map((orientation, index) => {
                                return ({
                                    label: orientation.label, value: orientation.value,
                                })
                            }) : [],
                            dress: val.dress.length > 0 ? JSON.parse(val.dress).map((dress, index) => {
                                return ({
                                    label: dress.label, value: dress.value,
                                })
                            }) : [],
                            grooming: val.grooming.length > 0 ? JSON.parse(val.grooming).map((grooming, index) => {
                                return ({
                                    label: grooming.label, value: grooming.value,
                                })
                            }) : [],
                            facial_exp: val.facial_exp.length > 0 ? JSON.parse(val.facial_exp).map((facial_exp, index) => {
                                return ({
                                    label: facial_exp.label, value: facial_exp.value,
                                })
                            }) : [],
                            mood: val.mood.length > 0 ? JSON.parse(val.mood).map((mood, index) => {
                                return ({
                                    label: mood.label, value: mood.value,
                                })
                            }) : [],
                            affect: val.affect.length > 0 ? JSON.parse(val.affect).map((affect, index) => {
                                return ({
                                    label: affect.label, value: affect.value,
                                })
                            }) : [],
                            judgement: val.judgement.length > 0 ? JSON.parse(val.judgement).map((judgement, index) => {
                                return ({
                                    label: judgement.label, value: judgement.value,
                                })
                            }) : [],
                            insight: val.insight.length > 0 ? JSON.parse(val.insight).map((insight, index) => {
                                return ({
                                    label: insight.label, value: insight.value,
                                })
                            }) : [],
                            process: val.process.length > 0 ? JSON.parse(val.process).map((process, index) => {
                                return ({
                                    label: process.label, value: process.value,
                                })
                            }) : [],
                            content: val.content.length > 0 ? JSON.parse(val.content).map((content, index) => {
                                return ({
                                    label: content.label, value: content.value,
                                })
                            }) : [],

                            // readinnessForChange
                            why_are_you_here: val.why_are_you_here,
                            help_with_any_other_problem: val.help_with_any_other_problem,
                            help_with_any_other_problem_explain: val.help_with_any_other_problem_explain,
                            readiness_mental_risk_rating: val.readiness_mental_risk_rating,
                            readiness_comments: val.readiness_comments,
                            readiness_risk_rating: val.readiness_risk_rating,
                            readiness_risk_rating_information: val.readiness_risk_rating_information,
                            action_score: val.action_score,
                            contemplation_score: val.contemplation_score,
                            precontemplation_score: val.precontemplation_score,
                            stage_of_change_designation: val.stage_of_change_designation,
                            readiness_notes_comment: val.readiness_notes_comment,
                            readiness_mental_risk_rating_information: val.readiness_mental_risk_rating_information,
                            readiness_mental_comments: val.readiness_mental_comments,

                            questions: val.questions.length > 0 ? JSON.parse(val.questions).map((val3, index) => {
                                return ({
                                    question: val3.question, answer: val3.answer, name: val3.name
                                })
                            }) :
                                [{ question: "My substance use is okay as it is.", answer: '', name: "okay_as_it" },
                                { question: "I am trying to use substances less than I used to.", answer: '', name: "less_than_I_used_to" },
                                { question: "I enjoy my substance use but sometimes I use too much.", answer: '', name: "I_use_too_much" },
                                { question: "I should cut down on my substance use.", answer: '', name: "my_substance_use" },
                                { question: "It’s a waste of time thinking about my substance use.", answer: '', name: "thinking_about_my" },
                                { question: "I have just recently changed my substance use habits.", answer: '', name: "substance_use_habits" },
                                { question: "Anyone can talk about wanting to do something about substance use, but I am actually doing something about it.", answer: '', name: "something_about_it" },
                                { question: "I am at the stage where I should think about using less substances.", answer: '', name: "less_substances" },
                                { question: "My substance use is a problem.", answer: '', name: "use_is_a_problem" },
                                { question: "It’s alright for me to keep using substances as I do now.", answer: '', name: "as_I_do_now" },
                                { question: "I am actually changing my substance use habits right now.", answer: '', name: "right_now" },
                                { question: "My life would still be the same, even if I use substances less.", answer: '', name: "use_substances_less" },],

                            // problemPotential
                            drug_problem: val.drug_problem,
                            drug_problem_who: val.drug_problem_who,
                            alchol_problem: val.alchol_problem,
                            alchol_problem_who: val.alchol_problem_who,
                            mental_illness: val.mental_illness,
                            mental_illness_who: val.mental_illness_who,
                            handicapped: val.handicapped,
                            injury_handicapped_who: val.injury_handicapped_who,
                            aod_problem: val.aod_problem,
                            recieving_opioid_replacement: val.recieving_opioid_replacement,
                            periods_of_abstinence: val.periods_of_abstinence,
                            abstinence: val.abstinence,
                            relapse_triggers: val.relapse_triggers,
                            cause_relapse: val.cause_relapse,
                            participate_in_any_support_group: val.participate_in_any_support_group,
                            support_group: val.support_group,
                            problem_comments: val.problem_comments,
                            problem_comments2: val.problem_comments2,
                            sponser: val.sponser,
                            attend_range: val.attend_range,
                            attendTimeOptions: [],
                            problemPotential_notes_comment: val.problemPotential_notes_comment,
                            relapse_risk_rating: val.relapse_risk_rating,
                            relapse_mental_risk_rating: val.relapse_mental_risk_rating,
                            relapse_mental_risk_rating_information: val.relapse_mental_risk_rating_information,
                            relapse_mental_comments: val.relapse_mental_comments,

                            problemBlock:problemBlock,
                            relapse_risk_rating_information: val.relapse_risk_rating_information,



                            // Enviornment
                            enviornment_comments: val.enviornment_comments,
                            indicate_living_arrangement: val.indicate_living_arrangement,
                            living_arrangement_comments: val.living_arrangement_comments,
                            indicate_living_arrangement_other: val.indicate_living_arrangement_other,
                            number_of_years: val.number_of_years,
                            number_of_months: val.number_of_months,
                            current_living_enviornment: val.current_living_enviornment,
                            run_from_home: val.run_from_home,
                            run_from_home_explain: val.run_from_home_explain,
                            employment_status: val.employment_status,
                            current_school_status: val.current_school_status,
                            educational_status: val.educational_status,
                            educational_status_other_explain: val.educational_status_other_explain,
                            years_schools: val.years_schools,
                            adl_ever_arrested: val.adl_ever_arrested,
                            adl_ever_arrested_explain: val.adl_ever_arrested_explain,
                            arrested_in_30_days: val.arrested_in_30_days,
                            how_many_times: val.how_many_times,

                            involved_with_court: val.involved_with_court,
                            court_officer: val.court_officer,
                            registered_sex_offender: val.registered_sex_offender,
                            dependent_children: val.dependent_children,
                            dependent_children_number: val.dependent_children_number,
                            custody: val.custody,
                            child_care_available: val.child_care_available,
                            reliable_transportation: val.reliable_transportation,
                            reliable_transportation_explain: val.reliable_transportation_explain,
                            satisfied_with_support_system: val.satisfied_with_support_system,
                            recovery_risk_rating: val.recovery_risk_rating,
                            recovery_mental_risk_rating: val.recovery_mental_risk_rating,
                            interaction: val.interaction,
                            any_arrest: val.any_arrest,
                            arrests: val.arrests,
                            recovery_risk_rating_information: val.recovery_risk_rating_information,
                            recovery_notes_comment: val.recovery_notes_comment,

                            recovery_mental_risk_rating_information: val.recovery_mental_risk_rating_information,
                            recovery_mental_comments: val.recovery_mental_comments,

                            // substanceUseDisorder   
                            substanceUseDisorder: val.substances.map((disorder, index) => {
                                return ({
                                    disorder: disorder.disorder,
                                    icd_10_code: disorder.icd_10_code,
                                    specific_substance: disorder.specific_substance,
                                    route_of_administration: disorder.route_of_administration,
                                    age_of_1st_use: disorder.age_of_1st_use,
                                    frequency_of_use: disorder.frequency_of_use,
                                    specifier: disorder.specifier,
                                    other_route_of_administration: disorder.other_route_of_administration,
                                    uid: disorder.uid,
                                })
                            }),
                            // mental_health_orders
                            mental_health_orders: val.mental_health_orders.map((mental, index) => {
                                return ({
                                    mental_health_disorder: mental.mental_health_disorder,
                                    icd_10_code: mental.icd_10_code,
                                    comments: mental.comments,
                                })
                            }),
                            // otherDisorder
                            otherDisorder: val.other_health_orders.map((other, index) => {
                                return ({
                                    disorder_name: other.disorder_name,
                                })
                            }),
                            // psychological_problems
                            psychological_problems: val.psychological_problems.map((psychological, index) => {
                                return ({
                                    category: psychological.category,
                                    disorder_name: psychological.disorder_name,
                                    icd_10_code: psychological.icd_10_code,
                                    comments: psychological.comments,
                                    disorderOptions: psychological.disorderOptions ? psychological.disorderOptions : [],

                                })
                            }),
                            // Level of care
                            placed_level_of_care: val.placed_level_of_care,
                            reason_for_difference: val.reason_for_difference,
                            assessed_comments: val.assessed_comments,
                            co_occuring_disorder_screen: val.co_occuring_disorder_screen,
                            co_occuring_disorder_screen_explain: val.co_occuring_disorder_screen_explain,
                            co_occuring_disorder_assesment: val.co_occuring_disorder_assesment,
                            co_occuring_disorder_assesment_explain: val.co_occuring_disorder_assesment_explain,
                            client_seeking_explain: val.client_seeking_explain,
                            explaination_of_the_above: val.explaination_of_the_above,
                            Admitted_to: val.Admitted_to,
                            // Referred_to: val.Referred_to,
                            release_of_information: val.release_of_information,
                            appropriate_release_of_information: val.appropriate_release_of_information,
                            reffered_from_drug_court: val.reffered_from_drug_court,
                            indigent_offender: val.indigent_offender,
                            client_start_date: val.client_start_date,
                            // refered_for_case: val.refered_for_case,
                            // assesed_level: val.assesed_level,
                            // no_service_availaible1: val.no_service_availaible1,
                            no_service_availaible2: val.no_service_availaible2,
                            refused_further_services: val.refused_further_services,
                            client_seeking_treatment: val.client_seeking_treatment,

                            interim_level_of_care: val.interim_level_of_care,
                            // interim_assessed_level_of_care:val.interim_assessed_level_of_care,
                            interim_assessed_level_of_care: val.interim_assessed_level_of_care.length > 0 ? JSON.parse(val.interim_assessed_level_of_care).map((medical, index) => {
                                return ({
                                    label: medical.label, value: medical.value,
                                })
                            }) : [],
                            disposition: val.disposition.length > 0 ? JSON.parse(val.disposition).map((disposition, index) => {
                                return ({
                                    label: disposition.label, value: disposition.value,
                                })
                            }) : [],
                            medical_provider_review: val.medical_provider_review.length > 0 ? JSON.parse(val.medical_provider_review).map((medical, index) => {
                                return ({
                                    label: medical.label, value: medical.value,
                                })
                            }) : [],
                            assesssed_level_of_care: val.assesssed_level_of_care.length > 0 ? JSON.parse(val.assesssed_level_of_care).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],
                            placed_level_of_care: val.placed_level_of_care.length > 0 ? JSON.parse(val.placed_level_of_care).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],
                            Referred_to: val.Referred_to.length > 0 ? JSON.parse(val.Referred_to).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],
                            refered_for_case: val.refered_for_case.length > 0 ? JSON.parse(val.refered_for_case).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],
                            assesed_level: val.assesed_level.length > 0 ? JSON.parse(val.assesed_level).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],
                            no_service_availaible1: val.no_service_availaible1.length > 0 ? JSON.parse(val.no_service_availaible1).map((assesssed, index) => {
                                return ({
                                    label: assesssed.label, value: assesssed.value,
                                })
                            }) : [],



                            // Signature

                            client_name: val.client_name,
                            assesment_staff: val.assesment_staff,
                            staff_name: val.staff_name,
                            physician_name: val.physician_name,

                            client_sign_uploaded_time: val.client_uploaded_date,
                            client_sign_uploaded_time_Assesment_Staff: val.assesment_staff_uploaded_date,
                            client_sign_uploaded_time_Physician: val.physician_uploaded_date,
                            client_sign_uploaded_time_Staff: val.staff_uploaded_date,
                            justification: val.justification,
                            uploaded_by: val.uploaded_by,

                            preview1: val.client_signature,
                            preview2: val.assesment_staff_signature,
                            preview3: val.staff_signature,
                            preview4: val.physician_signature,


                        }
                    )
                })
            })
        }

        // Specific substance Options
        let res_specific_options = await axios.post(global.restApiURL + "customrest/getSubstanceProblemType", JSON.stringify({
            dataListBody: {
                apikey: sessionStorage.getItem("__TOKEN__"),
                client_id: sessionStorage.getItem("clientId"),
            },
            "problem_type": "",
        }));
        if (res_specific_options.status == 200 && res_specific_options.data.success == true) {
            this.setState({ specific_substance_options: res_specific_options.data.data.problem_type.substance });
        }
        // Problem type Options
        let substanceProblemType_res = await axios.post(global.restApiURL + "customRest/getProblems", JSON.stringify({ dataListBody: this.state.dataListBody }));
        if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
            let options = substanceProblemType_res.data.data.problemType.length > 0 ? JSON.parse(substanceProblemType_res.data.data.problemType) : []
            this.setState({ problem_type_options: options })
        }

        // Load Disorder  Options
        let final_data = { dataListBody: this.state.dataListBody, }
        let disorder_res = await axios.post(global.restApiURL + "customrest/getSubstanceuseDisorderData", JSON.stringify(final_data))
        if (disorder_res.status == "200" && disorder_res.data.success) {
            if (disorder_res.data.data.substanceUseDisordersDrop.length > 0) {
                let tempVal = JSON.parse(disorder_res.data.data.substanceUseDisordersDrop)
                this.setState({ disorderOptions: tempVal })
            }

        }

        //  category Options
        let getCategories = await axios.post(global.restApiURL + "customrest/getCategories", JSON.stringify({ dataListBody: this.state.dataListBody }));
        if (getCategories.data.success && getCategories.status == 200) {
            this.setState({ categoryOptions: getCategories.data.data.categories })
        }

        // Class of Substance
        let classSubstance_res = await axios.post(global.restApiURL + "customRest/getSubstancesClass", JSON.stringify({ dataListBody: this.state.dataListBody }));
        if (classSubstance_res.status == 200 && classSubstance_res.data.success == true) {
            let options = classSubstance_res.data.data.substanceClass.length > 0 ? JSON.parse(classSubstance_res.data.data.substanceClass) : []
            this.setState({ Class_of_Substances_Options: options })
        }
    }
    render() {
        return (
            <>
                {this.state.printData.map((val, index) => {
                    return (
                        <div className="hold-transition sidebar-mini text-sm">
                            <div className="wrapper">
                                <div className="">
                                    <section className="content-body mx-2 ">
                                        <div className="row justify-content-center">
                                            <div className='row'>
                                                <div className="col-12" >
                                                    {/* <h2 className="text-green text-center">{val.form_type == "Adult_Assessment" ? "State Assessment (Adult)" : val.form_type == "Adolescent_Assessment" ? "State Assessment (Adolescent)" : ""}</h2> */}
                                                    <h2 className="text-green text-center" style={{ pageBreakBefore: "always", display: "block" }}>{val.form_type == "Adult_Assessment" ? "State Assessment (Adult)" : val.form_type == "Adolescent_Assessment" ? "State Assessment (Adolescent)" : ""}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    )
                })
                }

                {/* ################################************************************################################ */}
                {/* ================================== PRINT SUBSTANCE-USE START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-md-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p
                                                                    className="lead mx-auto"
                                                                    style={{ marginBottom: "1rem" }}
                                                                >
                                                                    <b className="text-green">Substance Use Background</b>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Do you have a history of withdrawal symptoms when
                                                                                you haven't been able to obtain alcohol and or
                                                                                other drugs (AOD), cut down on your use, or
                                                                                stopped using?</label>
                                                                            <input type="text" name="withdrawl_symptoms" disabled className="form-control" value={val.withdrawl_symptoms} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.withdrawl_symptoms == "Yes" && (
                                                                    <>
                                                                        <div className='row'>
                                                                            <div className="col-12">
                                                                                <label>Which symptoms have you had? </label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="old_symptoms" style={{ backgroundColor: "#e9ecef" }}>{val.old_symptoms.map((val) => { return (val.label) }).join(", ")}</div>
                                                                                {/* <TextareaAutosize disabled rows={2} value={val.old_symptoms.map((val) => { return (val.label) }).join(", ")} className="form-control" name="old_symptoms" style={{ overflow: "hidden" }} /> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    <label> Are you currently experiencing any of the above? </label>
                                                                                    <input type="text" name="new_symptoms" disabled className="form-control" value={val.new_symptoms} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {val.new_symptoms ==
                                                                            "Yes" && (
                                                                                <>
                                                                                    <div className='row'>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group" id='testCheck'>
                                                                                                <label>Please explain</label>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="new_symptoms_explain" style={{ backgroundColor: "#e9ecef" }}>{val.new_symptoms_explain}</div>
                                                                                                {/* <TextareaAutosize
                                                                                                    disabled
                                                                                                    minRows={(val.new_symptoms_explain?.length / 138) + val.new_symptoms_explain?.split(/\r\n|\r|\n/).length}
                                                                                                    value={val.new_symptoms_explain}
                                                                                                    className="form-control"
                                                                                                    name="new_symptoms_explain"
                                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                                    id='testCheck'
                                                                                                    
                                                                                                /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                    </>
                                                                )}
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have you used larger amounts of AOD than you intended or for longer than you intended?</label>
                                                                            <input type="text" name="Larger_amount_of_aod" disabled className="form-control" value={val.Larger_amount_of_aod} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.Larger_amount_of_aod ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="Larger_amount_aod_explain" style={{ backgroundColor: "#e9ecef" }}>{val.Larger_amount_aod_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={this.state.printData ? (val.Larger_amount_aod_explain?.length / 125) + val.Larger_amount_aod_explain?.split(/\r\n|\r|\n/).length : (val.Larger_amount_aod_explain?.length / 135) + val.Larger_amount_aod_explain?.split(/\r\n|\r|\n/).length}
                                                                                            // minRows={(val.Larger_amount_aod_explain?.length / 138) + val.Larger_amount_aod_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            name="Larger_amount_aod_explain"
                                                                                            value={val.Larger_amount_aod_explain}
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have previous efforts to cut down or control AOD use been unsuccessful? </label>
                                                                            <input type="text" name="previous_effort_to_control_aod" disabled className="form-control" value={val.previous_effort_to_control_aod} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.previous_effort_to_control_aod ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="previous_effort_to_cut_down_explain" style={{ backgroundColor: "#e9ecef" }}>{val.previous_effort_to_cut_down_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.previous_effort_to_cut_down_explain?.length / 138) + val.previous_effort_to_cut_down_explain?.split(/\r\n|\r|\n/).length}
                                                                                            value={val.previous_effort_to_cut_down_explain}
                                                                                            className="form-control"
                                                                                            name="previous_effort_to_cut_down_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                {val.substances && val.substances.map((row, index) => {
                                                                    console.log(row);
                                                                    return (<>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className='marginBottom1'>
                                                                                    <div className='row'>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Class of Substances</label>
                                                                                                <select className="form-control" disabled={true} name="class_of_substance" value={row.class_of_substance} >
                                                                                                    <option value=""> </option>
                                                                                                    {this.state.Class_of_Substances_Options.map((val) => {
                                                                                                        return (<option value={val.value}>{val.label}</option>)
                                                                                                    })}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Substance/Problem Type { } </label>
                                                                                                <select className="form-control" disabled={true} name="problem_type" value={row.problem_type} >
                                                                                                    <option value=""> </option>
                                                                                                    {this.state.problem_type_options.map((val) => {
                                                                                                        return (<option value={val.value}>{val.label}</option>)
                                                                                                    })}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-12'>
                                                                                            <div className='form-group'>
                                                                                                <label>Specific Substance  { }  </label>
                                                                                                <select className="form-control" disabled={true} name="specific_substance" value={row.specific_substance} >
                                                                                                    <option value=""> </option>
                                                                                                    {this.state.specific_substance_options.map((val) => {
                                                                                                        return (<option value={val.value}>{val.label}</option>)
                                                                                                    })}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className={row.route_of_administration == "other_specify" ? 'col-6' : "col-12"}>
                                                                                            <div className='form-group'>
                                                                                                <label>Route of Administration</label>
                                                                                                <select className='form-control' disabled name="route_of_administration" value={row.route_of_administration}>
                                                                                                    <option value=""></option>
                                                                                                    <option value="oral">Oral</option>
                                                                                                    <option value="smoking">Smoking</option>
                                                                                                    <option value="inhalation">Inhalation</option>
                                                                                                    <option value="injection">Injection/IV</option>
                                                                                                    <option value="rectal">Rectal</option>
                                                                                                    <option value="injection_intramuscular">Injection/Intramuscular</option>
                                                                                                    <option value="other_specify">Other</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        {row.route_of_administration == "other_specify" &&
                                                                                            <div className='col-6'>
                                                                                                <div className='form-group'>
                                                                                                    <label>Please Specify</label>
                                                                                                    <input type="text" name="other_route_of_administration" disabled className="form-control" value={row.other_route_of_administration} />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Age of 1st Use</label>
                                                                                                <input type="text" name="age_of_1st_use" disabled className="form-control" value={row.age_of_1st_use} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Date Last Used </label>
                                                                                                {/* {console.log("<label>Date Last Used </label>",moment(row.date_last_used).format("MM/DD/YYYY"))} */}
                                                                                                <input type="text" name="date_last_used" disabled className="form-control" value={row.date_last_used} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className='col-6'>
                                                                                        <div className='form-group'>
                                                                                        <label>How Long Used(Years)</label>
                                                                                        <input type="text" name="how_long_used" disabled className="form-control" value={row.how_long_used} />
                                                                                        </div>
                                                                                    </div> */}
                                                                                    <div className='row'>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Frequency of Use</label>
                                                                                                <select className='form-control' disabled name="frequency_of_use" value={row.frequency_of_use} >
                                                                                                    <option value=""></option>
                                                                                                    <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                                                                                    <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                                                                                    <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                                                                                    <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                                                                                    <option value="daily">Daily</option>
                                                                                                    <option value="other">Other</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Age of Regular Use</label>
                                                                                                <input type="text" name="age_of_regular_use" disabled className="form-control" value={row.age_of_regular_use} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Amount of Regular Use</label>
                                                                                                <input type="text" name="amount_of_regular_use" disabled className="form-control" value={row.amount_of_regular_use} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-6'>
                                                                                            <div className='form-group'>
                                                                                                <label>Rank Order</label>
                                                                                                <input type="text" name="rank_order" disabled className="form-control" value={row.rank_order} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div row className="marginBottom1">
                                                                                    <div className='row'>
                                                                                        <div className='col-12'> How long Used &nbsp;&nbsp;&nbsp;
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Day</label>
                                                                                                <input type="text" name="how_long_used_days" disabled className="form-control" value={row.how_long_used_days} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Month</label>
                                                                                                <input type="text" name="how_long_used_months" disabled className="form-control" value={row.how_long_used_months} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Year</label>
                                                                                                <input type="text" name="how_long_used" disabled className="form-control" value={row.how_long_used} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div row className="marginBottom1">
                                                                                    <div className='row'>
                                                                                        <div className='col-12'> Periods of Abstinence(Years)&nbsp;&nbsp;&nbsp;
                                                                                            <span><input type="checkbox" name="knownAbstinence" disabled checked={row.knownAbstinence} /> Unknown </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Day</label>
                                                                                                <input type="text" name="periods_of_abstinence_days" disabled className="form-control" value={row.periods_of_abstinence_days} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Month</label>
                                                                                                <input type="text" name="periods_of_abstinence_months" disabled className="form-control" value={row.periods_of_abstinence_months} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-4'>
                                                                                            <div className='form-group'>
                                                                                                <label>Year</label>
                                                                                                <input type="text" name="periods_of_abstinence_years" disabled className="form-control" value={row.periods_of_abstinence_years} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </>
                                                                    )
                                                                })}
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label>Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{val.substance_comments}</div>

                                                                            {/* <TextareaAutosize
                                                                                name="comments"
                                                                                disabled
                                                                                className='form-control'
                                                                                value={val.substance_comments}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                minRows={(val.substance_comments?.length / 138) + val.substance_comments?.split(/\r\n|\r|\n/).length}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* =================================== PRINT SUBSTANCE-USE END =========================================*/}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT ACUTE INTOXICATION START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-md-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem" }} >
                                                                    <b className="text-green">Acute intoxication/withdrawal potential</b>
                                                                </p>

                                                            </div>
                                                            <div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Do you spend a great deal of time in activities to obtain AOD and/or feeling its effects? </label>
                                                                            <input type="text" name="spend_great_deal_of_time" disabled className="form-control" value={val.spend_great_deal_of_time} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.spend_great_deal_of_time ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="spend_great_deal_of_time_explain" style={{ backgroundColor: "#e9ecef" }}>{val.spend_great_deal_of_time_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.spend_great_deal_of_time_explain?.length / 138) + val.spend_great_deal_of_time_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.spend_great_deal_of_time_explain}
                                                                                            name="spend_great_deal_of_time_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    )}


                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>  Have you had cravings or a strong desire to use AOD? </label>
                                                                            <input type="text" name="strong_desire_to_use_aod" disabled className="form-control" value={val.strong_desire_to_use_aod} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {val.strong_desire_to_use_aod ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="strong_desire_to_use_aod_explain" style={{ backgroundColor: "#e9ecef" }}>{val.strong_desire_to_use_aod_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.strong_desire_to_use_aod_explain?.length / 138) + val.strong_desire_to_use_aod_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.strong_desire_to_use_aod_explain}
                                                                                            name="strong_desire_to_use_aod_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have you used AOD to the point that you have neglected important social obligations (work, school, home)? </label>
                                                                            <input type="text" name="neglected_important_social_obligations" disabled className="form-control" value={val.neglected_important_social_obligations} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.neglected_important_social_obligations ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="neglected_important_social_obligations_explain" style={{ backgroundColor: "#e9ecef" }}>{val.neglected_important_social_obligations_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.neglected_important_social_obligations_explain?.length / 138) + val.neglected_important_social_obligations_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.neglected_important_social_obligations_explain}
                                                                                            name="neglected_important_social_obligations_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>  Have you continued to use despite the negative consequences (at work, school, home)? </label>
                                                                            <input type="text" name="continue_to_use_despite_negative_consequences" disabled className="form-control" value={val.continue_to_use_despite_negative_consequences} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.continue_to_use_despite_negative_consequences ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className=' row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="continue_to_use_despite_negative_consequences_explain" style={{ backgroundColor: "#e9ecef" }}>{val.continue_to_use_despite_negative_consequences_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.continue_to_use_despite_negative_consequences_explain?.length / 138) + val.continue_to_use_despite_negative_consequences_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.continue_to_use_despite_negative_consequences_explain}
                                                                                            name="continue_to_use_despite_negative_consequences_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have you continued using AOD even though your  use affected you socially (fights, problems in relationships, etc.)? </label>
                                                                            <input type="text" name="affected_you_socially" disabled className="form-control" value={val.affected_you_socially} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.affected_you_socially ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className=' row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="affected_you_socially_explain" style={{ backgroundColor: "#e9ecef" }}>{val.affected_you_socially_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.affected_you_socially_explain?.length / 138) + val.affected_you_socially_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.affected_you_socially_explain}
                                                                                            name="affected_you_socially_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Has your AOD use caused you to give up or not participate in social, occupational or recreational activities that you once enjoyed? </label>
                                                                            <input type="text" name="give_up_on_participate" disabled className="form-control" value={val.give_up_on_participate} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.give_up_on_participate ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className=' row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="give_up_on_participate_explain" style={{ backgroundColor: "#e9ecef" }}>{val.give_up_on_participate_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.give_up_on_participate_explain?.length / 138) + val.give_up_on_participate_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.give_up_on_participate_explain}
                                                                                            name="give_up_on_participate_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have you continued to use despite placing yourself and/or others in dangerous or unsafe  situations?</label>
                                                                            <input type="text" name="placing_yourself_in_danger" disabled className="form-control" value={val.placing_yourself_in_danger} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.placing_yourself_in_danger ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className=' row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="placing_yourself_in_danger_explain" style={{ backgroundColor: "#e9ecef" }}>{val.placing_yourself_in_danger_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.placing_yourself_in_danger_explain?.length / 138) + val.placing_yourself_in_danger_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.placing_yourself_in_danger_explain}
                                                                                            name="placing_yourself_in_danger_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Have you continued to use AOD after knowing it caused or contributed to physical and/or psychological problems?</label>
                                                                            <input type="text" name="contribute_to_physical_problem" disabled className="form-control" value={val.contribute_to_physical_problem} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.contribute_to_physical_problem ==
                                                                    "Yes" && (
                                                                        <>
                                                                            <div className=' row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Please explain</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="contribute_to_physical_problem_explain" style={{ backgroundColor: "#e9ecef" }}>{val.contribute_to_physical_problem_explain}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            disabled
                                                                                            minRows={(val.contribute_to_physical_problem_explain?.length / 138) + val.contribute_to_physical_problem_explain?.split(/\r\n|\r|\n/).length}
                                                                                            className="form-control"
                                                                                            value={val.contribute_to_physical_problem_explain}
                                                                                            name="contribute_to_physical_problem_explain"
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Do you need more AOD to get the same high or feel the same effect?{" "} </label>
                                                                            <input type="text" name="feel_same_effect" disabled className="form-control" value={val.feel_same_effect} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.feel_same_effect == "Yes" && (
                                                                    <>
                                                                        <div className=' row'>
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="feel_same_effect_explain" style={{ backgroundColor: "#e9ecef" }}>{val.feel_same_effect_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        disabled
                                                                                        minRows={(val.feel_same_effect_explain?.length / 138) + val.feel_same_effect_explain?.split(/\r\n|\r|\n/).length}
                                                                                        className="form-control"
                                                                                        value={val.feel_same_effect_explain}
                                                                                        name="feel_same_effect_explain"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                <div className=' row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Notes or comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="acute_comments" style={{ backgroundColor: "#e9ecef" }}>{val.acute_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control"
                                                                                value={val.acute_comments}
                                                                                name="acute_comments"
                                                                                disabled={true}
                                                                                minRows={(val.acute_comments?.length / 138) + val.acute_comments?.split(/\r\n|\r|\n/).length}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginBottom1">
                                                                    <div className="col-md-12">
                                                                        <div className="green_band">
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <p className="lead sa_risk">
                                                                                        <b>Substance Use Risk Rating</b>
                                                                                        <span className="acute_risk_rating0"></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <div className="form-group ml-0 pl-0">
                                                                                        <input className="autoMargin" type="radio" disabled={true} checked={val.acute_risk_rating == "0" ? true : false} name={"acute_risk_rating0" + val.form_type} value="0" /> &nbsp;
                                                                                        <label for="acute_risk_rating0" className="labelMargin">  0 </label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} checked={val.acute_risk_rating == "1" ? true : false} name={"acute_risk_rating1" + val.form_type} value="1" /> &nbsp;
                                                                                        <label for="acute_risk_rating1" className="labelMargin"> 1 </label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} checked={val.acute_risk_rating == "2" ? true : false} name={"acute_risk_rating2" + val.form_type} value="2" />{" "} &nbsp;
                                                                                        <label for="acute_risk_rating2" className="labelMargin"> 2 </label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} checked={val.acute_risk_rating == "3" ? true : false} name={"acute_risk_rating3" + val.form_type} value="3" /> &nbsp;
                                                                                        <label for="acute_risk_rating3" className="labelMargin"> 3 </label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} checked={val.acute_risk_rating === "4" ? true : false} name={"acute_risk_rating4" + val.form_type} value="4" /> &nbsp;
                                                                                        <label for="acute_risk_rating4" className="labelMargin">  4 </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* New Code */}
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        Diagnostic summary for this dimension
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Analysis</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="acute_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.acute_risk_rating_information}</div>
                                                                            {/* <TextareaAutosize readOnly className="form-control" value={val.acute_risk_rating_information} name="acute_risk_rating_information" disabled={true}
                                                                                style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Risk Rating Summary</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="acute_risk_rating_summury" style={{ backgroundColor: "#e9ecef" }}>{val.acute_risk_rating_summury}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control"
                                                                                minRows={(val.acute_risk_rating_summury?.length / 138) + val.acute_risk_rating_summury?.split(/\r\n|\r|\n/).length}
                                                                                value={val.acute_risk_rating_summury}
                                                                                name="acute_risk_rating_summury"
                                                                                disabled={true}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* New Code */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <hr/> */}
                {/* <div className="page-break" /> */}
                {/* ================================== PRINT ACUTE INTOXICATION END ==================================== */}

                {/* ################################************************************################################ */}
                {/* ================================== PRINT BIOMEDICAL CONDITION START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-md-12">
                                                        <div className="card shadow-none">
                                                            <div>
                                                                <p className="lead " style={{ marginBottom: '1rem' }}>
                                                                    <b className="text-green">
                                                                        Biomedical conditions and complications
                                                                    </b>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Do you have any current medical problems, including infectious communicable diseases?</label>
                                                                            <input type="text" name="current_medical_problem" disabled className="form-control" value={val.current_medical_problem} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.current_medical_problem == "Yes" &&
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <label>Please explain</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="current_medical_problems_explain" style={{ backgroundColor: "#e9ecef" }}>{val.current_medical_problems_explain}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control"
                                                                                minRows={(val.current_medical_problems_explain?.length / 138) + val.current_medical_problems_explain?.split(/\r\n|\r|\n/).length}
                                                                                disabled
                                                                                value={val.current_medical_problems_explain}
                                                                                name="current_medical_problems_explain"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className='row mt-3'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Are you pregnant?</label>
                                                                            {/* <input type="text" name="pregnant" disabled className="form-control" value={val.pregnant === 'yes' ? 'Yes' : val.pregnant === 'no' ? 'No' : ''} /> */}
                                                                            <input type="text" name="pregnant" disabled className="form-control" value={val.pregnant ? val.pregnant[0].toUpperCase() + val.pregnant.slice(1) : val.pregnant} /> 
                                                                        </div>
                                                                    </div>
                                                                    {val.pregnant == "yes" &&
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Total Number of Pregnancies</label>
                                                                                <input type="text" name="number_of_Pregnancies" disabled value={val.number_of_Pregnancies} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {val.pregnant == "yes" && <>
                                                                    <div className='row'>
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label>Due date </label>
                                                                                <input type="text" name="due_date" disabled value={val.due_date} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label>If yes, how many weeks?</label>
                                                                                <input type="text" name="weeks" disabled value={val.weeks} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-4">
                                                                            <div className="form-group">
                                                                                <label>If yes, are you receiving prenatal care? </label>
                                                                                <input type="text" name="prenatal_care" disabled value={val.prenatal_care === 'yes' ? 'Yes' : val.prenatal_care === 'no' ? 'No' : ''} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group" >
                                                                            <label>
                                                                                List any medications you currently take for <strong>medical conditions</strong>, have taken, or should take including over the counter medications:
                                                                            </label>

                                                                            <table className="table table-bordered table-striped">
                                                                                <thead>
                                                                                    <th>Medication {val.medication1}</th>
                                                                                    <th>Prescribed For</th>
                                                                                    <th>Dosage</th>
                                                                                    <th>Frequency</th>
                                                                                    <th>Taking as Prescribed?</th>
                                                                                    <th>If OTC, Taking as Directed?</th>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {val.bioMedications.map((medication, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_medication" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_medication}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_medication?.length / 18) + medication.bio_medication?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_medication" value={medication.bio_medication} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_prescribedFor" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_prescribedFor}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_prescribedFor?.length / 18) + medication.bio_prescribedFor?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_prescribedFor" value={medication.bio_prescribedFor} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_dosage" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_dosage}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_dosage?.length / 18) + medication.bio_dosage?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_dosage" value={medication.bio_dosage} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_frequency" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_frequency}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_frequency?.length / 18) + medication.bio_frequency?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_frequency" value={medication.bio_frequency} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_taking_as_prescribed" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_taking_as_prescribed === 'yes' ? 'Yes' : medication.bio_taking_as_prescribed === 'no' ? 'No' : ''}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_taking_as_prescribed?.length / 18) + medication.bio_taking_as_prescribed?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_taking_as_prescribed" value={medication.bio_taking_as_prescribed === 'yes' ? 'Yes' : medication.bio_taking_as_prescribed === 'no' ? 'No' : ''} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="bio_taking_as_directed" style={{ backgroundColor: "#e9ecef" }}>{medication.bio_taking_as_directed === 'yes' ? 'Yes' : medication.bio_taking_as_directed === 'no' ? 'No' : ''}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.bio_taking_as_directed?.length / 18) + medication.bio_taking_as_directed?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="bio_taking_as_directed" value={medication.bio_taking_as_directed === 'yes' ? 'Yes' : medication.bio_taking_as_directed === 'no' ? 'No' : ''} className="form-control" /> */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="medical_conditions_comment" style={{ backgroundColor: "#e9ecef" }}>{val.medical_conditions_comment}</div>
                                                                            {/* <TextareaAutosize
                                                                                minRows={(val.medical_conditions_comment?.length / 138) + val.medical_conditions_comment?.split(/\r\n|\r|\n/).length}
                                                                                name="medical_conditions_comment"
                                                                                disabled
                                                                                value={val.medical_conditions_comment}
                                                                                className="form-control"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className={val.known_allergies == "yes" ? 'col-6' : "col-12"}>
                                                                        <div className="form-group">
                                                                            <label>Do you have any known allergies (including food or medications)?</label>
                                                                            <input type="text" disabled name="known_allergies" value={val.known_allergies === 'yes' ? 'Yes' : val.known_allergies === 'no' ? 'No' : ''} className="form-control" />
                                                                            {/*  value={val.known_allergies}  */}
                                                                        </div>
                                                                    </div>
                                                                    {val.known_allergies == "yes" && <>
                                                                        <div className='col-6'>
                                                                            <div className="form-group">
                                                                                <label>Please explain</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="known_allergies_explain" style={{ backgroundColor: "#e9ecef" }}>{val.known_allergies_explain}</div>
                                                                                {/* <TextareaAutosize
                                                                                    minRows={(val.known_allergies_explain?.length / 62) + val.known_allergies_explain?.split(/\r\n|\r|\n/).length}
                                                                                    disabled
                                                                                    value={val.known_allergies_explain}
                                                                                    className="form-control"
                                                                                    name="known_allergies_explain"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <div className='form-group'>
                                                                            <label>Have you had TB or tested positive for TB in the past?</label>
                                                                            <input type="text" disabled name="had_tb_tested_positive" value={val.had_tb_tested_positive === 'yes' ? 'Yes' : val.had_tb_tested_positive === 'no' ? 'No' : ''} className="form-control" />
                                                                            {/* value={val.had_tb_tested_positive}  */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.had_tb_tested_positive == "yes" && <>
                                                                    <div className='row'>
                                                                        <div className="col-md-12">
                                                                            <b> For more than two weeks do you…. (consider possible withdrawal symptoms)</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label>1. Have sputum-producing cough?</label>
                                                                                <input type="text" name="sputum_producing_cough" disabled value={val.sputum_producing_cough === 'yes' ? 'Yes' : val.sputum_producing_cough === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                                {/* value={val.sputum_producing_cough} */}
                                                                            </div>
                                                                        </div>
                                                                        {val.sputum_producing_cough == "yes" && <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="sputum_producing_cough_explain" style={{ backgroundColor: "#e9ecef" }}>{val.sputum_producing_cough_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.sputum_producing_cough_explain?.length / 62) + val.sputum_producing_cough_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.sputum_producing_cough_explain}
                                                                                        name="sputum_producing_cough_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label>2. Cough up blood?</label>
                                                                                <input type="text" name="cough_up_blood" disabled value={val.cough_up_blood === 'yes' ? 'Yes' : val.cough_up_blood === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                                {/* value={val.cough_up_blood} */}
                                                                            </div>
                                                                        </div>
                                                                        {val.cough_up_blood == "yes" && <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="cough_up_blood_explain" style={{ backgroundColor: "#e9ecef" }}>{val.cough_up_blood_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.cough_up_blood_explain?.length / 62) + val.cough_up_blood_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled 
                                                                                        value={val.cough_up_blood_explain}
                                                                                        name="cough_up_blood_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label> 3. Have loss of appetite?</label>
                                                                                <input type="text" name="loss_of_appetite" disabled value={val.loss_of_appetite === 'yes' ? 'Yes' : val.loss_of_appetite === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                                {/* value={val.loss_of_appetite} */}
                                                                            </div>
                                                                        </div>
                                                                        {val.loss_of_appetite == "yes" && <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="loss_of_appetite_explain" style={{ backgroundColor: "#e9ecef" }}>{val.loss_of_appetite_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.loss_of_appetite_explain?.length / 62) + val.loss_of_appetite_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.loss_of_appetite_explain}
                                                                                        name="loss_of_appetite_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label> 4. Have night sweats?</label>
                                                                                <input type="text" name="night_sweats" disabled value={val.night_sweats === 'yes' ? 'Yes' : val.night_sweats === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                                {/* value={val.night_sweats} */}
                                                                            </div>
                                                                        </div>
                                                                        {val.night_sweats == "yes" && <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="night_sweats_explain" style={{ backgroundColor: "#e9ecef" }}>{val.night_sweats_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.night_sweats_explain?.length / 59) + val.night_sweats_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.night_sweats_explain}
                                                                                        name="night_sweats_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label>5. Have a fever?</label>
                                                                                <input type="text" name="fever" disabled value={val.fever === 'yes' ? 'Yes' : val.fever === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                                {/* value={val.fever} */}
                                                                            </div>
                                                                        </div>
                                                                        {val.fever == "yes" && <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="fever_explain" style={{ backgroundColor: "#e9ecef" }}>{val.fever_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.fever_explain?.length / 59) + val.fever_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.fever_explain}
                                                                                        name="fever_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                </>}

                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <div className='form-group'>
                                                                            <label> 6. Receive a TB medication?</label>
                                                                            <input type="tb_medication" name="fever" disabled value={val.tb_medication === 'yes' ? 'Yes' : val.tb_medication === 'no' ? 'No' : ''} className="form-control" readOnly />
                                                                            {/* value={val.tb_medication} */}
                                                                        </div>
                                                                    </div>
                                                                    {val.tb_medication == "yes" && <>
                                                                        <div className='col-6'>
                                                                            <div className='form-group'>
                                                                                <label>Please explain</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="tb_medication_explain" style={{ backgroundColor: "#e9ecef" }}>{val.tb_medication_explain}</div>
                                                                                {/* <TextareaAutosize
                                                                                    minRows={(val.tb_medication_explain?.length / 59) + val.tb_medication_explain?.split(/\r\n|\r|\n/).length}
                                                                                    disabled
                                                                                    value={val.tb_medication_explain}
                                                                                    name="tb_medication_explain"
                                                                                    className="form-control"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Notes or comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="notes" style={{ backgroundColor: "#e9ecef" }}>{val.notes}</div>
                                                                            {/* <TextareaAutosize 
                                                                                name="notes" 
                                                                                disabled 
                                                                                value={val.notes} 
                                                                                className="form-control"
                                                                                minRows={(val.notes?.length / 138) + val.notes?.split(/\r\n|\r|\n/).length}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginBottom1">
                                                                    <div className="col-md-12">
                                                                        <div className="green_band">
                                                                            <div className="row">
                                                                                <div className='col-lg-4 col-md-8 mb-2'>
                                                                                    <p className="lead sa_risk">
                                                                                        <b>Substance Use Risk Rating </b>
                                                                                        <span className="risk_rating"></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className='col-lg-4 col-md-8 mb-2'>
                                                                                    <div className="form-group">
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label0" name={"biomedical_risk_rating0" + val.form_type} value="0" checked={val.biomedical_risk_rating == "0" ? true : false} />&nbsp;
                                                                                        <label className="labelMargin">0</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label1" name={"biomedical_risk_rating1" + val.form_type} value="1" checked={val.biomedical_risk_rating == 1 ? true : false} />&nbsp;
                                                                                        <label className="labelMargin">1</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label2" name={"biomedical_risk_rating2" + val.form_type} value="2" checked={val.biomedical_risk_rating == 2 ? true : false} />{""}&nbsp;
                                                                                        <label className="labelMargin">2</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label3" name={"biomedical_risk_rating3" + val.form_type} value="3" checked={val.biomedical_risk_rating == 3 ? true : false} />&nbsp;
                                                                                        <label className="labelMargin">3</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label4" name={"biomedical_risk_rating4" + val.form_type} value="4" checked={val.biomedical_risk_rating == 4 ? true : false} />&nbsp;
                                                                                        <label className="labelMargin">4</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        Diagnostic summary for this dimension
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label>Analysis</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.biomedical_risk_rating_information}</div>
                                                                            {/* <TextareaAutosize 
                                                                                className="form-control" 
                                                                                minRows={(val.biomedical_risk_rating_information/ 135) + val.other_income_place?.split(/\r\n|\r|\n/).length}
                                                                                value={val.biomedical_risk_rating_information} 
                                                                                disabled 
                                                                                readOnly 
                                                                                style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label>Risk Rating Summary</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{val.biomedical_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control"
                                                                                minRows={(val.biomedical_comments?.length / 138) + val.biomedical_comments?.split(/\r\n|\r|\n/).length}
                                                                                disabled
                                                                                name="comments"
                                                                                value={val.biomedical_comments}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT BIOMEDICAL CONDITION END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT EMOTIONAL BEHAVIORAL START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-md-12">
                                                        <div className="card shadow-none">
                                                            <div>
                                                                <p
                                                                    className="lead "
                                                                    style={{ marginBottom: "1rem" }}
                                                                >
                                                                    <b className="text-green">
                                                                        Emotional, behavioral, cognitive conditions and
                                                                        complications{" "}
                                                                    </b>
                                                                </p>
                                                            </div>
                                                            <div>

                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Have you experienced significant events that have caused trauma? </label>
                                                                            <input type="text" disabled className="form-control" name="bvc_trauma" value={val.bvc_trauma === 'yes' ? 'Yes' : val.bvc_trauma === 'no' ? 'No' : ''} />
                                                                        </div>
                                                                    </div>

                                                                    {val.bvc_trauma == "yes" && (
                                                                        <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Please explain </label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="bvc_trauma_explain" style={{ backgroundColor: "#e9ecef" }}>{val.bvc_trauma_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        className="form-control"
                                                                                        disabled
                                                                                        minRows={(val.bvc_trauma_explain?.length / 62) + val.bvc_trauma_explain?.split(/\r\n|\r|\n/).length}
                                                                                        value={val.bvc_trauma_explain}
                                                                                        name="bvc_trauma_explain"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>  Have you been diagnosed with or told you have a mental illness? </label>
                                                                            <input type="text" disabled className="form-control" name="bvc_illness" value={val.bvc_illness === 'yes' ? 'Yes' : val.bvc_illness === 'no' ? 'NO' : ''} />
                                                                        </div>
                                                                    </div>
                                                                    {val.bvc_illness == "yes" && (
                                                                        <>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Please Clarify</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="mental_health_disorder_emotion" style={{ backgroundColor: "#e9ecef" }}>{val.mental_health_disorder_emotion}</div>
                                                                                    {/* <TextareaAutosize 
                                                                                        disabled 
                                                                                        value={val.mental_health_disorder_emotion} 
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }} 
                                                                                        className="form-control" 
                                                                                        name="mental_health_disorder_emotion" 
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>

                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label> Do you see anyone for mental health issues?{" "}</label>
                                                                            <input type="text" disabled className="form-control" name="bvc_issues" value={val.bvc_issues === 'yes' ? 'Yes' : val.bvc_issues === 'no' ? 'No' : ''} />
                                                                        </div>
                                                                    </div>
                                                                    {val.bvc_issues == "yes" && (
                                                                        <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Please explain{" "}</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="bvc_issues_explain" style={{ backgroundColor: "#e9ecef" }}>{val.bvc_issues_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.bvc_issues_explain?.length / 62) + val.bvc_issues_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.bvc_issues_explain}
                                                                                        className="form-control"
                                                                                        name="bvc_issues_explain"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Have you ever felt like killing or hurting yourself or someone else?</label>
                                                                            <input type="text" disabled className="form-control" name="bvc_felt" value={val.bvc_felt === 'yes' ? 'Yes' : val.bvc_felt === 'no' ? 'No' : ''} />
                                                                        </div>
                                                                    </div>
                                                                    {val.bvc_felt == "yes" && (
                                                                        <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="bvc_felt_explain" style={{ backgroundColor: "#e9ecef" }}>{val.bvc_felt_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.bvc_felt_explain?.length / 62) + val.bvc_felt_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.bvc_felt_explain}
                                                                                        className="form-control"
                                                                                        name="bvc_felt_explain"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label> Have you ever experienced hallucinations or difficulty telling what is real from that which is not (auditory, visual, olfactory, tactile)?</label>
                                                                            <input type="text" disabled className="form-control" name="emotional_bvc_hallucinations" value={val.emotional_bvc_hallucinations === 'yes' ? 'Yes' : val.emotional_bvc_hallucinations === 'no' ? 'No' : ''} />
                                                                        </div>
                                                                    </div>
                                                                    {val.emotional_bvc_hallucinations == "yes" && (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="emotional_bvc_hallucinations_explain" style={{ backgroundColor: "#e9ecef" }}>{val.emotional_bvc_hallucinations_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.emotional_bvc_hallucinations_explain?.length / 138) + val.emotional_bvc_hallucinations_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.emotional_bvc_hallucinations_explain}
                                                                                        className="form-control"
                                                                                        name="emotional_bvc_hallucinations_explain"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group" >
                                                                            <label> List any <strong> psychiatric medications</strong> you currently take, have taken, or should be taking, including over the counter medications</label>
                                                                            <table className="table table-bordered table-striped">
                                                                                <thead>
                                                                                    <th>Medication</th>
                                                                                    <th>Prescribed For</th>
                                                                                    <th>Dosage</th>
                                                                                    <th>Frequency</th>
                                                                                    <th>Taking as Prescribed?</th>
                                                                                    <th>If OTC, Taking as Directed?</th>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {val.emotionalMedications.map((medication, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_medication" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_medication}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_medication?.length / 14) + medication.emotional_medication?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_medication" value={medication.emotional_medication} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_prescribedFor" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_prescribedFor}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_prescribedFor?.length / 14) + medication.emotional_prescribedFor?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_prescribedFor" value={medication.emotional_prescribedFor} className="form-control" />  */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_dosage" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_dosage}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_dosage?.length / 14) + medication.emotional_dosage?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_dosage" value={medication.emotional_dosage} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_frequency" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_frequency}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_frequency?.length / 14) + medication.emotional_frequency?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_frequency" value={medication.emotional_frequency} className="form-control" /> */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_taking_as_prescribed" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_taking_as_prescribed === 'yes' ? 'Yes' : medication.emotional_taking_as_prescribed === 'no' ? 'No' : ''}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_taking_as_prescribed?.length / 14) + medication.emotional_taking_as_prescribed?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_taking_as_prescribed" value={medication.emotional_taking_as_prescribed === 'yes' ? 'Yes' : medication.emotional_taking_as_prescribed === 'no' ? 'No' : ''} className="form-control" />  */}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="form-control heightRemove " name="emotional_taking_as_directed" style={{ backgroundColor: "#e9ecef" }}>{medication.emotional_taking_as_directed === 'yes' ? 'Yes' : medication.emotional_taking_as_directed === 'no' ? 'No' : ''}</div>
                                                                                                {/* <TextareaAutosize minRows={(medication.emotional_taking_as_directed?.length / 14) + medication.emotional_taking_as_directed?.split(/\r\n|\r|\n/).length}
                                                                                                style={{ overflow: 'hidden' }} disabled name="emotional_taking_as_directed" value={medication.emotional_taking_as_directed === 'yes' ? 'Yes' : medication.emotional_taking_as_directed === 'no' ? 'No' : ''} className="form-control" />  */}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label style={{ fontSize: "1rem" }}> Comments :{" "} </label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="bvc_comments" style={{ backgroundColor: "#e9ecef" }}>{val.bvc_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                minRows={(val.bvc_comments?.length / 138) + val.bvc_comments?.split(/\r\n|\r|\n/).length}
                                                                                className="form-control"
                                                                                disabled
                                                                                value={val.bvc_comments}
                                                                                name="bvc_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <strong style={{ fontSize: "1rem", marginLeft: "2px", marginTop: "4%" }}> {" " + " "} Mental Status Examination: </strong>
                                                                    </div>
                                                                </div>

                                                                <hr />
                                                                <div className='row'>
                                                                    <div className="col-12 form-group"><strong>ORIENTATION</strong> </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >  </label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="orientation" style={{ backgroundColor: "#e9ecef" }}>{val.orientation.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                rows={2}
                                                                                value={val.orientation.map((val) => { return (val.label) }).join(", ")}
                                                                                name="orientation"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="orientation_comments" style={{ backgroundColor: "#e9ecef" }}>{val.orientation_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                minRows={(val.orientation_comments?.length / 62) + val.orientation_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="orientation_comments"
                                                                                value={val.orientation_comments}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className="col-12"><strong>GENERAL APPEARANCE</strong> </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label > Dress</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="dress" style={{ backgroundColor: "#e9ecef" }}>{val.dress.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize 
                                                                                className="form-control mt-2" 
                                                                                disabled 
                                                                                rows={2} 
                                                                                value={val.dress.map((val) => { return (val.label) }).join(", ")} 
                                                                                name="dress"
                                                                                style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label > Grooming</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="grooming" style={{ backgroundColor: "#e9ecef" }}>{val.grooming.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize 
                                                                                className="form-control mt-2" 
                                                                                disabled 
                                                                                rows={2} 
                                                                                value={val.grooming.map((val) => { return (val.label) }).join(", ")} 
                                                                                name="grooming"
                                                                                style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label > Facial Expression</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="facial" style={{ backgroundColor: "#e9ecef" }}>{val.facial_exp.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                rows={2}
                                                                                value={val.facial_exp.map((val) => { return (val.label) }).join(", ")}
                                                                                name="facial"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="general_comments" style={{ backgroundColor: "#e9ecef" }}>{val.general_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                minRows={(val.general_comments?.length / 62) + val.general_comments?.split(/\r\n|\r|\n/).length}
                                                                                value={val.general_comments}
                                                                                name="general_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12 form-group"><strong>MOOD/AFFECT</strong></div>
                                                                </div>
                                                                {/*  */}
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Mood</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="mood" style={{ backgroundColor: "#e9ecef" }}>{val.mood.map((item) => item.label).join(', ')}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.mood.map((item) => item.label).join(', ')} name="mood" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Affect</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="affect" style={{ backgroundColor: "#e9ecef" }}>{val.affect.map((item) => item.label).join(', ')}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.affect.map((item) => item.label).join(', ')} name="affect" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12 form-group">
                                                                        <div className="form-group">
                                                                            <label>Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="mood_comments" style={{ backgroundColor: "#e9ecef" }}>{val.mood_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                value={val.mood_comments}
                                                                                minRows={(val.mood_comments?.length / 138) + val.mood_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="mood_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div className="col-6 form-group">
                                                                        <strong>SPEECH</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Speech</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="speech" style={{ backgroundColor: "#e9ecef" }}>{val.speech.map((item) => item.label).join(', ')}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.speech.map((item) => item.label).join(', ')} name="speech" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="speech_comments" style={{ backgroundColor: "#e9ecef" }}>{val.speech_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                value={val.speech_comments}
                                                                                minRows={(val.speech_comments?.length / 62) + val.speech_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="speech_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div className="col-6 form-group">
                                                                        <strong>MEMORY</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Immediate</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="immediate" style={{ backgroundColor: "#e9ecef" }}>{val.immediate.map((val) => { return (val.label) })}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.immediate.map((val) => { return (val.label) })} name="immediate" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Recent</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="recent" style={{ backgroundColor: "#e9ecef" }}>{val.recent.map((val) => { return (val.label) })}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.recent.map((val) => { return (val.label) })} name="recent"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Remote</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="remote" style={{ backgroundColor: "#e9ecef" }}>{val.remote.map((val) => { return (val.label) })}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.remote.map((val) => { return (val.label) })} name="remote"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="memory_comments" style={{ backgroundColor: "#e9ecef" }}>{val.memory_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled 
                                                                                value={val.memory_comments}
                                                                                minRows={(val.memory_comments?.length / 62) + val.memory_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="memory_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}

                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div className="col-6 form-group">
                                                                        <strong>THOUGHT PROCESS</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Thought Process</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="process" style={{ backgroundColor: "#e9ecef" }}>{val.process.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                rows={2}
                                                                                value={val.process.map((val) => { return (val.label) }).join(", ")}
                                                                                name="process"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="process_comments" style={{ backgroundColor: "#e9ecef" }}>{val.process_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled 
                                                                                value={val.process_comments}
                                                                                minRows={(val.process_comments?.length / 62) + val.process_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="process_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div className="col-6 form-group">
                                                                        <strong>THOUGHT CONTENT</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Thought Content</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="content" style={{ backgroundColor: "#e9ecef" }}>{val.content.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" rows={2} disabled value={val.content.map((val) => { return (val.label) }).join(", ")} name="content"
                                                                                style={{ overflow: "hidden" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="thought_content_comments" style={{ backgroundColor: "#e9ecef" }}>{val.thought_content_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                minRows={(val.thought_content_comments?.length / 62) + val.thought_content_comments?.split(/\r\n|\r|\n/).length}
                                                                                value={val.thought_content_comments}
                                                                                name="thought_content_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div className="col-12 form-group">
                                                                        <strong>JUDGEMENT AND INSIGHT</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Judgement</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="judgement" style={{ backgroundColor: "#e9ecef" }}>{val.judgement.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.judgement.map((val) => { return (val.label) }).join(", ")} name="judgement" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label >Insight</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="insight" style={{ backgroundColor: "#e9ecef" }}>{val.insight.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize className="form-control mt-2" disabled rows={2} value={val.insight.map((val) => { return (val.label) }).join(", ")} name="insight" style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label >Comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="insight_comments" style={{ backgroundColor: "#e9ecef" }}>{val.insight_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled
                                                                                value={val.insight_comments}
                                                                                minRows={(val.insight_comments?.length / 138) + val.insight_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="insight_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6 form-group">
                                                                        <strong>Other Considerations</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 form-group">
                                                                        <div className="form-group">
                                                                            <label >Notes or comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="other_comments" style={{ backgroundColor: "#e9ecef" }}>{val.other_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control mt-2"
                                                                                disabled 
                                                                                value={val.other_comments}
                                                                                minRows={(val.other_comments?.length / 138) + val.other_comments?.split(/\r\n|\r|\n/).length}
                                                                                name="other_comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginBottom1">
                                                                    <div className="col-12">
                                                                        <div className="green_band">
                                                                            <div className="row">
                                                                                <div className="col-lg-4 col-md-8">
                                                                                    <p className="lead sa_risk">
                                                                                        <b>Substance Use Risk Rating </b>
                                                                                        <span className="risk_rating"></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-12 mb-2">
                                                                                    <div className="form-group">
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label0" name={"emotional_risk_rating0" + val.form_type} value="0" checked={val.emotional_risk_rating == "0" ? true : false} />
                                                                                        &nbsp;
                                                                                        <label className="labelMargin">0</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label1" name={"emotional_risk_rating1" + val.form_type} value="1" checked={val.emotional_risk_rating == 1 ? true : false} />
                                                                                        &nbsp;
                                                                                        <label className="labelMargin">1</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label2" name={"emotional_risk_rating2" + val.form_type} value="2" checked={val.emotional_risk_rating == 2 ? true : false} />{" "}
                                                                                        &nbsp;
                                                                                        <label className="labelMargin">2</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label3" name={"emotional_risk_rating3" + val.form_type} value="3" checked={val.emotional_risk_rating == 3 ? true : false} />
                                                                                        &nbsp;
                                                                                        <label className="labelMargin">3</label>
                                                                                        <input className="autoMargin" type="radio" disabled={true} data-tip data-for="label4" name={"emotional_risk_rating4" + val.form_type} value="4" checked={val.emotional_risk_rating == 4 ? true : false} />
                                                                                        &nbsp;
                                                                                        <label className="labelMargin">4</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        Diagnostic summary for this dimension
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Analysis</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.emotional_risk_rating_information}</div>
                                                                            {/* <TextareaAutosize className="form-control" row={2} value={val.emotional_risk_rating_information} readOnly style={{ backgroundColor: "#FEE5A1", overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Risk Rating Summary </label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{val.emotional_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                name="comments"
                                                                                minRows={(val.emotional_comments?.length / 138) + val.emotional_comments?.split(/\r\n|\r|\n/).length}
                                                                                disabled
                                                                                value={val.emotional_comments}
                                                                                className="form-control"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT EMOTIONAL BEHAVIORAL END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT READINESS FOR CHANGE START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">

                                            <div className='row' style={{ width: "100%" }}>
                                                <div>
                                                    <div className="card shadow-none">
                                                        <div className="">
                                                            <p
                                                                className="lead mx-auto"
                                                                style={{ marginBottom: "1rem" }}
                                                            >
                                                                <b className="text-green">Readiness for change</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12 ">
                                                            <div className="form-group">
                                                                <label>Why are you here today?</label>
                                                                <div className="form-control heightRemove PdfBackgroundColor" name="why_are_you_here" style={{ backgroundColor: "#e9ecef" }}>{val.why_are_you_here}</div>
                                                                {/* <TextareaAutosize 
                                                                   minRows={(val.why_are_you_here?.length / 146) + val.why_are_you_here?.split(/\r\n|\r|\n/).length} 
                                                                    disabled 
                                                                    name="why_are_you_here" 
                                                                    className="form-control" 
                                                                    value={val.why_are_you_here} 
                                                                    style={{ overflow: "hidden" ,textAlign : "justify"}} /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row marginBottom1">
                                                        <div className="col-12">
                                                            <strong>Readiness for Change Questionnaire</strong><br />
                                                            <span>For each sentence, please select the answer that best describes the <b>client’s perspective of themselves.</b> <a href="/readiness_for_change_questionnaire_-_drinking.pdf" target="_blank">You can see guidelines here.</a></span>
                                                            <div className="mt-2">
                                                                <div className="row marginBottom1">
                                                                    {val.questions.map((readiness, index) => {
                                                                        return (<>
                                                                            <div className={readiness.name == "something_about_it" ? "col-12" : "col-6"}>
                                                                                <div className="form-group">
                                                                                    <label>{readiness.question}</label>
                                                                                    <select className="form-control" disabled={true} value={readiness.answer} name={readiness.name}  >
                                                                                        <option value=""></option>
                                                                                        <option value="-2">Strongly disagree</option>
                                                                                        <option value="-1">Disagree</option>
                                                                                        <option value="0">Unsure</option>
                                                                                        <option value="1">Agree</option>
                                                                                        <option value="2">Strongly agree</option>
                                                                                    </select>

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-4">
                                                                        <div className="form-group">
                                                                            <label>Precontemplation score</label>
                                                                            <input type="text" className="form-control" disabled name="precontemplation_score" value={val.precontemplation_score} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-group">
                                                                            <label>Contemplation score</label>
                                                                            <input type="text" className="form-control" disabled name="contemplation_score" value={val.contemplation_score} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="form-group">
                                                                            <label>Action score</label>
                                                                            <input type="text" className="form-control" disabled name="action_score" value={val.action_score} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label>Stage of Change designation</label>
                                                                            <input type="text" className="form-control" disabled name="stage_of_change_designation" value={val.stage_of_change_designation} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 pb-2">
                                                                        <div className="form-group">
                                                                            <label>Do you need help with any other problems?</label>
                                                                            <input type="text" className="form-control" disabled name="help_with_any_other_problem" value={val.help_with_any_other_problem === 'yes' ? 'Yes' : val.help_with_any_other_problem === 'no' ? 'No' : ''} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {val.help_with_any_other_problem == "yes" && <>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>Please explain</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="help_with_any_other_problem_explain" style={{ backgroundColor: "#e9ecef" }}>{val.help_with_any_other_problem_explain}</div>
                                                                                {/* <TextareaAutosize
                                                                                    minRows={(val.help_with_any_other_problem_explain?.length / 138) + val.help_with_any_other_problem_explain?.split(/\r\n|\r|\n/).length}
                                                                                    disabled
                                                                                    name="help_with_any_other_problem_explain"
                                                                                    className="form-control"
                                                                                    value={val.help_with_any_other_problem_explain}
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>}

                                                                {/* New code  */}
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Notes or comments</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="readiness_notes_comment" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_notes_comment}</div>
                                                                            {/* <TextareaAutosize
                                                                                className="form-control"
                                                                                minRows={(val.readiness_notes_comment?.length / 138) + val.readiness_notes_comment?.split(/\r\n|\r|\n/).length}
                                                                                value={val.readiness_notes_comment}
                                                                                name="readiness_notes_comment"
                                                                                disabled={true}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }} 
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="green_band">
                                                                            <div className="row marginBottom1">
                                                                                <div className="col-lg-4 col-md-8">
                                                                                    <p className="lead sa_risk">
                                                                                        <b>Substance Use Risk Rating </b>
                                                                                        <span className="risk_rating"></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-12 mb-2">
                                                                                    <div className="form-group ml-0 pl-0">
                                                                                        <input className="autoMargin" data-tip data-for="label0" type="radio" disabled={true} name={"readiness_risk_rating0" + val.form_type} value="0" checked={val.readiness_risk_rating == "0" ? true : false} />&nbsp;
                                                                                        <label for="sa1" className="labelMargin">0</label>
                                                                                        <input className="autoMargin" data-tip data-for="label1" type="radio" disabled={true} name={"readiness_risk_rating1" + val.form_type} value="1" checked={val.readiness_risk_rating == 1 ? true : false} />&nbsp;
                                                                                        <label for="sa1" className="labelMargin">1</label>
                                                                                        <input className="autoMargin" data-tip data-for="label2" type="radio" disabled={true} name={"readiness_risk_rating2" + val.form_type} value="2" checked={val.readiness_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                        <label for="sa2" className="labelMargin">2</label>
                                                                                        <input className="autoMargin" data-tip data-for="label3" type="radio" disabled={true} name={"readiness_risk_rating3" + val.form_type} value="3" checked={val.readiness_risk_rating == 3 ? true : false} />&nbsp;
                                                                                        <label for="sa3" className="labelMargin">3</label>
                                                                                        <input className="autoMargin" data-tip data-for="label4" type="radio" disabled={true} name={"readiness_risk_rating4" + val.form_type} value="4" checked={val.readiness_risk_rating == 4 ? true : false} />&nbsp;
                                                                                        <label for="sa4" className="labelMargin">4</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* New code */}
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        Diagnostic summary for this dimension
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className='form-group'>
                                                                            <label>Analysis</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="analysis" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_risk_rating_information}</div>
                                                                            {/* <TextareaAutosize rows={2} value={val.readiness_risk_rating_information} className="form-control" disabled name="analysis" style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Risk Rating Summary</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="Comments" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                minRows={(val.readiness_comments?.length / 138) + val.readiness_comments?.split(/\r\n|\r|\n/).length}
                                                                                className="form-control"
                                                                                disabled
                                                                                value={val.readiness_comments}
                                                                                name="Comments"
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className="green_band">
                                                                            <div className="row marginBottom1">
                                                                                <div className="col-lg-4 col-md-8">
                                                                                    <p className="lead sa_risk">
                                                                                        <b>Mental Health Risk Rating </b>
                                                                                        <span className="risk_rating"></span>
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-12 mb-2">
                                                                                    <div className="form-group ml-0 pl-0">
                                                                                        <input className="autoMargin" data-tip data-for="label0" disabled={true} type="radio" name={"readiness_mental_risk_rating0" + val.form_type} value="0" checked={val.readiness_mental_risk_rating == "0" ? true : false} />&nbsp;
                                                                                        <label for="mh1" className="labelMargin">0</label>
                                                                                        <input className="autoMargin" data-tip data-for="label1" disabled={true} type="radio" name={"readiness_mental_risk_rating1" + val.form_type} value="1" checked={val.readiness_mental_risk_rating == 1 ? true : false} />&nbsp;
                                                                                        <label for="mh1" className="labelMargin">1</label>
                                                                                        <input className="autoMargin" data-tip data-for="label2" disabled={true} type="radio" name={"readiness_mental_risk_rating2" + val.form_type} value="2" checked={val.readiness_mental_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                        <label for="mh2" className="labelMargin">2</label>
                                                                                        <input className="autoMargin" data-tip data-for="label3" disabled={true} type="radio" name={"readiness_mental_risk_rating3" + val.form_type} value="3" checked={val.readiness_mental_risk_rating == 3 ? true : false} />&nbsp;
                                                                                        <label for="mh3" className="labelMargin" >3</label>
                                                                                        <input className="autoMargin" data-tip data-for="label4" disabled={true} type="radio" name={"readiness_mental_risk_rating4" + val.form_type} value="4" checked={val.readiness_mental_risk_rating == 4 ? true : false} />&nbsp;
                                                                                        <label for="mh4" className="labelMargin">4</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className='form-group mt-2'>
                                                                            <label>Analysis</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="readiness_mental_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_mental_risk_rating_information}</div>
                                                                            {/* <TextareaAutosize className="form-control" rows={3} value={val.readiness_mental_risk_rating_information} name="readiness_mental_risk_rating_information" readOnly style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="col-12">
                                                                        <div className='form-group'>
                                                                            <label>Risk Rating Summary</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="readiness_mental_comments" style={{ backgroundColor: "#e9ecef" }}>{val.readiness_mental_comments}</div>
                                                                            {/* <TextareaAutosize
                                                                                minRows={(val.readiness_mental_comments?.length / 138) + val.readiness_mental_comments?.split(/\r\n|\r|\n/).length}
                                                                                className="form-control"
                                                                                disabled={true}
                                                                                name="readiness_mental_comments"
                                                                                value={val.readiness_mental_comments}
                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                            /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT RADINESS FOR CHANGE END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT Problem Potential START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">

                                    <section className="content-body mx-2">

                                        <div className='row' style={{ width: "100%" }}>
                                            <div >
                                                <div className="card shadow-none">
                                                    <div>
                                                        <p className="lead " style={{ marginBottom: '1rem', }}>
                                                            <b className="text-green">Relapse/continued use/continued problem potential</b>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <label>Does anyone in your immediate family have or has had a…</label>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6" >
                                                                <div className="form-group">
                                                                    <label> Drug problem?</label>
                                                                    {/* <select className="form-control" disabled name="drug_problem" value={val.drug_problem} ></select> */}
                                                                    <input type="text" disabled name="drug_problem" value={val.drug_problem === 'yes' ? 'Yes' : val.drug_problem === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                            {val.drug_problem == "yes" && <>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>Who? </label>
                                                                        <input type="text" disabled name="drug_problem_who" value={val.drug_problem_who} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>Alcohol problem?</label>
                                                                    {/* <select className="form-control" disabled name="alchol_problem" value={val.alchol_problem} ></select> */}
                                                                    <input type="text" disabled name="alchol_problem" value={val.alchol_problem === 'yes' ? 'Yes' : val.alchol_problem === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                            {val.alchol_problem == "yes" && <>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>Who?</label>
                                                                        <input type="text" disabled name="alchol_problem_who" value={val.alchol_problem_who} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group ">
                                                                    <label>Mental/emotional illness?</label>
                                                                    {/* <select className="form-control" disabled name="mental_illness" value={val.mental_illness} ></select> */}
                                                                    <input type="text" disabled name="mental_illness" value={val.mental_illness === 'yes' ? 'Yes' : val.mental_illness === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                            {val.mental_illness == "yes" && <>
                                                                <div className="col-6">
                                                                    <div className="form-group ">
                                                                        <label>Who?</label>
                                                                        <input type="text" disabled name="mental_illness_who" value={val.mental_illness_who} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>Illness/injury/handicapped?</label>
                                                                    {/* <select className="form-control" disabled name="handicapped" value={val.handicapped} ></select> */}
                                                                    <input type="text" disabled name="handicapped" value={val.handicapped === 'yes' ? 'Yes' : val.handicapped === 'no' ? 'NO' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                            {val.handicapped == "yes" && <>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>Who?</label>
                                                                        <input type="text" disabled name="injury_handicapped_who" value={val.injury_handicapped_who} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>How many times have you been treated for an AOD problem?</label>
                                                                    <input type="text" disabled name="aod_problem" value={val.aod_problem} className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>Are you currently receiving medication-assisted opioid therapy?</label>
                                                                    <input type="text" name="recieving_opioid_replacement" disabled value={val.recieving_opioid_replacement === 'yes' ? 'Yes' : val.recieving_opioid_replacement === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* table start */}
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <th>Alcohol/Drug/BOTH</th>
                                                                <th>When</th>
                                                                <th>Where</th>
                                                                <th>Level of Care (including detox)</th>
                                                                <th>Type of Discharge</th>
                                                            </thead>
                                                            <tbody>
                                                                {val.problemBlock.map((medication, index) => (
                                                                    <tr key={index}>
                                                                        <td> <TextareaAutosize minRows={(medication.alchol?.length / 14) + medication.alchol?.split(/\r\n|\r|\n/).length}
                                                                            style={{ overflow: 'hidden' }} disabled name="alchol" value={medication.alchol} className="form-control" /></td>
                                                                        <td> <TextareaAutosize minRows={(medication.when?.length / 14) + medication.when?.split(/\r\n|\r|\n/).length}
                                                                            style={{ overflow: 'hidden' }} disabled name="when" value={medication.when} className="form-control" /> </td>
                                                                        <td> <TextareaAutosize minRows={(medication.where?.length / 14) + medication.where?.split(/\r\n|\r|\n/).length}
                                                                            style={{ overflow: 'hidden' }} disabled name="where" value={medication.where} className="form-control" /></td>
                                                                        <td> <TextareaAutosize minRows={(medication.level_of_care?.length / 14) + medication.level_of_care?.split(/\r\n|\r|\n/).length}
                                                                            style={{ overflow: 'hidden' }} disabled name="level_of_care" value={medication.level_of_care} className="form-control" /> </td>
                                                                        <td> <TextareaAutosize minRows={(medication.type_of_discharge?.length / 14) + medication.type_of_discharge?.split(/\r\n|\r|\n/).length}
                                                                            style={{ overflow: 'hidden' }} disabled name="type_of_discharge" value={medication.type_of_discharge} className="form-control" /> </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </table>
                                                        {/* table end */}
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Comments</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="problem_comments" style={{ backgroundColor: "#e9ecef" }}>{val.problem_comments}</div>
                                                                    {/* <TextareaAutosize
                                                                        name="problem_comments"
                                                                        disabled
                                                                        minRows={(val.problem_comments?.length / 138) + val.problem_comments?.split(/\r\n|\r|\n/).length}
                                                                        className="form-control"
                                                                        value={val.problem_comments}
                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label> Have you had any periods of abstinence from an AOD?</label>
                                                                    <input type="text" name="periods_of_abstinence" disabled={true} value={val.periods_of_abstinence === 'yes' ? "Yes" : val.periods_of_abstinence === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>
                                                            {val.periods_of_abstinence == "yes" &&
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>1. How was that abstinence/maintenance achieved? Please explain</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="abstinence" style={{ backgroundColor: "#e9ecef" }}>{val.abstinence}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.abstinence?.length / 62) + val.abstinence?.split(/\r\n|\r|\n/).length}
                                                                            disabled
                                                                            name="abstinence"
                                                                            className="form-control"
                                                                            value={val.abstinence}
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {val.periods_of_abstinence == "yes" && <>
                                                            <div className='row'>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>2. What would you consider your relapse triggers? Please explain</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="relapse_triggers" style={{ backgroundColor: "#e9ecef" }}>{val.relapse_triggers}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.relapse_triggers?.length / 62) + val.relapse_triggers?.split(/\r\n|\r|\n/).length}
                                                                            disabled
                                                                            name="relapse_triggers"
                                                                            value={val.relapse_triggers}
                                                                            className="form-control"
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label> 3. Are you aware of what caused you to relapse? Please explain</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="cause_relapse" style={{ backgroundColor: "#e9ecef" }}>{val.cause_relapse}</div>
                                                                        {/* <TextareaAutosize minRows={(val.cause_relapse?.length / 62) + val.cause_relapse?.split(/\r\n|\r|\n/).length} disabled name="cause_relapse" value={val.cause_relapse} className="form-control" style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>}
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>Are you participating in any support groups? (AA, NA, faith-based, other)</label>
                                                                    <input type="text" disabled name="participate_in_any_support_group" value={val.participate_in_any_support_group === 'yes' ? 'Yes' : val.participate_in_any_support_group === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>

                                                            {val.participate_in_any_support_group == "yes" && <>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label> Indicate name/type of support group</label>
                                                                        <input type="text" disabled name="support_group" value={val.support_group} className="form-control" />
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>Do you have a sponsor?</label>
                                                                    <input type="text" disabled name="sponser" value={val.sponser === 'yes' ? 'Yes' : val.sponser === 'no' ? 'No' : ''} className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <label>How often do you attend?</label>
                                                                    {/* <input type="text" disabled name="attend_range" value={val.attend_range} className="form-control" /> */}
                                                                    <select name="attend_range" disabled value={val.attend_range} className="form-control">
                                                                        <option value=""></option>
                                                                        <option value="1_3_times_per_month">1-3 times per month</option>
                                                                        <option value="1_2_times_per_week">1-2 times per week</option>
                                                                        <option value="3_6_times_per_week">3-6 times per week</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End */}
                                                        {/* New Field */}
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <label>Notes or comments</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="problemPotential_notes_comment" style={{ backgroundColor: "#e9ecef" }}>{val.problemPotential_notes_comment}</div>
                                                                    {/* <TextareaAutosize 
                                                                        className="form-control" 
                                                                        value={val.problemPotential_notes_comment} 
                                                                        name="problemPotential_notes_comment" 
                                                                        disabled={true}
                                                                        minRows={(val.problemPotential_notes_comment?.length / 138) + val.problemPotential_notes_comment?.split(/\r\n|\r|\n/).length}
                                                                        style={{ overflow: "hidden", textAlign: "justify" }} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* New Field */}
                                                        <div className="row marginBottom1">
                                                            <div className="col-12 mb-2">
                                                                <div className="green_band">
                                                                    <div className="row marginBottom1">
                                                                        <div className="col-lg-4 col-md-8">
                                                                            <p className="lead sa_risk">
                                                                                <b>Substance Use Risk Rating </b>
                                                                                <span className="risk_rating"></span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 mb-2">
                                                                            <div className="form-group ml-0 pl-0">
                                                                                <input className="autoMargin" data-tip data-for="label0" type="radio" disabled={true} name={"relapse_risk_rating0" + val.form_type} value="0" checked={val.relapse_risk_rating == "0" ? true : false} />&nbsp;
                                                                                <label for="sa1" className="labelMargin">0</label>
                                                                                <input className="autoMargin" data-tip data-for="label1" type="radio" disabled={true} name={"relapse_risk_rating1" + val.form_type} value="1" checked={val.relapse_risk_rating == 1 ? true : false} />&nbsp;
                                                                                <label for="sa1" className="labelMargin">1</label>
                                                                                <input className="autoMargin" data-tip data-for="label2" type="radio" disabled={true} name={"relapse_risk_rating2" + val.form_type} value="2" checked={val.relapse_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                <label for="sa2" className="labelMargin">2</label>
                                                                                <input className="autoMargin" data-tip data-for="label3" type="radio" disabled={true} name={"relapse_risk_rating3" + val.form_type} value="3" checked={val.relapse_risk_rating == 3 ? true : false} />&nbsp;
                                                                                <label for="sa3" className="labelMargin">3</label>
                                                                                <input className="autoMargin" data-tip data-for="label4" type="radio" disabled={true} name={"relapse_risk_rating4" + val.form_type} value="4" checked={val.relapse_risk_rating == 4 ? true : false} />&nbsp;
                                                                                <label for="sa4" className="labelMargin">4</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                Diagnostic summary for this dimension
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className='form-group'>
                                                                    <label>Analysis</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.relapse_risk_rating_information}</div>
                                                                    {/* <TextareaAutosize disabled className="form-control" value={val.relapse_risk_rating_information} readOnly style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }}
                                                                        minRows={2} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className='form-group'>
                                                                    <label>Risk Rating Summary</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="problem_comments2" style={{ backgroundColor: "#e9ecef" }}>{val.problem_comments2}</div>
                                                                    {/* <TextareaAutosize
                                                                        disabled
                                                                        className="form-control"
                                                                        value={val.problem_comments2}
                                                                        name="problem_comments2"
                                                                        minRows={(val.problem_comments2?.length / 138) + val.problem_comments2?.split(/\r\n|\r|\n/).length}
                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <div className="green_band">
                                                                    <div className="row marginBottom1">
                                                                        <div className="col-lg-4 col-md-8">
                                                                            <p className="lead sa_risk">
                                                                                <b>Mental Health Risk Rating </b>
                                                                                <span className="risk_rating"></span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-12 mb-2">
                                                                            <div className="form-group ml-0 pl-0">
                                                                                <input className="autoMargin" data-tip data-for="label0" disabled={true} type="radio" name={"relapse_mental_risk_rating0" + val.form_type} value="0" checked={val.relapse_mental_risk_rating == "0" ? true : false} />&nbsp;
                                                                                <label for="mh1" className="labelMargin">0</label>
                                                                                <input className="autoMargin" data-tip data-for="label1" disabled={true} type="radio" name={"relapse_mental_risk_rating1" + val.form_type} value="1" checked={val.relapse_mental_risk_rating == 1 ? true : false} />&nbsp;
                                                                                <label for="mh1" className="labelMargin">1</label>
                                                                                <input className="autoMargin" data-tip data-for="label2" disabled={true} type="radio" name={"relapse_mental_risk_rating2" + val.form_type} value="2" checked={val.relapse_mental_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                <label for="mh2" className="labelMargin">2</label>
                                                                                <input className="autoMargin" data-tip data-for="label3" disabled={true} type="radio" name={"relapse_mental_risk_rating3" + val.form_type} value="3" checked={val.relapse_mental_risk_rating == 3 ? true : false} />&nbsp;
                                                                                <label for="mh3" className="labelMargin" >3</label>
                                                                                <input className="autoMargin" data-tip data-for="label4" disabled={true} type="radio" name={"relapse_mental_risk_rating4" + val.form_type} value="4" checked={val.relapse_mental_risk_rating == 4 ? true : false} />&nbsp;
                                                                                <label for="mh4" className="labelMargin">4</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <div className='form-group mt-2'>
                                                                    <label>Analysis</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="relapse_mental_risk_rating_information" style={{ backgroundColor: "#e9ecef" }}>{val.relapse_mental_risk_rating_information}</div>
                                                                    {/* <TextareaAutosize className="form-control" value={val.relapse_mental_risk_rating_information} name="relapse_mental_risk_rating_information" readOnly style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-12">
                                                                <div className='form-group'>
                                                                    <label>Risk Rating Summary</label>
                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="relapse_mental_comments" style={{ backgroundColor: "#e9ecef" }}>{val.relapse_mental_comments}</div>
                                                                    {/* <TextareaAutosize
                                                                        className="form-control"
                                                                        disabled={true}
                                                                        name="relapse_mental_comments"
                                                                        value={val.relapse_mental_comments}
                                                                        minRows={(val.relapse_mental_comments?.length / 138) + val.relapse_mental_comments?.split(/\r\n|\r|\n/).length}
                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                    /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </section>

                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT Problem Potential End ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT RECOVERY/LIVING ENVIRONMENT START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">

                                    <section className="content-body mx-2">
                                        <div className="row">
                                            <div className='row' style={{ width: "100%" }}>
                                                <div className="col-md-12">
                                                    <div className="card shadow-none">
                                                        <div className="">
                                                            <p
                                                                className="lead mx-auto"
                                                                style={{ marginBottom: "1rem" }}
                                                            >
                                                                <b className="text-green">Recovery/living environment</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <b>LIVING ARRANGEMENT:</b>
                                                            <div className="mt-2">
                                                                <div className=" marginBottom1">

                                                                    <div className='row'>
                                                                        <div className={val.indicate_living_arrangement == "other" ? "col-6" : "col-12"}>
                                                                            <div className='form-group'>
                                                                                <label>Please indicate your living arrangement.</label>
                                                                                <select className="form-control" disabled={true} name="indicate_living_arrangement" value={val.indicate_living_arrangement} >
                                                                                    <option value=""></option>
                                                                                    <option value="alabama_housing_finance_authority">Alabama Housing Finance Authority</option>
                                                                                    <option value="center_residential_program">Center Operated/Contracted Residential Program</option>
                                                                                    <option value="center_subsidized_housing">Center Subsidized Housing</option>
                                                                                    <option value="homeless_shelter">Homeless/Shelter</option>
                                                                                    {val.form_type == "Adult_Assessment" ? <option value="Client_lives_alone">Client Lives Alone AND is 18 + (Independent)</option> : <option value="resides_with_family">Resides with Family</option>}
                                                                                    <option value="jail_correctional_facility">Jail/Correctional Facility</option>
                                                                                    <option value="group_home_or_residential_setting">Group Home or Residential Setting</option>
                                                                                    <option value="other">Other</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        {val.indicate_living_arrangement == "other" && <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="indicate_living_arrangement_other" style={{ backgroundColor: "#e9ecef" }}>{val.indicate_living_arrangement_other}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        className="form-control"
                                                                                        disabled
                                                                                        minRows={(val.indicate_living_arrangement_other?.length / 62) + val.indicate_living_arrangement_other?.split(/\r\n|\r|\n/).length}
                                                                                        value={val.indicate_living_arrangement_other}
                                                                                        name="indicate_living_arrangement_other"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>Comments</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="living_arrangement_comments" style={{ backgroundColor: "#e9ecef" }}>{val.living_arrangement_comments}</div>
                                                                                {/* <TextareaAutosize
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    minRows={(val.living_arrangement_comments?.length / 138) + val.living_arrangement_comments?.split(/\r\n|\r|\n/).length}
                                                                                    value={val.living_arrangement_comments}
                                                                                    name="living_arrangement_comments"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-3">
                                                                            <div className="form-group">
                                                                                <label> How long have you lived here?</label>
                                                                                <input type="text" name="number_of_years" disabled className="form-control" value={val.number_of_years} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <div className="form-group" style={{ marginTop: "8px" }}>
                                                                                <label> </label>
                                                                                <input type="text" name="number_of_months" disabled className="form-control" value={val.number_of_months} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Is your current living environment drug and alcohol free? </label>{" "}
                                                                                <input type="text" name="current_living_enviornment" disabled className="form-control" value={val.current_living_enviornment} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {val.form_type == "Adolescent_Assessment" &&
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-6">
                                                                                    <div className="form-group">
                                                                                        <label>Have you ever run away from home?</label>{" "}
                                                                                        <input type="text" name="run_from_home" disabled className="form-control" value={val.run_from_home} />
                                                                                    </div>
                                                                                </div>
                                                                                {val.run_from_home == "Yes" && <>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label>Please explain</label>{" "}
                                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="run_from_home_explain" style={{ backgroundColor: "#e9ecef" }}>{val.run_from_home_explain}</div>
                                                                                            {/* <TextareaAutosize
                                                                                                minRows={(val.run_from_home_explain?.length / 62) + val.run_from_home_explain?.split(/\r\n|\r|\n/).length}
                                                                                                disabled
                                                                                                value={val.run_from_home_explain}
                                                                                                className="form-control"
                                                                                                name="run_from_home_explain"
                                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                            /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    <div className='row'>
                                                                        <div className="col-12 form-group">
                                                                            <strong>CURRENT EMPLOYMENT STATUS:</strong>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>Please indicate your current employment status</label>{" "}
                                                                                <select className="form-control" disabled={true} name="employment_status" value={val.employment_status} >
                                                                                    <option value=""></option>
                                                                                    <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
                                                                                    <option value="disabled">Disabled</option>
                                                                                    <option value="full_time">Full-time</option>
                                                                                    <option value="homemaker">Homemaker</option>
                                                                                    <option value="part_time">Part-time</option>
                                                                                    <option value="retired">Retired</option>
                                                                                    <option value="student">Student</option>
                                                                                    <option value="supported_employment">Supported Employment</option>
                                                                                    <option value="unemployed_looking">Unemployed, looking</option>
                                                                                    <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {val.form_type == "Adolescent_Assessment" &&
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className={val.current_school_status == "Yes" ? "col-6" : "col-12"}>
                                                                                    <div className="form-group">
                                                                                        <label>Are you currently in school, enrolled in a GED program or a vocational program? </label>{ }
                                                                                        <input type="text" name="current_school_status" disabled className="form-control" value={val.current_school_status} />
                                                                                    </div>
                                                                                </div>
                                                                                {val.current_school_status == "Yes" && <>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label>if yes </label>{ }
                                                                                            <input type="text" name="educational_status" disabled className="form-control" value={val.educational_status} />
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {val.form_type == "Adolescent_Assessment" &&
                                                                        <>
                                                                            {val.educational_status == "other_explain" && <>
                                                                                <div className='row'>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label>if other </label>{ }
                                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="educational_status_other_explain" style={{ backgroundColor: "#e9ecef" }}>{val.educational_status_other_explain}</div>
                                                                                            {/* <TextareaAutosize style={{ overflow: "hidden" }} className="form-control" type="text" disabled value={val.educational_status_other_explain} name="educational_status_other_explain" /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>}
                                                                        </>
                                                                    }
                                                                    {val.form_type == "Adult_Assessment" &&
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-6">
                                                                                    <label>What is your educational status?</label>{" "}
                                                                                    <select className="form-control" disabled={true} name="educational_status" value={val.educational_status} >
                                                                                        <option value="none">None</option>
                                                                                        <option value="student">Student</option>
                                                                                        <option value="graduated_hs">Graduated HS</option>
                                                                                        <option value="ged">GED</option>
                                                                                        <option value="dropped_out">Dropped Out</option>
                                                                                        <option value="college_graduate">College Graduate</option>
                                                                                        <option value="some_education_after_hs">Some education after HS</option>
                                                                                        <option value="other">Other</option>
                                                                                    </select>
                                                                                </div>
                                                                                {val.educational_status == "other" && <>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group">
                                                                                            <label>Please explain</label>{" "}
                                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="educational_status_other_explain" style={{ backgroundColor: "#e9ecef" }}>{val.educational_status_other_explain}</div>
                                                                                            {/* <TextareaAutosize
                                                                                                minRows={(val.educational_status_other_explain?.length / 62) + val.educational_status_other_explain?.split(/\r\n|\r|\n/).length}
                                                                                                disabled className="form-control"
                                                                                                type="text"
                                                                                                value={val.educational_status_other_explain}
                                                                                                name="educational_status_other_explain"
                                                                                                style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                            /> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </>}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className='row mt-3'>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label> Number of years completed in school</label>
                                                                                <input className="form-control" type="text" disabled value={val.years_schools} name="years_schools" />
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Have you ever been arrested?</label>
                                                                                <input className="form-control" type="text" disabled value={val.adl_ever_arrested} name="adl_ever_arrested" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {val.adl_ever_arrested == "Yes" && <>
                                                                        <div className='row'>
                                                                            <div className="col-12">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="adl_ever_arrested_explain" style={{ backgroundColor: "#e9ecef" }}>{val.adl_ever_arrested_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.adl_ever_arrested_explain?.length / 138) + val.adl_ever_arrested_explain?.split(/\r\n|\r|\n/).length}
                                                                                        disabled
                                                                                        value={val.adl_ever_arrested_explain}
                                                                                        name="adl_ever_arrested_explain"
                                                                                        className="form-control"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                                    <div className='row'>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Have you been arrested in the last 30 days?</label>{" "}
                                                                                <input type="text" name="arrested_in_30_days" disabled className="form-control" value={val.arrested_in_30_days} />
                                                                            </div>
                                                                        </div>
                                                                        {val.arrested_in_30_days == "Yes" && <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>How many times?</label>
                                                                                    <input type="text" name="how_many_times" disabled className="form-control" value={val.how_many_times} />
                                                                                </div>
                                                                            </div>
                                                                        </>}
                                                                    </div>

                                                                    {/* For Adolescent */}
                                                                    {/* Have you had any alcohol or drug related arrests if yes please explain*/}
                                                                    {val.form_type == "Adolescent_Assessment" &&
                                                                        <>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Have you had any alcohol or drug related arrests?</label>
                                                                                        <select className="form-control" disabled={true} name="any_arrest" value={val.any_arrest} >
                                                                                            <option value=""></option>
                                                                                            <option value="Yes">Yes</option>
                                                                                            <option value="No">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className='row'>
                                                                        <div className={val.involved_with_court == "Yes" ? 'col-6' : 'col-12'}>
                                                                            <div className="form-group">
                                                                                <label>Are you currently involved with the court or a court officer?</label>{" "}
                                                                                <input type="text" name="involved_with_court" disabled className="form-control" value={val.involved_with_court} />
                                                                            </div>
                                                                        </div>

                                                                        {val.involved_with_court == "Yes" && <>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Please explain</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="court_officer" style={{ backgroundColor: "#e9ecef" }}>{val.court_officer}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        className="form-control"
                                                                                        disabled={true}
                                                                                        minRows={(val.court_officer?.length / 62) + val.court_officer?.split(/\r\n|\r|\n/).length}
                                                                                        value={val.court_officer}
                                                                                        name="court_officer"
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>

                                                                        </>}
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Are you a registered sex offender?</label>{" "}
                                                                                <select className="form-control" disabled={true} name="registered_sex_offender" value={val.registered_sex_offender}>
                                                                                    <option value=""></option>
                                                                                    <option value="Yes">Yes</option>
                                                                                    <option value="No">No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Do you have reliable transportation? </label>{" "}
                                                                                <select className="form-control" disabled={true} name="reliable_transportation" value={val.reliable_transportation} >
                                                                                    <option value=""></option>
                                                                                    <option value="Yes">Yes</option>
                                                                                    <option value="No">No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-6">
                                                                            {val.reliable_transportation != "" ?
                                                                                <div className="form-group">
                                                                                    <label>Please explain </label>{" "}
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="reliable_transportation_explain" style={{ backgroundColor: "#e9ecef" }}>{val.reliable_transportation_explain}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.reliable_transportation_explain?.length / 42) + val.reliable_transportation_explain?.split(/\r\n|\r|\n/).length}
                                                                                        value={val.reliable_transportation_explain}
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        disabled
                                                                                        placeholder={'Please explain'}
                                                                                        className={'form-control'}
                                                                                        name="reliable_transportation_explain"
                                                                                    /> */}
                                                                                </div>
                                                                                : ""
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>How satisfied are you with your support system? </label>{" "}
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="satisfied_with_support_system" style={{ backgroundColor: "#e9ecef" }}>{val.satisfied_with_support_system}</div>
                                                                                {/* <TextareaAutosize
                                                                                    minRows={(val.satisfied_with_support_system?.length / 138) + val.satisfied_with_support_system?.split(/\r\n|\r|\n/).length}
                                                                                    value={val.satisfied_with_support_system}
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    disabled
                                                                                    placeholder={'Please explain'}
                                                                                    className={'form-control'}
                                                                                    name="satisfied_with_support_system"
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-6">
                                                                            <div className="form-group">
                                                                                <label>Do you have dependent children?</label>{" "}
                                                                                <select className="form-control" disabled={true} name="dependent_children" value={val.dependent_children}>
                                                                                    <option value=""></option>
                                                                                    <option value="Yes">Yes</option>
                                                                                    <option value="No">No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        {val.dependent_children == "Yes" &&
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>How many?</label>
                                                                                    <input type="text" disabled name="dependent_children_number" value={val.dependent_children_number} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {val.dependent_children == "Yes" && <>
                                                                        <div className='row'>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Who has custody of these children? (if applicable)</label>{" "}
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="custody" style={{ backgroundColor: "#e9ecef" }}>{val.custody}</div>
                                                                                    {/* <TextareaAutosize 
                                                                                        className="form-control" 
                                                                                        disabled={true} 
                                                                                        type="text" 
                                                                                        name="custody" 
                                                                                        style={{overflow:"hidden",textAlign:"justify"}}
                                                                                        minRows={(val.custody?.length / 61) + val.custody?.split(/\r\n|\r|\n/).length}
                                                                                        value={val.custody} 
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="form-group">
                                                                                    <label>Is childcare available for these children? (if applicable) </label>{" "}
                                                                                    <select className="form-control" disabled={true} name="child_care_available" value={val.child_care_available} >
                                                                                        <option value=""></option>
                                                                                        <option value="Yes">Yes</option>
                                                                                        <option value="No">No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>}
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>What is the quality of interaction with your family?</label>{" "}
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="interaction" style={{ backgroundColor: "#e9ecef" }}>{val.interaction}</div>
                                                                                {/* <TextareaAutosize
                                                                                    className="form-control"
                                                                                    disabled={true}
                                                                                    minRows={(val.interaction?.length / 138) + val.interaction?.split(/\r\n|\r|\n/).length}
                                                                                    value={val.interaction}
                                                                                    name="interaction"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="form-group">
                                                                                <label>Notes or comments</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="recovery_notes_comment" style={{ backgroundColor: "#e9ecef" }}>{val.recovery_notes_comment}</div>
                                                                                {/* <TextareaAutosize className="form-control" minRows={(val.recovery_notes_comment?.length / 138) + val.recovery_notes_comment?.split(/\r\n|\r|\n/).length} value={val.recovery_notes_comment} name="recovery_notes_comment" disabled={true}
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className="green_band">
                                                                                <div className="row marginBottom1">
                                                                                    <div className="col-6">
                                                                                        <p className="lead sa_risk">
                                                                                            <b>Substance Use Risk Rating </b>
                                                                                            <span className="risk_rating"></span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group ml-0 pl-0">
                                                                                            <input className="autoMargin" data-tip data-for="label0" disabled type="radio" name={"recovery_risk_rating0" + val.form_type} value="0" checked={val.recovery_risk_rating == "0" ? true : false} />&nbsp;
                                                                                            <label for="sa1" className="labelMargin">0</label>
                                                                                            <input className="autoMargin" data-tip data-for="label1" disabled type="radio" name={"recovery_risk_rating1" + val.form_type} value="1" checked={val.recovery_risk_rating == 1 ? true : false} />&nbsp;
                                                                                            <label for="sa1" className="labelMargin">1</label>
                                                                                            <input className="autoMargin" data-tip data-for="label2" disabled type="radio" name={"recovery_risk_rating2" + val.form_type} value="2" checked={val.recovery_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                            <label for="sa2" className="labelMargin">2</label>
                                                                                            <input className="autoMargin" data-tip data-for="label3" disabled type="radio" name={"recovery_risk_rating3" + val.form_type} value="3" checked={val.recovery_risk_rating == 3 ? true : false} />&nbsp;
                                                                                            <label for="sa3" className="labelMargin">3</label>
                                                                                            <input className="autoMargin" data-tip data-for="label4" disabled type="radio" name={"recovery_risk_rating4" + val.form_type} value="4" checked={val.recovery_risk_rating == 4 ? true : false} />&nbsp;
                                                                                            <label for="sa4" className="labelMargin">4</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            Diagnostic summary for this dimension
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className='form-group'>
                                                                                <label>Analysis</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.recovery_risk_rating_information}</div>
                                                                                {/* <TextareaAutosize className="form-control" value={val.recovery_risk_rating_information} readOnly style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className='form-group'>
                                                                                <label>Risk Rating Summary</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="comment" style={{ backgroundColor: "#e9ecef" }}>{val.enviornment_comments}</div>
                                                                                {/* <TextareaAutosize
                                                                                    className="form-control"
                                                                                    minRows={(val.enviornment_comments?.length / 138) + val.enviornment_comments?.split(/\r\n|\r|\n/).length}
                                                                                    disabled
                                                                                    value={val.enviornment_comments}
                                                                                    name="comment"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12" style={{ marginTop: "10px" }} >
                                                                            <div className="green_band">
                                                                                <div className="row marginBottom1">
                                                                                    <div className="col-6">
                                                                                        <p className="lead sa_risk">
                                                                                            <b>Mental Health Risk Rating </b>
                                                                                            <span className="risk_rating"></span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="col-6">
                                                                                        <div className="form-group ml-0 pl-0">
                                                                                            <input className="autoMargin" data-tip data-for="label5" disabled type="radio" name={"recovery_mental_risk_rating0" + val.form_type} value="0" checked={val.recovery_mental_risk_rating == "0" ? true : false} />&nbsp;
                                                                                            <label for="mh1" className="labelMargin">0</label>
                                                                                            <input className="autoMargin" data-tip data-for="label6" disabled type="radio" name={"recovery_mental_risk_rating1" + val.form_type} value="1" checked={val.recovery_mental_risk_rating == 1 ? true : false} />&nbsp;
                                                                                            <label for="mh1" className="labelMargin">1</label>
                                                                                            <input className="autoMargin" data-tip data-for="label7" disabled type="radio" name={"recovery_mental_risk_rating2" + val.form_type} value="2" checked={val.recovery_mental_risk_rating == 2 ? true : false} />{" "}&nbsp;
                                                                                            <label for="mh2" className="labelMargin">2</label>
                                                                                            <input className="autoMargin" data-tip data-for="label8" disabled type="radio" name={"recovery_mental_risk_rating3" + val.form_type} value="3" checked={val.recovery_mental_risk_rating == 3 ? true : false} />&nbsp;
                                                                                            <label for="mh3" className="labelMargin" >3</label>
                                                                                            <input className="autoMargin" data-tip data-for="label9" disabled type="radio" name={"recovery_mental_risk_rating4" + val.form_type} value="4" checked={val.recovery_mental_risk_rating == 4 ? true : false} />&nbsp;
                                                                                            <label for="mh4" className="labelMargin">4</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className='form-group'>
                                                                                <label>Analysis</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" style={{ backgroundColor: "#e9ecef" }}>{val.recovery_mental_risk_rating_information}</div>
                                                                                {/* <TextareaAutosize className="form-control" value={val.recovery_mental_risk_rating_information} readOnly style={{ backgroundColor: '#FEE5A1', overflow: "hidden" }} /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-12">
                                                                            <div className='form-group'>
                                                                                <label>Risk Rating Summary</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="recovery_mental_comments" style={{ backgroundColor: "#e9ecef" }}>{val.recovery_mental_comments}</div>
                                                                                {/* <TextareaAutosize
                                                                                    className="form-control"
                                                                                    disabled
                                                                                    minRows={(val.recovery_mental_comments?.length / 138) + val.recovery_mental_comments?.split(/\r\n|\r|\n/).length}
                                                                                    value={val.recovery_mental_comments}
                                                                                    name="recovery_mental_comments"
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </section>

                                </div>
                            </div>
                        )
                    })
                }

                {/* , */}
                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT RECOVERY/LIVING ENVIRONMENT END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT SUBSTANCE USE DISORDER START ==================================== */}


                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-md-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem" }} > <b className="text-green">Substance Use Disorders</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12" >
                                                        <div className=" marginBottom1" >
                                                            {
                                                                val.substanceUseDisorder.map((row, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className="card">
                                                                                <div className="card-body">

                                                                                    <div className='row'>
                                                                                        <div className="col-12" >
                                                                                            <div className="form-group">
                                                                                                <label>Disorder</label>
                                                                                                {/* <input type="text" disabled className="form-control" name="disorder" id={index} value={row.disorder} /> */}
                                                                                                <select className="form-control" name="disorder" value={row.disorder} disabled={true} >
                                                                                                    <option value=""></option>
                                                                                                    {this.state.disorderOptions.map((val) => {
                                                                                                        return <option value={val.value}>{val.label}</option>
                                                                                                    })}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='row'>
                                                                                        <div className="col-6">
                                                                                            <div className="form-group">
                                                                                                <label>ICD 10 Code</label>
                                                                                                <input type="text" disabled className="form-control" name="icd_10_code" id={index} value={row.icd_10_code} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <>
                                                                                        <div className='row'>
                                                                                            <div className="col-12">
                                                                                                <div className="form-group">
                                                                                                    <label>Specific Substance</label>
                                                                                                    <select className="form-control" disabled={true} name="specific_substance" value={row.specific_substance} >
                                                                                                        <option value=""> </option>
                                                                                                        {this.state.specific_substance_options.map((val) => {
                                                                                                            return (<option value={val.value}>{val.label}</option>)
                                                                                                        })}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='row'>
                                                                                            <div className="col-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Route of Administration</label>
                                                                                                    <select name="route_of_administration" className="form-control" value={row.route_of_administration} disabled={true} >
                                                                                                        <option value=""></option>
                                                                                                        <option value="oral">Oral</option>
                                                                                                        <option value="smoking">Smoking</option>
                                                                                                        <option value="inhalation">Inhalation</option>
                                                                                                        <option value="injection">Injection/IV</option>
                                                                                                        <option value="injection_intramuscular"> Injection/Intramuscular </option>
                                                                                                        <option value="other_specify">Other (Specify)</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>


                                                                                            {row.route_of_administration == "other_specify" && <>
                                                                                                <div className='col-6'>
                                                                                                    <div className='form-group'>
                                                                                                        <label>Please Specify</label>
                                                                                                        <input type='text' disabled={true} name="other_route_of_administration" value={row.other_route_of_administration} className='form-control' />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                            }
                                                                                        </div>


                                                                                        <div className='row'>
                                                                                            <div className="col-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Age of 1st Use</label>
                                                                                                    <input type="text" disabled name="age_of_1st_use" className="form-control" id={index} value={row.age_of_1st_use} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="form-group">
                                                                                                    <label>Frequency</label>
                                                                                                    {/* <input type="text" disabled name="frequency_of_use" className="form-control" id={index} value={row.frequency_of_use} /> */}
                                                                                                    <select className='form-control' disabled={true} name="frequency_of_use" id={index} value={row.frequency_of_use} >
                                                                                                        <option value=""></option>
                                                                                                        <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                                                                                        <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                                                                                        <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                                                                                        <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                                                                                        <option value="daily">Daily</option>
                                                                                                        <option value="other">Other</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    <div className='row'>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group">
                                                                                                <label>Specifier</label>
                                                                                                {/* <input type="text" disabled name="specifier" className="form-control" id={index} value={row.specifier} /> */}
                                                                                                <select name="specifier" className="form-control" id={index} value={row.specifier} disabled={true} >
                                                                                                    <option value=""></option>
                                                                                                    {this.specifierOption.map((specifier) => (
                                                                                                        <option value={specifier.value}>{specifier.label}</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                {/* specifierOption */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT SUBSTANCE USE DISORDER END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT Mental Health Disorders START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem", }} > <b className="text-green">Mental Health Disorders</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12" >
                                                        {
                                                            val.mental_health_orders.map((row, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className="  marginBottom1">
                                                                                    <div className='row'>
                                                                                        <div className="col-12" >
                                                                                            <div className="form-group">
                                                                                                <label>Mental Health Disorder</label>
                                                                                                <input type="text" disabled value={row.mental_health_disorder} className="form-control" name="mental_health_disorder" id={index} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className="col-12" >
                                                                                            <div className="form-group">
                                                                                                <label>ICD 10 Code</label>
                                                                                                <input type="text" disabled value={row.icd_10_code} className="form-control" name="icd_10_code" id={index} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className="col-12">
                                                                                            <div className="form-group">
                                                                                                <label>Comments</label>
                                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{row.comments}</div>
                                                                                                {/* <TextareaAutosize
                                                                                                    type="text"
                                                                                                    disabled
                                                                                                    value={row.comments}
                                                                                                    className="form-control"
                                                                                                    minRows={(row.comments?.length / 125) + row.comments?.split(/\r\n|\r|\n/).length}
                                                                                                    name="comments"
                                                                                                    id={index}
                                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                                /> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT Mental Health Disorders END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT Other (Includes Medical) START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem" }} > <b className="text-green">Other (Includes Medical)</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12" >
                                                        <div className='card'>
                                                            <div className="card-body">
                                                                {val.otherDisorder.map((row, i) => (
                                                                    <>
                                                                        <tr className='paddingtop'>
                                                                            <th key={i} className='paddingtop' >Other Health Disorder (includes Medical)</th>
                                                                        </tr>
                                                                        <div key={i} style={{ marginTop: "10px" }}>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="disorder_name" style={{ backgroundColor: "#e9ecef" }}>{row.disorder_name}</div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                {/* <table className="table table-bordered table-stripped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Other Health Disorder (includes Medical)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(() => {
                                                                            const rows = [];
                                                                            const otherDisorder = val.otherDisorder;
                                                                            for (let i = 0; i < otherDisorder.length; i++) {
                                                                                const row = otherDisorder[i];
                                                                                rows.push(
                                                                                    <tr key={i}>
                                                                                        <td>
                                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="disorder_name" style={{ backgroundColor: "#e9ecef" }}>{row.disorder_name}</div>
                                                                                       
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                            return rows;
                                                                        })()}
                                                                    </tbody>
                                                                </table> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT Other (Includes Medical) End ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT Psychological and Environmental Problems START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm">
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem" }} > <b className="text-green">Psychological and Environmental Problems</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12" >
                                                        {val.psychological_problems.map((row, index) => {
                                                            return (
                                                                <>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Category</label>
                                                                                        {/* <input type="text" className="form-control" name="category" id={index} value={row.category} disabled={true} /> */}
                                                                                        <select className="form-control" name="category" value={row.category} disabled={true} >
                                                                                            <option value=""></option>
                                                                                            {this.state.categoryOptions.map((val) => {
                                                                                                return (<option value={val.value}>{val.label}</option>)
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-md-12">
                                                                                    <div className="form-group">
                                                                                        <label>Disorder</label>
                                                                                        {/* <input type="text" className="form-control" name="disorder_name" id={index} value={row.disorder_name} disabled={true} /> */}
                                                                                        <select className="form-control" name="disorder_name" value={row.disorder_name} disabled={true} >
                                                                                            <option value=""></option>
                                                                                            {row.disorderOptions.map((val) => {
                                                                                                return <option value={val.value}>{val.label}</option>
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>ICD-10 Code</label>
                                                                                        <input type="text" className="form-control" name="icd_10_code" id={index} value={row.icd_10_code} disabled={true} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label>Comments</label>
                                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="comments" style={{ backgroundColor: "#e9ecef" }}>{row.comments}</div>
                                                                                        {/* <TextareaAutosize
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="comments"
                                                                                            id={index}
                                                                                            minRows={(row.comments?.length / 125) + row.comments?.split(/\r\n|\r|\n/).length}
                                                                                            value={row.comments}
                                                                                            disabled={true}
                                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                        /> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT Psychological and Environmental Problems END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT  ASSESSED LEVEL OF CARE START ==================================== */}
                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm" >
                                <div className="wrapper">
                                    <div className="">
                                        <section className="content-body mx-2">
                                            <div className="row" >
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="col-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-auto" style={{ marginBottom: "1rem", }} > <b className="text-green">Assessed Level of Care</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row' style={{ width: "100%" }}>
                                                    <div className="card " style={{ width: "100%" }}>
                                                        <div className="card-body">
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label>Assessed Level of Care </label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="assesssed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                                                                        {/* <TextareaAutosize rows={3} name="assesssed_level_of_care" value={val.assesssed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control'
                                                                            style={{ overflow: "hidden" }} /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label>Placed Level of Care</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="placed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.placed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                                                                        {/* <TextareaAutosize rows={3} name="placed_level_of_care" value={val.placed_level_of_care.map((val) => { return (val.label) }).join(", ")} disabled={true} className='form-control'
                                                                            style={{ overflow: "hidden" }} /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Reason for difference</label>
                                                                        <select name="reason_for_difference" className='form-control' disabled={true} value={val.reason_for_difference} >
                                                                            <option value=""></option>
                                                                            {this.reasonDifferenceOptions.length > 0 && this.reasonDifferenceOptions.map(option => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Comment</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="assessed_comments" style={{ backgroundColor: "#e9ecef" }}>{val.assessed_comments}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.assessed_comments?.length / 59) + val.assessed_comments?.split(/\r\n|\r|\n/).length}
                                                                            name="assessed_comments"
                                                                            disabled={true}
                                                                            value={val.assessed_comments}
                                                                            className='form-control'
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label>Interim level of care</label>
                                                                        <input type="text" className="form-control" disabled={true} value={val.interim_level_of_care === 'yes' ? 'Yes' : val.interim_level_of_care === 'no' ? 'NO' : ''} name="interim_level_of_care" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {val.interim_level_of_care == "yes" &&
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                {/* <label>Disposition</label> */}
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="interim_assessed_level_of_care" style={{ backgroundColor: "#e9ecef" }}>{val.interim_assessed_level_of_care.length > 0 && val.interim_assessed_level_of_care.map((val) => { return (val.label) }).join(", ")}</div>
                                                                                {/* <TextareaAutosize rows={2} disabled={true} name="interim_assessed_level_of_care" value={val.interim_assessed_level_of_care.length > 0 && val.interim_assessed_level_of_care.map((val) => { return (val.label) }).join(", ")} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </>
                                                            }
                                                            { }
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Disposition</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="disposition" style={{ backgroundColor: "#e9ecef" }}>{val.disposition.map((val) => { return (val.label) }).join(", ")}</div>
                                                                        {/* <TextareaAutosize rows={2} disabled={true} name="disposition" value={val.disposition.map((val) => { return (val.label) }).join(", ")} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                    </div>
                                                                </div>

                                                                {val.disposition?.filter((val) => val.value === "Referred_to").length > 0 ?
                                                                    (
                                                                        <>

                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Referred to</label>
                                                                                    <Select
                                                                                        isMulti
                                                                                        isOptionDisabled={true}
                                                                                        isDisabled
                                                                                        name='Referred_to'
                                                                                        value={val.Referred_to}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            {val.disposition?.filter((val) => val.value === "Admitted_to").length > 0 ?
                                                                (
                                                                    <>
                                                                        <div className='row'>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Admitted to</label>
                                                                                    <div className="form-control heightRemove PdfBackgroundColor" name="Admitted_to" style={{ backgroundColor: "#e9ecef" }}>{val.Admitted_to}</div>
                                                                                    {/* <TextareaAutosize
                                                                                        minRows={(val.Admitted_to?.length / 59) + val.Admitted_to?.split(/\r\n|\r|\n/).length}
                                                                                        name="Admitted_to"
                                                                                        disabled={true}
                                                                                        value={val.Admitted_to}
                                                                                        className='form-control'
                                                                                        style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                    /> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                <div className='form-group'>
                                                                                    <label>Client Start Date</label>
                                                                                    <input type="text" className="form-control" disabled={true} value={val.client_start_date} name="client_start_date" />
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </>
                                                                ) : null
                                                            }
                                                            {val.disposition?.filter((val) => val.value == "Referred_for_case_management_services_to_assist").length > 0 &&
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='form-group'>
                                                                            <label>Referred for case management services to assist</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="refered_for_case" style={{ backgroundColor: "#e9ecef" }}>{val.refered_for_case.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize rows={2} disabled={true} name="refered_for_case" value={val.refered_for_case.map((val) => { return (val.label) }).join(", ")} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            <div className='row'>
                                                                {val.disposition?.filter((val) => val.value == "Assessed_level_not_available_referred_to").length > 0 &&
                                                                    <div className='col-6'>
                                                                        <div className='form-group'>
                                                                            <label>Assessed level not available, referred to</label>
                                                                            <div className="form-control heightRemove PdfBackgroundColor" name="assesed_level" style={{ backgroundColor: "#e9ecef" }}>{val.assesed_level.map((val) => { return (val.label) }).join(", ")}</div>
                                                                            {/* <TextareaAutosize rows={2} disabled={true} name="assesed_level" value={val.assesed_level.map((val) => { return (val.label) }).join(", ")} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {val.disposition?.filter((val) => val.value == "No_service_available_referred_to").length > 0 &&
                                                                    <div className='col-6'>
                                                                        <div className='form-group'>
                                                                            <label>No service available, referred to</label>
                                                                            <Select
                                                                                isMulti
                                                                                name='refered_for_case'
                                                                                isDisabled
                                                                                value={val.no_service_availaible1}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Second referral</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="no_service_availaible2" style={{ backgroundColor: "#e9ecef" }}>{val.no_service_availaible2}</div>
                                                                        {/* <TextareaAutosize minRows={(val.no_service_availaible2?.length / 59) + val.no_service_availaible2?.split(/\r\n|\r|\n/).length} name="no_service_availaible2" disabled={true} value={val.no_service_availaible2} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                    </div>
                                                                </div>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Co-occurring disorder screen administered? </label>
                                                                        <select name="co_occuring_disorder_screen" disabled={true} value={val.co_occuring_disorder_screen} className='form-control' >
                                                                            <option></option>
                                                                            {this.co_occuring_disorder_option.map(option => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Note</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="co_occuring_disorder_screen_explain" style={{ backgroundColor: "#e9ecef" }}>{val.co_occuring_disorder_screen_explain}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.co_occuring_disorder_screen_explain?.length / 59) + val.co_occuring_disorder_screen_explain?.split(/\r\n|\r|\n/).length}
                                                                            disabled={true}
                                                                            name="co_occuring_disorder_screen_explain"
                                                                            value={val.co_occuring_disorder_screen_explain}
                                                                            className='form-control'
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>

                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Co-occurring disorder assessment administered?</label>
                                                                        <select name="co_occuring_disorder_assesment" disabled={true} value={val.co_occuring_disorder_assesment} className='form-control' >
                                                                            <option></option>
                                                                            {this.co_occuring_disorder_option.map(option => (
                                                                                <option value={option.value}>{option.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Note</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="co_occuring_disorder_assesment_explain" style={{ backgroundColor: "#e9ecef" }}>{val.co_occuring_disorder_assesment_explain}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.co_occuring_disorder_assesment_explain?.length / 59) + val.co_occuring_disorder_assesment_explain?.split(/\r\n|\r|\n/).length}
                                                                            disabled={true}
                                                                            value={val.co_occuring_disorder_assesment_explain}
                                                                            name="co_occuring_disorder_assesment_explain"
                                                                            className='form-control'
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>

                                                                <div className='col-6'>
                                                                    <div className='form-group'>
                                                                        <label>Is client seeking treatment due to Co-dependent/Collateral?</label>
                                                                        <select name="client_seeking_treatment" disabled={true} value={val.client_seeking_treatment} className='form-control' >
                                                                            <option value=""></option>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {val.client_seeking_treatment == "yes" &&
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <div className='form-group'>
                                                                                <label>Please explain</label>
                                                                                <div className="form-control heightRemove PdfBackgroundColor" name="client_seeking_explain" style={{ backgroundColor: "#e9ecef" }}>{val.client_seeking_explain}</div>
                                                                                {/* <TextareaAutosize
                                                                                    minRows={(val.client_seeking_explain?.length / 138) + val.client_seeking_explain?.split(/\r\n|\r|\n/).length}
                                                                                    disabled={true}
                                                                                    value={val.client_seeking_explain}
                                                                                    name="client_seeking_explain"
                                                                                    className='form-control'
                                                                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                                                                /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label>Explanation of the above to include outcome</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="explaination_of_the_above" style={{ backgroundColor: "#e9ecef" }}>{val.explaination_of_the_above}</div>
                                                                        {/* <TextareaAutosize
                                                                            minRows={(val.explaination_of_the_above?.length / 135) + val.explaination_of_the_above?.split(/\r\n|\r|\n/).length}
                                                                            disabled={true}
                                                                            name="explaination_of_the_above"
                                                                            value={val.explaination_of_the_above}
                                                                            className='form-control'
                                                                            style={{ overflow: "hidden", textAlign: "justify" }}
                                                                        /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <div className='form-group'>
                                                                        <label>Medical provider review of LOC Assessment</label>
                                                                        <div className="form-control heightRemove PdfBackgroundColor" name="medical_provider_review" style={{ backgroundColor: "#e9ecef" }}>{val.medical_provider_review.map((val) => { return (val.label) })}</div>
                                                                        {/* <TextareaAutosize rows={2} disabled={true} name="medical_provider_review" value={val.medical_provider_review.map((val) => { return (val.label) }).join(", ")} className='form-control' style={{ overflow: "hidden" }} /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                            <div className='row'>
                                                                <div className='marginBottom1'>
                                                                    <div className='row' style={{ marginLeft: "20px", padding: "10px", }}>
                                                                        <div className='col-12 mb-2'>
                                                                            <h6 >Release of Information</h6>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-0.5'>
                                                                        {val.release_of_information ? (
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='ml-2'
                                                                                    id={"release_of_information" + "_" + val.form_type}
                                                                                    checked={val.release_of_information}
                                                                                    disabled={true}
                                                                                    name={"release_of_information" + val.form_type}
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='ml-2'
                                                                                    id={"release_of_information" + "_" + val.form_type}
                                                                                    checked={val.release_of_information}
                                                                                    disabled={true}
                                                                                    name={"release_of_information" + val.form_type}
                                                                                />
                                                                            )
                                                                        }
                                                                        </div>
                                                                        <div className='col-11'>
                                                                            <label>An appropriate release for this information is on file for this client.</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* <div style="break-after:page"></div> */}
                {/* ================================== PRINT  ASSESSED LEVEL OF CARE  END ==================================== */}
                {/* ################################************************************################################ */}
                {/* ================================== PRINT  SIGNATURE START ==================================== */}

                {
                    this.state.printData.map((val, index) => {
                        return (
                            <div className="hold-transition sidebar-mini text-sm" style={{ width: "100%" }}>
                                <div className="wrapper">
                                    <div>
                                        <section className="content-body ">
                                            <div className="row">
                                                <div className='row' style={{ width: "100%" }}>

                                                    <div className="col-12">
                                                        <div className="card shadow-none">
                                                            <div className="">
                                                                <p className="lead mx-2" style={{ marginBottom: "1rem", }} > <b className="text-green">Release of Information</b></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* table start */}
                                                <div className="row" style={{ width: "100%" }}>
                                                    <div className="col-12">
                                                        <div className='card'>
                                                            <div className='card-body'>
                                                                <table className="table table-bordered table-striped" >
                                                                    <thead>
                                                                        <th>Person</th>
                                                                        <th>Name</th>
                                                                        <th colSpan={2}>Signature</th>
                                                                        <th>Uploaded</th>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <input type="text" name="Client" disabled value="Client" className="form-control" />
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" name="" disabled value={val.client_name} className="form-control" />
                                                                            </td>
                                                                            <td colSpan={2}>
                                                                                {val.preview1 && <img src={val.preview1} height="40px" width="80%" />}
                                                                            </td>
                                                                            <td> {val.preview1 == "" ? "" : val.client_sign_uploaded_time} {val.preview1 == "" ? "" : val.client_sign_uploaded_time && "by " + val.preview1 == "" ? "" : val.uploaded_by}   </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <input type="text" name="assesment_staff" disabled value="Assesment Staff" className="form-control" />
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" name="" disabled value={val.assesment_staff} className="form-control" />
                                                                            </td>
                                                                            <td colSpan={2}>
                                                                                {val.preview2 && <img src={val.preview2} height="40px" width="80%" />}
                                                                            </td>
                                                                            <td> {val.preview2 == "" ? "" : val.client_sign_uploaded_time_Assesment_Staff} {val.preview2 == "" ? "" : val.client_sign_uploaded_time_Assesment_Staff && "by " + val.preview2 == "" ? "" : val.uploaded_by} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <input type="text" name="staff" disabled value="Staff" className="form-control" />
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" name="" disabled value={val.staff_name} className="form-control" />
                                                                            </td>
                                                                            <td colSpan={2}>
                                                                                {val.preview3 && <img src={val.preview3} height="40px" width="80%" />}
                                                                            </td>
                                                                            <td>{val.preview3 == "" ? "" : val.client_sign_uploaded_time_Staff}  {val.preview3 == "" ? "" : val.client_sign_uploaded_time_Staff && "by " + val.preview3 == "" ? "" : val.uploaded_by} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <input type="text" name="physician" disabled value="Physician" className="form-control" />
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" name="physician_name" disabled value={val.physician_name} className="form-control" />
                                                                            </td>
                                                                            <td colSpan={2}>
                                                                                {val.preview4 && <img src={val.preview4} height="40px" width="80%" />}
                                                                            </td>
                                                                            <td>{val.preview4 == "" ? "" : val.client_sign_uploaded_time_Physician} {val.preview4 == "" ? "" : val.client_sign_uploaded_time_Physician && "by " + val.preview4 == "" ? "" : val.uploaded_by} </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {(val.form_type == "Adult_Assessment" && sessionStorage.getItem("uncopeScore") < 2) || (val.form_type == "Adolescent_Assessment" && sessionStorage.getItem("craftScore") < 2) ? <>
                                                    <div className='row' style={{ width: "100%" }}>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label className='mx-2'>The client's questionnaire score was below 2 out of 6. Please provide a justification for performing the State Assessment.<span style={{ color: "red", fontSize: "20px" }}>*</span></label>
                                                                <div className="form-control heightRemove PdfBackgroundColor" name="justification" style={{ backgroundColor: "#e9ecef" }}>{val.justification ? val.justification : ""}</div>
                                                                {/* <TextareaAutosize 
                                                                    className="form-control" 
                                                                    disabled 
                                                                    minRows={(val.justification?.length / 146) + val.justification?.split(/\r\n|\r|\n/).length} 
                                                                    value={val.justification ? val.justification : ""} 
                                                                    name="justification"
                                                                     style={{ overflow: "hidden" }} 
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""}
                                                {/* table end */}
                                                {/* <div style="break-after:page"></div> */}
                                                {/* ================================== PRINT  SIGNATURE END ==================================== */}
                                                {/* ################################************************************################################ */}

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }
}