import React from 'react' ;
import Select from 'react-select';

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white'}),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "green"
          : isFocused
          ? "#0074F0"
          : null,
        color: isDisabled
          ? '#ccc'
          :isFocused
          ? "white"
          : isSelected
          ? "white"
          :"black",
  
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: "#007BFF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
      whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ':hover': {
        backgroundColor: "red",
        color: 'white',
      },
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          fontSize: '16px',
          color: '#495057'
      }
    }
  };

const MultiSelect = ({ name, value, onChange , options }) => (

    <Select
        name = {name}
        isMulti
        closeMenuOnSelect={false}
        value={value}
        onChange={onChange}
        options={options}
        styles={colourStyles}
        placeholder="Select"
    />

);

export default MultiSelect;