import axios from "axios";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import services from "../services";
import Patientinfoheader from "./Patientinfoheader";
import Sidenav from "./Sidenav";
import { Alert } from "./toastify";
import Topnav from "./Topnav";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextareaAutosize from 'react-textarea-autosize';
import swal from "sweetalert"

export default class ProblemPotential extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drug_problem: '',
      drug_problem_who: '',
      alchol_problem: '',
      alchol_problem_who: '',
      mental_illness: '',
      mental_illness_who: '',
      handicapped: '',
      injury_handicapped_who: '',
      aod_problem: '',
      recieving_opioid_replacement: '',
      periods_of_abstinence: '',
      abstinence: '',
      relapse_triggers: '',
      cause_relapse: '',
      participate_in_any_support_group: '',
      support_group: '',
      problem_comments: '',
      problem_comments2: '',
      sponser: '',
      attend_range: '',
      attendTimeOptions: [],
      relapse_risk_rating: '',
      relapse_mental_risk_rating: '',
     

      form_type: props.form_type,

      problemBlock:[
        {
          alchol:"",
          when:"",
          where:"",
          level_of_care:"",
          type_of_discharge:"",
        }
      ],
      relapse_risk_rating_information: "",
      problemPotential_notes_comment:"",

      relapse_mental_risk_rating_information: '',
      relapse_mental_comments: '',

      saved: true,
      validation: {
        aod_problem_error: "",
      },
      isSaveAndComplete: false,
      isRiskRatingMandatory : false,
      isRiskRatingMandatory2 : false
    }
  }
  relapse_risk_rating0 = `Low or no potential for relapse, good coping skills.`
  relapse_risk_rating1 = `Minimal relapse potential, with some vulnerability and has fair self management and relapse prevention skills.`
  relapse_risk_rating2 = `Impaired recognition and understanding of substance use relapse issues, but is able to self manage with prompting.`;
  relapse_risk_rating3 = `Little recognition and understanding of substance use relapse issues, and poor skills to interrupt addiction problems, or to avoid or limit relapse.`;
  relapse_risk_rating4 = `No skills to cope with addiction problems, or to prevent relapse. Continued addictive behavior places self and or others in imminent danger.`;
  
   problem_mental_risk_rating0 = `No potential for further mental health problems or low potential and good coping skills.`
   problem_mental_risk_rating1 = `Minimal relapse potential with some vulnerability and fair self management & relapse prevention skills.`
   problem_mental_risk_rating2 = `Impaired recognition and understanding of mental illness relapse issues, but is able to self manage.`
   problem_mental_risk_rating3 = `Little recognition or understanding of mental illness relapse issues and poor skills to cope with mental health problems.`
   problem_mental_risk_rating4 = `A. No immediate action required. Repeated TX episodes w/little pos. effect. No skills to cope with or interrupt MH problems, Not in imminent danger and is able to care for self. B. Immediate action required. Psychiatric disorder places them in imminent danger.`
  

  async componentWillMount() {
    // let problemPotentialOptions = await services.getOptionsfromClass(59);
    //let attendTimeOptions = problemPotentialOptions.options.attend_time;
    // let attendTimeOptions = '2022/09/06';
    // this.setState({ attendTimeOptions });
  }

  handleChange = (e,index) => {
    const updatedMedications = [...this.state.problemBlock];
    const updatedMedication = { ...updatedMedications[index], [e.target.name]: e.target.value };
    updatedMedications[index] = updatedMedication;
    this.setState({ problemBlock: updatedMedications });
    if (e.target.name == "aod_problem") {
      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        validation.aod_problem_error = "* Only numbers are allowed";
        this.setState({ validation: validation })

      } else {
        if (e.target.value.length > 3) {
          let validation = this.state.validation;
          validation.aod_problem_error = "* More than 3 numbers are not allowed";
          this.setState({ validation: validation });
        } else {

          let validation = this.state.validation;
          validation.aod_problem_error = "";
          this.setState({ [e.target.name]: e.target.value, validation: validation });
        }
      }
    } else {
      this.setState({ [e.target.name]: e.target.value, ["saved"]: false, });
    }
    if (e.target.name == "drug_problem" && e.target.value == "no") {
      this.setState({ ["drug_problem_who"]: "" });
    } else if (e.target.name == "alchol_problem" && e.target.value == "no") {
      this.setState({ ["alchol_problem_who"]: "" });
    } else if (e.target.name == "mental_illness" && e.target.value == "no") {
      this.setState({ ["mental_illness_who"]: "" });
    } else if (e.target.name == "handicapped" && e.target.value == "no") {
      this.setState({ ["injury_handicapped_who"]: "" });
    } else if (e.target.name == "participate_in_any_support_group" && e.target.value == "no") {
      this.setState({ ["support_group"]: "" });
    }else if (e.target.name == "relapse_risk_rating") {
      let val = e.target.value;
      switch (val) {
        case "0": this.setState({ ["relapse_risk_rating_information"]: this.relapse_risk_rating0, ["relapse_risk_rating"]: 0 })
          break;
        case "1": this.setState({ ["relapse_risk_rating_information"]: this.relapse_risk_rating1, ["relapse_risk_rating"]: 1 })
          break;
        case "2": this.setState({ ["relapse_risk_rating_information"]: this.relapse_risk_rating2, ["relapse_risk_rating"]: 2 })
          break;
        case "3": this.setState({ ["relapse_risk_rating_information"]: this.relapse_risk_rating3, ["relapse_risk_rating"]: 3 })
          break;
        case "4": this.setState({ ["relapse_risk_rating_information"]: this.relapse_risk_rating4, ["relapse_risk_rating"]: 4 })
          break;
      }
      this.props.riskRatingDecisionWithValue(e.target.value);
      if(e.target.value >= 2 && this.state.problem_comments2.length < 1){
        this.setState({isRiskRatingMandatory : true});
      }else{
        this.setState({isRiskRatingMandatory : false});
      }
    }else if(e.target.name == "problem_comments2"){
      if(e.target.value.length < 1 && this.state.relapse_risk_rating >= 2){
        this.setState( {isRiskRatingMandatory : true});
      }else{
        this.setState( {isRiskRatingMandatory : false});
      }
      this.props.riskRatingDecisionWithComment(e.target.value);
      this.props.riskRatingDecisionWithValue(this.state.relapse_risk_rating);
      this.setState({ problem_comments2: e.target.value });
    }else if (e.target.name == "relapse_mental_risk_rating") {
      let val = e.target.value
      switch (val) {
        case "0": this.setState({ ["relapse_mental_risk_rating_information"]: this.problem_mental_risk_rating0, ["relapse_mental_risk_rating"]: 0 })
          break;
        case "1": this.setState({ ["relapse_mental_risk_rating_information"]: this.problem_mental_risk_rating1, ["relapse_mental_risk_rating"]: 1 })
          break;
        case "2": this.setState({ ["relapse_mental_risk_rating_information"]: this.problem_mental_risk_rating2, ["relapse_mental_risk_rating"]: 2 })
          break;
        case "3": this.setState({ ["relapse_mental_risk_rating_information"]: this.problem_mental_risk_rating3, ["relapse_mental_risk_rating"]: 3 })
          break;
        case "4": this.setState({ ["relapse_mental_risk_rating_information"]: this.problem_mental_risk_rating4, ["relapse_mental_risk_rating"]: 4 })
          break;
      }
      this.props.riskRatingDecisionWithValue2(e.target.value);
      if(e.target.value >= 2 && this.state.relapse_mental_comments.length < 1){
        this.setState({isRiskRatingMandatory2 : true});
      }else{
        this.setState({isRiskRatingMandatory2 : false})
      }
    }else if(e.target.name == "relapse_mental_comments"){
      if(e.target.value.length < 1 && this.state.relapse_mental_risk_rating >= 2){
        this.setState( {isRiskRatingMandatory2 : true});
      }else{
        this.setState( {isRiskRatingMandatory2 : false});
      }
      this.props.riskRatingDecisionWithComment2(e.target.value);
      this.props.riskRatingDecisionWithValue2(this.state.relapse_mental_risk_rating);
      this.setState({ relapse_mental_comments: e.target.value });
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  }

  dataListBody = {
    apikey: sessionStorage.getItem("__TOKEN__"),
    client_id: sessionStorage.getItem("clientId"),
    form_type: this.props.form_type,
    uploaded_by: sessionStorage.getItem("username"),

  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if((this.state.problem_comments2.length < 1 && this.state.relapse_risk_rating >= 2) || (this.state.relapse_mental_comments.length < 1 && this.state.relapse_mental_risk_rating >= 2)){
      alert("Risk rating summary field is mandatory.");
    }else{
      let final_data = {
        ProblemPotential: this.state,
        dataListBody: this.dataListBody,
      };
      let res = await axios.post(
        global.restApiURL + "customRest/saveproblempotential",
        JSON.stringify(final_data)
      );
      if (res.data.success == true) {
        this.loadData();
        toast.success('Data added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_problemPotential',
        });
      }
    }
  }

  loadData = async (e) => {
    if (!this.props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customRest/getproblempotential",
        JSON.stringify(this.dataListBody)
      );
      if (res.data.success == true && res.status == 200) {
        let problemBlock = res.data.data.ProblemPotential.problemBlock || [];
  
        // Add a default row if problemBlock is empty
        if (problemBlock.length === 0) {
          problemBlock = [
            {
              alchol: "",
              when: "",
              where: "",
              level_of_care: "",
              type_of_discharge: "",
            },
          ];
        }
        this.setState({
          drug_problem: res.data.data.ProblemPotential.drug_problem,
          drug_problem_who: res.data.data.ProblemPotential.drug_problem_who,
          alchol_problem: res.data.data.ProblemPotential.alchol_problem,
          alchol_problem_who: res.data.data.ProblemPotential.alchol_problem_who,
          mental_illness: res.data.data.ProblemPotential.mental_illness,
          mental_illness_who: res.data.data.ProblemPotential.mental_illness_who,
          handicapped: res.data.data.ProblemPotential.handicapped,
          injury_handicapped_who: res.data.data.ProblemPotential.injury_handicapped_who,
          aod_problem: res.data.data.ProblemPotential.aod_problem,
          recieving_opioid_replacement: res.data.data.ProblemPotential.recieving_opioid_replacement,
          periods_of_abstinence: res.data.data.ProblemPotential.periods_of_abstinence,
          abstinence: res.data.data.ProblemPotential.abstinence,
          relapse_triggers: res.data.data.ProblemPotential.relapse_triggers,
          cause_relapse: res.data.data.ProblemPotential.cause_relapse,
          participate_in_any_support_group: res.data.data.ProblemPotential.participate_in_any_support_group,
          support_group: res.data.data.ProblemPotential.support_group,
          problem_comments: res.data.data.ProblemPotential.problem_comments,
          problem_comments2: res.data.data.ProblemPotential.problem_comments2,
          sponser: res.data.data.ProblemPotential.sponser,
          attend_range: res.data.data.ProblemPotential.attend_range,
          relapse_risk_rating: res.data.data.ProblemPotential.relapse_risk_rating,
          relapse_mental_risk_rating: res.data.data.ProblemPotential.relapse_mental_risk_rating,
          relapse_risk_rating_information: res.data.data.ProblemPotential.relapse_risk_rating_information,
          problemBlock:problemBlock,

          

          problemPotential_notes_comment: res.data.data.ProblemPotential?.problemPotential_notes_comment,
          
          // New Field Added
          relapse_mental_risk_rating_information: res.data.data.ProblemPotential.relapse_mental_risk_rating_information,
          relapse_mental_comments: res.data.data.ProblemPotential.relapse_mental_comments,

          saved: true,

        })

        let validation = this.state.validation;
        validation.aod_problem_error = "";
        this.setState({ validation: validation })

        setTimeout(() => {
          this.props.childToParent(this.state);
        }, 500)
      }
    } else {
      let res = await axios.post(
        global.restApiURL + "customRest/getproblempotentialsTemp",
        JSON.stringify(this.dataListBody)
      );
      if (res.data.success == true && res.status == 200) {
        let problemBlock = res.data.data.ProblemPotential.problemBlock || [];
  
        // Add a default row if problemBlock is empty
        if (problemBlock.length === 0) {
          problemBlock = [
            {
              alchol: "",
              when: "",
              where: "",
              level_of_care: "",
              type_of_discharge: "",
            },
          ];
        }
        this.setState({
          drug_problem: res.data.data.ProblemPotential.drug_problem,
          drug_problem_who: res.data.data.ProblemPotential.drug_problem_who,
          alchol_problem: res.data.data.ProblemPotential.alchol_problem,
          alchol_problem_who: res.data.data.ProblemPotential.alchol_problem_who,
          mental_illness: res.data.data.ProblemPotential.mental_illness,
          mental_illness_who: res.data.data.ProblemPotential.mental_illness_who,
          handicapped: res.data.data.ProblemPotential.handicapped,
          injury_handicapped_who: res.data.data.ProblemPotential.injury_handicapped_who,
          aod_problem: res.data.data.ProblemPotential.aod_problem,
          recieving_opioid_replacement: res.data.data.ProblemPotential.recieving_opioid_replacement,
          periods_of_abstinence: res.data.data.ProblemPotential.periods_of_abstinence,
          abstinence: res.data.data.ProblemPotential.abstinence,
          relapse_triggers: res.data.data.ProblemPotential.relapse_triggers,
          cause_relapse: res.data.data.ProblemPotential.cause_relapse,
          participate_in_any_support_group: res.data.data.ProblemPotential.participate_in_any_support_group,
          support_group: res.data.data.ProblemPotential.support_group,
          problem_comments: res.data.data.ProblemPotential.problem_comments,
          problem_comments2: res.data.data.ProblemPotential.problem_comments2,
          sponser: res.data.data.ProblemPotential.sponser,
          attend_range: res.data.data.ProblemPotential.attend_range,
          relapse_risk_rating: res.data.data.ProblemPotential.relapse_risk_rating,
          relapse_mental_risk_rating: res.data.data.ProblemPotential.relapse_mental_risk_rating,
          
          relapse_risk_rating_information: res.data.data.ProblemPotential.relapse_risk_rating_information,
          problemBlock:problemBlock,

          

          problemPotential_notes_comment: res.data.data.ProblemPotential?.problemPotential_notes_comment,
          // New Field Added
          relapse_mental_risk_rating_information: res.data.data.ProblemPotential.relapse_mental_risk_rating_information,
          relapse_mental_comments: res.data.data.ProblemPotential.relapse_mental_comments,

          saved: true,
          isSaveAndComplete: true,

        })
      }
    }

  }


  addRows = (e) => {
    e.preventDefault();
    const data = {
      alchol:"",
      when:"",
      where:"",
      level_of_care:"",
      type_of_discharge:"",
    };
    this.setState({
      problemBlock: [...this.state.problemBlock, data],
      saved: false, // Set saved to false when a row is added
    });
  };
  deleteRow = (index) => {
    const updatedMedications = [...this.state.problemBlock];
    const rowToRemove = updatedMedications[index];
  
    // Check if the row is not blank
    const isRowNotBlank = Object.values(rowToRemove).some(value => value.trim() !== '');
  
    if (isRowNotBlank) {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this row?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          updatedMedications.splice(index, 1);
          this.setState({ problemBlock: updatedMedications, Saved: false }, () => {
            // Call the childToParent function after state update
            this.props.childToParent(this.state);
          });
        }
      });
    } else {
      // Remove the blank row without showing the confirmation modal
      updatedMedications.splice(index, 1);
      this.setState({ problemBlock: updatedMedications, Saved: false }, () => {
        // Call the childToParent function after state update
        this.props.childToParent(this.state);
      });
    }
  };
  async componentDidMount() {
    this.loadData();
  }
  render() {
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div>
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card shadow-none">
                          <div>
                            <p className="lead float-left" style={{ marginBottom: '1rem' }}>
                              <b className="text-green">Relapse/continued use/continued problem potential</b>
                            </p>
                          </div>
                          <div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>Does anyone in your immediate family have or has had a…</label>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label> Drug problem?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="drug_problem" value={this.state.drug_problem} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {this.state.drug_problem == "yes" && <>
                                <div className="col-md-2">
                                  <label>Who?</label>
                                </div>

                                <div className="col-md-5">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="drug_problem_who" value={this.state.drug_problem_who} className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>Alcohol problem?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="alchol_problem" value={this.state.alchol_problem} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {this.state.alchol_problem == "yes" && <>
                                <div className="col-md-2">
                                  <label>Who?</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="alchol_problem_who" value={this.state.alchol_problem_who} className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>Mental/emotional illness?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="mental_illness" value={this.state.mental_illness} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {this.state.mental_illness == "yes" && <>
                                <div className="col-md-2">
                                  <label>Who?</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="mental_illness_who" value={this.state.mental_illness_who} className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>Illness/injury/handicapped?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="handicapped" value={this.state.handicapped} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {this.state.handicapped == "yes" && <>
                                <div className="col-md-2">
                                  <label>Who?</label>
                                </div>
                                <div className="col-md-5">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="injury_handicapped_who" value={this.state.injury_handicapped_who} onChange={this.handleChange} className="form-control" />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>How many times have you been treated for an AOD problem?</label>
                              </div>
                              <div className="col-md-2">
                                {/* <input type="text" name="aod_problem" className="form-control" onChange={this.handleChange} />
                                <span style={{ color: "red" }}>{this.state.validation.aod_problem_error}</span> */}

                                <input type="text" disabled={this.state.isSaveAndComplete} name="aod_problem" value={this.state.aod_problem} className="form-control" onChange={this.handleChange} maxlength="3" />
                                <span style={{ color: "red" }}>{this.state.validation.aod_problem_error}</span>
                              </div>
                              <div className="col-md-2">
                                <label>Are you currently receiving medication-assisted opioid therapy?</label>
                              </div>
                              <div className="col-md-5">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="recieving_opioid_replacement" value={this.state.recieving_opioid_replacement} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <button
                                  className="btn bt-sm btn-secondary mt-2 mr-3 float-right"
                                  onClick={this.addRows}
                                  disabled={this.state.isSaveAndComplete}
                                >
                                  <i className="fa fa-plus mr-1"></i>Add
                                </button>
                              </div>
                            </div>
                            <br/>
                            <div className="row marginBottom1">
                            <div style={{ overflowX: "scroll"}} >
                              <table className="table table-bordered table-striped" style={{ width: "max-content" }}>
                                <thead>
                                  <th>Alcohol/Drug/BOTH</th>
                                  <th>When</th>
                                  <th>Where</th>
                                  <th>Level of Care (including detox)</th>
                                  <th>Type of Discharge</th>
                                </thead>
                                <tbody>
                                    {this.state.problemBlock.map((medication, index) => (
                                        <tr key={index}>
                                          <td className="td1">
                                            <TextareaAutosize
                                              name="alchol"
                                              value={medication.alchol}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.alchol?.length / 24) + medication.alchol?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextareaAutosize
                                              name={"when"}
                                              value={medication.when}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.when?.length / 24) + medication.when?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td2">
                                            <TextareaAutosize
                                              name={"where"}
                                              value={medication.where}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.where?.length / 24) + medication.where?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td3">
                                            <TextareaAutosize
                                              name={"level_of_care"}
                                              value={medication.level_of_care}
                                              onChange={(e) => this.handleChange(e, index)}
                                              className="form-control"
                                              disabled={this.state.isSaveAndComplete}
                                              minRows={(medication.level_of_care?.length / 24) + medication.level_of_care?.split(/\r\n|\r|\n/).length}
                                              style={{ overflow: 'hidden' }}
                                            />
                                          </td>
                                          <td className="td3">
                                            <TextareaAutosize 
                                            style={{overflow:"hidden"}} 
                                            minRows={(medication.type_of_discharge?.length/18) + medication.type_of_discharge?.split(/\r\n|\r|\n/).length} 
                                            disabled={this.state.isSaveAndComplete} 
                                            name="type_of_discharge" 
                                            value={medication.type_of_discharge} 
                                            onChange={(e) => this.handleChange(e, index)}
                                            className="form-control" />
                                          </td>
                                          
                                         
                                          
                                          {index !== 0 && (
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                                onClick={() => this.deleteRow(index)}
                                                disabled={this.state.isSaveAndComplete}
                                              >
                                                Remove
                                              </button>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                      
                                    </tbody>
                              </table>
                            </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Comments</label>
                                  <TextareaAutosize 
                                  name="problem_comments" 
                                  minRows={(this.state.problem_comments?.length/110) + this.state.problem_comments?.split(/\r\n|\r|\n/).length}
                                  style={{overflow:'hidden'}} 
                                  disabled={this.state.isSaveAndComplete} 
                                  className="form-control" value={this.state.problem_comments}  
                                  onChange={this.handleChange} />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                Have you had any periods of abstinence from an AOD?
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="periods_of_abstinence" value={this.state.periods_of_abstinence} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                            </div>
                            {this.state.periods_of_abstinence == "yes" && <>
                              <div className="row marginBottom1">
                                <div className="col-md-3">
                                  <label>1. How was that abstinence/maintenance achieved? Please explain</label>
                                </div>
                                <div className="col-md-9">
                                <TextareaAutosize 
                                style={{overflow:'hidden'}} 
                                name="abstinence" 
                                minRows={(this.state.abstinence?.length/80) + this.state.abstinence?.split(/\r\n|\r|\n/).length}
                                disabled={this.state.isSaveAndComplete}
                                className="form-control" 
                                value={this.state.abstinence}
                                onChange={this.handleChange} />
                                </div>
                              </div>
                              <div className="row marginBottom1">
                                <div className="col-md-3">
                                  <label>2. What would you consider your relapse triggers? Please explain</label>
                                </div>
                                <div className="col-md-9">
                                <TextareaAutosize 
                                style={{overflow:'hidden'}} 
                                minRows={(this.state.relapse_triggers?.length/80) + this.state.relapse_triggers?.split(/\r\n|\r|\n/).length}
                                disabled={this.state.isSaveAndComplete} 
                                name="relapse_triggers" value={this.state.relapse_triggers} 
                                className="form-control" 
                                onChange={this.handleChange} />
                                </div>
                              </div>
                              <div className="row marginBottom1">
                                <div className="col-md-3">
                                  <label> 3. Are you aware of what caused you to relapse? Please explain</label>
                                </div>
                                <div className="col-md-9">
                                <TextareaAutosize 
                                style={{overflow:'hidden'}} 
                                minRows={(this.state.cause_relapse?.length/80) + this.state.cause_relapse?.split(/\r\n|\r|\n/).length}
                                disabled={this.state.isSaveAndComplete}
                                 name="cause_relapse" value={this.state.cause_relapse}
                                className="form-control"
                                onChange={this.handleChange} />
                                </div>
                              </div>
                            </>}
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                <label>Are you participating in any support groups? (AA, NA, faith-based, other)</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="participate_in_any_support_group" value={this.state.participate_in_any_support_group} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                </select>
                              </div>
                              {this.state.participate_in_any_support_group == "yes" && <>
                                <div className="col-md-2">
                                  Indicate name/type of support group
                                </div>
                                <div className="col-md-5">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="support_group" value={this.state.support_group} className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-3">
                                Do you have a sponsor?
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="sponser" value={this.state.sponser} onChange={this.handleChange}>
                                  <option value=''>Select</option>
                                  <option value='yes'>Yes</option>
                                  <option value='no'>No</option>
                                </select>
                              </div>
                              <div className="col-md-2">
                                How often do you attend?
                              </div>
                              <div className="col-md-5">
                                <select name="attend_range" disabled={this.state.isSaveAndComplete} value={this.state.attend_range} className="form-control" onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="1_3_times_per_month">1-3 times per month</option>
                                  <option value="1_2_times_per_week">1-2 times per week</option>
                                  <option value="3_6_times_per_week">3-6 times per week</option>
                                </select>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className='form-group'>
                                  <label>Notes or Comments</label>
                                  <TextareaAutosize 
                                  style={{overflow:'hidden'}} 
                                  minRows={(this.state.problemPotential_notes_comment?.length/110) + this.state.problemPotential_notes_comment?.split(/\r\n|\r|\n/).length}
                                  className="form-control" 
                                  disabled={this.state.isSaveAndComplete} 
                                  name="problemPotential_notes_comment" 
                                  onChange={this.handleChange} 
                                  value={this.state.problemPotential_notes_comment} />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row marginBottom1">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Substance Use Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" data-tip data-for="label0" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_risk_rating" value="0" checked={this.state.relapse_risk_rating == "0" ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa1" className="labelMargin">0</label>
                                        <input className="autoMargin" data-tip data-for="label1" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_risk_rating" value="1" checked={this.state.relapse_risk_rating == 1 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa1" className="labelMargin">1</label>
                                        <input className="autoMargin" data-tip data-for="label2" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_risk_rating" value="2" checked={this.state.relapse_risk_rating == 2 ? true : false} onChange={this.handleChange} />{" "}&nbsp;
                                        <label for="sa2" className="labelMargin">2</label>
                                        <input className="autoMargin" data-tip data-for="label3" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_risk_rating" value="3" checked={this.state.relapse_risk_rating == 3 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa3" className="labelMargin">3</label>
                                        <input className="autoMargin" data-tip data-for="label4" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_risk_rating" value="4" checked={this.state.relapse_risk_rating == 4 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa4" className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ReactTooltip id="label0" place="bottom" effect="solid" className="toolTipLabel0">{this.relapse_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label1" place="bottom" effect="solid" className="toolTipLabel1">{this.relapse_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label2" place="bottom" effect="solid" className="toolTipLabel2">{this.relapse_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label3" place="bottom" effect="solid" className="toolTipLabel3">{this.relapse_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label4" place="bottom" effect="solid" className="toolTipLabel4">{this.relapse_risk_rating4}</ReactTooltip>


                            <ReactTooltip id="label5" place="bottom" effect="solid" className="toolTipLabel0">{this.problem_mental_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label6" place="bottom" effect="solid" className="toolTipLabel1">{this.problem_mental_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label7" place="bottom" effect="solid" className="toolTipLabel2">{this.problem_mental_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label8" place="bottom" effect="solid" className="toolTipLabel3">{this.problem_mental_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label9" place="bottom" effect="solid" className="toolTipLabel4" >{this.problem_mental_risk_rating4}</ReactTooltip>

                            <div className="row">
                              <div className='col-md-12'>
                                Diagnostic summary for this dimension
                              </div>
                              <hr />
                              <div className="col-12">
                                <div className='form-group mt-2'>
                                  <label>Analysis</label>
                                  <TextareaAutosize 
                                  className="form-control" 
                                  minRows={(this.state.relapse_risk_rating == 2 || this.state.relapse_risk_rating == 3 || this.state.relapse_risk_rating == 4) ? 2 : 1}
                                  value={this.state.relapse_risk_rating_information} 
                                  name="relapse_risk_rating_information" 
                                  readOnly 
                                  style={{ backgroundColor: '#FEE5A1' , overflow:"hidden"}} />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    style={{overflow:'hidden'}}
                                    minRows={(this.state.problem_comments2?.length/110) + this.state.problem_comments2?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    value={this.state.problem_comments2} 
                                    onChange={this.handleChange} 
                                    name="problem_comments2"
                                  />
                                  {this.state.isRiskRatingMandatory && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                              {/*  */}
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row marginBottom1">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Mental Health Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" data-tip data-for="label5" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_mental_risk_rating" value="0" checked={this.state.relapse_mental_risk_rating == "0" ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="relapse_mental_risk_rating1" className="labelMargin">0</label>
                                        <input className="autoMargin" data-tip data-for="label6" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_mental_risk_rating" value="1" checked={this.state.relapse_mental_risk_rating == 1 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="relapse_mental_risk_rating1" className="labelMargin">1</label>
                                        <input className="autoMargin" data-tip data-for="label7" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_mental_risk_rating" value="2" checked={this.state.relapse_mental_risk_rating == 2 ? true : false} onChange={this.handleChange} />{" "}&nbsp;
                                        <label for="relapse_mental_risk_rating2" className="labelMargin">2</label>
                                        <input className="autoMargin" data-tip data-for="label8" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_mental_risk_rating" value="3" checked={this.state.relapse_mental_risk_rating == 3 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="relapse_mental_risk_rating3" className="labelMargin" >3</label>
                                        <input className="autoMargin" data-tip data-for="label9" disabled={this.state.isSaveAndComplete} type="radio" name="relapse_mental_risk_rating" value="4" checked={this.state.relapse_mental_risk_rating == 4 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="relapse_mental_risk_rating4" className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Analysis</label>
                                  <TextareaAutosize className="form-control"
                                    value={this.state.relapse_mental_risk_rating_information} 
                                    name="relapse_mental_risk_rating_information" 
                                    readOnly 
                                    style={{ backgroundColor: '#FEE5A1' , overflow:"hidden"}}
                                    minRows={this.state.relapse_mental_risk_rating == 4 ? 3 : this.state.relapse_mental_risk_rating == 3 ? 2 : 1}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize className="form-control" 
                                    style={{overflow:'hidden'}} 
                                    minRows={(this.state.relapse_mental_comments?.length/110) + this.state.relapse_mental_comments?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    value={this.state.relapse_mental_comments} 
                                    onChange={this.handleChange} 
                                    name="relapse_mental_comments"
                                  />
                                  {this.state.isRiskRatingMandatory2 && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <button type="submit" data-save="save" disabled={this.state.saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
