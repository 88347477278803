import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import Select from 'react-select';
import { validationParser, disable_button } from '../ReusableComponents/validationParser2';
import { selectOptionsGenerator, multiSelectOptionGenerator, createMultiselectDataToSend } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from 'react-tooltip';

import ViewHistoryModal from './Modals/Modal/ViewHistoryModal';

import { can_edit, can_view_version } from '../ReusableComponents/urlGenerator';
import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput';
import { ConfirmBeforeLeave } from './ConfirmBeforeLeave';
import { Alert } from "./toastify";
import { encryptStorage } from './LocalStorageInterceptor';

const axios = require('axios').default;

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '16px',
      color: '#495057'
    }
  }
};
const colourStyles2 = {
  control: styles => ({ ...styles, backgroundColor: 'white', borderColor: 'red' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "yellow"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? "yellow"
            ? 'white'
            : 'black'
          : "black",
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '17px',
      color: '#495057'
    }
  }
};

var validationObj = {};

var context;

class Lastnightstay extends Component {
  constructor() {

    super();

    context = this;

    this.handleId = this.handleId.bind(this);

    //this.handleApply = this.handleApply.bind(this);
    //this.handleCancel = this.handleCancel.bind(this);
    this.handleEvent = this.handleEvent.bind(this);

    this.dropdownParser = this.dropdownParser.bind(this);

    // this.getTimeStamp = this.getTimeStamp.bind(this);

    // var now = new Date();
    this.state = {
      disable:false,
      viewHistoryModal: false,
      object_id: 0,
      historyComparison: [],
      lastNightStayData: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      full_labelText: this.getModal(),
      tooltips: this.getModal(),
      tooltipsVisibility: this.getModal(),
      fieldName: this.getModal(),
      viewHistoryColumns: [],
      maxLength: this.getModal(),
      unSaved: false,
      clientGenderSelectOptions: [],
      deafHeardSelectOptions: [],
      hearcallSelectOptions: [],
      hispLatSelectOptions: [],
      pastRrcRecvSelectOptions: [],
      everServedArmforceSelectOptions: [],
      currMilitaryStatusSelectOptions: [],
      domesticViolenceVictimSelectOptions: [],
      currentlyFleeingSelectOptions: [],
      relationshipStatusSelectOptions: [],
      currentlyPreagnantSelectOptions: [],
      totalMonthIncomeSelectOptions: [],
      hearRrcCenterSelectOptions: [],
      hear_rrc_center_other: '',
      // Code Added by ashraf for ROSS Helpline
      disablingConditionMultiOptions: [],
      clientRacesMultiOptions: [],
      rrcServiceInterestedMultiOptions: [],
      pastRecvRrcServcMultiOptions: [],
      doRecvIncomeMultiOptions: [],
      recvNonCashBenefitsMultiOptions: [],
      coverByHealthInsMultiOptions: [],
      lgbtSelectOptions: [],
      selectedOption: null,
      selectedOption2: null,
      reliable_transportationOptions: [],
      ride_to_treatmentOptions: [],
      faimlyWithServicesOptions : "",
      faimlyServicesOptions : ""
    };
  }

  getModal() {
    var lastNightModal = {
      stayed_years: '',
      stayed_months: '',
      stayed_days: '',
      client_gender: '',
      disabling_condition: '',
      deaf_or_heard: '',
      client_races: '',
      hisp_lat_heritage: '',
      rrc_service_interested_in: '',
      hear_rrc_center: '',
      past_rrc_recv: '',
      past_recv_rrc_servc: '',
      ever_served_armforce: '',
      curr_military_status: '',
      domestic_violence_victim: '',
      currently_fleeing: '',
      relationship_status: '',
      currently_pregnant: '',
      identify_lgbt: '',
      do_recv_income: '',
      total_month_income: '',
      other_income_range: '',
      recv_non_cash_benefits: '',
      cover_by_health_ins: '',
      hear_rrc_center_other: '',
      reliable_transportation: '',
      ride_to_treatment: '',
      // Code Added by ashraf for ROSS Helpline
      hear_rrc_center_ross_helpline: "",
      helpline_call: "",
      // family_with_services: "",
      // family_services: ""
    };
    return lastNightModal;
  }

  closeViewHistoryModal = (status) => {
    this.setState({ viewHistoryModal: status })
  }

  handleMultiSelectChanges = (selectedOption, event) => {
    var updatedState = this.state.lastNightStayData;
    updatedState[event.name] = selectedOption;
    this.setState(prevState => ({
      ...prevState,
      lastNightStayData: updatedState,
      unSaved: true,
    }));
  };

  handle_disabling_condition = disabling_condition => {
    this.setState(prevState => ({
      ...prevState,
      lastNightStayData: {
        disabling_condition
      }
    }));
  };

  handle_client_races = (disabling_condition, event) => {
    this.setState(prevState => ({
      ...prevState,
      lastNightStayData: {
        client_races: disabling_condition
      }
    }));
  };

  componentWillMount() {
    axios.get(global.restApiURL + 'webservice/rest/class/id/9?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;
        validationObj = validationParser(responce.data.layoutDefinitions.childs[0].childs);
        let label_arr = [];
        Object.keys(validationObj.title).map((label) => {
          label_arr[label] = validationObj.title[label].replace('Recovery Resource Center', sessionStorage.getItem('selected_organization')).replace('RRC', sessionStorage.getItem('selected_organization'));
        });
        validationObj.title = label_arr;
        this.setState({
          labelText: validationObj.title,
          full_labelText: validationObj.full_title,
          tooltips: validationObj.tooltip,
          fieldName: validationObj.name,
          required: validationObj.mandatory,
          tooltipsVisibility: validationObj.tooltipVisibility,
          maxLength: validationObj.columnLength,
        })
        this.createSelectOptions();
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  componentDidMount() {
    this.setState({disable:true});
    disable_button('assessmentform');
    var prevExist = false;
    sessionStorage.setItem('savedObjectId', '');
    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=Assessment&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (responce.length !== 0) {
            prevExist = true;
            sessionStorage.setItem("savedObjectId", responce[0].id);
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
              .then(res => {
                const response = res.data.data.elements;
                var ob = this.datamapper(response);
                this.setState({
                  lastNightStayData: ob,
                  object_id: responce[0].id,
                  faimlyWithServicesLabel: response[30].name
                });
                sessionStorage.setItem("client_races_selected", res.data.data.elements[5].value)
                this.createSelectOptions();
              })
              .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
              })
          } else {
            prevExist = false;
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }

    $.validator.setDefaults({
      submitHandler: function () {
        var jsonData = '';
        if (prevExist === true) {
          jsonData = { 'className': 'Assessment', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem("savedObjectId"), 'published': true };
        } else {
          jsonData = { 'className': 'Assessment', 'key': sessionStorage.getItem('clientId'), 'published': true };
        }
        jsonData.elements = [];
        var formData = $("#assessmentform").serializeArray();
        $.each(formData, function () {
          if (this.name !== "disabling_condition" && this.name !== "client_races" && this.name !== "rrc_service_interested_in" &&
            this.name !== "past_recv_rrc_servc" && this.name !== "do_recv_income" && this.name !== "recv_non_cash_benefits" && this.name !== "cover_by_health_ins")
            jsonData.elements.push({ name: this.name, value: this.value });
          // else
          //   {
          //     jsonData.elements.push({name:this.name,value: context.state.lastNightStayData[this.name] });
          //   }
        });

        jsonData.elements.push({ name: context.state.fieldName.disabling_condition, value: createMultiselectDataToSend(context.state.lastNightStayData.disabling_condition) });
        jsonData.elements.push({ name: context.state.fieldName.client_races, value: createMultiselectDataToSend(context.state.lastNightStayData.client_races) });
        jsonData.elements.push({ name: context.state.fieldName.rrc_service_interested_in, value: createMultiselectDataToSend(context.state.lastNightStayData.rrc_service_interested_in) });
        jsonData.elements.push({ name: context.state.fieldName.past_recv_rrc_servc, value: createMultiselectDataToSend(context.state.lastNightStayData.past_recv_rrc_servc) });
        jsonData.elements.push({ name: context.state.fieldName.do_recv_income, value: createMultiselectDataToSend(context.state.lastNightStayData.do_recv_income) });
        jsonData.elements.push({ name: context.state.fieldName.recv_non_cash_benefits, value: createMultiselectDataToSend(context.state.lastNightStayData.recv_non_cash_benefits) });
        jsonData.elements.push({ name: context.state.fieldName.cover_by_health_ins, value: createMultiselectDataToSend(context.state.lastNightStayData.cover_by_health_ins) });
        var json = JSON.stringify(jsonData);
        axios.post(global.save_url, json)
          .then(function (res) {
            var response = res.data;
            if (response.success) {
              sessionStorage.setItem('success_msg', "Assessment saved successfully.");
              if ($('#clicked').val() === 'proceed') {
                window.location.href = global.domain + global.traversArray['lastnightstay'];
              }
              else {
                context.setState({ 'unSaved': false });
                if (!sessionStorage.getItem('savedObjectId')) {
                  sessionStorage.setItem('savedObjectId', response.id)
                }
                prevExist = true;
                Alert('success', "Assessment saved successfully.")
              }
            }
            else {
              sessionStorage.setItem('success_msg', "Something went wrong!");
            }
            console.log("Msg : ", sessionStorage.getItem('success_msg'));
          })
          .catch(function (err) {
            alert(err.message);
          });
      }
    });
  }

  createMultiselectDataToSend(selectedOptions) {
    var structuredResponse = [];
    if (selectedOptions) {
      if (selectedOptions.length !== 0) {
        selectedOptions.map((val) => {
          structuredResponse.push(val.label);
        });
      }
    }
    return structuredResponse;
  }

  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        deafHeardSelectOptions: selectOptionsGenerator(validationObj.options.deaf_or_heard, this.state.lastNightStayData.deaf_or_heard),
        hispLatSelectOptions: selectOptionsGenerator(validationObj.options.hisp_lat_heritage, this.state.lastNightStayData.hisp_lat_heritage),
        pastRrcRecvSelectOptions: selectOptionsGenerator(validationObj.options.past_rrc_recv, this.state.lastNightStayData.past_rrc_recv),
        everServedArmforceSelectOptions: selectOptionsGenerator(validationObj.options.ever_served_armforce, this.state.lastNightStayData.ever_served_armforce),
        currMilitaryStatusSelectOptions: selectOptionsGenerator(validationObj.options.curr_military_status, this.state.lastNightStayData.curr_military_status),
        domesticViolenceVictimSelectOptions: selectOptionsGenerator(validationObj.options.domestic_violence_victim, this.state.lastNightStayData.domestic_violence_victim),
        currentlyFleeingSelectOptions: selectOptionsGenerator(validationObj.options.currently_fleeing, this.state.lastNightStayData.currently_fleeing),
        relationshipStatusSelectOptions: selectOptionsGenerator(validationObj.options.relationship_status, this.state.lastNightStayData.relationship_status),
        currentlyPreagnantSelectOptions: selectOptionsGenerator(validationObj.options.currently_pregnant, this.state.lastNightStayData.currently_pregnant),
        lgbtSelectOptions: selectOptionsGenerator(validationObj.options.identify_lgbt, this.state.lastNightStayData.identify_lgbt),
        totalMonthIncomeSelectOptions: selectOptionsGenerator(validationObj.options.total_month_income, this.state.lastNightStayData.total_month_income),
        hearRrcCenterSelectOptions: selectOptionsGenerator(validationObj.options.hear_rrc_center, this.state.lastNightStayData.hear_rrc_center),
        hearcallSelectOptions: selectOptionsGenerator(validationObj.options.helpline_call, this.state.lastNightStayData.helpline_call),
        disablingConditionMultiOptions: multiSelectOptionGenerator(validationObj.options.disabling_condition),
        clientRacesMultiOptions: multiSelectOptionGenerator(validationObj.options.client_races),
        rrcServiceInterestedMultiOptions: multiSelectOptionGenerator(validationObj.options.rrc_service_interested_in),
        pastRecvRrcServcMultiOptions: multiSelectOptionGenerator(validationObj.options.past_recv_rrc_servc),
        doRecvIncomeMultiOptions: multiSelectOptionGenerator(validationObj.options.do_recv_income),
        recvNonCashBenefitsMultiOptions: multiSelectOptionGenerator(validationObj.options.recv_non_cash_benefits),
        coverByHealthInsMultiOptions: multiSelectOptionGenerator(validationObj.options.cover_by_health_ins),
        reliable_transportationOptions: selectOptionsGenerator(validationObj.options.reliable_transportation, this.state.lastNightStayData.reliable_transportation),
        ride_to_treatmentOptions: selectOptionsGenerator(validationObj.options.ride_to_treatment, this.state.lastNightStayData.ride_to_treatment),
        faimlyWithServicesOptions: selectOptionsGenerator(validationObj.options.family_with_services, this.state.lastNightStayData.family_with_services),
        faimlyServicesOptions: selectOptionsGenerator(validationObj.options.services, this.state.lastNightStayData.services),
      });
    }
  }

  datamapper(fetchedObjectData) {
    let obj = this.getModal();
    fetchedObjectData.map((fod) => {
      if (fod.type === "numeric" || fod.type === "select" || fod.type === "input" || fod.type === "date") {
        obj[fod.name] = fod.value;
      } else if (fod.type === "multiselect") {
        let multiSelectOptions = fod.value !== null && fod.value.length > 0 && fod.value.map((item, i) => {
          var op = { value: item, label: item };
          return op;
        }, this);
        obj[fod.name] = multiSelectOptions;
      }
    });
    return obj;
  }


  dropdownParser(rowata) {
    var mainObj = [];
    rowata.map((val) => {
      let innerObj = {
        type: "",
        name: "",
        options: ""
      };
      if (val.fieldtype === 'select' || val.fieldtype === 'multiselect') {
        innerObj.type = val.fieldtype;
        innerObj.name = val.name;
        innerObj.options = val.options;
        mainObj[val.name] = innerObj;
      }
    });
    return mainObj;
  }

  handleViewHistoryDetails = (event) => {
    this.setState({
      viewHistoryModal: true
    })
  }

  handleId = (e) => {
    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  saveChanges() {
    //isko b dekhna hai
    $('#clicked').val('save');
    $('#assessmentform').submit();
  }

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  }

  optionClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }
  selectedBadgeClicked(optionsList) {
    this.setState({ multiSelect: optionsList });
  }

  handleSave = (event) => {
    this.setState({disable:false})
    const { name, value } = event.target;
    this.setState((prevState) => {
      const lastNightStayData = { ...prevState.lastNightStayData };
      lastNightStayData[name] = value;
      if (name === 'helpline_call' && value === 'Yes') {
        lastNightStayData.hear_rrc_center_ross_helpline = ''; // Reset the hear_rrc_center_ross_helpline value
      }
      return {
        lastNightStayData,
        unSaved: true,
      };
    });
  };

  render() {
    sessionStorage.setItem('formname', 'Assessment')
    $(document).ready(function () {
      $('#assessmentform').validate({
        rules: {
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} />
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <form action="javascript:void(0);" method="post" id="assessmentform">
                <div className="row">
                  <Sidenav />
                  <fieldset disabled={!can_edit('Assessment')} className="container-fluid col-md-9 col-lg-10">
                    <div className="card card-default">
                      <div className="card-header">
                        <p className="lead float-left"><b class="text-green">Assessment Form<small></small></b></p>
                        {can_view_version('Assessment') ? (<a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right" onClick={() => this.handleViewHistoryDetails()} style={{ marginLeft: '5px' }}> View History</a>) : ""}&nbsp;&nbsp;
                        <button type="submit" id="saveButton" className="btn btn-primary float-right" value="proceed" disabled={this.state.disable} onClick={this.handleId}>
                          <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                        <button type="submit" value='save' className="btn btn-success float-right btnmargin" disabled={this.state.disable} onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        <input type="hidden" id="clicked" value="" readOnly />
                        <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem('clientId')} />
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <LabelField title={"How long have you been staying where you were last night?"} mandatory={this.state.required.stayed_years} name="stayed_years" tooltip={this.state.tooltips.stayed_years} id="stayed_years" />
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="row">
                              <div className="col-4">
                                <LabelField title={this.state.labelText.stayed_years} mandatory={this.state.required.stayed_years} name="stayed_years" tooltip={this.state.tooltips.stayed_years} id="stayed_years" />
                                <input type="number" placeholder="Years" min="0" max="100" className="form-control" id="stayed_years" name="stayed_years" defaultValue={this.state.lastNightStayData.stayed_years} onChange={this.handleSave} />
                              </div>
                              <div className="col-4">
                                <LabelField title={this.state.labelText.stayed_months} mandatory={this.state.required.stayed_months} name="stayed_months" tooltip={this.state.tooltips.stayed_months} id="stayed_months" />
                                <input type="number" placeholder="Months" min="0" max="11" name="stayed_months" className="form-control" id="stayed_months" defaultValue={this.state.lastNightStayData.stayed_months} onChange={this.handleSave} />
                              </div>
                              <div className="col-4">
                                <LabelField title={this.state.labelText.stayed_days} mandatory={this.state.required.stayed_days} name="stayed_days" tooltip={this.state.tooltips.stayed_days} id="stayed_days" />
                                <input type="number" placeholder="Days" min="0" max="30" className="form-control" id="stayed_days" name="stayed_days" defaultValue={this.state.lastNightStayData.stayed_days} onChange={this.handleSave} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.disabling_condition} mandatory={this.state.required.disabling_condition} name="disabling_condition" tooltip={this.state.tooltips.disabling_condition} id="disabling_condition" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group is-invalid">
                              <Select
                                style={{ borderColor: "#b94a48" }}
                                name={this.state.fieldName.disabling_condition}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.disabling_condition}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.disablingConditionMultiOptions}
                                placeholder="Select"
                                styles={true ? colourStyles : colourStyles2}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.deaf_or_heard} mandatory={this.state.required.deaf_or_heard} name="deaf_or_heard" tooltip={this.state.tooltips.deaf_or_heard} id="deaf_or_heard" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="deaf_or_heard" name="deaf_or_heard">
                                {this.state.deafHeardSelectOptions}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.identify_lgbt} mandatory={this.state.required.identify_lgbt} name="identify_lgbt" tooltip={this.state.tooltips.identify_lgbt} id="identify_lgbt" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="identify_lgbt" name="identify_lgbt" >
                                {this.state.lgbtSelectOptions}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.client_races} mandatory={this.state.required.client_races} name="client_races" tooltip={this.state.tooltips.client_races} id="client_races" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Select
                                name={this.state.fieldName.client_races}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.client_races}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.clientRacesMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.hisp_lat_heritage} mandatory={this.state.required.hisp_lat_heritage} name="hisp_lat_heritage" tooltip={this.state.tooltips.hisp_lat_heritage} id="hisp_lat_heritage" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="hisp_lat_heritage" name="hisp_lat_heritage">
                                {this.state.hispLatSelectOptions}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.rrc_service_interested_in} mandatory={this.state.required.rrc_service_interested_in} name="rrc_service_interested_in" tooltip={this.state.tooltips.rrc_service_interested_in} id="rrc_service_interested_in" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {/* <MultiSelectReact 
                                  options={this.state.rrc_services}
                                  optionClicked={this.optionClicked.bind(this)}
                                  selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
                                  selectedOptionsStyles={selectedOptionsStyles}
                                  optionsListStyles={optionsListStyles}
                                  isTextWrap={true} /> */}
                              <Select
                                name={this.state.fieldName.rrc_service_interested_in}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.rrc_service_interested_in}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.rrcServiceInterestedMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.hear_rrc_center} mandatory={this.state.required.hear_rrc_center} name="hear_rrc_center" tooltip={this.state.tooltips.hear_rrc_center} id="hear_rrc_center" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select my_agency" id="hear_rrc_center" name="hear_rrc_center" onChange={this.handleSave}>
                                {this.state.hearRrcCenterSelectOptions}
                              </select>

                            </div>
                          </div>
                          {this.state.lastNightStayData.hear_rrc_center == 'Other (Please Specify Below)' ? (
                            <>
                              <div className="col-md-6">
                              </div>
                              <div className="col-md-6 form-group">
                                <input required={this.state.required.hear_rrc_center_other} name="hear_rrc_center_other" className="form-control" id="hear_rrc_center_other" defaultValue={this.state.lastNightStayData.hear_rrc_center_other} onChange={this.handleSave} />
                              </div>
                            </>
                          ) : ""}

                          {/* New Code Added for ROSS Helpline Start*/}

                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.helpline_call} name="helpline_call" tooltip={this.state.tooltips.helpline_call} id="helpline_call" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="helpline_call" name="helpline_call" value={this.state.lastNightStayData.helpline_call} onChange={this.handleSave}>
                                {this.state.hearcallSelectOptions}
                              </select>
                            </div>

                          </div>

                          {this.state.lastNightStayData.helpline_call === 'Yes' && (
                            <>
                              <div className="col-md-6">
                                <LabelField title={this.state.labelText.hear_rrc_center_ross_helpline} mandatory={true} name="hear_rrc_center_ross_helpline" tooltip={this.state.tooltips.hear_rrc_center_ross_helpline} id="hear_rrc_center_ross_helpline" />
                              </div>
                              <div className="col-md-6 form-group">
                                <input type="date" required={true} name="hear_rrc_center_ross_helpline" className="form-control" id="hear_rrc_center_ross_helpline" value={this.state.lastNightStayData.hear_rrc_center_ross_helpline} onChange={this.handleSave} />
                              </div>
                            </>
                          )}










                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.past_rrc_recv} mandatory={this.state.required.past_rrc_recv} name="past_rrc_recv" tooltip={this.state.tooltips.past_rrc_recv} id="past_rrc_recv" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="past_rrc_recv" name="past_rrc_recv" onChange={this.handleSave}>
                                {this.state.pastRrcRecvSelectOptions}
                              </select>
                            </div>
                          </div>
                          {this.state.lastNightStayData.past_rrc_recv === "Yes" ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <LabelField title={this.state.labelText.past_recv_rrc_servc} mandatory={this.state.required.past_recv_rrc_servc} name="past_recv_rrc_servc" tooltip={this.state.tooltips.past_recv_rrc_servc} id="past_recv_rrc_servc" />
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <Select
                                      name={this.state.fieldName.past_recv_rrc_servc}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      value={this.state.lastNightStayData.past_recv_rrc_servc}
                                      onChange={this.handleMultiSelectChanges}
                                      options={this.state.pastRecvRrcServcMultiOptions}
                                      styles={colourStyles}
                                      placeholder="Select"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : <input type="hidden" name="past_recv_rrc_servc" value="" />
                          }
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.ever_served_armforce} mandatory={this.state.required.ever_served_armforce} name="ever_served_armforce" tooltip={this.state.tooltips.ever_served_armforce} id="ever_served_armforce" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="ever_served_armforce" name="ever_served_armforce" onChange={this.handleSave}>
                                {this.state.everServedArmforceSelectOptions}
                              </select>
                            </div>
                          </div>
                          {this.state.lastNightStayData.ever_served_armforce === "Yes" ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <LabelField title={this.state.labelText.curr_military_status} mandatory={this.state.required.curr_military_status} name="curr_military_status" tooltip={this.state.tooltips.curr_military_status} id="curr_military_status" />
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">

                                    <select className="custom-select" id="curr_military_status" name="curr_military_status" >
                                      {this.state.currMilitaryStatusSelectOptions}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : <input type="hidden" name="curr_military_status" value="" />}
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.domestic_violence_victim} mandatory={this.state.required.domestic_violence_victim} name="domestic_violence_victim" tooltip={this.state.tooltips.domestic_violence_victim} id="domestic_violence_victim" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="domestic_violence_victim" name="domestic_violence_victim" onChange={this.handleSave}>
                                {this.state.domesticViolenceVictimSelectOptions}
                              </select>
                            </div>
                          </div>
                          {this.state.lastNightStayData.domestic_violence_victim === "Yes" ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <LabelField title={this.state.labelText.currently_fleeing} mandatory={this.state.required.currently_fleeing} name="currently_fleeing" tooltip={this.state.tooltips.currently_fleeing} id="currently_fleeing" />
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <select className="custom-select" id="currently_fleeing" name="currently_fleeing">
                                      {this.state.currentlyFleeingSelectOptions}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : <input type="hidden" name="currently_fleeing" value="" />}
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.relationship_status} mandatory={this.state.required.relationship_status} name="relationship_status" tooltip={this.state.tooltips.relationship_status} id="relationship_status" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="relationship_status" name="relationship_status" >
                                {this.state.relationshipStatusSelectOptions}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.currently_pregnant} mandatory={this.state.required.currently_pregnant} name="currently_pregnant" tooltip={this.state.tooltips.currently_pregnant} id="currently_pregnant" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="currently_pregnant" name="currently_pregnant" >
                                {this.state.currentlyPreagnantSelectOptions}
                              </select>
                            </div>
                          </div>
                          
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.do_recv_income} mandatory={this.state.required.do_recv_income} name="do_recv_income" tooltip={this.state.tooltips.do_recv_income} id="do_recv_income" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {/* <MultiSelectReact 
                                options={this.state.receive_income}
                                optionClicked={this.optionClicked.bind(this)}
                                selectedBadgeClicked={this.selectedBadgeClicked.bind(this)}
                                selectedOptionsStyles={selectedOptionsStyles}
                                optionsListStyles={optionsListStyles}
                                isTextWrap={true} /> */}
                              <Select
                                name={this.state.fieldName.do_recv_income}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.do_recv_income}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.doRecvIncomeMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.total_month_income} mandatory={this.state.required.total_month_income} name="total_month_income" tooltip={this.state.tooltips.total_month_income} id="total_month_income" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="custom-select" id="total_month_income" name="total_month_income" onChange={this.handleSave}>
                                {this.state.totalMonthIncomeSelectOptions}
                              </select>
                            </div>
                          </div>
                          {this.state.lastNightStayData.total_month_income === 'Other (Please Specify Below)' ? (
                            <>
                              <div className="col-md-6">
                                <LabelField title={this.state.labelText.other_income_range} mandatory={this.state.required.other_income_range} name="other_income_range" tooltip={this.state.tooltips.other_income_range} id="other_income_range" />
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <input required={this.state.required.other_income_range} name="other_income_range" className="form-control" id="other_income_range" defaultValue={this.state.lastNightStayData.other_income_range} onChange={this.handleSave} />
                                </div>
                              </div>
                            </>) : <input type="hidden" name="other_income_range" value="" />}
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.recv_non_cash_benefits} mandatory={this.state.required.recv_non_cash_benefits} name="recv_non_cash_benefits" tooltip={this.state.tooltips.recv_non_cash_benefits} id="recv_non_cash_benefits" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Select
                                name={this.state.fieldName.recv_non_cash_benefits}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.recv_non_cash_benefits}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.recvNonCashBenefitsMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.cover_by_health_ins} mandatory={this.state.required.cover_by_health_ins} name="cover_by_health_ins" tooltip={this.state.tooltips.cover_by_health_ins} id="cover_by_health_ins" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Select
                                name={this.state.fieldName.cover_by_health_ins}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.lastNightStayData.cover_by_health_ins}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.coverByHealthInsMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                              />
                            </div>
                          </div>

                          {/* Code By Ashraf Start */}
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.reliable_transportation} mandatory={this.state.required.reliable_transportation} name="reliable_transportation" tooltip={this.state.tooltips.reliable_transportation} id="reliable_transportation" />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">

                              <select className="custom-select" id="reliable_transportation" name="reliable_transportation" onChange={this.handleSave}>
                                {this.state.reliable_transportationOptions}
                              </select>
                            </div>
                          </div>

                          {this.state.lastNightStayData.reliable_transportation == "No" ? (<>
                            <div className="col-md-6">
                              <LabelField title={this.state.labelText.ride_to_treatment} name="ride_to_treatment" mandatory={this.state.required.ride_to_treatment} tooltip={this.state.tooltips.ride_to_treatment} id="ride_to_treatment" />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <select className="custom-select" id="ride_to_treatment" name="ride_to_treatment" onChange={this.handleSave}>
                                    {this.state.ride_to_treatmentOptions}
                                </select>
                              </div>
                            </div>
                          </>) : (<> <input type='hidden' value={''}  id="ride_to_treatment" name="ride_to_treatment"  /> </>)}

                          {/* Code by Ashraf End */}
                        </div>

                        {/* added by Kuldeep Singh */}

                        <div className='row'>
                          <div className="col-md-6">
                            <LabelField title={this.state.labelText.family_with_services} name="family_with_services_label" id='family_with_services_label' mandatory={this.state.required.family_with_services} tooltip={this.state.tooltips.family_with_services} />
                          </div>
                          <div className="col-md-6">
                            <select name='family_with_services' id='family_with_services' className="custom-select" aria-hidden="true" onChange={this.handleSave} >
                              {this.state.faimlyWithServicesOptions}
                            </select>
                          </div>
                        </div>

                        <div className='row mt-3'>
                          {this.state.lastNightStayData.family_with_services === "Yes" ?
                            <>
                              <div className="col-md-6">
                                <LabelField title={this.state.labelText.services} name="family_services_label" id='family_services_label' tooltip={this.state.tooltips.services} />
                              </div>
                              <div className="col-md-6">
                                <select name='services' id='services' className="custom-select" aria-hidden="true" onChange={this.handleSave} required={this.state.lastNightStayData.services}>
                                {this.state.faimlyServicesOptions}
                                </select>
                              </div> </> : "" 
                          } 
                        </div>

                      </div>
                      <div className="card-footer float-right" >
                        <div className="float-right">
                          {can_view_version('Assessment') ? <a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right " style={{ marginLeft: '5px' }} onClick={() => this.handleViewHistoryDetails()}>View History</a> : ''}
                          <button type="submit" className="btn btn-primary float-right" value="proceed" disabled={this.state.disable} onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" value='save' className="btn btn-success float-right btnmargin" disabled={this.state.disable} onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </form>
            </section>
          </div>
          <Footer />
          <ViewHistoryModal viewHistoryModal={this.state.viewHistoryModal} class_name={'Assessment'} onRef={ref => (this.childForm = ref)} class_definition={this.state.full_labelText} onViewHistoryModalClose={this.closeViewHistoryModal} obj_id={this.state.object_id} />
        </div>
      </div>
    )

  }
}
export default Lastnightstay
