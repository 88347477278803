import React, { useRef, useState } from "react";
import styled from "styled-components";
import Filter from "./CoverFilter";

import { sampleData } from "./ClientsStackBar";

import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";
import { makeStyles } from "@material-ui/core";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Title = styled.span`
  flex: 0 0 40%;
`;

const FilterContainer = styled.div`
  display: flex;
  flex: 0 0 60%;
  justify-content: space-between;
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 150px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
`;

function CoverTableHeader({ title, filter, options }) {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("2021");


  return (
    <HeaderContainer>
      <Title>{title}</Title>
      <FilterContainer>
        <Filter
          selected={year}
          value={year}
          placeholder="Show Data for Year 2021"
          width="220px"
          
          options={[
            "2021",
            "2020",
            "2019",
            "2018",
            "2017",
          ]}
          
        />

        <Filter placeholder={filter} width="150px" options={options}  />
        <Filter
         selected={month}
          placeholder="All Months"
          width="150px"
          
          options={sampleData[0].data.map((e) => e.x)}
          
        />

        <FilterDate>
          <ReactDatePicker
            selected={date}
            placeholderText="Filter Date"
            onChange={(date) => setDate(date)}
            className="filterByDate"
          />

          <img src={CalendarIcon} alt="calendaricon" />
        </FilterDate>
        <Button>Download CSV</Button>
      </FilterContainer>
    </HeaderContainer>
  );
}

export default CoverTableHeader;
