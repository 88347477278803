import React from 'react' ;
import $ from 'jquery';
import '../global.js';

export function validationParser(rawData)
      {
        // console.log(rawData);
        var validationObj = [] ;
        let innerValidationObj = {};

        rawData.map( (val) => {

         innerValidationObj = {
                name : "",
                title : "",
                full_title: "",
                tooltip : "",
                fieldtype : "",
                options : [] ,
                columnLength : "",
                noeditable : "",
                invisible : "",
                mandatory : "",
                regex : "" 
            } ;

            if(val.childs === undefined )
            {
                innerValidationObj.title = val.title.split('|')[1] ? val.title.split('|')[1].trim() : val.title.split('|')[0].trim() ;
                innerValidationObj.full_title = val.title ;
                innerValidationObj.name = val.name ;
                innerValidationObj.tooltip = val.tooltip;
                innerValidationObj.options = val.options ;
                innerValidationObj.fieldtype = val.fieldtype ;
                innerValidationObj.columnLength = val.columnLength;
                innerValidationObj.noteditable = val.noteditable ;
                innerValidationObj.invisible = val.invisible ;
                innerValidationObj.mandatory = val.mandatory ;
                innerValidationObj.regex = val.regex ;

                validationObj[val.name] = innerValidationObj ;
                 
            }
            else
            {
                val.childs.map( (innerChild) => {

                    innerValidationObj.title = val.title.split('|')[1] ? val.title.split('|')[1].trim() : val.title.split('|')[0].trim() ;
                    innerValidationObj.full_title = val.title ;
                    innerValidationObj.name = val.name ;
                    innerValidationObj.tooltip = val.tooltip;
                    innerValidationObj.options = val.options ;
                    innerValidationObj.fieldtype = val.fieldtype ;
                    innerValidationObj.columnLength = val.columnLength;
                    innerValidationObj.noteditable = val.noteditable ;
                    innerValidationObj.invisible = val.invisible ;
                    innerValidationObj.mandatory = val.mandatory ;
                    innerValidationObj.regex = val.regex ;
    
                    validationObj[innerChild.name] = innerValidationObj ;
                   
                });
            }  
        } );

        //Fetching the keys from validation object
        var fieldKeys = Object.keys(innerValidationObj);
        var validationObj1 = {};
        fieldKeys.map((key) => {
            if(key !== "tooltip")
                validationObj1[key] = getObject(validationObj , key);
            else
            {
                var tooltipObj = getObject(validationObj , key);
                //console.log('tooltip',validationObj, tooltipObj, key);
                validationObj1[key] = tooltipObj["values"];
                validationObj1["tooltipVisibility"] = tooltipObj["visible"];
            }
        });
        

        return validationObj1 ;
}

function getObject( validationObj  , fieldToFetch )
{
        var keys = Object.keys(validationObj) ;
        var dataObject1 = {} ;
        if(fieldToFetch !== "tooltip")
        {
            keys.map((key) => {
                dataObject1[key] = validationObj[key][fieldToFetch];
            });
        }
        else
        {
            var tooltipValues = {};
            var tooltipVisibility = {} ;

            keys.map((key) => {
                tooltipValues[key] = validationObj[key][fieldToFetch];
                if( validationObj[key][fieldToFetch] === "" )
                    tooltipVisibility[key] = "none" ;
                else
                    tooltipVisibility[key] = ""
            });

            dataObject1["values"] = tooltipValues;
            dataObject1["visible"] = tooltipVisibility;

        }
        return dataObject1 ;
}

export function disable_button(form_name=''){
    var form = form_name !== '' ? '#'+form_name : '';
   
    
    $(form+' :button').each(function() {

        $(this).prop('disabled', true);

      });

     $(form+' :input:not(:button)').on('keyup change mousedown', function() {
        
        $(':button').each(function() {

        $(this).prop('disabled', false);

      });

      });

     

    $(form+' div[class*="-container"][class*="css-"]').on('keyup change mousedown', function(){
        
        $(':button').each(function() {
            $(this).prop('disabled', false);

          });

        
    });

}

