import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Modal from './Modals/Modal/index';
import DateTime from 'react-datetime';
import $ from 'jquery';
import '../global.js';
import { Base64 } from 'js-base64';
import { encode, decode } from 'js-base64';
import Loader from './Loader';
import { dataFormatter } from '../ReusableComponents/dataFormater';

var context;
const axios = require('axios').default;



class CallbackToLogin extends Component {
  constructor(props) {
    super();
    context = this;

  }

  componentDidMount() {
    console.log(Base64.decode(this.props.match.params.id), this.props.match.params.id);
    let response = JSON.parse(Base64.decode(this.props.match.params.id));
    console.log('response', response);
    if (response.success && (response.data.roles.length > 0 || response.data.admin === true) &&
      response.data.apiKey !== '' && response.data.full_name !== '' &&
      response.data.id > 0) {
      let response_data = response;
      axios.post(global.restApiURL + 'customRest/wso2SessionCheck', JSON.stringify({ 'apikey': response.data.apiKey }))
        .then(res => {
          var response = res.data;
          if (response.success && res.status == 200) {
            console.log(response_data);
            axios.post(global.restApiURL + 'customRest/sendOTP', JSON.stringify({
              'apikey': response_data.data.apiKey,
              'username': response_data.data.username
            })).then(async (data) => {
              if (data.data.success == true && data.status == 200) {
                console.log('OTP Sent Succesfully');
                sessionStorage.setItem('__TOKEN__', response_data.data.apiKey);
                sessionStorage.setItem('__CL_TOKEN__', response_data.data.classes_token);
                if (response_data.data.admin === true) {
                  sessionStorage.setItem('__Res__', 'admini');
                } else {
                  sessionStorage.setItem('__Res__', response_data.data.roles);
                }
                localStorage.setItem('username', response_data.data.username);
                sessionStorage.setItem('loggedinusername', response_data.data.full_name);
                sessionStorage.setItem('loggedinuseremail', response_data.data.email)
                sessionStorage.setItem('username', response_data.data.username);
                sessionStorage.setItem('loggedinUserID', response_data.data.id);
                sessionStorage.setItem('permissions', JSON.stringify(response_data.data.permissions));
                sessionStorage.setItem('verified', false);
                // const location = {
                //   pathname: '/Otp',
                //   state: {__TOKEN__: response_data.data.apiKey}
                // }
                // history.push(location);
                if (data.data.msg == 'Verified') {
                  sessionStorage.setItem('verified', true);
                  window.location.href = "/myclients";
                } else {
                  window.location.href = "/otp";
                }
              }
            }).catch(err => {
              context.setState({ loading: false, error: true });
              console.error(err);
              window.location.href = '/';
            });
            // window.location.href="/Otp";
          }else if ("error_code" in response) {
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            sessionStorage.setItem('error_msg', 'Invalid User. Contact Administrator.');
            window.location.href = '/';
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          context.setState({ loading: false, error: true });
          console.error(err);
        }
      );
    } else if ("error_code" in response) {
      console.log("Session Out2");
      console.log('response out', response.data.roles.length, response.data.admin,
        response.data.apiKey, response.data.full_name,
        response.data.id);
      window.location.href = global.domain + global.traversArray['out'];
    }else {
      sessionStorage.setItem('error_msg', 'Invalid User. Contact Administrator.');
      window.location.href = '/';
    }
  }

  render() {
    return (
      <div><Loader /></div>
    )
  }
}


export default CallbackToLogin