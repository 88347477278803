import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { newMultiSelectOptionGenerator } from "../ReusableComponents/OptionsGenerator";
import services from "../services";
import Patientinfoheader from "./Patientinfoheader";
import Sidenav from "./Sidenav";
import Topnav from "./Topnav";
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';


export default class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Enviornment: {
        enviornment_comments: "",
        indicate_living_arrangement: "",
        living_arrangement_comments: "",
        indicate_living_arrangement_other: "",
        number_of_years: "",
        number_of_months: "",
        current_living_enviornment: "",
        run_from_home: '',
        run_from_home_explain: '',
        employment_status: "",
        current_school_status: "",
        educational_status: "",
        educational_status_other_explain: "",
        years_schools: "",
        adl_ever_arrested: "",
        adl_ever_arrested_explain: "",
        arrested_in_30_days: "",
        how_many_times: "",

        involved_with_court: "",
        court_officer: "",
        registered_sex_offender: "",
        dependent_children: "",
        dependent_children_number: '',
        custody: "",
        child_care_available: "",
        reliable_transportation: "",
        reliable_transportation_explain: "",
        satisfied_with_support_system: "",
        recovery_risk_rating: "",
        recovery_mental_risk_rating: "",
        interaction: "",
        any_arrest: "",
        arrests: "",
        recovery_risk_rating_information: "",
        recovery_notes_comment: "",
        // New Field
        recovery_mental_risk_rating_information: "",
        recovery_mental_comments: "",
        form_type: props.form_type,
      },
      validation: {
        number_of_years_error: "",
        number_of_months_error: "",
      },
      dataListBody: {
        apikey: sessionStorage.getItem("__TOKEN__"),
        client_id: sessionStorage.getItem("clientId"),
        form_type: props.form_type,
        uploaded_by: sessionStorage.getItem("username"),
      },
      saved: true,
      isSaveAndComplete: false,
      rows: 3,
			minRows: 5,
			maxRows: 5,
      isRiskRatingMandatory : false,
      isRiskRatingMandatory2 : false
    };
  }
  recovery_risk_rating0 = `Supportive environment and or able to cope in environment.`
  recovery_risk_rating1 = `Passive support or significant others are not interested in patients' addiction recovery, but is not too distracted by this and is able to cope.`
  recovery_risk_rating2 = `The environment is not supportive of addiction recovery but, with clinical structure, able to cope most of the time.`;
  recovery_risk_rating3 = `The environment is not supportive of addiction recovery and the patient finds coping difficult, even with clinical structure.`;
  recovery_risk_rating4 = `Environment is chronically hostile & toxic to recovery. Clt. unable to cope with the negative effects of this environment on recovery, and the environment may pose a threat to the clt's safety.`;
 
  recovery_mental_risk_rating0 = `Has a supportive environment or is able to cope with poor support.`
  recovery_mental_risk_rating1 = `Has passive support or significant others are not interested in an improved MH environment. Client is not too distracted by the situation to be able to cope.`
  recovery_mental_risk_rating2 = `Environment is not supportive of good MH but with clinical structure the client is able to cope most of the time.`
  recovery_mental_risk_rating3 = `Environment is not supportive of good MH and client finds coping difficult even with clinical structure.`
  recovery_mental_risk_rating4 = `A. No immediate action required. Env is not supportive and is chronically hostile and toxic to good MH. Unable to cope w/neg. effects of env on their recovery. B. Immediate action required; Client unable to cope in environment.`

  async componentDidMount() {
    let recovery = await services.getOptionsfromClass(60);
    let living_arrangement_options = newMultiSelectOptionGenerator(recovery?.options?.living_arrangement);
    let current_employment_options = newMultiSelectOptionGenerator(recovery?.options?.current_employment_status);
    let educational_status_options = newMultiSelectOptionGenerator(recovery?.options?.educational_status);
    this.setState({ living_arrangements_options: living_arrangement_options, current_employment_status: current_employment_options, educational_status_options: educational_status_options });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if((this.state.Enviornment.enviornment_comments.length < 1 && this.state.Enviornment.recovery_risk_rating >= 2) || (this.state.Enviornment.recovery_mental_comments.length < 1 && this.state.Enviornment.recovery_mental_risk_rating >= 2)){
      alert("Risk rating summary field is mandatory.");
    }else{
      let final_data = {
        Enviornment: this.state.Enviornment,
        dataListBody: this.state.dataListBody,
        // form_type: this.props.form_type,
      };
      let res = await axios.post(
        global.restApiURL + "customRest/saveEnviornment",
        JSON.stringify(final_data)
      );
      if (res.data.success == true) {
        this.loadData();
        toast.success('Data added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_recovery',
        });
      }
    }
  };

  handleChange = (e) => {
    // debugger
    this.setState({ saved: false })
    if (e.target.name == "indicate_living_arrangement" && e.target.value == "other") {
      let Enviornments = this.state.Enviornment;
      Enviornments["indicate_living_arrangement_other"] = "";
      Enviornments["indicate_living_arrangement"] = "other";
    } else if (e.target.name == "indicate_living_arrangement" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["run_from_home_explain"] = "";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "current_school_status" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["educational_status"] = "";
      Enviornments["current_school_status"] = "No";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "adl_ever_arrested" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["adl_ever_arrested_explain"] = "";
      Enviornments["adl_ever_arrested"] = "No";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "arrested_in_30_days" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["how_many_times"] = "";
      Enviornments["arrested_in_30_days"] = "No";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "any_arrest" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["arrests"] = "";
      Enviornments["any_arrest"] = "No";
      this.setState({ Enviornment: Enviornments });
    }
    else if (e.target.name == "involved_with_court" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["court_officer"] = "";
      Enviornments["involved_with_court"] = "No";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "dependent_children" && e.target.value == "No") {
      let Enviornments = this.state.Enviornment;
      Enviornments["dependent_children_number"] = "";
      Enviornments["custody"] = "";
      Enviornments["child_care_available"] = "";
      Enviornments["dependent_children"] = "No";
      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "educational_status" && e.target.value !== "other") {
      let Enviornments = this.state.Enviornment;
      Enviornments.educational_status = e.target.value;
      Enviornments["educational_status_other_explain"] = "";

      this.setState({ Enviornment: Enviornments });
    } else if (e.target.name == "recovery_risk_rating") {
      let val = e.target.value
      let Enviornment = this.state.Enviornment;
      switch (val) {
        case "0":
          Enviornment.recovery_risk_rating_information = this.recovery_risk_rating0;
          Enviornment.recovery_risk_rating = 0;
          this.setState({ Enviornment: Enviornment });
          break;
        case "1":
          Enviornment.recovery_risk_rating_information = this.recovery_risk_rating1;
          Enviornment.recovery_risk_rating = 1;
          this.setState({ Enviornment: Enviornment });
          break;
        case "2":
          Enviornment.recovery_risk_rating_information = this.recovery_risk_rating2;
          Enviornment.recovery_risk_rating = 2;
          this.setState({ Enviornment: Enviornment });
          break;
        case "3":
          Enviornment.recovery_risk_rating_information = this.recovery_risk_rating3;
          Enviornment.recovery_risk_rating = 3;
          this.setState({ Enviornment: Enviornment });
          break;
        case "4":
          Enviornment.recovery_risk_rating_information = this.recovery_risk_rating4;
          Enviornment.recovery_risk_rating = 4;
          this.setState({ Enviornment: Enviornment });
          break;
      }
      this.props.riskRatingDecisionWithValue(e.target.value);
      if(e.target.value >= 2 && this.state.Enviornment.enviornment_comments.length < 1){
        this.setState({isRiskRatingMandatory : true });
      }else{
        this.setState({isRiskRatingMandatory : false });
      }
    }else if(e.target.name == "enviornment_comments"){
      let Enviornments = this.state.Enviornment;
      if(e.target.value.length < 1 && this.state.Enviornment.recovery_risk_rating >= 2){
        this.setState( {isRiskRatingMandatory : true});
      }else{
        this.setState( {isRiskRatingMandatory : false});
      }
      this.props.riskRatingDecisionWithComment(e.target.value);
      this.props.riskRatingDecisionWithValue(this.state.Enviornment.recovery_risk_rating);
      Enviornments["enviornment_comments"] = e.target.value;
    }else if (e.target.name == "recovery_mental_risk_rating") {
      let val = e.target.value
      let Enviornment = this.state.Enviornment;
      switch (val) {
        case "0":
          Enviornment.recovery_mental_risk_rating_information = this.recovery_mental_risk_rating0;
          Enviornment.recovery_mental_risk_rating = 0;
          this.setState({ Enviornment: Enviornment });
          break;
        case "1":
          Enviornment.recovery_mental_risk_rating_information = this.recovery_mental_risk_rating1;
          Enviornment.recovery_mental_risk_rating = 1;
          this.setState({ Enviornment: Enviornment });
          break;
        case "2":
          Enviornment.recovery_mental_risk_rating_information = this.recovery_mental_risk_rating2;
          Enviornment.recovery_mental_risk_rating = 2;
          this.setState({ Enviornment: Enviornment });
          break;
        case "3":
          Enviornment.recovery_mental_risk_rating_information = this.recovery_mental_risk_rating3;
          Enviornment.recovery_mental_risk_rating = 3;
          this.setState({ Enviornment: Enviornment });
          break;
        case "4":
          Enviornment.recovery_mental_risk_rating_information = this.recovery_mental_risk_rating4;
          Enviornment.recovery_mental_risk_rating = 4;
          this.setState({ Enviornment: Enviornment });
          break;
      }
      this.props.riskRatingDecisionWithValue2(e.target.value);
      if(e.target.value >= 2 && this.state.Enviornment.recovery_mental_comments.length < 1){
        this.setState({isRiskRatingMandatory2 : true });
      }else{
        this.setState({isRiskRatingMandatory2 : false });
      }
    }else if(e.target.name == 'recovery_mental_comments'){
      let Enviornments = this.state.Enviornment;
      this.props.riskRatingDecisionWithValue2(this.state.Enviornment.recovery_mental_risk_rating);
      this.props.riskRatingDecisionWithComment2(e.target.value);
      if(e.target.value.length < 1 && this.state.Enviornment.recovery_mental_risk_rating >= 2){
        this.setState( {isRiskRatingMandatory2 : true});
      }else{
        this.setState( {isRiskRatingMandatory2 : false});
      }
      Enviornments["recovery_mental_comments"] = e.target.value;
    }else if (e.target.name == "number_of_years" || e.target.name == "number_of_months") {
      // this is for Year and Month validation
      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        if (e.target.name == "number_of_years") {
          validation.number_of_years_error = "*Only numbers are allowed";
        } else {
          validation.number_of_months_error = "*Only numbers are allowed";
        }
        this.setState({ validation: validation })
      } else if (e.target.name == "number_of_months" && e.target.value > 12) {
        let validation = this.state.validation;
        validation.number_of_months_error = "Month should be in the range of 1-12";
        this.setState({ validation: validation })
      } else {
        let Enviornments = this.state.Enviornment;
        let validation = this.state.validation;
        if (e.target.name == "number_of_years") {
          validation.number_of_years_error = "";
        } else {
          validation.number_of_months_error = "";
        }
        Enviornments[e.target.name] = e.target.value;
        this.setState({ Enviornment: Enviornments, validation: validation });
      }
    }else {
      let Enviornments = this.state.Enviornment;
      if(e.target.name === 'reliable_transportation' && e.target.value === ""){
        Enviornments["reliable_transportation_explain"] = "";
      }
      Enviornments[e.target.name] = e.target.value;
      this.setState({ Enviornment: Enviornments });
    }
    setTimeout(() => {
      this.props.childToParent(this.state.Enviornment);
    }, 500)
  }

  loadData = async (e) => {
    if (!this.props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customrest/getEnviornment",
        JSON.stringify(this.state.dataListBody)
      );

      if (res.status == 200 && res.data.success == true) {
        var xyz = this.state.Enviornment;
        xyz.indicate_living_arrangement = res.data.data.Enviornment.indicate_living_arrangement;
        xyz.living_arrangement_comments = res.data.data.Enviornment.living_arrangement_comments;
        xyz.indicate_living_arrangement_other = res.data.data.Enviornment.indicate_living_arrangement_other;
        xyz.number_of_years = res.data.data.Enviornment.number_of_years;
        xyz.number_of_months = res.data.data.Enviornment.number_of_months;
        xyz.current_living_enviornment = res.data.data.Enviornment.current_living_enviornment;
        xyz.run_from_home = res.data.data.Enviornment.run_from_home;
        xyz.run_from_home_explain = res.data.data.Enviornment.run_from_home_explain;
        xyz.employment_status = res.data.data.Enviornment.employment_status;
        xyz.current_school_status = res.data.data.Enviornment.current_school_status;
        xyz.educational_status = res.data.data.Enviornment.educational_status;
        xyz.educational_status_other_explain = res.data.data.Enviornment.educational_status_other_explain;
        xyz.years_schools = res.data.data.Enviornment.years_schools;
        xyz.adl_ever_arrested = res.data.data.Enviornment.adl_ever_arrested;
        xyz.adl_ever_arrested_explain = res.data.data.Enviornment.adl_ever_arrested_explain;
        xyz.arrested_in_30_days = res.data.data.Enviornment.arrested_in_30_days;
        xyz.how_many_times = res.data.data.Enviornment.how_many_times;
        xyz.any_arrest = res.data.data.Enviornment.any_arrest;
        xyz.arrests = res.data.data.Enviornment.arrests;
        xyz.involved_with_court = res.data.data.Enviornment.involved_with_court;
        xyz.court_officer = res.data.data.Enviornment.court_officer;
        xyz.registered_sex_offender = res.data.data.Enviornment.registered_sex_offender;
        xyz.dependent_children = res.data.data.Enviornment.dependent_children;
        xyz.dependent_children_number = res.data.data.Enviornment.dependent_children_number;
        xyz.custody = res.data.data.Enviornment.custody;
        xyz.child_care_available = res.data.data.Enviornment.child_care_available;
        xyz.reliable_transportation = res.data.data.Enviornment.reliable_transportation;
        xyz.reliable_transportation_explain = res.data.data.Enviornment.reliable_transportation_explain;
        xyz.satisfied_with_support_system = res.data.data.Enviornment.satisfied_with_support_system;
        xyz.recovery_risk_rating = res.data.data.Enviornment.recovery_risk_rating;
        xyz.recovery_mental_risk_rating = res.data.data.Enviornment.recovery_mental_risk_rating;
        xyz.enviornment_comments = res.data.data.Enviornment.enviornment_comments;
        xyz.interaction = res.data.data.Enviornment.interaction;
        xyz.recovery_risk_rating_information = res.data.data.Enviornment.recovery_risk_rating_information;
        xyz.recovery_notes_comment = res.data.data.Enviornment.recovery_notes_comment;
        // New Field
        xyz.recovery_mental_risk_rating_information = res.data.data.Enviornment.recovery_mental_risk_rating_information;
        xyz.recovery_mental_comments = res.data.data.Enviornment.recovery_mental_comments;

        this.setState({ Enviornment: xyz });
        this.setState({ saved: true })

        setTimeout(() => {
          this.props.childToParent(this.state.Enviornment);
        }, 500)
      }
    } else {
      let res = await axios.post(
        global.restApiURL + "customrest/getEnviornmentTemp",
        JSON.stringify(this.state.dataListBody)
      );

      if (res.status == 200 && res.data.success == true) {
        var xyz = this.state.Enviornment;
        xyz.indicate_living_arrangement = res.data.data.Enviornment.indicate_living_arrangement;
        xyz.living_arrangement_comments = res.data.data.Enviornment.living_arrangement_comments;
        xyz.indicate_living_arrangement_other = res.data.data.Enviornment.indicate_living_arrangement_other;
        xyz.number_of_years = res.data.data.Enviornment.number_of_years;
        xyz.number_of_months = res.data.data.Enviornment.number_of_months;
        xyz.current_living_enviornment = res.data.data.Enviornment.current_living_enviornment;
        xyz.run_from_home = res.data.data.Enviornment.run_from_home;
        xyz.run_from_home_explain = res.data.data.Enviornment.run_from_home_explain;
        xyz.employment_status = res.data.data.Enviornment.employment_status;
        xyz.current_school_status = res.data.data.Enviornment.current_school_status;
        xyz.educational_status = res.data.data.Enviornment.educational_status;
        xyz.educational_status_other_explain = res.data.data.Enviornment.educational_status_other_explain;
        xyz.years_schools = res.data.data.Enviornment.years_schools;
        xyz.adl_ever_arrested = res.data.data.Enviornment.adl_ever_arrested;
        xyz.adl_ever_arrested_explain = res.data.data.Enviornment.adl_ever_arrested_explain;
        xyz.arrested_in_30_days = res.data.data.Enviornment.arrested_in_30_days;
        xyz.how_many_times = res.data.data.Enviornment.how_many_times;
        xyz.any_arrest = res.data.data.Enviornment.any_arrest;
        xyz.arrests = res.data.data.Enviornment.arrests;
        xyz.involved_with_court = res.data.data.Enviornment.involved_with_court;
        xyz.court_officer = res.data.data.Enviornment.court_officer;
        xyz.registered_sex_offender = res.data.data.Enviornment.registered_sex_offender;
        xyz.dependent_children = res.data.data.Enviornment.dependent_children;
        xyz.dependent_children_number = res.data.data.Enviornment.dependent_children_number;
        xyz.custody = res.data.data.Enviornment.custody;
        xyz.child_care_available = res.data.data.Enviornment.child_care_available;
        xyz.reliable_transportation = res.data.data.Enviornment.reliable_transportation;
        xyz.reliable_transportation_explain = res.data.data.Enviornment.reliable_transportation_explain;
        xyz.satisfied_with_support_system = res.data.data.Enviornment.satisfied_with_support_system;
        xyz.recovery_risk_rating = res.data.data.Enviornment.recovery_risk_rating;
        xyz.recovery_mental_risk_rating = res.data.data.Enviornment.recovery_mental_risk_rating;
        xyz.enviornment_comments = res.data.data.Enviornment.enviornment_comments;
        xyz.interaction = res.data.data.Enviornment.interaction;
        xyz.recovery_risk_rating_information = res.data.data.Enviornment.recovery_risk_rating_information;
        xyz.recovery_notes_comment = res.data.data.Enviornment.recovery_notes_comment;
        // New Field
        xyz.recovery_mental_risk_rating_information = res.data.data.Enviornment.recovery_mental_risk_rating_information;
        xyz.recovery_mental_comments = res.data.data.Enviornment.recovery_mental_comments;
        this.setState({ Enviornment: xyz, isSaveAndComplete: true });
        this.setState({ saved: true })
      }
    }
  };

  reSization=()=>{
    let Enviornment = this.state.Enviornment;
    this.setState({Enviornment : Enviornment});
  }

  async componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={this.handleSubmit}>
                  <div className="row"  onMouseMove={this.reSization}>
                      <div className="col-md-12">
                        <div className="card shadow-none">
                          <div>
                            <p className="lead float-left" style={{ marginBottom: '1rem' }}>
                              <b className="text-green">
                                Recovery/living environment
                              </b>
                            </p>
                          </div>
                          <div>
                            <div class="row marginTop2">
                              <div className="col-md-12 form-group">
                                <strong>LIVING ARRANGEMENT:</strong>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>
                                  Please indicate your living arrangement
                                </label>{" "}
                              </div>
                              <div className="col-md-6">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="indicate_living_arrangement" value={this.state.Enviornment.indicate_living_arrangement} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="alabama_housing_finance_authority">Alabama Housing Finance Authority</option>
                                  <option value="center_residential_program">Center Operated/Contracted Residential Program</option>
                                  <option value="center_subsidized_housing">Center Subsidized Housing</option>
                                  <option value="homeless_shelter">Homeless/Shelter</option>
                                  {this.props.form_type == "Adult_Assessment" ? <option value="Client_lives_alone">Client Lives Alone AND is 18 + (Independent)</option> : <option value="resides_with_family">Resides with Family</option>}
                                  <option value="jail_correctional_facility">Jail/Correctional Facility</option>
                                  <option value="group_home_or_residential_setting">Group Home or Residential Setting</option>
                                  <option value="other">Other</option>
                                </select>
                              </div>

                              {this.state.Enviornment.indicate_living_arrangement == "other" && <>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Please explain</label>
                                    <TextareaAutosize 
                                      className="form-control" 
                                      rows={2} 
                                      style={{overflow:'hidden'}} 
                                      disabled={this.state.isSaveAndComplete} 
                                      value={this.state.Enviornment.indicate_living_arrangement_other} 
                                      name="indicate_living_arrangement_other" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </div>
                              </>}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Comments</label>
                                  <TextareaAutosize className="form-control" 
                                    disabled={this.state.isSaveAndComplete} 
                                    minRows={(this.state.Enviornment.living_arrangement_comments?.length/110) + this.state.Enviornment.living_arrangement_comments?.split(/\r\n|\r|\n/).length}
                                    value={this.state.Enviornment.living_arrangement_comments} 
                                    name="living_arrangement_comments" 
                                    style={{overflow:"hidden"}}
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                How long have you lived here?
                              </div>
                              <div className="col-md-2">
                                <input type="text" name="number_of_years" disabled={this.state.isSaveAndComplete} className="form-control" placeholder="years" value={this.state.Enviornment.number_of_years} onChange={this.handleChange} maxLength={4} />
                                <span style={{ color: "red" }}>{this.state.validation.number_of_years_error}</span>
                                {/* // number_of_years_error:"",
                                // number_of_months_error: "", */}
                              </div>
                              <div className="col-md-2">
                                <input type="text" name="number_of_months" disabled={this.state.isSaveAndComplete} className="form-control" placeholder="months" value={this.state.Enviornment.number_of_months} onChange={this.handleChange} maxLength={2} />
                                <span style={{ color: "red" }}>{this.state.validation.number_of_months_error}</span>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Is your current living environment drug and alcohol free? </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.Enviornment.current_living_enviornment} name="current_living_enviornment" onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                            {this.props.form_type == "Adolescent_Assessment" &&
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>Have you ever run away from home?</label>{" "}
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="run_from_home" value={this.state.Enviornment.run_from_home} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {this.state.Enviornment.run_from_home == "Yes" && <>
                                  <div className="col-md-4">
                                    <TextareaAutosize 
                                      minRows={(this.state.Enviornment.run_from_home_explain?.length/33) + this.state.Enviornment.run_from_home_explain?.split().length}
                                      style={{overflow:'hidden'}}  
                                      placeholder="Please explain" 
                                      disabled={this.state.isSaveAndComplete} 
                                      value={this.state.Enviornment.run_from_home_explain} 
                                      className="form-control" name="run_from_home_explain" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                            }
                            <div className="row marginTop2">
                              <div className="col-md-12 form-group">
                                <strong>CURRENT EMPLOYMENT STATUS:</strong>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Please indicate your current employment status</label>{" "}
                              </div>
                              <div className="col-md-6">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="employment_status" value={this.state.Enviornment.employment_status} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="confined_to_institution_correctional_facility">Confined to Institution/Correctional Facility</option>
                                  <option value="disabled">Disabled</option>
                                  <option value="full_time">Full-time</option>
                                  <option value="homemaker">Homemaker</option>
                                  <option value="part_time">Part-time</option>
                                  <option value="retired">Retired</option>
                                  <option value="student">Student</option>
                                  <option value="supported_employment">Supported Employment</option>
                                  <option value="unemployed_looking">Unemployed, looking</option>
                                  <option value="unemployed_not_looking">Unemployed, not looking for 30 days</option>
                                </select>
                              </div>
                            </div>
                            {this.props.form_type == "Adult_Assessment" &&
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>What is your educational status?</label>{" "}
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="educational_status" value={this.state.Enviornment.educational_status} onChange={this.handleChange}>
                                    <option value="none">None</option>
                                    <option value="student">Student</option>
                                    <option value="graduated_hs">Graduated HS</option>
                                    <option value="ged">GED</option>
                                    <option value="dropped_out">Dropped Out</option>
                                    <option value="college_graduate">College Graduate</option>
                                    <option value="some_education_after_hs">Some education after HS</option>
                                    <option value="other">Other</option>
                                  </select>
                                </div>

                                {this.state.Enviornment.educational_status == "other" && <>
                                  <div className="col-md-4">
                                    <TextareaAutosize 
                                      rows={1} 
                                      disabled={this.state.isSaveAndComplete} 
                                      placeholder="Please explain" 
                                      className="form-control" 
                                      type="text"
                                      style={{overflow:'hidden'}}  
                                      value={this.state.Enviornment.educational_status_other_explain} 
                                      name="educational_status_other_explain" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                            }

                            {this.props.form_type == "Adolescent_Assessment" &&
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>Are you currently in school, enrolled in a GED program or a vocational program? </label>{ }
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="current_school_status" value={this.state.Enviornment.current_school_status} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {this.state.Enviornment.current_school_status == "Yes" && <>
                                  <div className="col-md-2">
                                    <select className="form-control" disabled={this.state.isSaveAndComplete} name="educational_status" value={this.state.Enviornment.educational_status} onChange={this.handleChange}>
                                      <option value="">Select</option>
                                      <option value="none">None</option>
                                      <option value="graduated_hs">Graduated HS</option>
                                      <option value="ged">GED</option>
                                      <option value="drroped_out">Drroped out</option>
                                      <option value="some_education_after_hs">Some education after HS</option>
                                      <option value="other_explain">Other : explain</option>
                                      {/* {this.state.educational_status_options.length > 0 && this.state?.educational_status_options?.map(option => (
                                        <option value={option.value}>{option.label}</option>
                                     ))} */}
                                    </select>
                                  </div>
                                </>}
                                {/* {this.state.Enviornment.educational_status == "other_explain" && <>
                                  <div className="col-md-2">
                                    <input className="form-control" disabled={this.state.isSaveAndComplete} placeholder="Please explain" type="text" value={this.state.Enviornment.educational_status_other_explain} name="educational_status_other_explain" onChange={this.handleChange} />
                                  </div>
                                </>} */}
                                {this.state.Enviornment.educational_status == "other_explain" && <>
                                  <div className="col-md-2">
                                    <TextareaAutosize
                                      style={{ overflow: "hidden" }}
                                      minRows={(this.state.Enviornment.educational_status_other_explain?.length / 9) + this.state.Enviornment.educational_status_other_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control" disabled={this.state.isSaveAndComplete}
                                      placeholder="Please explain"
                                      type="text"
                                      value={this.state.Enviornment.educational_status_other_explain}
                                      name="educational_status_other_explain"
                                      onChange={this.handleChange}
                                    />
                                  </div>
                                </>}
                              </div>
                            }
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label> Number of years completed in school</label>
                              </div>
                              <div className="col-md-2">
                                <input className="form-control" type="text" disabled={this.state.isSaveAndComplete} value={this.state.Enviornment.years_schools} name="years_schools" onChange={this.handleChange} />
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Have you ever been arrested?</label>
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} value={this.state.Enviornment.adl_ever_arrested} name="adl_ever_arrested" onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {this.state.Enviornment.adl_ever_arrested == "Yes" && <>
                                <div className="col-md-4">
                                  <TextareaAutosize
                                    style={{overflow:'hidden'}} 
                                    minRows={(this.state.Enviornment.adl_ever_arrested_explain?.length/30) + this.state.Enviornment.adl_ever_arrested_explain?.split(/\r\n|\r|\n/).length}
                                    placeholder="Please explain" 
                                    disabled={this.state.isSaveAndComplete} 
                                    value={this.state.Enviornment.adl_ever_arrested_explain} 
                                    name="adl_ever_arrested_explain" 
                                    className="form-control" 
                                    onChange={this.handleChange} 
                                  />
                                </div>
                              </>}
                            </div>
                            {/* Ashraf start */}
                            {/* <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Are you currently in school, enrolled in a GED program or a vocational program? xxxx </label>{this.state.current_school_status}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" name="current_school_status"  value={this.state.Enviornment.current_school_status} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>

                              {this.state.current_school_status == "Yes" && <>
                                <div className="col-md-4">
                                  <TextareaAutosize rows={1} placeholder="Please explain" name="adl_ever_arrested_explain" className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div> */}
                            {/* Ashraf End */}
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>
                                  Have you been arrested in the last 30 days?
                                </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="arrested_in_30_days" value={this.state.Enviornment.arrested_in_30_days} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {this.state.Enviornment.arrested_in_30_days == "Yes" && <>
                                <div className="col-md-2">
                                  <label>How many times?</label>
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="how_many_times" value={this.state.Enviornment.how_many_times} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                  </select>
                                </div>
                              </>}
                            </div>

                            {this.props.form_type == "Adolescent_Assessment" &&
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>Have you had any alcohol or drug related arrests?</label>
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="any_arrest" value={this.state.Enviornment.any_arrest} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {this.state.Enviornment.any_arrest == "Yes" && <>
                                  <div className="col-md-4">
                                    <TextareaAutosize
                                      style={{overflow:'hidden'}} 
                                      minRows={(this.state.Enviornment.arrests?.length/32) + this.state.Enviornment.arrests?.split(/\r\n|\r|\n/).length}
                                      placeholder="Please explain" 
                                      disabled={this.state.isSaveAndComplete} 
                                      value={this.state.Enviornment.arrests} 
                                      className="form-control" name="arrests" 
                                      onChange={this.handleChange} 
                                    />
                                  </div>
                                </>}
                              </div>
                            }
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Are you currently involved with the court or a court officer?</label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="involved_with_court" value={this.state.Enviornment.involved_with_court} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {/* {this.state.Enviornment.involved_with_court == "Yes" && <>
                                <div className="col-md-2">
                                  <label>Who and why?</label>
                                </div>
                                <div className="col-md-2">
                                  <input className="form-control" disabled={this.state.isSaveAndComplete} type="text" value={this.state.Enviornment.court_officer} name="court_officer" onChange={this.handleChange} />
                                </div>
                              </>} */}

                              {this.state.Enviornment.involved_with_court == "Yes" && <>
                                <div className="col-md-4">
                                  <TextareaAutosize 
                                    style={{overflow:'hidden'}}
                                    minRows={(this.state.Enviornment.court_officer?.length/30) + this.state.Enviornment.court_officer?.split(/\r\n|\r|\n/).length}
                                    placeholder="Please explain" 
                                    disabled={this.state.isSaveAndComplete} 
                                    value={this.state.Enviornment.court_officer} 
                                    name="court_officer" 
                                    className="form-control" 
                                    onChange={this.handleChange} 
                                  />
                                </div>
                              </>}
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Are you a registered sex offender?</label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="registered_sex_offender" value={this.state.Enviornment.registered_sex_offender} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>How satisfied are you with your support system? </label>{" "}
                              </div>
                              <div className="col-md-12">
                                {/* <input className="form-control" disabled={this.state.isSaveAndComplete} type="text" name="satisfied_with_support_system" value={this.state.Enviornment.satisfied_with_support_system} onChange={this.handleChange} /> */}
                                <TextareaAutosize
                                    minRows={(this.state.Enviornment.satisfied_with_support_system?.length/110) + this.state.Enviornment.satisfied_with_support_system?.split(/\r\n|\r|\n/).length}
                                    value={this.state.Enviornment.satisfied_with_support_system} 
                                    style={{overflow:"hidden"}}
                                    disabled={this.state.isSaveAndComplete} 
                                    placeholder={'Please explain'}
                                    className={'form-control'}
                                    name="satisfied_with_support_system"
                                    onChange={this.handleChange}
                                  />
                              
                              </div>
                            </div>
                            {/*  */}

                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Do you have dependent children?</label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="dependent_children" value={this.state.Enviornment.dependent_children} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {this.state.Enviornment.dependent_children == "Yes" && <>
                                <div className="col-md-2">
                                  <label>How many?</label>
                                </div>
                                <div className="col-md-2">
                                  <input type="text" disabled={this.state.isSaveAndComplete} name="dependent_children_number" value={this.state.Enviornment.dependent_children_number} className="form-control" onChange={this.handleChange} />
                                </div>
                              </>}
                            </div>
                            {this.state.Enviornment.dependent_children == "Yes" && <>
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>Who has custody of these children? (if applicable)</label>{" "}
                                </div>
                                <div className="col-md-12">
                                  {/* <input className="form-control" disabled={this.state.isSaveAndComplete} type="text" name="custody" value={this.state.Enviornment.custody} onChange={this.handleChange} /> */}
                                  <TextareaAutosize
                                    minRows={(this.state.Enviornment.custody?.length/110) + this.state.Enviornment.custody?.split(/\r\n|\r|\n/).length}
                                    style={{overflow:'hidden'}}
                                    value={this.state.Enviornment.custody} 
                                    disabled={this.state.isSaveAndComplete} 
                                    placeholder={'Please explain'}
                                    className={'form-control'}
                                    name="custody"
                                    onChange={this.handleChange}
                                  />
                               
                                </div>
                              </div>
                              <div className="row marginBottom1">
                                <div className="col-md-6">
                                  <label>Is childcare available for these children? (if applicable) </label>{" "}
                                </div>
                                <div className="col-md-2">
                                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="child_care_available" value={this.state.Enviornment.child_care_available} onChange={this.handleChange} >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </div>
                            </>}
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>What is the quality of interaction with your family?</label>{" "}
                              </div>
                              {/* <div className="col-md-2">
                                <input className="form-control" 
                                disabled={this.state.isSaveAndComplete} 
                                type="text" name="interaction" 
                                value={this.state.Enviornment.interaction} 
                                onChange={this.handleChange} 
                                /> */}
                                <div className="col-md-12 form-group">
                                  <TextareaAutosize
                                    minRows={(this.state.Enviornment.interaction?.length/110) + this.state.Enviornment.interaction?.split(/\r\n|\r|\n/).length}
                                    value={this.state.Enviornment.interaction} 
                                    style={{overflow:"hidden"}}
                                    disabled={this.state.isSaveAndComplete} 
                                    placeholder={'Please explain'}
                                    className={'form-control'}
                                    name="interaction"
                                    onChange={this.handleChange}
                                  />  
                              </div>
                            </div>
                            {/*  */}
                            <div className="row marginBottom1">
                              <div className="col-md-6">
                                <label>Do you have reliable transportation? </label>{" "}
                              </div>
                              <div className="col-md-2">
                                <select className="form-control" disabled={this.state.isSaveAndComplete} name="reliable_transportation" value={this.state.Enviornment.reliable_transportation} onChange={this.handleChange}>
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {this.state.Enviornment.reliable_transportation === '' ? "" :
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <TextareaAutosize
                                      className="form-control"
                                      disabled={this.state.isSaveAndComplete}
                                      rows={2}
                                      placeholder='Please explain'
                                      style={{ overflow: "hidden" }}
                                      minRows={(this.state.Enviornment.reliable_transportation_explain?.length / 41) + this.state.Enviornment.reliable_transportation_explain?.split(/\r\n|\r|\n/).length}
                                      value={this.state.Enviornment.reliable_transportation_explain}
                                      name="reliable_transportation_explain"
                                      onChange={this.handleChange}
                                      required={true}
                                    />
                                    {this.state.Enviornment.reliable_transportation !== "" && this.state.Enviornment.reliable_transportation_explain === "" ? <span style={{ color: 'red' }}>&nbsp; This field is mandatory *.</span> : ""}
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-md-12">
                                <div className='form-group'>
                                  <label>Notes or Comments</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    minRows={(this.state.Enviornment.recovery_notes_comment?.length/110) + this.state.Enviornment.recovery_notes_comment?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} 
                                    name="recovery_notes_comment" 
                                    onChange={this.handleChange} 
                                    style={{overflow:"hidden"}} 
                                    value={this.state.Enviornment.recovery_notes_comment}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row marginBottom1">
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row marginBottom1">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Substance Use Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label0" type="radio" name="recovery_risk_rating" value="0" checked={this.state.Enviornment.recovery_risk_rating == "0" ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa1" className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label1" type="radio" name="recovery_risk_rating" value="1" checked={this.state.Enviornment.recovery_risk_rating == 1 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa1" className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label2" type="radio" name="recovery_risk_rating" value="2" checked={this.state.Enviornment.recovery_risk_rating == 2 ? true : false} onChange={this.handleChange} />{" "}&nbsp;
                                        <label for="sa2" className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label3" type="radio" name="recovery_risk_rating" value="3" checked={this.state.Enviornment.recovery_risk_rating == 3 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa3" className="labelMargin">3</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label4" type="radio" name="recovery_risk_rating" value="4" checked={this.state.Enviornment.recovery_risk_rating == 4 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="sa4" className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="green_band">
                                  <div className="row marginBottom1">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Mental Health Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label5" type="radio" name="recovery_mental_risk_rating" value="0" checked={this.state.Enviornment.recovery_mental_risk_rating == "0" ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating1" className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label6" type="radio" name="recovery_mental_risk_rating" value="1" checked={this.state.Enviornment.recovery_mental_risk_rating == 1 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating1" className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label7" type="radio" name="recovery_mental_risk_rating" value="2" checked={this.state.Enviornment.recovery_mental_risk_rating == 2 ? true : false} onChange={this.handleChange} />{" "}&nbsp;
                                        <label for="recovery_mental_risk_rating2" className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label8" type="radio" name="recovery_mental_risk_rating" value="3" checked={this.state.Enviornment.recovery_mental_risk_rating == 3 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating3" className="labelMargin" >3</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label9" type="radio" name="recovery_mental_risk_rating" value="4" checked={this.state.Enviornment.recovery_mental_risk_rating == 4 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating4" className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <ReactTooltip id="label0" place="bottom" effect="solid" className="toolTipLabel0">{this.recovery_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label1" place="bottom" effect="solid" className="toolTipLabel1">{this.recovery_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label2" place="bottom" effect="solid" className="toolTipLabel2">{this.recovery_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label3" place="bottom" effect="solid" className="toolTipLabel3">{this.recovery_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label4" place="bottom" effect="solid" className="toolTipLabel4" >{this.recovery_risk_rating4}</ReactTooltip>

                            <ReactTooltip id="label5" place="bottom" effect="solid" className="toolTipLabel0">{this.recovery_mental_risk_rating0}</ReactTooltip>
                            <ReactTooltip id="label6" place="bottom" effect="solid" className="toolTipLabel1">{this.recovery_mental_risk_rating1}</ReactTooltip>
                            <ReactTooltip id="label7" place="bottom" effect="solid" className="toolTipLabel2">{this.recovery_mental_risk_rating2}</ReactTooltip>
                            <ReactTooltip id="label8" place="bottom" effect="solid" className="toolTipLabel3">{this.recovery_mental_risk_rating3}</ReactTooltip>
                            <ReactTooltip id="label9" place="bottom" effect="solid" className="toolTipLabel4" >{this.recovery_mental_risk_rating4}</ReactTooltip>


                            <div className="row">
                              <div className='col-md-12'>
                                Diagnostic summary for this dimension
                              </div>
                              <hr />
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Analysis</label>
                                  <TextareaAutosize 
                                    className="form-control" 
                                    minRows={(this.state.Enviornment.recovery_risk_rating == "" || this.state.Enviornment.recovery_risk_rating == 0) ? 1 : 2}
                                    name='recovery_risk_rating_information' 
                                    value={this.state.Enviornment.recovery_risk_rating_information} 
                                    readOnly 
                                    style={{ backgroundColor: '#FEE5A1', overflow:'hidden'}} />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize 
                                    className="form-control"
                                    minRows={(this.state.Enviornment.enviornment_comments?.length/110) + this.state.Enviornment.enviornment_comments?.split(/\r\n|\r|\n/).length}
                                    disabled={this.state.isSaveAndComplete} value={this.state.Enviornment.enviornment_comments} 
                                    name="enviornment_comments" 
                                    style={{overflow:"hidden"}} 
                                    onChange={this.handleChange}
                                  />
                                  {this.state.isRiskRatingMandatory && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="green_band">
                                  <div className="row marginBottom1">
                                    <div className="col-md-4">
                                      <p className="lead sa_risk">
                                        <b>Mental Health Risk Rating </b>
                                        <span className="risk_rating"></span>
                                      </p>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="form-group ml-0 pl-0">
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label5" type="radio" name="recovery_mental_risk_rating" value="0" checked={this.state.Enviornment.recovery_mental_risk_rating == "0" ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating1" className="labelMargin">0</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label6" type="radio" name="recovery_mental_risk_rating" value="1" checked={this.state.Enviornment.recovery_mental_risk_rating == 1 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating1" className="labelMargin">1</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label7" type="radio" name="recovery_mental_risk_rating" value="2" checked={this.state.Enviornment.recovery_mental_risk_rating == 2 ? true : false} onChange={this.handleChange} />{" "}&nbsp;
                                        <label for="recovery_mental_risk_rating2" className="labelMargin">2</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label8" type="radio" name="recovery_mental_risk_rating" value="3" checked={this.state.Enviornment.recovery_mental_risk_rating == 3 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating3" className="labelMargin" >3</label>
                                        <input className="autoMargin" disabled={this.state.isSaveAndComplete} data-tip data-for="label9" type="radio" name="recovery_mental_risk_rating" value="4" checked={this.state.Enviornment.recovery_mental_risk_rating == 4 ? true : false} onChange={this.handleChange} />&nbsp;
                                        <label for="recovery_mental_risk_rating4" className="labelMargin">4</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Analysis</label>
                                  <TextareaAutosize 
                                    className="form-control"
                                    minRows={this.state.Enviornment.recovery_mental_risk_rating == 1 ? 2 : this.state.Enviornment.recovery_mental_risk_rating == 4 ? 3 : 1}
                                    style={{ backgroundColor: '#FEE5A1', overflow:'hidden'}}
                                    name='recovery_mental_risk_rating_information' 
                                    value={this.state.Enviornment.recovery_mental_risk_rating_information} 
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className='form-group'>
                                  <label>Risk Rating Summary</label>
                                  <TextareaAutosize className="form-control" 
                                    minRows={(this.state.Enviornment.recovery_mental_comments?.length/110) + this.state.Enviornment.recovery_mental_comments?.split(/\r\n|\r|\n/).length} 
                                    disabled={this.state.isSaveAndComplete} 
                                    style={{overflow:"hidden"}} 
                                    value={this.state.Enviornment.recovery_mental_comments} 
                                    name="recovery_mental_comments" 
                                    onChange={this.handleChange}
                                  />
                                  {this.state.isRiskRatingMandatory2 && <> <span style={{color:"red"}}>This field is mandatory.*</span></>}
                                </div>
                              </div>
                              {/*  */}
                            </div>
                            <div className='row'>
                              <div className='col-md-12'>
                                <button type="submit" data-save="save" disabled={this.state.saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
