import React , {Component} from 'react';
import '../global.js';

import $ from 'jquery';
import Modal from './Modals/Modal/index';
import {validationParser1} from '../ReusableComponents/validationParser' ;
import {formObjDataMapper, formObjDataMapper2} from '../ReusableComponents/formObjDataMapper' ;
import Select from 'react-select';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator' ;
import moment from 'moment-timezone';
import { encryptStorage } from './LocalStorageInterceptor.js';  
//import "../LocalStorageInterceptor"

var validationObj = {} ;
var context ;

var prevExist = false;

const axios = require('axios').default;

class Patientinfoheader extends Component{
	constructor(){
    super();
    context = this;
    this.state = {
      status:{},
      comment:{},
      showStatusChangeButton:false,
      showStatusModal: false,
      statusOptions:[],
      statusData : this.getModal("statusData"),
      interviewers:[],
      role: 'admin',
    }
  }

  getModal( type )
    {
        let dataModel = '' ;
        switch(type)
        {
            case "statusData" :
                dataModel = {
                    currentStatus : '',
                    newStatus : '',
                    comment: '',
                    updated_on: '',
                }
            break;

            case "resetData" :
                dataModel = {
                    currentStatus : '',
                    newStatus : '',
                    comment: '',
                    updated_on: '',
                }
            break;
          }

      return dataModel;
    }

  componentWillMount()
     {
        if(this.state.role !== 'staff' && this.props.dashboard){
         // for fetching layout definition
        
        axios.get(global.restApiURL+'webservice/rest/class/id/46?apikey='+sessionStorage.getItem('__CL_TOKEN__'))
        .then(res => {
          var response = res.data;   

          if(response.success && res.status==200)
          {
              validationObj = validationParser1(response.data.layoutDefinitions.childs[0].childs);
              console.log("validation : " , validationObj) ;

              this.setState({
                  ...validationObj
              });
              this.createSelectOptions();
          }
          else if ( "error_code" in response )
          {
              alert( response.msg ) ;
              window.location.href = global.domain+global.traversArray['out'];
          }
          else
          {
              alert( response.msg ) ;
          }
          
          })
          .catch(err => { // log request error and prevent access to undefined state
              this.setState({ loading: false, error: true });
              console.error(err); 
          });
      }
     }

  createSelectOptions()
  {
    if(validationObj !== "")
    {
      this.setState({

          //Select options
          statusSelectOptions : selectOptionsGenerator( validationObj.status.options , context.state.statusData.status ),
          
        });

    }
  }

  componentDidMount()
  {
    if(this.props.onRef){ this.props.onRef(this) }
    
    sessionStorage.setItem('ClientStatusresponceId','');
   // for client info
   if(sessionStorage.getItem('clientId')!=='' && this.props.dashboard){
    axios.get(global.restApiURL+'webservice/rest/object-list?apikey='+sessionStorage.getItem('__TOKEN__')+'&limit=1&objectClass=ClientInformation&q={"client_id":"'+sessionStorage.getItem('clientId')+'"}')
    .then(res => {
      const responce = res.data.data; // get the data array instead of object

        if(responce[0].id!==''){
          console.log();
          prevExist = true;
            axios.get(global.restApiURL+'webservice/rest/object/id/'+responce[0].id+'?apikey='+sessionStorage.getItem('__TOKEN__'))
            .then(res => {

              sessionStorage.setItem('ClientStatusresponceId',res.data.data.id);
              const responce = res.data.data.elements;
              
              this.datamapper(responce)    

              
            })
            .catch(err => { // log request error and prevent access to undefined state
              this.setState({ loading: false, error: true });
              console.error(err); 
            })
        }else{
          prevExist = false;
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err); 
      })


   
    }
                  
  }

  datamapper(arr){
      //console.log(arr);
       let obj= {                
              currentStatus : '',
            }
        $.each(arr, function(index, value){
                //console.log(value.name+"==="+value.value)
                  if(value.name==='client_status'){
                    obj.currentStatus= (value.value).trim();
                  }

                  if(value.name==='last_updated_on'){
                    obj.updated_on= moment(value.value).format('MM/DD/YYYY hh:mm a');
                    
                  }
                           
                 
              });            
        
        this.setState({
          statusData : obj
        });
      
      this.createSelectOptions();

  }

    

  closeStatusModal = () => {
    this.setState({showStatusModal : false})
  }

  showStatusModal = () => {
    this.setState({showStatusModal : true})
  }

  handleStatusSelectChange( event )
    {
        console.log("selectchange : " , event.target.name);
        let name = event.target.name ;
        if(name === 'status'){
          name = 'newStatus';
        }
        let value = event.target.value ;

            context.setState((prevState) => ({
                ...prevState,
                statusData : {
                    ...prevState.statusData,
                    [name] : value
                }
            }));
        
    }

    saveStatus = () =>
      {

        if(sessionStorage.getItem('clientId')!=='')
        {
            /*let saveType =  event.target ;
            console.log( "save : " , saveType );*/
          let jsonData = '';
           
          jsonData = {
                apikey: sessionStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
                client_status: context.state.statusData.newStatus,
                old_status: context.state.statusData.currentStatus,
                comment: context.state.statusData.comment,
                last_updated_by: sessionStorage.getItem('loggedinUserID'),
                last_updated_on: moment().format('YYYY-MM-DD HH:mm') ,
                o_id: sessionStorage.getItem('ClientStatusresponceId') ,
              } ;

            
            var json =  JSON.stringify(jsonData);
            
            console.log("Data to send : " , json) ;

            //context.onModalClose();
                
            axios.post(global.restApiURL + 'customRest/clientUpdateStatus', json)
                .then(function (res) {            
            var response = res.data;
            // console.log("save response" , response.success );
            if( response.success && res.status==200 )
            {
                sessionStorage.setItem('success_msg',"Status updated successfully.");
                sessionStorage.setItem('client_status',context.state.statusData.newStatus);
                context.closeStatusModal();
                window.location.href= "";
            }
            else if ( "error_code" in response )
            {
                alert( response.msg ) ;
                window.location.href = global.domain+global.traversArray['out'];
            }
            else
            {
                sessionStorage.setItem('error_msg',"Error occured while updating Status");
                context.closeStatusModal();

            }

                console.log("Msg : " , sessionStorage.getItem( 'success_msg' ) );    
            })
            .catch(function (err) {
                alert(err.message);
            });

        }  

      }

  render () {

    $(document).ready(function () {
        
      $('#statusForm').validate({

      submitHandler: function() {
          context.saveStatus();
        },
           
      errorElement: 'span',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-group').append(error);
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass('is-invalid');
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('is-invalid');
      }
    });
    });

    let showStatusChangeButton = this.state.showStatusChangeButton;
    let statusOptions = this.state.statusOptions;

    if(((global.is_admin || global.is_super_admin) && global.is_client_status_waiting_for_admin_approval) || (!global.is_staff && global.is_client_inactive && !global.is_client_status_waiting_for_admin_approval) && this.state.statusData.currentStatus !== '') //roles are valid + staff not allowed to update the status + Update status only in case if client is suspended or Inactive
    {
      //let statusOptions = [];
      //this.setState({showStatusChangeButton : true});
      showStatusChangeButton = true;

      //status options for Admin and others
      if(global.is_admin || global.is_super_admin ){
        statusOptions = [<option value=""></option>, <option value="Active" > Active</option> , <option value="Inactive" > Inactive</option>, <option value="Suspended" > Suspended</option>]
      }else{
        if(this.state.statusData.currentStatus === 'Suspended'){
          statusOptions = [<option value=""></option>,<option value="Activate" > Request Admin to Activate</option> , <option value="Inactivate" > Request Admin to Inactivate</option>]
        }else if(this.state.statusData.currentStatus === 'Inactive'){
          statusOptions = [<option value=""></option>,<option value="Active" > Active</option>, <option value="Inactive" > Inactive</option> ]
        }
        
      }

      //this.setState({statusOptions});
   }
   let ph=[]
   let ph1=""

   if(this.props?.phone?.length>0){
    ph=this.props.phone
     ph1="("+ph[0]+ph[1]+ph[2]+")"+ph[3]+ph[4]+ph[5]+"-"+ph[6]+ph[7]+ph[8]+ph[9]

   }
   else{
     ph1=""

   }
    // ph=this.props?.phone.length>0?this.props.phone:""
    
    //  console.log(ph1,"ph");
    
   
  	return (
      
  		<section className="content" >
        
      <div className="container-fluid">
        <div className="row" style={{paddingTop:"20px"}}>
          <div className="col-lg-9 col-md-9 col-xs-12">
            <div className="invoice p-3 mb-3">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <i className="fas fa-user"></i> &nbsp; {this.props.client_name}
                  </h4>
                </div>
              </div>
              <div className="row invoice-info">
                <div className="col-sm-4 invoice-col">                  
                  <address>                    
                    Client ID: {this.props.clientId}<br />
                    Date of birth: {this.props.dob}<br />
                    GPRA Number: {encryptStorage.getItem('gpra_no')}
                  </address>
                </div>
                <div className="col-sm-4 invoice-col">                 
                  <address>
                    Email: {this.props.email}<br />
                    Phone: {this.props.phone}<br />
                    Peer Support Specialist: <span className="text-green">{this.props.peer_support_specialist}</span>                    
                  </address>
                </div>
                <div className="col-sm-4 invoice-col">
                  <span className="text-green"><h6>Next Follow Up: {this.props.next_followup_date}</h6></span>
                  Intake Date: {this.props.intake_date}<br />
                  Initiated By: {this.props .initiated_by}<br />                  
                </div>
              </div>
            </div>
      </div>
      {this.props.dashboard ? (
        <div className="col-lg-3 col-md-3 col-xs-12">
            <div className="invoice mb-3" style={{minHeight: '149px', padding: '1rem 1rem 0.3rem 1rem'}}>
              <div className="row">
                <div className="col-12">
                  <h4>
                    <i className="fas fa-file-alt"></i> &nbsp; Status Information                  
                  </h4>
                </div>
              </div>
              <div className="row invoice-info">
                <div className="col-sm-12 invoice-col">                  
                  <address>
                    Current Status: {this.state.statusData.currentStatus == 'Activate' ? 'Activation Requested' : this.state.statusData.currentStatus == 'InActivate' ?  'InActivation Requested' : this.state.statusData.currentStatus}<br />
                    Status updated on: {this.state.statusData.updated_on}<br />
                    
                    {showStatusChangeButton ?  <button className="btn btn-default btn-sm" onClick={this.showStatusModal} >Change Status</button> : '' }
                  </address>
                </div>               
              </div>             
            </div> 
        </div>
        
        ) : (
          <div className="col-lg-3 col-md-3 col-xs-12">
            <div className="invoice p-3 mb-3">
              <div className="row">
                <div className="col-12">
                  <h4>
                    <i className="fas fa-file-alt"></i> &nbsp; Form Information                    
                  </h4>
                </div>
              </div>
              <div className="row invoice-info">
                <div className="col-sm-12 invoice-col">                  
                  <address>
                    Name: {this.props.formname}<br />
                    Interviewer: {this.props.initiated_by}<br />
                    Interview Date: {this.props.intake_date}
                  </address>
                </div>               
              </div>             
            </div> 
          </div>
          )}
          
        </div>
      </div>
      <Modal 
      showOverlay={true}
      show = {this.state.showStatusModal}
      onClose = {this.closeStatusModal}
      disableOverlayClick = {true}
      showCloseButton = {true}
      size = "sm"
className="modal-md modal-dialog"
      >
      <Modal.Header style={{ borderTopLeftRadius : "10px" , borderTopRightRadius : "10px"}}>
          <Modal.Title>
              <div className="row" >
                  <div className="col-md-12">
                      <h4 className="modal-title float-left text-green">Change Status</h4>
                  </div>
                  
              </div>
          </Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
      <form action="javascript:void(0)" method="post" id="statusForm">
      <div className="row">
            <div className="col-md-12 form-group">Current Status: {this.state.statusData.currentStatus}</div>
            <div className="col-md-12 form-group">
        <label>New Status</label>
        <select name="status" required={this.state.status.mandatory} className="custom-select" aria-hidden="true" onChange={this.handleStatusSelectChange} >
            {statusOptions}
        </select>
      </div>
            <div class="col-md-12">
        <div class="form-group">
          <label>Comments</label>
          <textarea className="form-control" placeholder="Add comment..." rows="4" onChange={this.handleStatusSelectChange} name="comment"></textarea>
        </div>
      </div>
          </div>
          </form>
      </Modal.Body>
      <Modal.Footer style={{ borderBottomLeftRadius : "10px" , borderBottomRightRadius : "10px" }}>
          <div className="row">
              <div className="col-md-6">
              </div>
              <div className="col-md-6" > 
                      
                      <button id="Open" type="submit" className="btn btn-success float-right btnmargin" htmlType="submit" form="statusForm" > <i className="fas fa-save"></i> Save</button>
                  </div>
          </div>
      </Modal.Footer>
  </Modal>
    </section>


    
  

  	)
  }
}

export default Patientinfoheader
