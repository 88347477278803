import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    fontSize: "1rem",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

function Filter({ placeholder, width, options }) {
  const [personName, setPersonName] = React.useState([]);
  const classes = useStyles();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div style={{ height: "50px" }}>
      <FormControl
        sx={{
          m: 1,
          width: 150,
        }}
        style={{
          width: width,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          border: "none",
        }}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",

            paddingTop: "20px",
            transform: "none",
            transition: "none",
          }}
        >
          {personName.length !== 0 ? "" : placeholder}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          className={classes.root}
          style={{ width: width }}
        >
          {options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox
                // color="#7BB731"
                style={{ color: "#7BB731" }}
                checked={personName.indexOf(name) > -1}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default Filter;
