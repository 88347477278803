import React from "react";
import styled from "styled-components";
import FollowupAttempPieChart from "./FollowupAttempPieChart";
import OutcomesStackBar from "./OutcomesStackBar";


import BjaSourcesTable from "./BjaSourcesTable";

import AdmhSourceTable1 from "./AdmhSourceTable1";
import AdmhSourceTable2 from "./AdmhSourceTable2";
import AdmhSourceTable3 from "./AdmhSourceTable3";

import Topnav from "../Topnav";
import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import PrintReportIcon from "../../assests/images/icon-print.png";
import { HelpBlock } from "react-bootstrap";
import "../../../src/App.css";
import { Box } from "@material-ui/core";

const MainContainer = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const Header = styled.div`
  width: 100%;
`;

// width: 100%;
//   display: flex;
const PrintReport = styled.div`
width: 100%;
padding:0px 0px 0px 15px;
display: flex;
justify-content: end;
align-items: center;
height: 40px;

padding-right: 16px;
background-color: #efefef;
span {
  color: #7bb731;
  margin-left: 8px;
}
img {
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// Code By ashraf Start

const PageHeading = styled.div`
  width: 100%;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: Center;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const PageHeadingStyle = styled.div`
  margin: "0px auto";
  display: "block";
  width: 100%;
  background-color: #efefef;
`;

const printreportPageContainer = styled.div`
  margin: "0px auto";
  display: "block";
  background-color: #efefef;
  width: 100% !important;
`;

// Code By ashraf End

const FirstChart = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 0 16px 0 16px;
`;

function BjaReport() {
  return (
    <MainContainer>
      <Header>
        <Topnav dropdown={true} />
      </Header>
      
      <PageHeadingStyle>
        <h3 className="py-2" style={{ color: "#89BC3B", textAlign: "center" }}>
          ADMH Report
        </h3>
      </PageHeadingStyle>

      <printreportPageContainer style={{ width: "100%" }}>
        <div
          className="py-2"
          style={{
            color: "#89BC3B",
            textAlign: "right",
            display: "inlineBlock",
          }}
        >
          <img
            className="mr-2"
            style={{ cursor: "pointer" }}
            src={PrintReportIcon}
            alt="print"
            onClick={() => window.print()}
          />
          <span
            className="mr-3"
            style={{ cursor: "pointer" }}
            onClick={() => window.print()}
          >
            Print Report
          </span>
        </div>
      </printreportPageContainer>

      <FirstChart>
  
        <FollowupAttempPieChart addToDashBoard={true} />
        <OutcomesStackBar  addToDashBoard={true} />
      </FirstChart>


      <AdmhSourceTable1
        title="Outcomes"
        filter="All Outcomes"
        filterOptions={[
          "Assesment",
          "Treatment",
          "Follow-Ups",
          "WaitTime",
          "Others",
        ]}
      />

      <AdmhSourceTable2
        title="Participation"
        filter="All Participation"
        filterOptions={["RRC", "Treatment", "Barriers"]}
      />

      <AdmhSourceTable3
        title="FollowUp"
        filter="All FollowUp"
        filterOptions={["Follow-up Attempts", "Follow-up Contacts - No Referral", "Follow-up Contacts - Has Referral, Hasn't Started Treatment","Follow-up Contacts - Started Treatment"]}
      />
    </MainContainer>
  );
}

export default BjaReport;
