import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import moment from 'moment';
import {
  Button,
} from 'react-bootstrap';


const axios = require('axios').default;
class Intakeassessment extends Component {
  constructor() {
    super();
    this.handleId = this.handleId.bind(this);

    //this.handleApply = this.handleApply.bind(this);
    //this.handleCancel = this.handleCancel.bind(this);
    this.handleEvent = this.handleEvent.bind(this);

    var now = new Date();
    this.state = {
      startDateintakedate: new Date,
      month1followupdate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      sixmonthfollow: new Date(now.getFullYear(), now.getMonth() + 6, now.getDate()),
      dob: '',
      colSize: "col-md-6",
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
      homelessnessdata: {
        location_type: '',
        name: '',
        at_this_location_since: '',
        at_this_location_till: '',
        friends_name: '',
        phone: '',
        leave_msg: '',
        address: '',
        city: '',
        state: '',
        county: '',
        zip_code: ''
      },
      required: {
        location_type: '',
        name: '',
        at_this_location_since: '',
        at_this_location_till: '',
        friends_name: '',
        phone: '',
        leave_msg: '',
        address: '',
        city: '',
        state: '',
        county: '',
        zip_code: '',
      }
    };
  }
  handleChange = date => {

    //console.log("Selected date is : " + date ) ;

    this.setState({
      startDateintakedate: date
    });
  };
  handlemonth1followupdate = date => {

    //console.log("Selected date is : " + date ) ;

    this.setState({
      month1followupdate: date
    });
  };

  handleChangesixmonthfollow = date => {

    //console.log("Selected date is : " + date ) ;

    this.setState({
      sixmonthfollow: date
    });
  };
  handleChangedob = date => {

    //console.log("Selected date is : " + date ) ;

    this.setState({
      dob: date
    });
  };

  componentDidMount() {

    $.validator.setDefaults({
      submitHandler: function () {

        if ($('#clicked').val() === 'proceed') {
          window.location.href = global.domain + global.traversArray['intakeassessment'];
        } else {
          window.location.href = "";
        }

      }
    });
  }
  handleId = (e) => {
    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });

  }
  render() {
    sessionStorage.setItem('formname', 'Intake Assessment')

    let start = this.state.startDate.format('MM-DD-YYYY HH:mm:ss');

    let end = this.state.endDate.format('MM-DD-YYYY HH:mm:ss');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }

    $(document).ready(function () {
      const date1 = new Date(sessionStorage.getItem('intake_date'));
      const date2 = new Date(sessionStorage.getItem('dob'));
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const yearDays = Math.ceil(Math.ceil(diffTime / (1000 * 60 * 60 * 24)) / 365);
      $('#age').html(yearDays);

      $('#intakebasicinfoform').validate({
        rules: {

        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.input-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">

            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={sessionStorage.getItem('dob')} email={sessionStorage.getItem('email')} phone={sessionStorage.getItem('phone')} peer_support_specialist={sessionStorage.getItem('peer_support_specialist')} next_followup_date={sessionStorage.getItem('month_1_followup_date')} intake_date={sessionStorage.getItem('intake_date')} initiated_by={sessionStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={sessionStorage.getItem('client_first_name') + " " + sessionStorage.getItem('client_middle_initial') + " " + sessionStorage.getItem('client_last_name')} />


            <form method="post" id="intakebasicinfoform">
              <section className="content">
                <div className="row">
                  <Sidenav />
                  <div className="container-fluid col-md-9 col-lg-10">

                    <div className="card card-default">


                      <div className="card-header">
                        <p className="lead float-left"><b class="text-green">Assessment Form <small></small></b> </p>
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                          <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                        <button type="submit" id="saveButton" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        <input type="hidden" id="clicked" value="" readOnly />

                        <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />

                      </div>
                      <div className="card-body">

                        <div className="row">
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Intake Assessment Completed By</b></label><br />
                              {sessionStorage.getItem('last_updated_by')}

                            </div>
                          </div>

                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Client First Name</b></label><br />
                              {sessionStorage.getItem('client_first_name')}
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Client Last Name</b> </label><br />
                              {sessionStorage.getItem('client_last_name')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Client Middle Initial </b></label><br />
                              {sessionStorage.getItem('client_middle_initial')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Phone </b></label><br />
                              {sessionStorage.getItem('phone')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Leave a message? </b></label><br />
                              {sessionStorage.getItem('Leave_msg_pri')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Secondary Phone </b></label><br />
                              {sessionStorage.getItem('Secondary_phone')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Leave a message?</b></label><br />
                              {sessionStorage.getItem('Leave_msg_sec')}
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Date of Birth </b></label><br />
                              {sessionStorage.getItem('dob')}

                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <div className="form-group">
                              <label><b>Client Age at Intake </b></label><br />

                              <div id="age"></div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <label><b>Where did you stay last night?</b></label><br />
                            {sessionStorage.getItem('Type_lns')}
                          </div>
                          <div className="col-lg-3 col-md-4">
                            <label><b>Last Night Stay Address</b></label><br />
                            {sessionStorage.getItem('Address_lns')}
                          </div>
                        </div>


                      </div>
                      <div className="card-footer float-right" >
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>



                    </div>


                  </div>
                </div>
              </section>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    )

  }
}
export default Intakeassessment
