import React from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export function Alert(type, message){
    switch (type) {
        case 'warning':
            return toast.warning(<div> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}</div>, {toastId : "warning"} )
        case 'error':
            return toast.error(<div> <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}</div>) // look this line
        case 'success':
            return toast.success(<div> <i class="fa fa-check" aria-hidden="true"></i> {message}</div>, {toastId : "success"})
        case 'info':
            return toast.info(message)
        case 'dark':
            return toast.dark(message)
        default:
            return toast(message)
    }
}