import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { date_validate } from '../../ReusableComponents/globalFunctions';

import ArrowRightIcon from "../../assests/images/arrow-right.svg";
import { encryptStorage } from "../LocalStorageInterceptor";
import Modal from ".././Modals/Modal/index"
import RecordNotFoundIcon from "../../assests/images/records-not-found.svg";

const MainContainer = styled.section`
  width: 445px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 28px;
  @media (max-width: 946px) {
    margin-left: 10px;
    width: 440px;
  }

  @media (max-width: 500px) {
    width: 95%;
    margin-left: 0px;
    margin: 0 auto;
    margin-top: 48px;
  }
`;

const TableTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;

  letter-spacing: -0.09px;

  color: #000000;
`;

const ShowAllBtn = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 140%;
    margin-right: 7px;
    letter-spacing: -0.09px;

    color: #27272a;
  }
`;

const ArrowRightIconContainer = styled.img``;

const TableDescContainer = styled.div`
  min-height: 275px;
  width: 100%;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;

const Desc = styled.div`
  width: 100%;
`;

const FieldHeadTitlesContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

const Field = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 403px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;

const HeadTitle = styled.span`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  /* or 18px */
  width: 25%;
  word-wrap: break-word;
  flex-wrap: wrap;
  letter-spacing: -0.09px;
  @media (max-width: 403px) {
    font-size: 12px;
  }
  color: #646464;
`;

const FieldDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  margin-bottom: 9px;
  @media (max-width: 403px) {
    padding-right: 8px;
    padding-left: 8px;
  }
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TableVal = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  width: 30%;
  letter-spacing: -0.09px;

  color: #646464;
`;

const RecordsNotFoundContainer = styled(TableDescContainer)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  span {
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;

    text-align: center;
    letter-spacing: -0.09px;

    color: rgba(0, 0, 0, 0.4);
  }
`;

const handleRowClicked = (rowData) => {
  sessionStorage.setItem('clientId', rowData.Client_id);
  encryptStorage.setItem('client_last_name', rowData.last_name);
  encryptStorage.setItem('ssn', rowData.ssn_no);
  encryptStorage.setItem('gpra_no', rowData.gpra_no);
  encryptStorage.setItem('client_middle_initial', rowData.middle_name);
  encryptStorage.setItem('client_first_name', rowData.first_name);
  encryptStorage.setItem('email', rowData.Primary_email)
  encryptStorage.setItem('phone', rowData.Primary_phone)
  encryptStorage.setItem('intake_date', moment(rowData.Intake_date).format('MM/DD/YYYY'));
  encryptStorage.setItem('dob', moment(rowData.Dob).format('MM/DD/YYYY'))
  encryptStorage.setItem('month_1_followup_date', date_validate(moment(rowData.Intake_date).format('MM/DD/YYYY'), 90));
  encryptStorage.setItem('last_updated_by', rowData.Last_updated_by);
  encryptStorage.setItem('Secondary_phone', rowData.Secondary_phone);
  sessionStorage.setItem('Leave_msg_pri', rowData.Leave_msg_pri);
  sessionStorage.setItem('Leave_msg_sec', rowData.Leave_msg_sec);
  sessionStorage.setItem('Type_lns', rowData.Type_lns);
  encryptStorage.setItem('Address_lns', rowData.Address_lns);
  sessionStorage.setItem('viewMode', 'false');
  encryptStorage.setItem('peer_support_specialist', rowData.PeerSupportSpecialist);
  sessionStorage.setItem('client_status', rowData.Client_status);
  sessionStorage.removeItem('client_search_filter')
  console.log('globals updaated', global);
  setTimeout(() => window.location.href = "/dashboard", 500);
}

function ClientTable(props) {  //{ title, headTitle, records, clientList }

  const [showAccordian, setShowAccordian] = useState(false);
  const [AccordianData, setAccordianData] = useState(null);

  const showAccordianFnc = (val) => {
    debugger;
    setShowAccordian(true);
    setAccordianData(val.note);
  }

  return (
    <MainContainer>
      <TableTitleContainer>
        {props.title}
        {props.title == "My Clients" &&
          <ShowAllBtn onClick={() => window.location.href = "/clients"}>
            <span>Show All</span>
            <ArrowRightIconContainer
              src={ArrowRightIcon}
              alt="Show All"
            ></ArrowRightIconContainer>
          </ShowAllBtn>
        }

      </TableTitleContainer>
      {!props.headTitle ? (
        <RecordsNotFoundContainer>
          <img src={RecordNotFoundIcon} alt="No records found" />
          <span>No records found</span>
        </RecordsNotFoundContainer>
      ) : (
        <TableDescContainer>
          <Desc>
            <FieldHeadTitlesContainer>
              <Field>
                {props.headTitle?.map((title, index) => {
                  return <HeadTitle key={index}>{title.name}</HeadTitle>;
                })}
              </Field>
            </FieldHeadTitlesContainer>
            <FieldDescContainer>
              {props.title !== "My Notes" ?
                <>
                  {props.clientList?.length > 0 ? props.clientList?.slice(0, 5).map((val, index) => (
                    <RowContainer key={index} onClick={() => { props.title == "My Clients" && handleRowClicked(val) }} style={props.title == "My Clients" ? { cursor: 'pointer' } : { cursor: 'default' }}>
                      {props.headTitle?.map((title, index) => {
                        if(props.title == "My Clients"){
                          return <TableVal key={index}>{val[title.condition]}</TableVal>;
                        }else{
                          return <TableVal key={index}>{val[title.title]}</TableVal>;
                        }
                      })}
                    </RowContainer>
                  )) :
                    <div>
                      <RowContainer>
                        <img src={RecordNotFoundIcon} alt="No records found" style={{ marginLeft: '35%' }} />
                      </RowContainer>
                      <RowContainer>
                        <span style={{ color: 'lightgray', marginLeft: '35%' }}>No records found</span>
                        {/* <TableVal>No Record Found</TableVal> */}
                      </RowContainer>
                    </div>
                  }
                </> :
                <>
                  {props.clientList?.length > 0 ? props.clientList?.slice(0, 5).map((val, index) => (
                    <div id="accordion">
                      {/* <div className="card"> */}
                        {/* <div className="card-header"> */}
                          <div className="row " style={{fontSize: '14px', cursor: 'pointer',marginBottom: '2%'}} data-toggle="collapse" data-parent="#accordion" data-target={`#key` + val.id}>
                            <div className="col-md-4">
                              <span className="float-left">{val.client_name}</span>
                            </div>
                            <div className="col-md-4">
                              <span className="float-left text-gray">{val.note.substring(0,20)}</span>
                            </div>
                            <div className="col-md-4">
                              <span className="float-left text-gray">{val.updated_on}</span>
                            </div>
                          </div>
                        {/* </div> */}
                        <div id={`key` + val.id} className="panel-collapse collapse card">
                          <div className="card-body">
                            {val.note}
                          </div>
                        </div>
                      {/* </div> */}
                    </div>
                  )) :
                    <div>
                      <RowContainer>
                        <img src={RecordNotFoundIcon} alt="No records found" style={{ marginLeft: '35%' }} />
                      </RowContainer>
                      <RowContainer>
                        <span style={{ color: 'lightgray', marginLeft: '35%' }}>No records found</span>
                        {/* <TableVal>No Record Found</TableVal> */}
                      </RowContainer>
                    </div>
                  }
                </>
              }
            </FieldDescContainer>
          </Desc>
        </TableDescContainer>
      )}
    </MainContainer>
  );
}

export default ClientTable;
