import React , {Component} from 'react';
import { ToastContainer } from "react-toastify";

class Footer extends Component{

	render () {
		return (
      <footer className={this.props.sidebar === false ? "" : "main-footer"}>
        <div className="float-right d-none d-sm-block">
          <b>Version </b>4.0
        </div>
        <strong>{this.Yr}</strong>
        <strong>Copyright &copy; {'2014'}-{new Date().getFullYear()}</strong> All rights reserved.
        {/* <ToastContainer/> */}
      </footer>
    )
	}
}

export default Footer