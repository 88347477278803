import moment from 'moment';

export function formObjDataMapper( objData , classStructure )
{
    let obj = classStructure ;
      
    // eslint-disable-next-line array-callback-return
    objData.map((fod) => {
          if(fod.type === "numeric" || fod.type === "select" || fod.type === "input" || fod.type === "textarea" || fod.type === "checkbox" )
          {
            obj[fod.name] = fod.value ;
          }
          else if (fod.type === "multiselect" )
          {
            let multiSelectOptions = fod.value !== null && fod.value.length > 0  && fod.value.map((item, i) => {
       
                var op = { value: item , label: item } ;
     
              return op ;
     
            }, this);
              
              obj[fod.name] = multiSelectOptions ;
          }
          else if( fod.type === "date" && fod.value != '')
          {
            obj[fod.name] = new Date(fod.value)  ;
          }
      });
    return obj ;
}

  export function formObjDataMapper2( objData , classStructure ){
    let obj = classStructure ;
    objData.map(
      (fod) => {
                  if( fod.type === "numeric" || fod.type === "select" || fod.type === "input" || fod.type === "textarea" || 
                      fod.type === "checkbox" || fod.type === "date" || fod.type === "user" || fod.type === "time" || fod.type === "datetime"
                    ){
                      if(fod.type === "date" ){
                          obj[fod.name] = (fod.value !== "") ? moment(fod.value).format("MM/DD/YYYY") : "";
                      }else if(fod.type === "datetime" ){
                        obj[fod.name] = (fod.value !== "") ? moment(fod.value).format("MM/DD/YYYY HH:mm") : "" ;
                      }else{
                        obj[fod.name] = fod.value;
                      }
                  }else if (fod.type === "multiselect" ){
                    let multiSelectOptions = fod.value !== null && fod.value.length > 0  && fod.value.map(
                      (item, i) => {
                        var op = { value: item , label: item } ;
                        return op ;
                      }, this
                    );
                    obj[fod.name] = multiSelectOptions ;
                  }
      }
    );
    return obj ;
  }



export function parseMultiSelectField(values, options){
  if(values !== null && values.length > 0 && options !== null && options.length > 0){
    let options_values = [];
    let updated_values = [];
    options.map(option => options_values[option.label] = option.value);
    values.map((option) => updated_values.push({label : option.label, value : options_values[option.label]}));

    return updated_values;
  }
}