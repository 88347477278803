import React from "react";
import styled from "styled-components";
import Topnav from "../Topnav";
//import ReferralSource from "./ReferralSource";
import ReferralSource from "./ReferralSource_New";
// import ReferralSourcesTable from "./ReferralSourcesTable_old";
import ReferralSourcesTable from "./ReferralSourcesTable";
import ReferralSourcesTable1 from "./ReferralSourcesTable1";

import TotalReferrals from "./TotalReferrals";
import PrintReportIcon from "../../assests/images/icon-print.png";
import '../../../src/App.css';


const MainContainer = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  background-color: #f9f9f9;
  overflow : hidden;
`;
const Header = styled.div`
  width: 100%;
`;

const PrintReport = styled.div`
  width: 100%;
  padding:0px 0px 0px 15px;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const PageHeading = styled.div`
  width: 100%;
  padding:10px 0px 0px 0px;
  display: flex;
  justify-content: Center;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  // img {
  //   width: 20px;
  //   height: 20px;
  //   cursor: pointer;
  // }
`;

// width: 100%;
// display: flex;
// const PrintReport = styled.div`
 
//   margin-left: 90%;
//   justify-content: flex-end;
//   align-items: center;
//   height: 40px;

//   padding-right: 16px;
//   background-color: #efefef;
//   span {
//     color: #7bb731;
//     margin-left: 8px;
//   }
//   img {
//     width: 20px;
//     height: 20px;
//     cursor: pointer;
//   }
// `;

const FirstChart = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 0 16px 0 16px;
`;
const PageHeadingStyle=styled.div`
margin:"0px auto";
display:"block";
width:100%;
background-color:#efefef;

`;


const printreportPageContainer=styled.div`
margin:"0px auto";
display:"block";
background-color:#efefef;
width:100% !important;


`;


function ReferralReport() {
  return (
    <MainContainer>
      <Header>
        <Topnav dropdown={true} />
      </Header>
    

<PageHeadingStyle>
   <h3 className="py-2" style={{color:"#89BC3B",textAlign:"center"}}>Referral Report</h3>
   </PageHeadingStyle>


   <printreportPageContainer style={{width:"100%"}}>
    <div className="py-2" style={{color:"#89BC3B",textAlign:"right",display:"inlineBlock"}} >
      <img className="mr-2" style={{cursor:"pointer"}} src={PrintReportIcon} alt="print" onClick={() => window.print()} />
        <span className="mr-3" style={{cursor:"pointer"}} onClick={() => window.print()}>Print Report</span>
   </div>
   </printreportPageContainer>

      <FirstChart>
        <ReferralSource addToDashBoard={true} />
        
        <TotalReferrals addToDashBoard={true} />
      </FirstChart>
      <ReferralSourcesTable
        title="Referral Source"
        filter="All Sources"
        filterOptions={["Hospitals", "Community Organization", "Other"]}
      />
      <ReferralSourcesTable1
        title="Referral Targets"
        filter="All Types"
        filterOptions={["Detox", "Residential"]}
      />
    </MainContainer>
  );
}

export default ReferralReport;
