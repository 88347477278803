import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { VictoryLabel, VictoryPie } from "victory";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #fff;
//   border:1px solid black;
`;

const DataCategoryContainer = styled.div`
//   padding-left: 80px;
  display: flex;
  flex-direction: column;

  padding-top: 30px;

  height: 80%;
`;

const GenderDesc = styled.div`
  display: flex;
  /* align-items: center; */

  margin-bottom: 10px;
  font-size: 12px;
`;

const GenderColor = styled.div`
  background-color: ${(props) => props.bgColor && props.bgColor};
  width: 30px;
  height: 10px;
  border-radius: 10px;
  margin-top: 5px;
`;

const GenderTitle = styled.div`
  margin-left: 10px;
`;

const color = [
  // "#B3DAD1",
  "#83f2d9",

  // "#92BBDA",
  "#91a9ab",

  "#FFF7C3",

  // "#D9F3F5",
  "#20A7E3",

  // "#E7D7F4",
  // "#c999f0",

  // // "#99C5D5",
  // "#89BC3B",

  

];

function PieChartFollowup(props) {
  const gdByRacePieData = props.gdPiaChart;
  const getgdchart = props.gdchart;

 

  return (
    <MainContainer>
      <VictoryPie
        width={330}
        colorScale={color}
        style={{
          parent: {
            width: "50%",
            height: "90%",
            // border:"1px solid red"
          },
          labels: { fill: "white" },
        }}
        innerRadius={100}
        // labelRadius={120}
        // labels={({ datum }) => `# ${datum.y}`}
        //   labelComponent={<CustomLabel />}

        data={getgdchart}
        labelComponent={
          <VictoryLabel
            textAnchor="middle"
            backgroundPadding={{ left: 10, right: 15 }}
            style={{
              fontSize: 14,
              lineHeight: "10px",
              fontFamily: "Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif",
            }}
          />
        }
      />{" "}
      <DataCategoryContainer>
        {" "}
        {gdByRacePieData.map((data, index) => {
          return (
            // <GenderDesc>
            //   {" "}
            //   {/* <GenderColor bgColor={color[index]}></GenderColor> */}{" "}
              <div className="row  chartDesc"  >
                <div className="col-2">
                <GenderColor bgColor={data.color}></GenderColor>
                </div>
                <div className="col-10">
                <GenderTitle>{data.attempts}</GenderTitle>
                </div>
              </div>

            // </GenderDesc>
          );
        })}{" "}
      </DataCategoryContainer>{" "}
    </MainContainer>
  );
}

export default PieChartFollowup;
