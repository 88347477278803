import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import { validationParser } from '../ReusableComponents/validationParser2';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { LabelField } from '../ReusableComponents/LabelnInput';
import { formObjDataMapper, formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import Select from 'react-select';
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import TextAreaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import DateTime from 'react-datetime';

import { Button } from './Modals/Buttons/index';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import moment from 'moment-timezone';

import { date_validate } from '../ReusableComponents/globalFunctions';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';

import { can_edit, can_add } from '../ReusableComponents/urlGenerator';
import { encryptStorage } from './LocalStorageInterceptor';

var validationObj = {};
var context;

const axios = require('axios').default;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderRightStyle: 'solid',
      borderRightWidth: '10px',
      borderRightColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        // borderStyle: 'solid',
        // borderWidth: '1px',
        // borderColor: defaultThemes.default.divider.default,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
      ':last-of-type': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      }
    },
  },
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    whiteSpace: "inherit"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '16px',
      color: '#495057'
    }
  }
};


class Referrals extends Component {

  constructor() {
    super();
    context = this;

    this.state = {
      ...this.getModal("referralsData"),
      referralsData: this.getModal("referralsData"),
      mandatoryInfo: this.getModal("referralsData"),
      labelText: this.getModal("referralsData"),
      tooltips: this.getModal("referralsData"),
      tooltipsVisibility: this.getModal("referralsData"),
      fieldName: this.getModal("referralsData"),
      fieldType: this.getModal("referralsData"),

      //SelectOptions
      referralTypeSelectOptions: [],
      wasAssmntCompletedSelectOptions: [],
      wasClientDeemForMatSelectOptions: [],
      whichMatProgramSelectOptions: [],
      wereFamilyReferToServiceSelectOptions: [],
      whichReferralServiceSelectOptions: [],

      //MultiSelectOption
      clientRefferedAgencyMultiOptions: [],

      referralHistoryData: [],
      referralsHistoryColumns: this.getModal("referralsHistoryColumns"),

      //Modals
      showAddReferralsModal: sessionStorage.getItem('modalOpen') ? true : false,
      showNextActionModal: false,
      showReferralDetailsModal: false


    };


    this.handleReferralSelectChange = this.handleReferralSelectChange.bind(this);
    this.getReferralsList = this.getReferralsList.bind(this);
  }

  getModal(type) {
    let dataModel = '';
    switch (type) {
      case "referralsData":
        dataModel = {
          referral_type: '',
          othr_referral_type: '',
          client_referred_agency: '',
          othr_agency: '',
          was_asesmnt_completed: '',
          othr_assessment: '',
          was_client_deem_for_mat: '',
          which_mat_program: '',
          othr_mat_program: '',
          were_family_refer_to_service: '',
          which_referral_service: '',
          notes: '',
          client_id: '',
          version: '',
        }
        break;

      case "referralsHistoryColumns":
        dataModel = [
          {
            name: 'Referred On',
            selector: 'date',
            wrap: true,
            sortable: true,
            cell: (row) => {
              return moment.unix(row.date).format("MM/DD/YYYY hh:mm a");
            },


          },
          {
            name: 'Referral Type',
            selector: 'referral_type',
            wrap: true,
            sortable: true,
          },
          {
            name: 'Agency',
            selector: 'client_referred_agency',
            wrap: true,
            sortable: true,
          },
          {
            name: 'Referred By',
            selector: 'referred_by',
            wrap: true,
            sortable: true,
          },
          {
            name: 'Details',
            sortable: false,
            cell: (row) => (
              <div>
                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={() => this.handleReferralDetails(row.id)}></i></Link>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ];
        break;

      case "resetData":
        dataModel = {
          referral_type: '',
          othr_referral_type: '',
          client_referred_agency: '',
          othr_agency: '',
          was_asesmnt_completed: '',
          othr_assessment: '',
          was_client_deem_for_mat: '',
          which_mat_program: '',
          othr_mat_program: '',
          were_family_refer_to_service: '',
          which_referral_service: '',
          notes: '',
          client_id: '',
          version: '',
        }
        break;
    }
    return dataModel;
  }

  componentWillMount() {
    // for fetching layout definition
    axios.get(global.restApiURL + 'webservice/rest/class/id/28?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(async (res) => {
        var response = res.data;

        if (response.success && res.status == 200) {
          validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
          let validationObj_old = validationParser1(response.data.layoutDefinitions.childs[0].childs);
          console.log("validation : ", validationObj);

          await this.setState({
            labelText: validationObj.title,
            mandatoryInfo: validationObj.mandatory,
            tooltips: validationObj.tooltip,
            tooltipsVisibility: validationObj.tooltipVisibility,
            fieldName: validationObj.name,
            fieldType: validationObj.fieldtype,
            ...validationObj_old
          });

          await this.createSelectOptions();

        }
        else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(response.msg);
        }

      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      });
  }

  componentDidMount() {
    this.getReferralsList();
    if (sessionStorage.getItem('viewDetail') > 0) {
      this.handleReferralDetails(sessionStorage.getItem('viewDetail'))
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getReferralsList = () => {
    if (sessionStorage.getItem('clientId') !== '') {

      let getReferralListBody = {
        apikey: sessionStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };

      axios.post(global.restApiURL + 'customRest/getReferralsList', JSON.stringify(getReferralListBody))
        .then(res => {

          if (res.data.success && res.status == 200) {
            // const response = res.data.data.elements;

            setTimeout(function () {

              context.setState({
                referralHistoryData: context.parseReferralHistory(res.data.data)
              });
              console.log("obj his data : ", context.state.referralHistoryData);
              sessionStorage.setItem('referralsListData_length', res.data.data.length)
            }, 50)


            encryptStorage.setItem('month_1_followup_date', date_validate(encryptStorage.getItem('intake_date'), 1))
            if (res.data.data.length > 0) {


              res.data.data[0].map((obj) => {
                if (obj.name == 'date') {
                  sessionStorage.setItem('last_referal_date', moment.unix(obj.value).tz('America/New_York').format('MM/DD/YYYY'));
                  encryptStorage.setItem('month_1_followup_date', date_validate(moment.unix(obj.value).tz("America/New_York").format('MM/DD/YYYY'), 1));
                }
              });
            }
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }


        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }
  }

  parseReferralHistory(historyDataArray) {
    let parsedData = [];

    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {

      var op = {};

      item.map((val) => {
        if (val.name === "client_referred_agency") {
          op[val.name] = val.value ? val.value.join(', ') : '';
        } else {
          op[val.name] = val.value;
        }

      })

      // console.log("op : " , op );

      return op;

    }, this);

    // console.log("parsed Data : " , parsedData);

    return parsedData;
  }

  createSelectOptions() {
    if (validationObj !== "" || validationObj !== null) {
      this.setState({

        //Select options
        referralTypeSelectOptions: validationObj?.options?.referral_type?.length > 0 ? selectOptionsGenerator(validationObj?.options?.referral_type, context?.state?.referralsData?.referral_type) : [],
        wasAssmntCompletedSelectOptions: selectOptionsGenerator(validationObj?.options?.was_asesmnt_completed, context?.state?.referralsData?.was_asesmnt_completed),
        wasClientDeemForMatSelectOptions: selectOptionsGenerator(validationObj?.options?.was_client_deem_for_mat, context?.state?.referralsData.was_client_deem_for_mat),
        whichMatProgramSelectOptions: selectOptionsGenerator(validationObj?.options?.which_mat_program, context?.state?.referralsData.which_mat_program),
        wereFamilyReferToServiceSelectOptions: selectOptionsGenerator(validationObj?.options?.were_family_refer_to_service, context?.state?.referralsData?.were_family_refer_to_service),
        whichReferralServiceSelectOptions: selectOptionsGenerator(validationObj?.options?.which_referral_service, context?.state?.referralsData?.which_referral_service),
        // Multiselect
        clientRefferedAgencyMultiOptions: multiSelectOptionGenerator(validationObj?.options?.client_referred_agency),

      });

    }
  }

  handleReferralDetails(objId) {
    let historyID = objId;

    console.log("history : ", historyID);

    context.setState({
      referralsData: context.getModal("referralsData")
    })

    axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
      .then(res => {

        const response = res.data.data.elements;
        //console.log("Obj data , res : " , formObjDataMapper2(response , context.getModal("followupdata")) , response );

        let parsedReferralsDetails = formObjDataMapper2(response, context.getModal("referralsData"));

        context.setState({
          referralsData: parsedReferralsDetails,
          showReferralDetailsModal: true
        })

        console.log(context.state.referralsData);

        context.createSelectOptions();

        // context.onFollowUpDetailsModalOpen();

      })
      .catch(err => { // log request error and prevent access to undefined state
        context.setState({ loading: false, error: true });
        console.error(err);
      })
    if (sessionStorage.getItem('viewDetail') > 0) {
      sessionStorage.removeItem('viewDetail')
    }
  }

  openAddReferralModal() {
    context.setState({

      showAddReferralsModal: true,
      showReferralDetailsModal: false,
      showNextActionModal: false,
      referralsData: context.getModal('resetData')
    })
    context.createSelectOptions();
  }

  closeAddReferralModal() {
    context.getReferralsList();
    context.setState({
      showAddReferralsModal: false,
      referralsData: context.getModal('resetData')
    })
    setTimeout(() => context.createSelectOptions(), 100);
    sessionStorage.removeItem('modalOpen');
  }

  handleReferralSelectChange(event) {
    console.log("selectchange : ", event.target.name);
    let name = event.target.name;
    let value = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      referralsData: {
        ...prevState.referralsData,
        [name]: value
      }
    }));

  }

  handleChange = (event) => {
    let referralsDataCpy = context.state.referralsData;
    referralsDataCpy[event.target.name] = event.target.value;
    context.setState({ referralsData: referralsDataCpy });
  }

  handleMultiSelectChanges = (selectedOption, event) => {

    var updatedState = this.state.referralsData;

    updatedState[event.name] = selectedOption;

    this.setState(prevState => ({
      ...prevState,
      referralsData: updatedState
    }));

    // console.log( event.name ," : ", this.state.lastNightStayData);
  };

  saveReferralsdetails() {
    console.log("Save referrals details");

    if (sessionStorage.getItem('clientId') !== '') {
      /*let saveType =  event.target ;
      console.log( "save : " , saveType );*/

      var jsonData = '';
      if (sessionStorage.getItem('referralsListData_length') === 0) {
        jsonData = { 'className': 'Referrals', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
      } else {

        jsonData = { 'className': 'Referrals', 'key': (sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('referralsListData_length')) + 1)), 'published': true };
      }
      let jsonData_validation = [];
      jsonData.elements = [];

      // // var formData = $("#phq9form").serializeArray();     

      // // console.log("formdata : " , formData) ;

      let keys = Object.keys(context.getModal("referralsData"));

      let addreferralDataToSend = context.state.referralsData;

      //console.log('data state',addreferralDataToSend);
      addreferralDataToSend['client_id'] = sessionStorage.getItem('clientId');

      if (sessionStorage.getItem('loggedinUserID')) {
        jsonData.elements.push({ name: 'updated_by', value: sessionStorage.getItem('loggedinUserID') });
        // jsonData.elements.push({ name: 'updated_on', value: new DateTime});
      }


      //console.log("state : " , context.state.referralsData , "changed : " , addreferralDataToSend ) ;

      keys.map((key) => {
        if (key !== 'client_referred_agency') {
          jsonData.elements.push({ name: context.state.fieldName[key], value: addreferralDataToSend[key] });
          if (addreferralDataToSend[key] !== '' && key !== 'client_id') {
            jsonData_validation.push(key);
          }
        }
      })
      jsonData.elements.push({ name: context.state.fieldName.client_referred_agency, value: createMultiselectDataToSend(addreferralDataToSend.client_referred_agency) });

      if (addreferralDataToSend.client_referred_agency.length > 0) {
        jsonData_validation.push('client_referred_agency');
      }



      if (jsonData_validation.length === 0) {
        sessionStorage.setItem('error_msg', 'Empty Information is not saved');
        window.location.href = "";
      } else {
        console.log('jsonData_validation', jsonData_validation)
        var json = JSON.stringify(jsonData);

        console.log("Data to send : ", json);

        //context.onModalClose();

        axios.post(global.save_url, json)
          .then(function (res) {
            var response = res.data;
            // console.log("save response" , response.success );
            if (response.success && res.status == 200) {
              sessionStorage.setItem('success_msg', "Referral added successfully.");
              sessionStorage.setItem('referralsListData_length', (parseInt(sessionStorage.getItem('referralsListData_length')) + 1));

              context.openNextActionModal();
            }
            else if ("error_code" in response) {
              alert(response.msg);
              window.location.href = global.domain + global.traversArray['out'];
            }
            else {
              alert(response.msg);
              context.closeAddReferralModal();
            }

            console.log("Msg : ", sessionStorage.getItem('success_msg'));
          })
          .catch(function (err) {
            console.log(err.message);
            //alert(err.message);
          });
      }
      sessionStorage.removeItem('modalOpen');

    }
    else {
      console.log("Please fill complete form to save ! ");
    }

  }

  closeNextActionModal() {
    context.getReferralsList();
    context.setState({
      showNextActionModal: false,
      referralsData: context.getModal('resetData')
    });
    setTimeout(() => context.createSelectOptions(), 100);
  }

  route = (route) => {
    sessionStorage.setItem('modalOpen', true);
    switch (route) {
      case 'Follow-Up':
        window.location.href = global.domain + 'followup';
        break;

      case 'Routing':
        window.location.href = global.domain + 'routing';
        break;

    }
  }

  openNextActionModal() {
    this.getReferralsList();
    context.setState({
      showAddReferralsModal: false,
      showReferralDetailsModal: false,
      showNextActionModal: true,
      referralsData: context.getModal('resetData')
    });
    context.createSelectOptions();
  }

  closeReferralDetailsModal() {
    context.setState({
      showReferralDetailsModal: false,
      referralsData: context.getModal('resetData')
    });
    context.createSelectOptions();
  }

  openReferralDetailsModal() {
    context.setState({
      showAddReferralsModal: false,
      showReferralDetailsModal: true,
      showNextActionModal: false
    });
  }

  render() {
    $(document).ready(function () {

      $('#Referralsform').validate({

        submitHandler: function () {
          context.saveReferralsdetails();
        },

        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.formfield').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });
    let referral_types = [];
    if (context.state.referralsData.client_referred_agency && context.state.referralsData.client_referred_agency.length > 0) {
      context.state.referralsData.client_referred_agency.map((agency) => {
        if (agency.label == 'Other (Please Specify Below)' && context.state.referralsData.othr_agency !== '') {
          referral_types.push(<li>{agency.label + " - " + context.state.referralsData.othr_agency}</li>);
        }else{
          referral_types.push(<li>{agency.label}</li>);
        }
      })
    }
    sessionStorage.setItem('formname', 'Referrals');

    return (

      <div className="hold-transition sidebar-mini text-sm">
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">

            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
              email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
              peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav />

                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card card-default">
                    <div className="card-header">
                      <p className="lead float-left"><b className="text-green">Referrals</b></p>
                      <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['referrals']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                      {can_add('Referrals') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddReferralModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Referral</button> : ''}
                      <input type="hidden" id="clicked" value="" readOnly />
                      <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem('clientId')} />
                    </div>

                    <div className="card-body">
                      <DataTable
                        columns={this.state.referralsHistoryColumns}
                        data={this.state.referralHistoryData}
                        customStyles={global.customTableStyles}
                        striped={true}
                        responsive={true}
                        pagination
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                      />
                    </div>

                    <div className="card-footer ">
                      <div classNMW="float-right">
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['referrals']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>

                        {can_add('Referrals') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddReferralModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Referral</button> : ''}

                      </div>
                    </div>

                    <Modal
                      showOverlay={true}
                      show={this.state.showAddReferralsModal}
                      onClose={this.closeAddReferralModal}
                      disableOverlayClick={true}
                      showCloseButton={false}
                      size="md"
                      className="modal-dialog modal-lg"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-6">
                              <h4 className="modal-title float-left text-green">Add Referral</h4>
                            </div>
                            <div className="col-md-6"  >
                              <button id="Open" type="submit" className="btn btn-success float-right btnmargin" htmlType="submit" form="Referralsform"  > <i className="fas fa-save"></i> Save</button>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form id="Referralsform" method="post">
                          <div className="row form-group">
                            <div className="col-md-6">

                              <LabelField {...context.state.referral_type} />

                            </div>
                            <div className="col-md-6 formfield">
                              <select name={context.state.fieldName.referral_type} required={this.state.referral_type.mandatory} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} >
                                {this.state.referralTypeSelectOptions}
                              </select>
                            </div>
                          </div>
                          {context.state.referralsData.referral_type === "Other" ? (
                            <div className="row form-group">
                              <div className="col-md-6" />
                              <div className="col-md-6 formfield">
                                <input type="text" className="form-control" name={context.state.fieldName.othr_referral_type} id={context.state.fieldName.othr_referral_type} placeholder={this.state.labelText.othr_referral_type} defaultValue={this.state.referralsData.othr_referral_type} required={this.state.mandatoryInfo.othr_referral_type} maxLength={this.state.othr_referral_type.columnLength} onChange={this.handleChange} />
                              </div>
                            </div>
                          ) : <input type="hidden" name="othr_referral_type" value="" />}
                          <div className="row form-group">
                            <div className="col-md-6">
                              <LabelField {...context.state.client_referred_agency} />
                            </div>
                            <div className="col-md-6 formfield">
                              <Select
                                name={context.state.fieldName.client_referred_agency}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.referralsData.client_referred_agency}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.clientRefferedAgencyMultiOptions}
                                styles={colourStyles}
                                placeholder="Select"
                                required={this.state.client_referred_agency.mandatory}
                              />
                            </div>
                          </div>
                          {context.state.referralsData.client_referred_agency && context.state.referralsData.client_referred_agency.filter(function (e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ? (
                            <div className="row form-group">
                              <div className="col-md-6" />
                              <div className="col-md-6 formfield">
                                <input type="text" className="form-control" name={context.state.fieldName.othr_agency} id={context.state.fieldName.othr_agency} placeholder={this.state.labelText.othr_agency} defaultValue={this.state.referralsData.othr_agency} required={this.state.mandatoryInfo.othr_agency} maxLength={this.state.othr_agency.columnLength} onChange={this.handleChange} />
                              </div>
                            </div>
                          ) : <input type="hidden" name="othr_agency" value="" />}
                          <div className="row form-group">
                            <div className="col-md-6">
                              <LabelField {...context.state.was_asesmnt_completed} />
                            </div>
                            <div className="col-md-6 formfield">
                              <select name={context.state.fieldName.was_asesmnt_completed} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} required={this.state.was_asesmnt_completed.mandatory}>
                                {this.state.wasAssmntCompletedSelectOptions}
                              </select>
                            </div>
                          </div>
                          {context.state.referralsData.was_asesmnt_completed === "Yes" ? (
                            <div className="row form-group">
                              <div className="col-md-6" />
                              <div className="col-md-6">
                                <input type="text" className="form-control" name={context.state.fieldName.othr_assessment} id={context.state.fieldName.othr_assessment} placeholder={this.state.labelText.othr_assessment} defaultValue={this.state.referralsData.othr_assessment} required={this.state.mandatoryInfo.othr_assessment} maxLength={this.state.othr_assessment.columnLength} onChange={this.handleChange} />
                              </div>
                            </div>
                          ) : <input type="hidden" name="othr_assessment" value="" />}
                          <div className="row form-group">
                            <div className="col-md-6">
                              <LabelField {...context.state.was_client_deem_for_mat} />
                            </div>
                            <div className="col-md-6 formfield">
                              <select name={context.state.fieldName.was_client_deem_for_mat} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} required={this.state.was_client_deem_for_mat.mandatory}>
                                {this.state.wasClientDeemForMatSelectOptions}
                              </select>
                            </div>
                          </div>
                          {context.state.referralsData.was_client_deem_for_mat === "Yes" ? (
                            <div className="row form-group">
                              <div className="col-md-6">
                                <LabelField {...context.state.which_mat_program} />
                              </div>
                              <div className="col-md-6 formfield">
                                <select name={context.state.fieldName.which_mat_program} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} required={this.state.which_mat_program.mandatory}>
                                  {this.state.whichMatProgramSelectOptions}
                                </select>
                              </div>

                            </div>
                          ) : <input type="hidden" name="which_mat_program" value="" />}
                          {context.state.referralsData.which_mat_program === "Other (Please Specify Below)" ? (
                            <div className="row form-group">
                              <div className="col-md-6" />
                              <div className="col-md-6 formfield">
                                <input type="text" className="form-control" name={context.state.fieldName.which_mat_program} id={context.state.fieldName.which_mat_program} placeholder={this.state.labelText.which_mat_program} defaultValue={this.state.referralsData.which_mat_program} required={this.state.mandatoryInfo.which_mat_program} maxLength={this.state.othr_mat_program.columnLength} onChange={this.handleChange} />
                              </div>
                            </div>
                          ) : <input type="hidden" name="othr_mat_program" value="" />}
                          <div className="row form-group">
                            <div className="col-md-6">
                              <LabelField {...context?.state?.were_family_refer_to_service} />
                            </div>
                            <div className="col-md-6 formfield">
                              <select name={context?.state?.fieldName?.were_family_refer_to_service} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} required={this.state.were_family_refer_to_service.mandatory}>
                                {this.state.wereFamilyReferToServiceSelectOptions}
                              </select>
                            </div>
                          </div>
                          {context?.state?.referralsData?.were_family_refer_to_service === "Yes" ? (
                            <div className="row form-group">
                              <div className="col-md-6">
                                <LabelField {...context.state.which_referral_service} />
                              </div>
                              <div className="col-md-6 formfield">
                                <select name={context.state.fieldName.which_referral_service} className="custom-select" aria-hidden="true" onChange={this.handleReferralSelectChange} required={this.state.which_referral_service.mandatory}>
                                  {this.state.whichReferralServiceSelectOptions}
                                </select>

                              </div>
                            </div>
                          ) : <input type="hidden" name="which_referral_service" value="" />}
                          <div className="row form-group">
                            <div className="col-lg-12 col-md-12 formfield">
                              <div className="form-group">
                                <LabelField {...context.state.notes} />
                                <TextAreaAutosize className="form-control" id={context.state.fieldName.notes} name={context.state.fieldName.notes} defaultValue={context.state.referralsData.notes} rows={1} onChange={this.handleReferralSelectChange} ></TextAreaAutosize>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="justify-content-between" >
                              <button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddReferralModal} type="button">Close</button>
                            </div>
                          </div>
                          <div className="col-md-6" >

                            <button type="submit" id="saveButton" className="btn btn-success float-right btnmargin" htmlType="submit" form="Referralsform" > <i className="fas fa-save"></i> Save</button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      showOverlay={true}
                      show={this.state.showNextActionModal}
                      onClose={this.closeNextActionModal}
                      disableOverlayClick={true}
                      showCloseButton={true}
                      size="sm"
                      className="modal-md modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-8">
                              <h4 className="modal-title float-left text-green">Next Actions</h4>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="modal-body">
                          <div className="row">
                            <p style={{ paddingLeft: "10px" }}>Your referral is added. Would you like to proceed to one of the following pages?</p>
                          </div>
                          <div className="row" style={{ margin: "10px 0px 50px 0px", width: "100%" }}>

                            <div className="col-md-4">
                              <div className="btn btn-app  btn-success" type="button" style={{ width: "133px" }} onClick={this.openAddReferralModal}><i className="fas fa-prescription"></i> Add Referral</div>
                            </div>
                            <div className="col-md-4">
                              <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Follow-Up')}><i className="fas fa-calendar-check"></i> Add Follow-Up</div>
                            </div>

                            <div className="col-md-4">
                              <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Routing')}><i className="fas fa-medkit"></i> Add Routing</div>
                            </div>
                          </div>
                        </div>

                      </Modal.Body>

                    </Modal>

                    <Modal
                      showOverlay={true}
                      show={this.state.showReferralDetailsModal}
                      onClose={this.closeReferralDetailsModal}
                      disableOverlayClick={true}
                      showCloseButton={false}
                      size="sm"
                      className="modal-md modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-6">
                              <h4 className="modal-title float-left text-green">Referral</h4>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p className="boldText">{context.state.labelText.referral_type}</p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <p >{context.state.referralsData.othr_referral_type !== '' ? context.state.referralsData.othr_referral_type : context.state.referralsData.referral_type}</p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p className="boldText">{context.state.labelText.client_referred_agency}</p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <p>
                                  <ul>
                                    {referral_types}
                                  </ul>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p className="boldText">{context.state.labelText.was_asesmnt_completed}</p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <p>{context.state.referralsData.was_asesmnt_completed}</p>

                              </div>
                            </div>
                          </div>
                          {context.state.referralsData.was_asesmnt_completed == "yes" ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.othr_assessment}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p>{context.state.referralsData.othr_assessment}</p>
                                </div>
                              </div>
                            </div> :
                            ""
                          }

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p className="boldText">{context.state.labelText.was_client_deem_for_mat}</p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <p >{context.state.referralsData.was_client_deem_for_mat}</p>
                              </div>
                            </div>
                          </div>
                          {context.state.referralsData.was_client_deem_for_mat == 'Yes' ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.which_mat_program}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p >{context.state.referralsData.othr_mat_program !== '' ? context.state.referralsData.othr_mat_program : context.state.referralsData.which_mat_program}</p>
                                </div>
                              </div>
                            </div> :
                            ""
                          }
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <p className="boldText">{context?.state?.labelText?.were_family_refer_to_service}</p>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <p>{context?.state?.referralsData?.were_family_refer_to_service}</p>
                              </div>
                            </div>
                          </div>
                          
                          {context?.state?.referralsData?.were_family_refer_to_service == 'Yes' ?
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.which_referral_service}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>{context.state.referralsData.which_referral_service}</label>
                                </div>
                              </div>
                            </div> :
                            ""
                          }
                          
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <p className="boldText" for="exampleFormControlTextarea3">Notes</p>
                              </div>
                              <div className="">
                                {context.state.referralsData.notes}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="justify-content-between" >
                              <button className="btn btn-default float-left" id="closeButton" onClick={this.closeReferralDetailsModal} type="button">Close</button>
                            </div>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Referrals;