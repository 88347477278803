import React from "react";
import styled from "styled-components";
import EmptyPieIcon from "../../assests/images/empty-pie.png";

const MainContainer = styled.div`
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // height: 100%;
  // margin-top: 20px;
  // box-sizing: border-box;
  display:none;
`;

const blank=styled.div`

`;

const ImgContainer = styled.div``;

const WidgetTextContainer = styled.div`
  font-size: 14px;
  margin-top: 30px;
`;

const GreenSpan = styled.span`
  color: #7bb731;
`;

const StatText = styled.div`
  opacity: 0.8;
  font-size: 11px;
  margin-top: 8px;
`;

function BlankState({ emptyIcon }) {
  return (
    
    <blank></blank>
    // <MainContainer>
    //   <ImgContainer>
    //     <img src={emptyIcon ? emptyIcon : EmptyPieIcon} alt="emptyStateIcon" />
    //   </ImgContainer>
    //   <WidgetTextContainer>
    //     <span>
    //       Create a Widget using '<GreenSpan>+Add Dashboard</GreenSpan>' button
    //     </span>
    //   </WidgetTextContainer>
    //   <StatText>
    //     <span>This is where your statistics will appear</span>
    //   </StatText>
    //  </MainContainer>
  );
}

export default BlankState;
