import React from 'react';
// import { createStore } from 'global-store'

export function selectOptionsGenerator(options, trueValue) {
    let optionsArray = options?.length > 0 && options?.map((item, i) => {

        if (item.key === trueValue) {
            if (i === 0) {
                return (
                    <option key={i} value="" selected={true}>Select</option>
                )
            }
            else {
                return (
                    <option key={i} value={item.key} selected={true} >{item.value}</option>
                )
            }

        }
        else {
            if (i === 0) {
                return (
                    <option key={i} value="" selected={false} >Select</option>
                )
            }
            else {
                return (
                    <option key={i} value={item.key} selected={false} >{item.value}</option>
                )
            }
        }

    }, this);

    return optionsArray;
};

export function userOptionsGenerator(options, trueValue) {
    let optionsArray = options?.length > 0 && options?.map((item, i) => {

        if (item.value == trueValue) {

            if (i === 0) {
                return (
                    <option key={i} value="" selected={true} ></option>
                )
            }
            else {
                return (
                    <option key={i} value={item.value} selected={true} >{item.key}</option>
                )
            }

        }
        else {

            if (i === 0) {
                return (
                    <option key={i} value="" selected={false} ></option>
                )
            }
            else {
                return (
                    <option key={i} value={item.value} selected={false} >{item.key}</option>
                )
            }
        }

    }, this);

    return optionsArray;
};


export function interviewersOptionsGenerator(options, trueValue) {
    console.log('options', options, trueValue);
    let optionsArray = options.length > 0 && options.filter(item => item?.name != "RRC Admin").map((item, i) => {

        if (item.key === trueValue) {
            if (i === 0) {
                return (
                    <option key={i} value="" selected={true} >Select</option>
                )
            }
            else {
                return (
                    <option key={i} value={item.id} selected={true} >{item.name}</option>
                )
            }

        }
        else {
            if (i === 0) {
                return (
                    <option key={i} value="" selected={false} >Select</option>
                )
            }
            else {
                return (
                    <option key={i} value={item.id} selected={false} >{item.name}</option>
                )
            }
        }

    }, this);

    console.log("option array : ", optionsArray);

    return optionsArray;
};

export function multiSelectOptionGenerator(options) {
    let multiSelectOptions = options?.length > 0 && options?.map((item, i) => {

        var op = { value: item.key, label: item.value };

        return op;

    }, this);

    return multiSelectOptions;
}

export function newMultiSelectOptionGenerator(options) {
    let multiSelectOptions = options?.length > 0 && options?.map((item, i) => {

        var op = { value: item.value, label: item.key };

        return op;

    }, this);

    return multiSelectOptions;
}

export function createMultiselectDataToSend(selectedOptions) {
    //console.log("selected option : " , selectedOptions) ;
    var structuredResponse = [];

    if (selectedOptions) {
        if (selectedOptions.length !== 0) {
            // eslint-disable-next-line array-callback-return
            selectedOptions.map((val) => {
                structuredResponse.push(val.label);
            });
        }
    }
    return structuredResponse;
}

// export function createRadioGroupOptions (options)
// {
//     let optionsArray = options.length > 0  && options.map((item, i) => {

//         let

//     },this);
// }


