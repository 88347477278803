import React, { useState, useEffect } from "react";

import styled from "styled-components";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import CoverFilter from "./CoverFilter";

//import { sampleData } from "./ClientsStackBar";
const MainContainer = styled.div`
  margin-top: 16px;
  overflow: auto;
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
`;

const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  // width: 90px;
  min-width: 90px;
  height: 80px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #afafaf;
  border-top: 1px solid #afafaf;
  border-bottom: 1px solid #afafaf;
  text-align: center;
  background-color: #e9e9e9;
  width: 100%;
`;

const SourceName = styled(Header)`
  min-width: 210px;
  border-left: 1px solid #afafaf;
`;

const Total = styled(Header)`
  min-width: 120px;
  background-color: #fff;
`;

const TableDesc = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #afafaf;
  border-left: 1px solid #afafaf;
`;

const TitleContainer = styled.div`
  min-width: 209px;
  display: flex;
  height: fit-content;
`;

const SourceNameDescContianer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 210px;
  border-right: 1px solid #bbbbbb;
`;

const SourceNameTitle = styled.div`
  width: 109px;
  display: flex;
  align-items: center;
  height: initial;
  border-right: 1px solid #bbbbbb;
  background-color: #f9f9f9;
  padding-left: 10px;
`;

const SourceNameDesc = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #f0efe9;
  background-color: ${(props) =>
    props.index === 0 || props.index % 2 === 0 ? "#F9F9F9" : "#FDFCF5"};
  span {
    padding-left: 10px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SourceNameDescColumn = styled.div`
  display: flex;
`;

const ColumnVal = styled(Header)`
  height: 50px;
  background-color: ${(props) =>
    props.index === 0 || props.index % 2 === 0 ? "#F9F9F9" : "#FDFCF5"};
  border: none;
  border-right: 1px solid #afafaf;
  border-bottom: 1px solid #f0efe9;
  width: 98.06px;
`;

const RowTotal = styled(Total)`
  height: 50px;
  border: none;
  border-right: 1px solid #afafaf;
  border-bottom: 1px solid #f0efe9;
`;

function BjaTableContainer(props) {
  const getSampleData1 = props.genderData;
  const bjaHeader = props.bjaReportHeader;

  // console.log("header part");
  // console.log(getSampleData1);

  return (
    <MainContainer>
      {/* <TableHeader>
        <SourceName> Source Name </SourceName>
       {bjaHeader.map((e) => (
          <Header>{e.x} </Header>
         ))} 
          
         <Total>Total</Total>
        
      </TableHeader> */}
      {getSampleData1.map((e, index) => (
        <TableDesc key={e}>
          {/* <TitleContainer> */}
          {/* <SourceNameTitle>{e.source}</SourceNameTitle> */}
          {/* <SourceNameDescContianer>
              
                <SourceNameDesc index={index} key={index} >
                     <span>{e.source}</span>
                </SourceNameDesc>
               
            </SourceNameDescContianer> */}
          {/* </TitleContainer> */}
          {/* <ColumnContainer>
                <SourceNameDescColumn>
                   <ColumnVal style={{"font-size": "12px"}}>{e.GPRA_Client_ID}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Entry_Date}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.First_Name}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Last_Name}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Specific_Referral_Source}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Ref_Target}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Agency_Target}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Assigned_Peer_Support_Specialist}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Discharge}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.RRC_Participation_Type}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Received_MAT_Post_RRC}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Overdose_Post_RRC}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Follow_Up_Contacts}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.Days_Between_RRC_Referral_Treatment}</ColumnVal>
                    <ColumnVal style={{"font-size": "12px"}}>{e.duration}</ColumnVal>

                  <RowTotal>
                   {Math.ceil(e.total)} 
                </RowTotal>
                 </SourceNameDescColumn>
          </ColumnContainer> */}
        </TableDesc>
      ))}

      <div className="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr style={{ backgroundColor: "#e9e9e9" }}>
              <th scope="col" colspan="2">
                Source ID
              </th>
              {bjaHeader.map((e) => (
                // <Header> </Header>
                <th scope="col">{e.x}</th>
              ))}

              {/* <th scope="col">Last</th> */}
              <th scope="col" colspan="2">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {/* background-color: ${(props) => */}
            {/* // props.index === 0 || props.index % 2 === 0 ? "#F9F9F9" : "#FDFCF5"}; */}
            {getSampleData1.map((val, key) => (
              <tr style={{ backgroundColor: "#FDFCF5" }}>
                <td colspan="2">{val.source}</td>
                <td>{val.GPRA_Client_ID}</td>
                <td>{val.Entry_Date}</td>
                <td>{val.First_Name}</td>
                <td>{val.Last_Name}</td>
                <td>{val.Specific_Referral_Source}</td>
                <td>{val.Ref_Target}</td>
                <td>{val.Agency_Target}</td>

                <td>{val.Assigned_Peer_Support_Specialist}</td>
                <td>{val.Discharge}</td>
                <td>{val.RRC_Participation_Type}</td>
                <td>{val.Received_MAT_Post_RRC}</td>
                <td>{val.Overdose_Post_RRC}</td>
                <td>{val.Follow_Up_Contacts}</td>
                <td>{val.Days_Between_RRC_Referral_Treatment}</td>
                <td>{val.duration}</td>
                <td colspan="2">{val.total}</td>
              </tr>
            ))}

            {/* <tr style={{"backgroundColor": "#F9F9F9"}}>
      <td colspan="2">Demo1</td>
      <td>837257</td>
      <td>2021-04-04</td>
      <td>Wiley</td>
      <td>Clark</td>
      <td>Federal Probation"</td>
      <td>None</td>
      <td>None</td>
     
      <td>Danielle Sims</td>
      <td></td>
      <td>Discharged, less than 30 days</td>
      <td>No</td>
      <td></td>
      <td>1-2 contacts</td>
      <td></td>
      <td></td>
      <td colspan="2">0</td>
     

    </tr> */}
          </tbody>
        </table>
      </div>
    </MainContainer>
  );
}

export default BjaTableContainer;
