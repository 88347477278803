
// Calculation for readiness for change score
export const getReadinessScore = (Arr) => {
    let blankCount = 0
    let totalSum = 0;

    for (let val of Arr) {
        if (val || val === 0) {
            totalSum += val
        } else {

            blankCount++
        }
    }
    return { balnkCount: blankCount, totalSum: totalSum }
}


// For Calculating Maximum Number from an Array
export function getIndexOfMaxValueOfAnArray(Arr = []) {
    let MaxNumber = Number.NEGATIVE_INFINITY;
    let index = undefined;
    for (let i = 0; i < Arr.length; i++) {
        if (typeof (Arr[i]) == "number") {
            if (MaxNumber <= Arr[i]) {
                MaxNumber = Arr[i]
                index = i;
            }

        } else {

        }
    }
    return { index: index, maxval: MaxNumber }
}